import React, {useState, useCallback} from 'react';
import {
  View,
  StyleSheet,
  Image,
  FlatList,
  TouchableOpacity,
  ActivityIndicator,
} from 'react-native';
import Text from '../../components/Text';
import scale from '../../utils/scale';
import {countryFlag} from '../../configs/Data';
import Theme from '../../style/Theme';
import {Colors} from '../../configs';
import {TcodeArea} from '../../type/codeArea';
import useDimensions from '../../hooks/useDimensions';

interface ModalChangePhoneCodeProps {
  onChangeCode: (item: TcodeArea) => void;
  close: () => void;
  hidenCode?: boolean;
}

const ModalChangePhoneCode = (props: ModalChangePhoneCodeProps) => {
  const { isDesktop } = useDimensions();
  const {countries} = props;
  const renderItem = useCallback(
    ({item}) => {
      const onPress = () => {
        props.onChangeCode && props.onChangeCode(item);
      };
      return (
        <TouchableOpacity style={styles.item} onPress={onPress}>
          <Image source={countryFlag[item.code]} style={styles.flag} />
          {
            !props.hidenCode && (
              <Text
              size={15}
              lineHeight={24}
              color={Colors.DarkJungleGreen}
              style={styles.textCode}>
              {item.prefix_cell_phone}
              </Text>
            )
          }
          <Text size={15} lineHeight={24} color={Colors.DarkJungleGreen}>
            {item.name}
          </Text>
        </TouchableOpacity>
      );
    },
    [props.onChangeCode],
  );
  return countries && countries.length ? (
    <View style={styles.container}>
      {
        props.close && (
          <TouchableOpacity onPress={props.close}>
            <Text size={15} lineHeight={24} color={Colors.White}>Cerrar</Text>
          </TouchableOpacity>
        )
      }
      <FlatList
        data={countries}
        renderItem={renderItem}
        contentContainerStyle={{...styles.contentContainerStyle, ...isDesktop ? styles.contentContainerDesktopStyle : {}}}
        showsVerticalScrollIndicator={false}
        keyExtractor={item => item.id.toString()}
      />
    </View>
  ) : (
    <ActivityIndicator />
  );
};

export default ModalChangePhoneCode;

const styles = StyleSheet.create({
  container: {
    // paddingTop: 30,
    paddingHorizontal: 24,
    display: 'flex',
    flex: 1,
    margin: 'auto'
  },
  iconSearch: {
    width: 20,
    height: 20,
  },
  item: {
    ...Theme.flexRow,
    paddingVertical: 16,
    backgroundColor: '#fff',
    borderRadius: 10,
    padding: 10,
    marginVertical: 5,
  },
  flag: {
    width: 32,
    height: 20,
    marginRight: 16,
  },
  textCode: {
    width: 76,
  },
  contentContainerStyle: {
    paddingVertical: 18,
  },
  contentContainerDesktopStyle: {
    width: 320,
    alignSelf: 'center'
  }
});
