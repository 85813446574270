import {prescriptionId} from '../models/constants';
// import {EventRegister} from "react-native-event-listeners";
// import {EVENT_LOADED_COUNTRY} from '../constants/Events';

class PrescriptionService {
  constructor() {}

  set(country) {
    localStorage.setItem(prescriptionId, JSON.stringify(country));
  }

  clear() {
    localStorage.removeItem(prescriptionId);
  }

  get = () => {
    try {
      const prescriptionIdData = localStorage.getItem(prescriptionId);
      return prescriptionIdData;
    } catch (Err) {
      console.log(Err);
      return false;
    }
  };
}

export default PrescriptionService;
