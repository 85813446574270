import React, {useCallback, useEffect, useState} from "react";
import {useHistory, useParams, useLocation} from "react-router-dom";
import FullscreenLoading from "../../components/fullscreenLoading";
import {api, appHelpers, helpers} from '../../import';
import {showModalInfo} from "../../modules/ViewHelpers";
import locales from "../../locales";
import locales_es from "../../locales/es";
import Text from "../../components/Text";
import ButtonChangeCategory from "../../components/ButtonChangeCategory";
import InputApp from "../../components/InputApp";
import Content from "../../components/Layout/Content";
import {useTheme} from "../../configs/Theme";
import {Image, Modal, StyleSheet, View} from "react-native";
import Theme from "../../style/Theme";
import {getBottomSpace} from "react-native-iphone-x-helper";
import scale from "../../utils/scale";
import TranslationService from '../../locales';
import useModalWithKeyboard from "../../hooks/useModalWithKeyboard";
import ModalSlideBottom from "../../components/ModalSlideBottom";
import ModalSearchStrings from "../../components/ModalSearchStrings";
import {categoryList} from "../../type/category";
import ButtonLinear from "../../components/Buttons/ButtonLinear";
import iconNext from "../../images/ic_next.png";


// A custom hook that builds on useLocation to parse
// the query string for you.
function useQuery() {
  const {search} = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}


export default function EntityUpdater(props) {

  const {theme} = useTheme();
  const history = useHistory();
  const params = history.location;
  const translationService = new TranslationService();
  const [translations, setTranslations] = useState(null);

  const [loading, setLoading] = useState(true);

  const [invalidTokenMsg, setInvalidTokenMsg] = useState('');
  const [showWelcome, setShowWelcome] = useState(true);
  const [medic, setMedic] = useState(null);
  const [patientData, setPatientData] = useState(null);
  const [errors, setErrors] = useState([]);

  const [medicalInsuranceList, setMedicalInsuranceList] = useState(null);
  const [medicalInsurance, setMedicalInsurance] = useState(null);
  const [medicalInsurancePlan, setMedicalInsurancePlan] = useState('');
  const [medicalInsuranceNumber, setMedicalInsuranceNumber] = useState('');

  let {medicToken, patientToken} = useParams();
  let query = useQuery();
  const errorCode = query.get('error_code');

  const {
    open: openMedicalInsuranceModal,
    close: closeMedicalInsuranceModal,
    visible: visibleMedicalInsuranceModal,
    transY: transYMedicalInsuranceModal,
  } = useModalWithKeyboard();

  const medicalInsuranceNumberRef = React.createRef();

  const onChangeMedicalInsurance = useCallback((item: categoryList) => {
    setMedicalInsurance(item);
    closeMedicalInsuranceModal();
  }, [errors]);

  const onSend = () => {
    const _errors: any = [];
    if (
      medicalInsurance &&
      medicalInsurance.is_none !== undefined &&
      medicalInsurance.is_none === false &&
      !medicalInsuranceNumber
    ) {
      _errors.push('medicalInsuranceNumber');
      setErrors(_errors);
      showModalInfo(
        locales_es.infoModal.title,
        'Debe ingresar el número de afiliado del paciente, o seleccionar que no tiene cobertura',
      );
      return;
    }

    if (_errors.length) {
      setErrors(_errors);
      showModalInfo(
        locales_es.infoModal.title,
        locales_es.errorModal.checkData,
      );
    } else {
      setErrors(_errors);
      setLoading(true);
      const objData = {
        medical_insurance_id: medicalInsurance && medicalInsurance.id ? medicalInsurance.id : null,
        medical_insurance_plan: medicalInsurancePlan,
        medical_insurance_number: medicalInsuranceNumber,
      };
      api
        .putPatientsPublic(medicToken, patientToken, errorCode, objData)
        .then((res: any) => {
          showModalInfo(
            locales_es.successModal.title,
            'Datos guardados correctamente.',
          );
          setLoading(false);
        })
        .catch((err: any) => {
          showModalInfo(
            locales_es.errorModal.title,
            helpers.getErrorMsg(err),
          );
          setLoading(false);
        });
    }
  };

  useEffect(() => {
    // Oculta Intercom mediante CSS
    if (window && window.document) {
      window.document.body.classList.add('rcta-hide-intercom');
    }

    if (!medicToken || !patientToken || !errorCode) {
      alert('El enlace es inválido. Por favor, solicite uno nuevo.');
    } else {
      api
        .getPatientPublic(medicToken, patientToken, errorCode)
        .then((resPatientPublic: any) => {
          setMedic({
            name: resPatientPublic.data.medic_name,
            lastname: resPatientPublic.data.medic_lastname,
            full_profile_image: resPatientPublic.data.medic_full_profile_image,
            country_id: resPatientPublic.data.medic_country_id
          })
          setPatientData({
            name: resPatientPublic.data.name,
            lastname: resPatientPublic.data.lastname,
            country_id: resPatientPublic.data.country_id,
            medical_insurance_id: resPatientPublic.data.medical_insurance_id,
            medical_insurance_plan: resPatientPublic.data.medical_insurance_plan,
            medical_insurance_number: resPatientPublic.data.medical_insurance_number,
          })
          api.getMedicalInsurancesByCountryId(resPatientPublic.data.country_id).then(res => {
            const insurancesList = res.data;
            setMedicalInsuranceList(insurancesList);
            if (resPatientPublic.data && resPatientPublic.data.medical_insurance_id) {
              const insurance = insurancesList.filter(
                (i: any) =>
                  Number(i.id) === Number(resPatientPublic.data.medical_insurance_id),
              )[0];
              setMedicalInsurance(insurance);
            } else {
              setMedicalInsurance(res.data[0]);
            }
            setLoading(false);
          })
            .catch(err => {
              helpers.processError(err);
            });
          setMedicalInsurancePlan(
            resPatientPublic.data && resPatientPublic.data.medical_insurance_plan
              ? resPatientPublic.data.medical_insurance_plan
              : '',
          );
          setMedicalInsuranceNumber(
            resPatientPublic.data && resPatientPublic.data.medical_insurance_number
              ? resPatientPublic.data.medical_insurance_number
              : '',
          );
        }).catch(err => {
        setInvalidTokenMsg(helpers.getErrorMsg(err));
        setLoading(false);
      });
      translationService.translate().then(translation => {
        setTranslations(translation);
      });
    }
  }, [])

  return loading ? <FullscreenLoading/> : <>
    {invalidTokenMsg ?
      <div className="container d-flex justify-content-center align-items-center flex-column text-center pb-5 mb-5">
        <h4 className="mb-4 p-2 mt-5">{locales_es.errorModal.title}</h4>
        <p>{invalidTokenMsg}</p>
      </div>
      : showWelcome ?
      <div className="container d-flex justify-content-center align-items-center flex-column text-center pb-5 mb-5">
        <h4 className="mb-4 p-2 mt-5">Su Doctor Necesita Que Revise Sus Datos</h4>
        {medic?.full_profile_image &&
          <img className="masthead-avatar mb-5" width="50%" style={{maxWidth: '200px', borderRadius: '50%'}}
               src={medic?.full_profile_image} alt={`Dr/a. ${medic?.name} ${medic?.lastname}`}/>
        }
        <p>Dr/a. {medic?.name} {medic?.lastname} solicita que revise los siguientes datos para poder validar
          correctamente
          sus recetas médicas de manera más eficiente.</p>
        <button className="btn btn-primary"
                onClick={() => setShowWelcome(false)}
        >Revisar mis datos
        </button>
      </div>
      :
      <Content
        style={styles.container}
        showsVerticalScrollIndicator={false}
        extraHeight={100}
        extraScrollHeight={100}
        contentContainerStyle={styles.contentContainerStyle}>
        <Text bold={true} marginTop={30}>
          Paciente: {patientData.name} {patientData.lastname}
        </Text>
        <Text size={13} lineHeight={16} marginTop={24} marginBottom={6}>Cobertura Médica</Text>
        <ButtonChangeCategory
          testID="buttonMedicalInsurance"
          category={medicalInsurance}
          onPress={openMedicalInsuranceModal}
        />

        <InputApp
          testID="inputMedicalInsurancePlan"
          title={
            translations && translations.medicalInsurancePlan
              ? translations.medicalInsurancePlan
              : locales_es.medicalInsurancePlan
          }
          placeholder={
            translations && translations.medicalInsurancePlan
              ? translations.medicalInsurancePlan
              : locales_es.medicalInsurancePlan
          }
          marginTop={24}
          value={medicalInsurancePlan}
          onChangeText={setMedicalInsurancePlan}
          blurOnSubmit={true}
          autoCapitalize="words"
          returnKeyType="next"
          autoFocus={false}
          onSubmitEditing={() => {
            medicalInsuranceNumberRef.current.focus();
          }}
        />

        <InputApp
          testID="inputMedicalInsuranceNumber"
          forwardRef={medicalInsuranceNumberRef}
          title={
            translations && translations.medicalInsuranceNumber
              ? translations.medicalInsuranceNumber
              : locales_es.medicalInsuranceNumber
          }
          placeholder={
            translations && translations.medicalInsuranceNumber
              ? translations.medicalInsuranceNumber
              : locales_es.medicalInsuranceNumber
          }
          marginTop={24}
          returnKeyType="go"
          value={medicalInsuranceNumber}
          onChangeText={setMedicalInsuranceNumber}
          borderColor={
            errors.includes('medicalInsuranceNumber')
              ? theme.lineErrorColor
              : theme.innearColor
          }
          keyboardType={'numeric'}
        />
        <ButtonLinear
          testID="buttonAddPatient"
          white
          title={'Guardar Cambios'}
          children={
            <Image
              source={iconNext}
              style={styles.buttonChildren}
            />
          }
          onPress={onSend}
          style={styles.buttonLinear}
        />

        <Modal
          visible={visibleMedicalInsuranceModal}
          onRequestClose={closeMedicalInsuranceModal}
          transparent
          animationType={'none'}>
          <ModalSlideBottom
            onClose={closeMedicalInsuranceModal}
            transY={transYMedicalInsuranceModal}>
            <ModalSearchStrings
              data={medicalInsuranceList}
              onChangeCategory={onChangeMedicalInsurance}
              placeholder={locales_es.search + '...'}
            />
          </ModalSlideBottom>
        </Modal>
      </Content>
    }
  </>;
}

const styles = StyleSheet.create({
  header: {
    ...Theme.flexRowSpace,
  },
  container: {
    ...Theme.flexOne,
  },
  contentContainerStyle: {
    paddingHorizontal: 24,
    // paddingTop: 40,
    paddingBottom: getBottomSpace() + 160,
    margin: 'auto',
    maxWidth: 600,
    width: '100%',
  },
  buttonChildren: {
    ...Theme.icons,
    marginLeft: 8,
  },
  inputApp: {
    marginTop: 24,
  },
  name: {
    marginTop: scale(38),
  },
  homeAddress: {
    marginTop: 32,
  },
  buttonLinear: {
    marginTop: 52,
  },
  bottomFixedWrapper: {
    position: 'absolute',
    bottom: 0,
    zIndex: 100,
    width: '100%',
    paddingHorizontal: 24,
    paddingBottom: 52,
    backgroundColor: '#fff',
  },
  genders: {
    marginTop: 24,
    ...Theme.flexRow,
    ...Theme.center,
  },
  phoneView: {
    ...Theme.flexRow,
    marginTop: 4,
  },
  phoneNumber: {
    marginLeft: 8,
    flex: 1,
  },
});
