import {StyleSheet, Dimensions, Platform} from 'react-native';
import {getBottomSpace, getStatusBarHeight} from 'react-native-iphone-x-helper';
import Theme from '../style/Theme';

export const NAV_HEIGHT = 45;
export const TAB_HEIGHT = 50;
export const BOTTOM_BOX_HEIGHT = 50;

export const deviceHeight = Dimensions.get('window').height;
export const deviceWidth = Dimensions.get('window').width;

// Only for FindDoctors, FindHospital, Appointment screens
export const spaceHeight = deviceHeight - 375 - 45;

// Only for Intro screens
export const introSpaceHeight = deviceHeight - 364;

// Common gradient colors
export const blueGradient = {
  colors: ['rgb(75,102,234)', 'rgb(130,160,247)'],
  colorsStart: {x: 0.2, y: 0.4},
  colorsEnd: {x: 1.0, y: 1.0},
};

// CommonStyles
export const CommonStyles = StyleSheet.create({
  // GLOBALS
  alignSelfStart: {
    alignSelf: 'flex-start',
  },
  alignSelfCenter: {
    alignSelf: 'center',
  },
  alignItemsCenter: {
    alignItems: 'center',
  },
  justifyContentCenter: {
    justifyContent: 'center',
  },
  textAlignCenter: {
    textAlign: 'center',
  },
  textAlignRight: {
    textAlign: 'right',
  },
  textTransformUppercase: {
    textTransform: 'uppercase',
  },
  activityIndicator: {
    marginVertical: 30,
  },
  // end GLOBALS
  KeyboardAwareScrollView: {
    flex: 1,
    backgroundColor: '#ffffff',
  },
  mainContent: {
    zIndex: 900,
    top: NAV_HEIGHT,
  },
  mainContentAndroid: {
    zIndex: 900,
    top: NAV_HEIGHT * 2,
  },
  normalPage: {
    position: 'relative',
    flex: 1,
    backgroundColor: '#FFFFFF',
  },
  normalSinglePage: {
    flex: 1,
    backgroundColor: '#FFFFFF',
  },
  wrapperBox: {
    marginTop: 20,
    marginBottom: 20,
  },
  noTabScrollView: {
    marginTop: NAV_HEIGHT,
  },
  smallWrapperBox: {
    marginTop: 10,
    marginBottom: 10,
  },
  scrollViewFullHeight: {
    flex: 1,
  },
  scrollView: {
    marginTop: NAV_HEIGHT,
    marginBottom: TAB_HEIGHT,
  },
  scrollViewWithoutTabs: {
    marginTop: NAV_HEIGHT,
  },
  scrollViewWithBottomBox: {
    marginBottom: BOTTOM_BOX_HEIGHT * 2,
  },
  scrollViewWithBottomFab: {
    paddingBottom: BOTTOM_BOX_HEIGHT * 2,
  },
  fabButton: {
    position: 'absolute',
    bottom: 13,
    right: 8,
    zIndex: 1000,
  },
  fabButtonTop: {
    position: 'absolute',
    top: NAV_HEIGHT,
    right: 80,
    zIndex: 1000,
  },
  titleBox: {
    marginRight: 30,
    marginLeft: 30,
  },
  // Color
  whiteColor: {
    color: '#FFFFFF',
  },
  greyColor: {
    color: 'rgb(105,105,105)',
  },
  lightgreyColor: {
    color: 'rgb(150,150,150)',
  },
  blackColor: {
    color: 'rgb(19,19,19)',
  },
  softBlueColor: {
    color: 'rgb(75,102,234)',
  },
  redColor: {
    color: 'rgb(255,0,0)',
  },
  greenColor: {
    color: 'rgb(0,255,0)',
  },
  alternateGreenColor: {
    color: 'rgb(20, 201, 83)',
  },
  darkSkyBlueColor: {
    color: 'rgb(63,103,230)',
  },
  periBlueColor: {
    color: 'rgb(79,109,230)',
  },
  fullscreenCenter: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    alignContent: 'center',
  },
  // Text
  extraLargeText: {
    height: 52,
    fontSize: 32,
    fontFamily: 'Poppins-Bold',
  },
  extraLargeText2: {
    fontSize: 32,
    fontFamily: 'Poppins-Bold',
  },
  titleText: {
    fontSize: 30,
    lineHeight: 38,
  },
  header: {
    ...Theme.flexRowSpace,
    paddingTop: Platform.OS === 'ios' ? getStatusBarHeight() : 0,
    height: Platform.OS === 'ios' ? getStatusBarHeight() + 80 : 60,
    ...Theme.shadowHide,
    zIndex: 100,
  },
  headerText: {
    fontSize: 18,
    lineHeight: 30,
  },
  container: {
    paddingTop: 32,
    paddingHorizontal: 24,
    paddingBottom: getBottomSpace() + 12,
    ...Theme.flexOne,
  },
  itemHeaderText: {
    fontSize: 17,
    lineHeight: 29,
  },
  mediumText: {
    fontSize: 16,
  },
  normalText: {
    fontSize: 15,
    lineHeight: 23,
  },
  smallText: {
    fontSize: 13,
    lineHeight: 30,
  },
  shortSmallText: {
    fontSize: 13,
    lineHeight: 23,
  },
  light: {
    fontFamily: 'Poppins-Light',
  },
  regularBold: {
    fontFamily: 'Poppins-Regular',
  },
  mediumBold: {
    fontFamily: 'Poppins-Medium',
  },
  semiBold: {
    fontFamily: 'Poppins-SemiBold',
  },
  extraBold: {
    fontFamily: 'Poppins-Bold',
  },
  // Image
  borderRadius: {
    borderRadius: 200,
  },
  // Item box
  mainInnerPadding: {
    width: deviceWidth - 55,
    justifyContent: 'center',
    alignSelf: 'center',
  },
  itemWhiteBox: {
    marginTop: 10,
    marginBottom: 10,
    marginLeft: 15,
    marginRight: 15,
    borderRadius: 8,
    backgroundColor: '#FFFFFF',
    shadowColor: '#000',
    shadowOffset: {width: 0, height: 1},
    shadowOpacity: 0.5,
    shadowRadius: 2,
    elevation: 6,
    textAlign: 'center',
  },
  itemBox: {
    marginTop: 10,
    marginBottom: 10,
    marginLeft: 15,
    marginRight: 15,
    borderRadius: 8,
    textAlign: 'center',
  },
  // Form Styles
  textInputField: {
    flexDirection: 'row',
    width: deviceWidth - 55,
    marginBottom: 25,
    backgroundColor: '#FFFFFF',
  },
  textInput: {
    borderColor: 'rgb(229,229,229)',
    borderWidth: 1,
    borderStyle: 'solid',
    borderRadius: 50,
    paddingLeft: 25,
    backgroundColor: '#ffffff',
    width: deviceWidth - 55,
    height: 45,
    color: 'rgb(150,150,150)',
    fontSize: 16,
    fontFamily: 'Poppins-Regular',
  },
  textInputWithIcon: {
    paddingLeft: 50,
  },
  textInputDisabled: {
    borderColor: 'rgb(250,250,250)',
    borderWidth: 1,
    borderStyle: 'solid',
    borderRadius: 50,
    width: deviceWidth - 55,
    height: 45,
    paddingLeft: 50,
    color: 'rgb(220,220,220)',
    fontSize: 16,
    fontFamily: 'Poppins-Regular',
  },
  textInputError: {
    width: deviceWidth - 55,
    height: 45,
    color: 'rgb(150,150,150)',
    borderWidth: 2,
    borderRadius: 50,
    borderColor: 'rgb(255,0,0)',
    fontSize: 16,
    fontFamily: 'Poppins-Regular',
  },
  textAreaNoBorder: {
    borderWidth: 0,
  },
  textArea: {
    textAlignVertical: 'top',
    borderColor: 'rgb(229,229,229)',
    borderWidth: 1,
    borderStyle: 'solid',
    borderRadius: 10,
    paddingLeft: 25,
    paddingRight: 25,
    backgroundColor: '#ffffff',
    width: deviceWidth - 55,
    color: 'rgb(150,150,150)',
    fontSize: 16,
    fontFamily: 'Poppins-Regular',
  },
  textAreaError: {
    width: deviceWidth - 55,
    borderWidth: 2,
    color: 'rgb(150,150,150)',
    borderColor: 'rgb(255,0,0)',
  },
  selectboxField: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: deviceWidth - 55,
    height: 45,
    marginTop: 20,
    marginBottom: 20,
    paddingLeft: 20,
    paddingRight: 5,
    borderColor: 'rgb(229,229,229)',
    borderWidth: 1,
    borderStyle: 'solid',
    borderRadius: 50,
    backgroundColor: '#FFFFFF',
    color: 'rgb(60,60,60)',
    fontSize: 16,
    fontFamily: 'Poppins-Regular',
  },
  selectboxFieldError: {
    borderWidth: 2,
    borderStyle: 'solid',
    borderColor: 'rgb(255,0,0)',
  },
  selectboxLabel: {
    width: deviceWidth - 55,
    color: 'rgb(60,60,60)',
    fontSize: 16,
    fontFamily: 'Poppins-Regular',
    paddingRight: 20,
  },
  pickerView: {
    color: 'rgb(60,60,60)',
    marginTop: 10,
    alignSelf: 'center',
    borderColor: 'rgb(229,229,229)',
    borderWidth: 1,
    borderStyle: 'solid',
    borderRadius: 50,
    backgroundColor: '#ffffff',
    width: deviceWidth - 55,
    height: 45,
  },
  picker: {
    paddingTop: 10,
    paddingBottom: 10,
    paddingLeft: 15,
    paddingRight: 15,
    alignSelf: 'center',
    width: deviceWidth * 0.84,
    color: 'rgb(150,150,150)',
    fontSize: 16,
    fontFamily: 'Poppins-Regular',
  },
  // Button Styles
  backButton: {
    flex: 1,
    alignItems: 'center',
    width: 41,
    height: 41,
  },
  nextButton: {
    flex: 1,
    alignItems: 'center',
    width: 60,
    height: 60,
  },
  // Intro pages styles
  introPageImageBox: {
    alignItems: 'center',
    marginTop: introSpaceHeight * 0.22,
  },
  introPageTextBox: {
    alignItems: 'center',
    marginTop: introSpaceHeight * 0.12,
  },
  introPageSubText: {
    width: deviceWidth - 75,
    // height: 140,
    marginTop: 15,
    color: 'rgb(105,105,105)',
    fontSize: 15,
    fontFamily: 'Poppins-Regular',
    textAlign: 'center',
  },
  introPageButtonBox: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: introSpaceHeight * 0.27,
    marginBottom: 20,
  },
  signatureImage: {
    width: 100,
    height: 100,
  },
  swiperButtonBox: {
    alignItems: 'center',
    marginBottom: 30,
  },
  introPageButton: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    width: 60,
    height: 60,
  },
  // StartScreens
  labelField: {
    marginTop: 30 + NAV_HEIGHT,
    marginBottom: 25,
    paddingLeft: (deviceWidth - (deviceWidth - 55)) / 2,
  },
  pickerBox: {
    position: 'relative',
    height: 402,
    flexDirection: 'row',
    marginBottom: 15,
  },
  selectedOption: {
    position: 'absolute',
    top: deviceHeight / 2.6 + NAV_HEIGHT,
    width: deviceWidth,
    height: 75,
  },
  // Form Screens
  screenTitleBox: {
    height: 75,
    marginTop: spaceHeight * 0.3 + NAV_HEIGHT,
    marginBottom: spaceHeight * 0.15,
    paddingLeft: (deviceWidth - (deviceWidth - 55)) / 2,
  },
  orderServiceTitleBox: {
    paddingVertical: 16,
    paddingHorizontal: 20,
    borderColor: 'rgb(229,229,229)',
    borderBottomWidth: 1,
    borderStyle: 'solid',
  },
  buttonBox: {
    height: 45,
    alignItems: 'center',
  },
  buttonBoxMargin: {
    marginTop: 30,
  },
  buttonBoxDisabled: {
    opacity: 0.5,
  },
  footerButtonBoxContainer: {
    width: deviceWidth,
    padding: 10,
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    bottom: 10,
  },
  // Circle edit button
  editButton: {
    position: 'absolute',
    top: 25,
    right: 15,
    elevation: 12,
  },
  signInTitleBox: {
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 50,
    marginBottom: 48,
  },
  formBox: {
    alignItems: 'center',
  },
  noteBox: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'flex-end',
    marginBottom: 15,
  },
  chboxContainer: {
    flexDirection: 'row',
    width: deviceWidth * 0.84,
    height: deviceHeight * 0.1,
    alignItems: 'center',
    alignSelf: 'center',
  },
  chboxRemember: {
    width: 25,
    height: 25,
    borderRadius: 10,
  },
  chboxText: {
    color: '#fff',
    fontSize: 16,
    marginLeft: 10,
  },
  datePicker: {
    width: deviceWidth * 1,
  },
  datePickerLink: {
    color: '#0691ce',
    fontFamily: 'SFUIDisplay-Regular',
    fontSize: 15,
    width: deviceWidth * 1,
    textAlign: 'center',
    marginTop: 10,
    marginBottom: 10,
  },
});
