import React, {memo, useCallback} from 'react';
import {
  StyleSheet,
  TouchableOpacity,
  Image,
  ImageSourcePropType,
  ViewStyle,
  ColorValue,
} from 'react-native';
import Theme from '../../style/Theme';
import { useHistory } from "react-router-dom";
import {useTheme} from '../../configs/Theme';
import iconBack from '../../images/ic_back.png';

interface ButtonIconHeaderProps {
  onPress?: () => void;
  marginLeft?: number;
  marginRight?: number;
  icon?: ImageSourcePropType;
  tintColor?: string;
  borderColor?: ColorValue | string;
  style?: ViewStyle;
  styleIcon?: ViewStyle;
  backgroundColor?: string;
}

const ButtonIconHeader = memo((props: ButtonIconHeaderProps) => {
  const {theme} = useTheme();
  const history = useHistory();
  const onPress = useCallback(() => {
    if (props.onPress) {
      props.onPress();
    } else {
      history.goBack();
    }
  }, []);
  return (
    <TouchableOpacity
      style={[
        styles.backButton,
        props.style,
        {
          marginLeft: props.marginLeft ? props.marginLeft : 0,
          marginRight: props.marginRight ? props.marginRight : 0,
          borderColor: props.borderColor || theme.innearColor,
          backgroundColor: props.backgroundColor || theme.backgroundItem,
        },
      ]}
      onPress={onPress}>
      <Image
        source={props.icon || iconBack}
        style={[
          {width: 20, height: 20},
          {tintColor: props.tintColor || theme.activeTincolor},
          props.styleIcon,
        ]}
      />
    </TouchableOpacity>
  );
});

export default ButtonIconHeader;

const styles = StyleSheet.create({
  container: {},
  backButton: {
    width: 40,
    height: 40,
    borderRadius: 8,
    borderWidth: 1,
    ...Theme.center,
  },
});
