import React from "react";

const Overlay = (props) => {
  const overflowFlag = (typeof props.overflow === 'undefined') ? true : props.overflow;
  const nameClass = `position-absolute w-100 ${props.style && (props.style.height < 2000) ? 'overflow-auto' : 'overflow-hidden'} ${ props.style ? '': 'h-100'}`;
  console.log(nameClass)
  return (
    <div className={nameClass} id={props.id} style={{zIndex: 100, backgroundColor: 'rgb(250, 250, 250)', ...(props.style ?? {})}}>
      {props.children}
    </div>
  );
};

export default Overlay;
