import React, {Component} from "react";

export default class Header extends Component {

    render() {
        return (
          <header className="app-header">
              <div id="navbar1" className="app-navbar horizontal">
              <div className="navbar-wrap">

                <div className="app-logo d-inline-block ml-0">
                  <div className="logo-wrap pl-0">
                    <img src={this.props.clinicData.logo} alt={this.props.clinicData.name} className="logo-img"/>
                  </div>
                </div>

              </div>
            </div>
          </header>
        )
    }
}


