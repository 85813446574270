import React, {useState, useCallback, useEffect} from 'react';
import {View, StyleSheet, Image, ActivityIndicator} from 'react-native';
import Theme from '../../../style/Theme';
import scale from '../../../utils/scale';
import validationEmail from '../../../utils/validation/email';
import FullScreenLoading from '../../../components/fullscreenLoading';
import {useHistory} from 'react-router-dom';
import {Routes, Colors} from '../../../configs';
import TextInput from '../../../components/TextInput';
import Text from '../../../components/Text';
import ModalChangePhoneCode from '../../../components/SignUp/ModalChangePhoneCode';
import {TcodeArea} from '../../../type/codeArea';
import useModalWithKeyboard from '../../../hooks/useModalWithKeyboard';
import {getBottomSpace, getStatusBarHeight} from 'react-native-iphone-x-helper';
import InputApp from '../../../components/InputApp';
import ButtonChangeCode from '../../../components/ButtonChangeCode';
import ButtonLinear from '../../../components/Buttons/ButtonLinear';
import {useTheme} from '../../../configs/Theme';
import locales_es from '../../../locales/es';
import {CommonStyles} from '../../../styles/CommonStyles';
import APIService from '../../../modules/apiService';
import AuthService from '../../../modules/authService';
import Helpers from '../../../modules/helpers';
import CountryService from '../../../modules/CountryService';
import {
  EVENT_LOADED_COUNTRY,
  EVENT_SHOW_MODAL_INFO,
} from '../../../constants/Events';
import {
  validateEmptyFields,
  validateRepeatedInputs,
  InitForm, handleValidatorInputsTypes
} from '../../../utils/validation/forms';
import {SOURCE_ICON} from '../../../images';
import {EventRegister} from "../../../modules/EventRegister";
import iconAccept from "../../../images/ic_accept@3x.png";
import iconEyeOn from "../../../images/ic_eye_on@3x.png";
import RequireData from '../../../components/RequireData';
import RequireIndicator from '../../../components/RequireIndicator';
import useDimensions from '../../../hooks/useDimensions';
import TranslationService from '../../../locales';
import HeaderButton from '../../../components/HeaderButton';
import Content from '../../../components/Layout/Content';
import ButtonChangeCategory from '../../../components/ButtonChangeCategory';

interface IProps {
  countryIn?: string;
}

function SignUpForm({countryIn}: IProps) {
  const { isDesktop } = useDimensions();
  const api = new APIService();
  const auth = new AuthService();
  const helpers = new Helpers();
  const countryService = new CountryService();
  const translationService = new TranslationService();
  const [translations, setTranslations] = useState(null);
  const [load, setLoad] = useState(false);
  const [loading, setLoading] = useState(false);
  const [country, setCountry] = useState(false);
  const [countries, setCountries] = useState([]);
  const [errors, setErrors] = useState([]);
  const [email, setEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [password, setPassword] = useState('');
  const [passwordRepeat, setPasswordRepeat] = useState('');
  const [termsChecked, setTermsChecked] = useState(false);
  const [termsPage, setTermsPage] = useState('');
  const [privacyPage, setPrivacyPage] = useState('');
  const [visiblePassword, setVisiblePassword] = useState(false);
  const [isValidEmail, setIsValidEmail] = useState(false);
  const [codeArea, setCodeArea] = useState(null);
  const {visible, open, close} = useModalWithKeyboard();
  const {theme} = useTheme();
  const passwordRef = React.createRef();
  const passwordRepeatRef = React.createRef();
  const phoneNumberRef = React.createRef();
  const [searchQueryParams, setSearchQueryParams] = useState<any>(null);
  const [errorMessages, setErrorMessages] = useState({
    email: '',
    password: '',
    passwordRepeat: '',
    phoneNumber: '',
  });
  const [enableButton, setEnableButton] = useState(true);

  const [updateRegister, setUpdateRegister] = useState(true);
  const [postRegister, setPostRegister] = useState(false);

  const history = useHistory();

  const onShowHidePassword = useCallback(() => {
    setVisiblePassword(prev => !prev);
  }, []);

  const successLoginRedirect = (res: any) => {
    if (isDesktop) {
      auth.loginFromRegister(res);
    }
    history.replace(`/${Routes.SignUpSuccessful}`, {index: 0});
  };

  const getIOSInputEventHandlers = () => {
    if (!helpers.isIOS()) {
      return {};
    }
  
    return {
      onTouchStart: e => {
        e.currentTarget.style.fontSize = "16px";
      },
      onBlur: e => {
        e.currentTarget.style.fontSize = "";
      }
    };
  }

  const onSignUp = () => {
    const errors = validateEmptyFields({
      email,
      password,
      passwordRepeat,
      phoneNumber,
      termsChecked
    });
    if (errors.length) {
      setErrors(errors);
    } else if (!validationEmail(email)) {
      errors.push('email');
      EventRegister.emit(EVENT_SHOW_MODAL_INFO, {
        title: locales_es.infoModal.title,
        message: locales_es.register.errorEmail,
      });
    } else if (!validateRepeatedInputs(password, passwordRepeat)) {
      errors.push('passwordRepeat');
      errorMessages.passwordRepeat = locales_es.register.errorPasswordRepeatNotMatched
      EventRegister.emit(EVENT_SHOW_MODAL_INFO, {
        title: locales_es.infoModal.checkData,
        message: locales_es.register.errorPasswordRepeatNotMatched
      });
      setErrors(errors);
      setTimeout(() => {
        setErrors(errors.filter((item:any) => item !== 'passwordRepeat'));
      }, 5000);
    } else {
      setLoading(true);
      countryService.getData().then((res: any) => {
        const objData: any = {
          country_id: res && res.id ? res.id : codeArea.id,
          prefix_cell_phone: codeArea.prefix_cell_phone,
          user_type: 'medic',
          email,
          password,
          password_confirmation: passwordRepeat,
          cell_phone: phoneNumber,
        };
        if (countryIn) {
          for (const [key, value] of searchQueryParams.entries()) {
            objData[key] = value;
          }
        }
        auth
          .register(objData, (countryIn) ? false : true)
          .then((res: any) => {
            setLoading(false);
            successLoginRedirect(res);
          })
          .catch(err => {
            setLoading(false);
            EventRegister.emit(EVENT_SHOW_MODAL_INFO, {
              title: locales_es.infoModal.title,
              message: helpers.getErrorMsg(err),
            });
          });
      });
    }
  };

  const onTermOfUse = useCallback(() => {
    goToWebviewLoaderTerms();
  }, [termsPage]);

  const onPrivacyPolicy = useCallback(() => {
    goToWebviewLoaderPrivacy();
  }, [privacyPage]);
  
  const onGoToLogin = useCallback(() => {
    history.push(`/${Routes.Login}`);
  }, [history]);

  const onChangeCode = useCallback((item: TcodeArea) => {
    setCodeArea(item);
    close();
  }, []);

  const onBlur = (inputStr: string) => {
    setErrors(errors.filter(item => item !== inputStr));
  };

  const setTermsAndPrivacyLinks = (countryId: number) => {
    let termsPage = 'https://rcta.me';
    let privacyPage = 'https://rcta.me';
    countries.map((c: any) => {
      if (c.id === countryId) {
        termsPage = c.terms_and_conditions_url;
        privacyPage = c.privacy_policies_url;
      }
    });
    setTermsPage(termsPage);
    setPrivacyPage(privacyPage);
  };

  const goToWebviewLoaderTerms = () => {
    // history.push(`/${Routes.WebviewLoader}`, {uri: termsPage});
    window.open(termsPage, '_blank');
  };

  const goToWebviewLoaderPrivacy = () => {
    window.open(privacyPage, '_blank');
  };

  const getCountries = () => {
    api
      .getCountries()
      .then((res: any) => {
        setCountries(res.data);
        let countries = res.data;
        countryService.getData().then((res: any) => {
          setCodeArea(res);
          veryPostRegister(countries);
        });
      })
      .catch((err: any) => {
        EventRegister.emit(EVENT_SHOW_MODAL_INFO, {
          title: locales_es.errorModal.title,
          message: helpers.getErrorMsg(err),
        });
      });
  }

  useEffect(() => {
    getCountries();
  }, [load]);

  useEffect(() => {
    translationService.translate().then(translation => {
      setTranslations(translation);
    });
  }, [codeArea]);

  useEffect(() => {
    setIsValidEmail(validationEmail(email));
  }, [email]);

  useEffect(() => {
    if (codeArea && codeArea.id && countries && countries.length) {
      setTermsAndPrivacyLinks(codeArea.id);
    }
  }, [countries, codeArea]);

  const loadCountry = () => {
    countryService
      .getData()
      .then((res: any) => {
        setCountry(res);
      })
      .catch((err: any) => {
        console.log(err);
        window.location.href = `/${Routes.CountrySelector}`;
      });
  };

  const loadCountryByCode = (code: string) => {
    countryService
      .getByCode(code)
      .then((res: any) => {
        setCountry(res);
        getCountries();
      })
      .catch((err: any) => {
        console.log(err);
        window.location.href = `/${Routes.CountrySelector}`;
      });
  }

  useEffect(() => {
    if (!countryIn) {
      loadCountry();
    } else {
      setSearchQueryParams(new URLSearchParams(window.location.search))
      loadCountryByCode(countryIn);
    }
  }, [history]);

  useEffect(() => {
    const listener = EventRegister.addEventListener(
      EVENT_LOADED_COUNTRY,
      data => {
        loadCountry();
      },
    );

    return function cleanup() {
      EventRegister.removeEventListener(listener);
    };
  });

  const onGetHere = useCallback(() => {
    history.push(`/${Routes.CountrySelector}`);
  }, [history]);

  const initValues = {
    email,
    password,
    passwordRepeat,
    phoneNumber,
    termsChecked
  }

  const initForm: InitForm = {
    setErrors,
    errors,
    setErrorMessages,
    errorMessages,
    setEnableButton,
    initValues,
  }

  const veryPostRegister = (countries:any) => {
    let res =auth.getTemporalDataPostRegister()
    if(res){
      setEmail(res.email);
      setPassword(res.password);
      setPasswordRepeat(res.password_confirmation);
      setPhoneNumber(res.cell_phone);
      setCodeArea(countries.filter((item:any) => item.prefix_cell_phone === res.prefix_cell_phone)[0]);
      setNewCountry(countries.filter((item:any) => item.id === res.country_id)[0]);
      setEnableButton(false);
      setPostRegister(true);
      setUpdateRegister(true);
      setTermsChecked(true);
    }
    setUpdateRegister(false);
  };

  const onUpdateData = () => {
    const errors = validateEmptyFields({
      email,
      password,
      passwordRepeat,
      phoneNumber,
    });
    if (errors.length) {
      setErrors(errors);
    } else if (!validationEmail(email)) {
      errors.push('email');
      EventRegister.emit(EVENT_SHOW_MODAL_INFO, {
        title: locales_es.infoModal.title,
        message: locales_es.register.errorEmail,
      });
    } else if (!validateRepeatedInputs(password, passwordRepeat)) {
      errors.push('passwordRepeat');
      errorMessages.passwordRepeat = locales_es.register.errorPasswordRepeatNotMatched
      EventRegister.emit(EVENT_SHOW_MODAL_INFO, {
        title: locales_es.infoModal.checkData,
        message: locales_es.register.errorPasswordRepeatNotMatched
      });
      setErrors(errors);
      setTimeout(() => {
        setErrors(errors.filter((item:any) => item !== 'passwordRepeat'));
      }, 5000);
    } else {
      const objData = {
        prefix_cell_phone: codeArea.prefix_cell_phone,
        email,
        password,
        password_confirmation: passwordRepeat,
        cell_phone: phoneNumber,
        country_id: newCountry.id,
        country: newCountry
      };
      setLoading(true);
      auth.updateRegisterData(objData).then((res: any) => {
        if(res.status){
          setLoading(false);
          goBack();
        }
      }).catch((err: any )=> {
        console.log(err);
        setLoading(false);
        EventRegister.emit(EVENT_SHOW_MODAL_INFO, {
          title: locales_es.infoModal.title,
          message: helpers.getErrorMsg(err),
        });
      });
    }
  }

  const goBack = () => {
    history.replace(`/${Routes.SignUpSuccessful}`, {index: 0});
  }

  const {
    open: openCountriesModal,
    close: closeCountriesModal,
    visible: visibleCountriesModal,
  } = useModalWithKeyboard();

  const [newCountry, setNewCountry] = useState<categoryList>({
    id: 0,
    name: 'Cambiar de país',
  });

  const onChangeCountry = (item: categoryList) => {
    setNewCountry(item);
    closeCountriesModal();
  };

  const handleTerms = () => {
    setTermsChecked(!termsChecked);
  };

  const maxLengthEmail = (value: string) => {
    if (value.length >= 75){
      setErrors([...errors, 'email']);
      setErrorMessages({...errorMessages, email: "El E-mail no puede contener más de 75 caracteres."})
      setEnableButton(true)
      return
    }
  }
  
  return (
    <>
      {loading && <FullScreenLoading/>}
      {
        !updateRegister && (
          <Content
            style={styles.container}
            showsVerticalScrollIndicator={false}
            contentContainerStyle={{
              paddingHorizontal: 24,
              paddingBottom: !postRegister ? getBottomSpace() + 32: 32,
              paddingTop: !postRegister  ? getStatusBarHeight() + 22: 22,
            }}>
            <HeaderButton onPress={() => window.location.href = (`/${Routes.CountrySelector}`)}/>
            {
          !postRegister ? (
      <>
              <Text size={24} lineHeight={28} bold marginTop={scale(10)}>
              {translations?.register && translations?.register.welcome
                ? translations?.register.welcome
                : locales_es.register.welcome}
            </Text>
            <Text
              size={13}
              lineHeight={22}
              marginTop={16}
              marginBottom={scale(5)}
              // color={Colors.DarkJungleGreen}
            >
              {translations?.register && translations?.register.intro
                ? translations?.register.intro
                : locales_es.register.intro}
            </Text>
            </>
          ): (<Text size={24} lineHeight={28} bold marginTop={scale(40)}>
          Actualizar Datos
        </Text>)}
            
            <RequireData/>
        <InputApp
              marginTop={scale(5)}
          borderColor={
            errors.includes('email') ? theme.lineErrorColor : theme.innearColor
          }
          title={locales_es.email}
          placeholder={locales_es.email}
          value={email}
              onChangeText={(event:any) =>{ handleValidatorInputsTypes(initForm,event,'email',setEmail,'email',8);maxLengthEmail(event);}}
          icon={
            !email ? null : isValidEmail ? (
              <Image
                source={iconAccept}
                style={CommonStyles.icon}
              />
            ) : null
          }
          // isShowIcon={isValidEmail}
          isShowIcon={true}
          keyboardType="email-address"
          blurOnSubmit={true}
          autoCapitalize="none"
          returnKeyType="next"
          autoFocus={false}
          onSubmitEditing={() => {
            passwordRef.current.focus();
          }}
              maxLength={75}
              showRequired = {true}
              {...getIOSInputEventHandlers()}
        />
        {
          errors.includes('email') && ( <Text size={13} lineHeight={16} marginTop={4} color={Colors.Red}>{errorMessages.email}</Text> )
        }
        <InputApp
          borderColor={
            errors.includes('password')
              ? theme.lineErrorColor
              : theme.innearColor
          }
          forwardRef={passwordRef}
          title={locales_es.password}
          placeholder={locales_es.password}
          label="Al menos 8 caracteres, letras mayúsculas y minúsculas, y al menos un caracter especial (por ejemplo '!')"
          value={password}
          onChangeText={(event:any) => handleValidatorInputsTypes(initForm,event,'password',setPassword,'password',8)}
          secureTextEntry={!visiblePassword}
          marginTop={24}
          returnKeyType="next"
          autoCapitalize="none"
          isShowIcon
          icon={
            <Image
              source={iconEyeOn}
              style={Theme.icons}
            />
          }
          iconPress={onShowHidePassword}
          onSubmitEditing={() => {
            passwordRepeatRef.current.focus();
          }}
          maxLength={16}
          showRequired = {true}
          {...getIOSInputEventHandlers()}
        />
            {
              errors.includes('password') && ( <Text size={13} lineHeight={16} marginTop={4} color={Colors.Red}>{errorMessages.password}</Text> )
            }
        <InputApp
          borderColor={
            errors.includes('passwordRepeat')
              ? theme.lineErrorColor
              : theme.innearColor
          }
          forwardRef={passwordRepeatRef}
          title={locales_es.repeatPassword}
          placeholder={locales_es.repeatPassword}
          value={passwordRepeat}
              onChangeText={(event:any) => handleValidatorInputsTypes(initForm,event,'password',setPasswordRepeat,'passwordRepeat',8)}
          secureTextEntry={!visiblePassword}
          marginTop={24}
          returnKeyType="next"
          autoCapitalize="none"
          icon={
            <Image
              source={iconEyeOn}
              style={Theme.icons}
            />
          }
          isShowIcon
          iconPress={onShowHidePassword}
          onSubmitEditing={() => {
            phoneNumberRef.current.focus();
          }}
              maxLength={16}
              showRequired = {true}
              {...getIOSInputEventHandlers()}
        />
            {
              errors.includes('passwordRepeat') && ( <Text size={13} lineHeight={16} marginTop={4} color={Colors.Red}>{errorMessages.passwordRepeat}</Text> )
            }
            <Text size={13} lineHeight={16} marginTop={24}>
              {translations && translations.cellphone
                ? translations.cellphone
                : <>Teléfono Móvil <RequireIndicator /> </>}
        </Text>
            <View>
          <View style={styles.phoneView}>
            {codeArea ? (
              <ButtonChangeCode codeArea={codeArea} onPress={open}/>
            ) : (
              <ActivityIndicator/>
            )}
            <TextInput
              borderColor={
                errors.includes('phoneNumber')
                  ? theme.lineErrorColor
                  : theme.innearColor
              }
              // onBlur={() => onBlur('phoneNumber')}
              editable={true}
              forwardRef={phoneNumberRef}
              value={phoneNumber}
                  placeholder={
                    translations && translations.cellphone
                      ? translations.cellphone
                      : locales_es.phoneNumber
                  }
                  onChangeText={(event:any) => handleValidatorInputsTypes(initForm,event,'number',setPhoneNumber,'phoneNumber',6)}
              style={styles.phoneNumber}
              keyboardType="numeric"
              maxLength={12}
              returnKeyType="go"
              {...getIOSInputEventHandlers()}
            />
          </View>
          {
            errors.includes('phoneNumber') && ( <Text size={13} lineHeight={16} marginTop={4} color={Colors.Red}>{errorMessages.phoneNumber}</Text> )
          }
          <Text type="H6" style={styles.phoneDescription}>
            No se comparte este dato con nadie ni se hace público. Se solicita
            el nro. de teléfono solamente para poder estar en contacto ante
            cualquier necesidad o soporte que debamos hacerle.
          </Text>
        </View>
            {
              postRegister && (
                <>
                <Text size={13} lineHeight={16} marginTop={24} marginBottom={6} semibold>
                  Cambiar de país
                </Text>
                <ButtonChangeCategory 
                  category={newCountry}
                  onPress={openCountriesModal}
                  marginTop={10}
                  img={newCountry.code}
                />
                </>
              )
            }
            <div style={{
                display: 'flex',
                marginTop: 20,
                marginLeft: 10,
                marginRight: 10,
                marginBottom: 10,
                background: 'transparent',
                alignItems: 'center'
              }}>
              <input id="inputCheckTerms" 
                style={{width: 20, height: 20}} 
                className="form-control"
                checked={termsChecked}
                onChange={(event:any) => handleValidatorInputsTypes(initForm,termsChecked,'boolean',setTermsChecked,'termsChecked')}
                required={true} type="checkbox"/>
              <Text
                style={{ width: '100%' }}
                  type="P6"
                  // color={Colors.DarkJungleGreen}
                  center>
                  {translations?.register
                    ? translations?.register.terms
                    : locales_es.register.terms}
                  {'\n'}
                <Text
                  blueLight
                  type="P6"
                  // color={Colors.BlueCrayola}
                  onPress={onTermOfUse}
                  center>
                  Términos y Condiciones
                </Text>{' '}
                y las{' '}
                <Text
                  blueLight
                  type="P6"
                  // color={Colors.BlueCrayola}
                  onPress={onPrivacyPolicy}
                  center>
                  Políticas de Privacidad
                </Text>
              </Text>
            </div>
            <ButtonLinear
              white
              title={!postRegister ? locales_es.signUp : 'Actualizar Datos'}
              onPress={!postRegister ? onSignUp : onUpdateData}
              disabled={enableButton}
            />
            {
              postRegister && (
                <ButtonLinear
                  white
                  title={'Cancelar'}
                  onPress={goBack}
                />
              )
            }
            {
              !postRegister && (
                <>
                  
        {country && !countryIn && (
          <View style={styles.changeApp}>
            <Image
              source={SOURCE_ICON[`${country.code}`]}
              style={{
                height: 20,
                width: 20,
                margin: 10,
              }}
              resizeMode="contain"
            />
            <Text white type="H6" color={Colors.White}>
              <Text
                type="H6"
                color={Colors.Black}
                style={{textDecorationLine: 'underline'}}
                onPress={onGetHere}>
                Cambiar de país
              </Text>
            </Text>
          </View>
        )}
        <View style={styles.bottom}>
          <Text type="H6" color={Colors.GrayBlue}>
            {translations?.register
              ? translations?.register.signup
              : locales_es.register.signup}{' '}
            <Text
              type="H6"
              color={Colors.BlueCrayola}
              semibold
              onPress={onGoToLogin}>
              {locales_es.login}
            </Text>
          </Text>
        </View>
                </>
              )
            }
          </Content>
        )
      }
      
      {
        updateRegister && (<FullScreenLoading />)
      }
        {visible &&
          <div style={styles.modal}>
            <ModalChangePhoneCode
              onChangeCode={onChangeCode}
              countries={countries}
              close={close}
            />
          </div>
        }
      {visibleCountriesModal &&
        <div style={styles.modal}>
          <ModalChangePhoneCode
            onChangeCode={onChangeCountry}
            countries={countries}
            close={closeCountriesModal}
            hidenCode={true}
          />
        </div>
      }
    </>
  );
}

export default SignUpForm;

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  password: {
    marginTop: 24,
  },
  phoneNumber: {
    marginLeft: 8,
    flex: 1,
  },
  emailInput: {
    marginTop: scale(34),
  },
  bottom: {
    paddingTop: scale(20),
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  flag: {
    width: 32,
    height: 20,
  },
  changePhoneCode: {
    position: 'absolute',
    right: 16,
    alignSelf: 'center',
  },
  phoneView: {
    ...Theme.flexRow,
    marginTop: 4,
  },
  phoneDescription: {
    marginTop: 4,
  },
  changeApp: {
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
    // position: 'absolute',
    // bottom: 16 + getBottomSpace(),
    paddingTop: scale(20),
    alignSelf: 'center',
  },
  modal: {
    position: 'absolute',
    backgroundColor: 'rgba(0,0,0,.5)',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    display: 'flex',
  },
  modalDesktop: {
    position: "fixed"
  }
});
