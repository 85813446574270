import React, {memo, useCallback, useEffect, useState} from 'react';
import {StyleSheet, Image} from 'react-native';
import Text from '../../components/Text';
import Theme from '../../style/Theme';
import {Routes, Colors} from '../../configs';
import scale from '../../utils/scale';
import ButtonLinear from '../../components/Buttons/ButtonLinear';
import {useHistory} from 'react-router-dom';
import Container from '../../components/Layout/Container';
import TranslationService from '../../locales';
import {auth} from '../../import';
import locales_es from '../../locales/es';
import iconSuccess from '../../images/img_success_1@3x.png';
import { userTemporalDataPostRegister } from '../../models/constants';

interface SignUpSuccessfulProps {}

const SignUpSuccessful = memo((props: SignUpSuccessfulProps) => {
  const history = useHistory();
  const translationService = new TranslationService();
  const [translations, setTranslations] = useState(null);
  const [countryId, setCountryId] = useState(null);
  const [postRegister, setPostRegister] = useState(false);

  const onPressFillProfile = useCallback(() => {
    history.push(`/${Routes.BasicInformation}`, {hasSignup: true});
  }, [history]);

  const onPressModifyData = useCallback(() => {
    history.replace(`/${Routes.SignUp}`, {index: 0});
  }, [history]);

  useEffect(() => {
    auth.getLocalUserData().then((res: any) => {
      setCountryId(res.user.country_id);
    });
  }, [countryId]);

  useEffect(() => {
    verifyPostRegister();
    translationService.translate().then(translation => {
      setTranslations(translation);
    });
  }, []);

  const verifyPostRegister = async () => {
    const postRegister = sessionStorage.getItem(userTemporalDataPostRegister);
    if (postRegister) {
      setPostRegister(true);
    }
  }

  return (
    <Container style={styles.container}>
      <Image
        source={iconSuccess}
        style={styles.successImage}
      />
      <Text size={20} lineHeight={24} bold>
        {translations?.register
          ? translations.register.welcome
          : locales_es.register.welcome}
      </Text>
      <Text size={15} lineHeight={24} center marginTop={16}>
        {translations?.register
          ? translations?.register.successIntro
          : locales_es.register.successIntro}
      </Text>
      <ButtonLinear
        white
        title={'Completar mi Perfil'}
        onPress={onPressFillProfile}
        style={styles.buttonLinear}
      />
      {
        postRegister && (
          <Text type="H4" color={Colors.BlueCrayola} marginTop={scale(5)} onPress={onPressModifyData} >
              Ir un paso atrás
          </Text>
        )
      }
    </Container>
  );
});

export default SignUpSuccessful;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    ...Theme.center,
    paddingHorizontal: 24,
  },
  buttonLinear: {
    paddingHorizontal: 32,
    marginTop: 32,
  },
  successImage: {
    width: scale(160, true),
    height: scale(160, true),
    marginBottom: scale(55, true),
  },
});
