import React, {Component} from 'react';
import {View, Text} from 'react-native';

import locales_es from './../../locales/es.js';
import {CommonStyles} from '../../styles/CommonStyles';
import * as Progress from 'react-native-progress';
import Metrics from '../../Themes/Metrics';
import Colors from '../../configs/Colors';

export default class FullscreenProgress extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const {progress} = this.props;

    return (
      <View
        style={[
          {
            flex: 1,
            alignItems: 'center',
            justifyContent: 'center',
            position: 'absolute',
            top: 0,
            right: 0,
            left: 0,
            bottom: 0,
            zIndex: 1000,
            flexDirection: 'column',
            backgroundColor: 'rgba(255,255,255,0.9)',
            minHeight: 'auto'
          },
        ]}>
        <div
          className="rcta-progress-circle"
          style={{
            height: '30%',
            width: '90%',
            justifyContent: 'center',
            alignItems: 'center',
            display: 'flex',
          }}>
          <Progress.Circle
            size={Metrics.WIDTH * 0.4}
            progress={progress}
            color={Colors.TealBlue}
          />
        </div>
        <Text
          style={[
            {
              color: '#000',
              backgroundColor: 'transparent',
              textAlign: 'center',
              marginTop: 10,
              fontSize: 26,
              width: '80%',
            },
            CommonStyles.alignSelfCenter,
          ]}>
          {this.props.text || locales_es.successModal.title}
        </Text>
      </View>
    );
  }
}
