import React, {Component} from 'react';
import "../../../node_modules/react-datepicker/dist/react-datepicker.css";
import {langCode} from '../../locales/es';
import DatePicker from "react-datepicker";
import { registerLocale } from "react-datepicker";
import es from 'date-fns/locale/es';
import IMask from 'imask';

registerLocale(langCode, es);

const mask = 'dd/MM/yyyy';

export default class FormDatePicker extends Component {
     constructor(props) {
        super(props);

        this.datePickerRef = React.createRef();
        this.mask = null;
    }
     componentDidMount() {
        this.mask = IMask(this.datePickerRef.current.input, {
            mask: Date,
            pattern: 'd/m/`Y',
            format: function(date) {
                let day = date.getDate();
                let month = date.getMonth() + 1;
                let year = date.getFullYear();

                if(day < 10) {
                    day = '0' + day;
                }
                if(month < 10) {
                    month = '0' + month;
                }

                return [day, month, year].join('/');
            },
            parse: function(str) {
                const dayMonthYear = str.split('/');
                return new Date(
                    dayMonthYear[2],
                    dayMonthYear[1] - 1,
                    dayMonthYear[0]
                );
            },
            // max: new Date()
        });
    }
    render() {
        const {id, minDate, maxDate, value, required, disabled, placeholder, handleChange, customClassName, wrapperCustomClassName, hideCalendar, withPortal, portalId, excludeDates} = this.props;
        // const defaultClassName = 'form-control datePicker rounded';
        const defaultClassName = 'new-form-control rounded';
        const className = customClassName ? (defaultClassName + customClassName) : defaultClassName;

        const defaultWrapperClassName = 'form-group ';
        const wrapperClassName = wrapperCustomClassName ? (defaultWrapperClassName + wrapperCustomClassName) : defaultWrapperClassName;

        return (
            <div className={wrapperClassName}>
              {this.props.label && <label>{this.props.label}</label>}
                <div className="input-group date">
                    <DatePicker
                        calendarClassName={hideCalendar ? "d-none" : null}
                        className={className}
                        key={id}
                        id={id}
                        locale={langCode}
                        defaultValue={value}
                        selected={value}
                        onChange={handleChange}
                        disabled={disabled}
                        dateFormat={mask}
                        required={required}
                        placeholderText={placeholder}
                        peekNextMonth
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode="select"
                        maxDate={maxDate}
                        minDate={minDate}
                        autoComplete="off"
                        withPortal={withPortal}
                        portalId={portalId}
                        ref={this.datePickerRef}
                        excludeDates={excludeDates}
                    />
                </div>
            </div>
        );
    }
}
