import React, {Component} from 'react';
import {View, Image, Text, ActivityIndicator} from 'react-native';

import LinearGradient from 'react-native-linear-gradient';

// Images
import styles from './styles';
import Colors from '../../configs/Colors';
export default class Loading extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const opacity = '0.5';
    return (
      <LinearGradient
        locations={[0.15, 1]}
        colors={[
          `rgba(255, 255, 255, ${opacity})`,
          `rgba(255, 255, 255, ${opacity})`,
        ]}
        style={styles.loadingScreen}>
        <View style={{flex: 1, alignItems: 'center', justifyContent: 'center'}}>
          <ActivityIndicator color={Colors.TurquoiseBlue} />
        </View>
      </LinearGradient>
    );
  }
}
