import React, { memo, useState, useEffect, useCallback } from "react";
import {
  StyleSheet,
  ActivityIndicator,
  View,
  Image,
  TouchableOpacity,
} from "react-native";
import { Link, useHistory } from "react-router-dom";
import { getStatusBarHeight } from "react-native-iphone-x-helper";
import { RWebShare } from "react-web-share";
import locales_es from "../../../locales/es";
import Text from "../../../components/Text";
import Card from "../../../components/Card";
import LinearColors from "../../../components/LinearColors";
import { SOURCE_ICON } from "../../../images";
import { Colors, Routes } from "../../../configs";
import { EventRegister } from "../../../modules/EventRegister";
import { EVENT_SHOW_MODAL_INFO } from "../../../constants/Events";
import { api, auth, helpers } from "../../../import";
import { IUserData } from "../../Home/shared/useHomePage";
import { showModalAction, showModalInfo } from "../../../modules/ViewHelpers";
import PrescriptionProfile from "../../../components/WorkProfileManagement/PrescriptionProfile";
import ButtonLinear from "../../../components/Buttons/ButtonLinear";
import AccountItem from "../../../components/AccountItem/Index";
import { ConsultsType, height } from "../../../configs/Const";
import Modal from "../../../components/Modal";
import PrescriptionService from "../../../modules/PrescriptionService";
import { API_FRONT_URL } from "../../../models/front-urls";
import ic_copy from "../../../images/ic_copy@3x.png";
import { Tooltip } from "../../../components/Tooltip";
import Toast from "../../../components/Toast";

const ConfigurationDesktop = memo(() => {
  const [showModal, setShowModal] = useState(false);
  const [QRUrl, setQRUrl] = useState("");
  const history = useHistory();
  const [userData, setUserData] = useState<IUserData | null>();
  const [prescriptionProfiles, setPrescriptionProfiles] = useState<any>();
  const prescriptionService = new PrescriptionService();
  const [deletingProfile, setDeletingProfile] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [textToaster, setTextToaster] = useState<string>("");
  const [variantToaster, setVariantToaster] = useState<string>("success");
  const [permisionMedicine, setPermissionMedicine] = useState<boolean>(false);

  const loadPrescriptionProfiles = () => {
    api
      .getMyPrescriptionProfiles()
      .then((res: any) => {
        if (res?.data?.length) {
          setPrescriptionProfiles(res.data);
        } else {
          setPrescriptionProfiles(null);
        }
      })
      .catch(() => {
        setPrescriptionProfiles(null);
      });
  };

  const getUserData = () => {
    auth
      .getRemoteUserData()
      .then((res: any) => {
        setUserData(res.data.user);
        setPermissionMedicine(res.data.permission);
      })
      .catch((err: any) => {
        setUserData(null);
        helpers.processError(err);
      });
  };

  useEffect(() => {
    getUserData();
    loadPrescriptionProfiles();
  }, []);

  const onPressNewProfile = useCallback(() => {
    prescriptionService.clear();
    history.push(`/${Routes.WorkProfile}`, {
      stepTitle: locales_es.stepOneOfThree,
      stepNumber: 1,
    });
  }, [history]);

  const sendMailQR = () => {
    history.push(`/${Routes.SendDocuments}`, {
      documents: [],
      emails: [{ value: "" }],
      selectionType: ConsultsType.QRAddPatient,
    });
  };

  const openQRWebView = () => {
    api
      .getQRToShow()
      .then((res: any) => {
        if (res.data && res.data.qr_full_url) {
          setQRUrl(res.data.qr_full_url);
          EventRegister.emit(EVENT_SHOW_MODAL_INFO, {
            title: locales_es.infoModal.title,
            message:
              "Muestre este QR al paciente para que pueda darse de alta en su ficha de pacientes.",
            actions: [
              {
                text: "Ver QR",
                onPress: () => setShowModal(true),
                white: true,
              },
            ],
          });
          // alert('Muestre este QR al paciente para que pueda darse de alta en su ficha de pacientes.');
          // history.push(`/${Routes.WebviewLoader}`, {uri: res.data.qr_full_url});
        } else {
          showModalInfo(
            locales_es.errorModal.title,
            "No se pudo cargar el QR."
          );
        }
      })
      .catch((err: any) => {
        helpers.processError(err);
      });
  };

  const deleteProfile = async (profileId: string, setLoading: any) => {
    const selectedProfileToDelete = prescriptionProfiles.find(
      (item) => item.id === profileId
    );
    const confirmDeletePrescriptionProfile = async () => {
      setLoading(true);
      setDeletingProfile(true);
      await api
        .deletePrescriptionProfile(profileId)
        .then(async (res: any) => {
          await loadPrescriptionProfiles();
          setLoading(false);
          showModalInfo(locales_es.successModal.title, res.message);
          setDeletingProfile(false);
        })
        .catch((err: any) => {
          setLoading(false);
          setDeletingProfile(false);
          helpers.processError(err);
        });
    };
    if (deletingProfile) return;
    if (
      !deletingProfile &&
      prescriptionProfiles &&
      prescriptionProfiles.length === 1
    ) {
      showModalInfo(
        locales_es.deletePrescriptionProfileInfo.title,
        locales_es.deletePrescriptionProfileInfo.subtitle
      );
      return;
    }
    if (
      !deletingProfile &&
      prescriptionProfiles &&
      prescriptionProfiles.length > 1 &&
      selectedProfileToDelete.active_charger_account === 1
    ) {
      showModalAction(
        locales_es.deletePrescriptionProfileWithChargerAccount.title,
        locales_es.deletePrescriptionProfileWithChargerAccount.subtitle,
        confirmDeletePrescriptionProfile,
        "50%"
      );
    }
    if (
      !deletingProfile &&
      prescriptionProfiles &&
      prescriptionProfiles.length > 1 &&
      selectedProfileToDelete.active_charger_account === 0
    ) {
      showModalAction(
        locales_es.deletePrescriptionProfile.title,
        locales_es.deletePrescriptionProfile.subtitle,
        confirmDeletePrescriptionProfile,
        "50%"
      );
    }
  };

  const openLink = () => {
    let link = `${API_FRONT_URL}/p/${userData?.slug}`;

    window.open(link, "_blank");
  };

  const onCopyClipboard = () => {
    const copy = window.navigator.clipboard.writeText(
      `${API_FRONT_URL}/p/${userData?.slug}`
    );

    if (!copy) {
      setTextToaster("No se pudo copiar el link");
      setVariantToaster("error");
      setShowToast(true);
      setTimeout(() => {
        setShowToast(false);
      }, 5000);
    } else {
      setTextToaster("Link copiado al portapapeles");
      setVariantToaster("success");
      setShowToast(true);
      setTimeout(() => {
        setShowToast(false);
      }, 5000);
    }
  };

  return (
    <View style={styles.container}>
      {showToast && <Toast variant={`${variantToaster}`} text={textToaster} />}
      <View style={styles.content}>
        <View style={styles.headerWrapper}>
          <Text size={38} lineHeight={36} bold>
            {locales_es.configuration_page.title}
          </Text>
        </View>
        <View style={styles.cardWrapper}>
          <View style={styles.cardItem}>
            <Card
              header={{
                icon: SOURCE_ICON.doctor,
                title: locales_es.configuration_page.basic_information,
                action: (
                  <Link
                    to={{
                      pathname: Routes.BasicInformation,
                      state: { profileMode: true },
                    }}
                  >
                    <Image
                      source={{ uri: SOURCE_ICON.edit, width: 20, height: 20 }}
                      style={styles.cardAction}
                    />
                  </Link>
                ),
              }}
            >
              {typeof userData === "undefined" && <ActivityIndicator />}
              {userData === null && (
                <Text size={16}>
                  {locales_es.configuration_page.no_user_data}
                </Text>
              )}
              {userData && (
                <View style={styles.userDataWrapper}>
                  <View style={styles.userDataItem}>
                    <Text size={16} bold>
                      {
                        locales_es.configuration_page.user_data
                          .professional_prefix
                      }
                    </Text>
                    <Text size={16}>{userData.professional_prefix?.name}</Text>
                  </View>
                  <View style={styles.userDataItem}>
                    <Text size={16} bold>
                      {locales_es.configuration_page.user_data.name}
                    </Text>
                    <Text size={16}>{userData.name}</Text>
                  </View>
                  <View style={styles.userDataItem}>
                    <Text size={16} bold>
                      {locales_es.configuration_page.user_data.lastname}
                    </Text>
                    <Text size={16}>{userData.lastname}</Text>
                  </View>
                  <View style={styles.userDataItem}>
                    <Text size={16} bold>
                      {
                        locales_es.configuration_page.user_data
                          .identificationType
                      }
                    </Text>
                    <Text size={16}>
                      {userData.identification_type &&
                      userData.identification_type.name
                        ? userData.identification_type.name
                        : locales_es.configuration_page.no_data}
                    </Text>
                  </View>
                  <View style={styles.userDataItem}>
                    <Text size={16} bold>
                      {locales_es.configuration_page.user_data.identification}
                    </Text>
                    <Text size={16}>
                      {userData.identification
                        ? userData.identification
                        : locales_es.configuration_page.no_data}
                    </Text>
                  </View>
                  <View style={styles.userDataItem}>
                    <Text size={16} bold>
                      {locales_es.configuration_page.user_data.cell_phone}
                    </Text>
                    <Text size={16}>
                      {userData.prefix_cell_phone} {userData.cell_phone}
                    </Text>
                  </View>
                  {permisionMedicine && (
                  <View style={styles.userDataItem}>
                    <Text size={16} bold>
                      {locales_es.configuration_page.user_data.frontUri}
                    </Text>
                    <View
                      style={{
                        flexDirection: "row",
                        alignItems: "left",
                        justifyContent: "left",
                      }}
                    >
                      <Text
                        size={16}
                        bold
                        style={{
                          color: Colors.BlueCrayola,
                          textDecorationLine: "underline",
                        }}
                        onPress={openLink}
                      >
                        {` ${API_FRONT_URL}/p/${userData.slug}`}
                      </Text>

                      <TouchableOpacity onPress={onCopyClipboard}>
                        <Tooltip text="Copiar link de pago">
                          <Image
                            source={ic_copy}
                            resizeMode="contain"
                            style={{
                              tintColor: Colors.Black,
                              minHeight: 25,
                              minWidth: 25,
                              marginHorizontal: 10,
                            }}
                          />
                        </Tooltip>
                      </TouchableOpacity>
                    </View>
                  </View>
                  )}
                </View>
              )}
            </Card>
          </View>
          <View style={styles.cardItem}>
            <View style={{height: '67%'}}>
              <Card
                header={{
                  icon: SOURCE_ICON.exp,
                  title: locales_es.configuration_page.prescription_profiles_info,
                  action: (
                    <TouchableOpacity
                      onPress={() => {
                        EventRegister.emit(EVENT_SHOW_MODAL_INFO, {
                          title: locales_es.prescriptionProfilesInfo.title,
                          message: locales_es.prescriptionProfilesInfo.content,
                        });
                      }}
                    >
                      <Image
                        source={{ uri: SOURCE_ICON.help, width: 25, height: 25 }}
                        style={styles.cardAction}
                      />
                    </TouchableOpacity>
                  ),
                }}
                
              >
                {typeof prescriptionProfiles === "undefined" && (
                  <ActivityIndicator />
                )}
                {prescriptionProfiles === null && (
                  <Text size={16}>
                    {locales_es.configuration_page.no_prescription_profile}
                  </Text>
                )}
                {prescriptionProfiles && (
                  <View style={styles.prescriptionProfileWrapper}>
                    {prescriptionProfiles.map((profile: any, i: number) => (
                      <PrescriptionProfile
                        {...profile}
                        key={i}
                        deleteProfile={deleteProfile}
                      />
                    ))}
                  </View>
                )}
               { prescriptionProfiles && (
                    <View style={{ marginLeft: 10, marginRight: 10, marginBottom: 25, }}>
                      <ButtonLinear
                        white
                        leftChildren={
                          <Image
                            source={{
                              uri: SOURCE_ICON.add,
                              width: 20,
                              height: 20,
                            }}
                            style={{ tintColor: "white", marginRight: 8 }}
                          />
                        }
                        style={{  }}
                        styleButton={{ marginTop: 0 }}
                        colors={[Colors.BlueCrayola, Colors.Blue]}
                        title={"Añadir Perfil Nuevo"}
                        onPress={onPressNewProfile}
                      />
                    </View>
                  )}
              </Card>
            </View>
            <Card
              header={{
                icon: SOURCE_ICON.setting,
                title: locales_es.configuration_page.password,
                action: (
                  <Link to={Routes.ChangePassword} style={styles.cardAction}>
                    <Image
                      source={{
                        uri: SOURCE_ICON.edit,
                        width: 20,
                        height: 20,
                      }}
                      style={styles.cardAction}
                    />
                  </Link>
                ),
              }}
            >
              <View style={styles.passwordAction}>
                <Link to={Routes.ChangePassword} style={styles.passwordLink}>
                  {locales_es.configuration_page.change_password}
                </Link>
              </View>
            </Card>
          </View>
        </View>
        <View style={styles.cardWrapper}>
          <View style={styles.cardItem}>
            <Card>
              <View
                style={{
                  justifyContent: "space-between",
                  alignItems: "center",
                  flexDirection: "row",
                  flex: 1,
                }}
              >
                <Text size={16}>Imprimir QR</Text>
                <AccountItem
                  style={styles.firstView}
                  icon={SOURCE_ICON.qrPrint}
                  onPress={sendMailQR}
                  hideArrowAndToggle
                />
              </View>
            </Card>
          </View>
          <View
            style={{
              ...styles.cardItem,
              ...{ maxWidth: /* //TODO: Protected presciptions 550 */ 600 },
            }}
          >
            <Card>
              <View style={styles.shareWrapper}>
                <Text size={16}>Mostrar QR</Text>
                <View>
                  <AccountItem
                    style={styles.firstView}
                    icon={SOURCE_ICON.qrShow}
                    onPress={openQRWebView}
                    hideArrowAndToggle
                  />
                </View>
              </View>
            </Card>
          </View>
          {/* //TODO: Protected presciptions
          <View style={styles.cardItem}>
            <Card>
              <View
                style={{
                  justifyContent: "space-between",
                  alignItems: "center",
                  flexDirection: "row",
                  flex: 1,
                }}
              >
                <Text  size={16}>Recetas Protegidas</Text>
                  <Switch value={protectedPrescriptions}  />
              </View>
            </Card>
          </View> */}
        </View>
        <View style={styles.cardWrapper}>
          <View
            style={{
              ...styles.cardItem,
              ...{ maxWidth: /* //TODO: Protected presciptions 550 */ 1224 },
            }}
          >
            <Card>
              <View style={styles.shareWrapper}>
                <Text size={16}>
                  {locales_es.configuration_page.share_title}
                </Text>
                <View>
                  <RWebShare
                    data={{
                      text: locales_es.shareAppInfoTextWeb,
                      url: "https://rcta.me",
                      title: locales_es.shareAppInfoTitle,
                    }}
                    closeText="Cerrar"
                  >
                    <button style={styles.shareButtonWrapper}>
                      <LinearColors
                        vertical
                        locations={[0, 0.75]}
                        colors={[Colors.TurquoiseBlue, Colors.TealBlue]}
                        style={styles.shareButton}
                      >
                        <Text white type="H5" bold>
                          {locales_es.configuration_page.share_action}
                        </Text>
                      </LinearColors>
                    </button>
                  </RWebShare>
                </View>
              </View>
            </Card>
          </View>
        </View>
      </View>
      {showModal && QRUrl && (
        <Modal onRequestClose={() => setShowModal(false)}>
          <img src={QRUrl} width={200} height={200} alt="QR code" />
        </Modal>
      )}
    </View>
  );
});

export default ConfigurationDesktop;

const styles = StyleSheet.create<any>({
  container: { flex: 1, minHeight: "100%" },
  content: {
    minHeight: "100%",
    paddingBottom: 24,
    paddingLeft: 24,
    paddingRight: 24,
    paddingTop: getStatusBarHeight(),
  },
  cardWrapper: {
    display: "flex",
    flexDirection: "row",
    marginTop: 24,
    gap: 24,
    flexWrap: "wrap",
  },
  cardItem: { flex: 1, gap: 24, minWidth: 300, maxWidth: 600 },
  cardAction: { tintColor: "#00c1c1", color: "#00c1c1" },
  userDataWrapper: { gap: 24 },
  userDataItem: { gap: 8 },
  prescriptionProfileWrapper: {
    overflow: "auto",
    marginLeft: -24,
    marginRight: -24,
    marginTop: -16,
    maxHeight: 250,
    marginBottom: 20,
  },
  passwordAction: {
    justifyContent: "center",
    alignItems: "center",
    flex: 1,
  },
  passwordLink: { color: "#00c1c1", fontWeight: "bold", fontSize: 16 },
  shareButtonWrapper: { border: "none", padding: 0, borderRadius: 16 },
  shareButton: {
    height: 50,
    minWidth: 100,
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 12,
  },
  shareWrapper: {
    justifyContent: "space-between",
    alignItems: "center",
    flexDirection: "row",
    flex: 1,
  },
  headerWrapper: { flexDirection: "row", gap: 16, alignItems: "center" },
});
