import { useParams } from "react-router-dom";
import { useEffect, useReducer } from "react";
import APIService from "../../modules/apiService";
import Helpers from "../../modules/helpers";
import locales_es from "../../locales/es";
import { INSURANCE_PERMISSION, MEDICINES_PERMISSION, PRESCRIPTION_ERROR_INVALID_CREDENTIAL, PRESCRIPTION_MODE_NO_MEDICAL_INSURANCE, PRESCRIPTION_REQUEST_STATUS_ACCEPTED } from "../../models/constants";

const api = new APIService();
const helpers = new Helpers();

const initialState = {
  name: "",
  lastName: "",
  email: "",
  phoneNumber: "",
  identification: "",
  identificationOptions: [],
  identificationTypeId: "1",
  medicalInsurancesOptions: [],
  medicalInsurance: {},
  medicalInsurancePlan: "",
  medicalInsuranceNumber: "",
  medicalInsuranceDisabled: false,
  medicalInsuranceName: "",
  prescriptionProfile: {},
  user: {},
  medicId: "",
  comment: "",
  observations: "",
  medicines: [],
  sentRequest: false,
  errors: [],
  gender:'',
  gendersList:[],
  showView: false
};

const reducer = (state: any, action: any) => {
  switch (action.type) {
    case "name":
      return { ...state, name: action.payload };
    case "lastName":
      return { ...state, lastName: action.payload };
    case "email":
      return { ...state, email: action.payload };
    case "phoneNumber":
      return { ...state, phoneNumber: action.payload };
    case "identification":
      return { ...state, identification: action.payload };
    case "identificationOptions":
      return { ...state, identificationOptions: action.payload };
    case "identificationTypeId":
      return { ...state, identificationTypeId: action.payload };
    case 'gendersList':
      return { ...state, gendersList: action.payload};
    case 'gender':
      return { ...state, gender: action.payload};
    case "medicalInsurancesOptions":
      return { ...state, medicalInsurancesOptions: action.payload };
    case "medicalInsurance":
      return { ...state, medicalInsurance: action.payload };
    case "medicalInsurancePlan":
      return { ...state, medicalInsurancePlan: action.payload };
    case "medicalInsuranceNumber":
      return { ...state, medicalInsuranceNumber: action.payload };
    case "medicalInsuranceName":
      return { ...state, medicalInsuranceName: action.payload };
    case 'medicalInsuranceDisabled':
      return { ...state, medicalInsuranceDisabled: action.payload };
    case "prescriptionProfile":
      return { ...state, prescriptionProfile: action.payload };
    case "user":
      return { ...state, user: action.payload };
    case "comment":
      return { ...state, comment: action.payload };
    case "observations":
      return { ...state, observations: action.payload };
    case "medicines":
      return { ...state, medicines: action.payload };
    case "sentRequest":
      return { ...state, sentRequest: action.payload };
    case "medicId":
      return { ...state, medicId: action.payload };
    case "errors":
      return { ...state, errors: action.payload };
    case "showView":
      return { ...state, showView: action.payload };
    default:
      return state;
  }
};

export default function usePrescriptionRequest(
  showMainModal: any,
  hideMainLoading: any
) {
  const params: { slug: string } = useParams();
  const [state, dispatch] = useReducer(reducer, initialState);

  const clearForm = () => {
    dispatch({
      type: "name",
      payload: "",
    });
    dispatch({
      type: "lastName",
      payload: "",
    });
    dispatch({
      type: "email",
      payload: "",
    });
    dispatch({
      type: "phoneNumber",
      payload: "",
    });
    dispatch({
      type: "identification",
      payload: "",
    });
    dispatch({
      type: "identificationTypeId",
      payload: "1",
    });
    dispatch({
      type: "medicalInsurance",
      payload: {},
    });
    dispatch({
      type: "medicalInsurancePlan",
      payload: "",
    });
    dispatch({
      type: "medicalInsuranceNumber",
      payload: "",
    });
    dispatch({
      type: "comment",
      payload: "",
    });
    dispatch({
      type: "observations",
      payload: "",
    });
    dispatch({
      type: "medicines",
      payload: [],
    });
    dispatch({
      type: 'gender',
      payload: '',
    });
  };

  useEffect(() => {
    api
      .getGenders()
      .then((res) => {
        const data = res.data
        dispatch({
          type: 'gendersList',
          payload: data,
        });
        dispatch({
          type: 'gender',
          payload: data[2],
        });
      })
      .catch((err) => {
        showMainModal(
          locales_es.errorModal.title,
          helpers.getErrorMsg(err)
        );
      });
    
    api
      .getPrescriptionProfilesBySlug(params.slug)
      .then((res) => {

        const insurancePermission = res.permission;
        const data = res.data.sort((a: any, b: any) => b.id - a.id).filter((item: any) => item.clinic_id === null);
        const activeCharguerAccountFlag = data.some((item: any) => item.active_charger_account === 1);
        if (data.length > 0) {
          const prescriptionProfile = (!activeCharguerAccountFlag) ? data[0] : data.find((item: any) => item.active_charger_account === 1);
          hideMainLoading();
          dispatch({
            type: "showView",
            payload: true,
          })
          dispatch({
            type: "prescriptionProfile",
            payload: prescriptionProfile,
          });
          if (prescriptionProfile?.user) {
            dispatch({
              type: "user",
              payload: prescriptionProfile?.user,
            });
            dispatch({
              type: "medicId",
              payload: prescriptionProfile?.medic_id,
            });
          }
          getInsurances(insurancePermission);
          api
            .getIdentificationTypesByCountryId(res.data[0].user.country_id)
            .then((res) => {
              const identificationOptions = res.data.map((item: any) => {
                item.label = item.name;
                item.value = item.id;
                return item;
              });
              dispatch({
                type: "identificationOptions",
                payload: identificationOptions,
              });
            });
          
        } else {
          showMainModal(
            locales_es.infoModal.title,
            "El profesional ha inhabilitado la solicitud de recetas."
          );
        }
      })
      .catch((err) => {
        hideMainLoading();
        showMainModal(
          locales_es.infoModal.title,
          "El profesional ha inhabilitado la solicitud de recetas."
        );
      });
    
  }, [params.slug]);

  const getInsurances = (insurancePermission: any) => {
    api
    .getMedicalInsurances(insurancePermission)
    .then((res) => {
      if (res.data.length > 0) {
        res.data[0].name = 'Sin cobertura';
      }
      const medicalInsurances = res.data.map((item: any) => {
        item.label = item.name;
        item.value = item.id;
        return item;
      });
      dispatch({
        type: "medicalInsurancesOptions",
        payload: medicalInsurances,
      });
      if (medicalInsurances.length == 1 && !insurancePermission) {
        dispatch({type: 'medicalInsurance', payload: medicalInsurances[0]});
        dispatch({type: 'medicalInsurancePlan', payload: ''});
        dispatch({type: 'medicalInsuranceNumber', payload: ''});
        dispatch({type: 'medicalInsuranceDisabled', payload: true});
        dispatch({type: 'medicalInsuranceName', payload: 'Sin cobertura'});
      }
    })
    .catch((err) => {
      dispatch({
        type: "errors",
        payload: "medicalInsurances",
      });
    });
  }

  const postPrescriptionRequest = (data: any) => {
    return api.postMedicalPrescriptionRequest(data);
  };

  const removeFromErrors = (error: string) => {
    let parsedErrors = JSON.parse(JSON.stringify(state.errors));
    parsedErrors = parsedErrors.filter((item: string) => item !== error);
    dispatch({
      type: "errors",
      payload: parsedErrors,
    });
  };

  return {
    state,
    helpers,
    dispatch,
    params,
    clearForm,
    removeFromErrors,
    postPrescriptionRequest,
  };
}
