import React, { useState } from 'react';
import { StyleSheet, View, TouchableOpacity, Text, Animated, ScrollView, Image } from 'react-native';
import { IMAGE } from "../../images/Images";
import { height } from '../../configs/Const';
import iconArrowRight from '../../images/ic_arrow_right.png';

interface Props {
    children?: any;
    style?: any;
    widthOpen?: string;
    widthClose?: string;
    colorOpen?: string;
    colorClose?: string;
}

const Collapsible = ({ children,style,widthOpen,widthClose,colorClose,colorOpen}: Props) => {
    const [collapsed, setCollapsed] = useState(false);
    const [animation] = useState(new Animated.Value(collapsed ? 0 : 1));

    const toggleCollapse = () => {
        setCollapsed(!collapsed);
        Animated.timing(animation, {
          toValue: collapsed ? 1 : 0, 
          duration: 300, 
          useNativeDriver: false,
        }).start();
      };
    
      const sidebarWidth = animation.interpolate({
        inputRange: [0, 1],
        outputRange: ['0%', '100%'], 
      });

      const buttonPosition = animation.interpolate({
        inputRange: [0, 1],
        outputRange: ['0%', '100%'], 
      });
    
      return (
      <>
        <View style={{...styles.container, width: collapsed ? widthClose : widthOpen}}>
          <Animated.View style={[styles.sideBar, { width: sidebarWidth}]}>
            <ScrollView style={{overflow: 'hidden'}}>
                {children}
            </ScrollView>
          </Animated.View>
        </View>
        <View style={styles.buttonContainer}>
          <TouchableOpacity onPress={toggleCollapse} 
          style={[styles.button, 
            { left: buttonPosition }, 
            {backgroundColor: collapsed ? colorClose : colorOpen}]}>
            <Image source={ iconArrowRight } style={{...styles.buttonText, marginLeft: collapsed ? '12px' : 0, transform: !collapsed ? 'scaleX(-1)': 'scaleX(1)'}} resizeMode="contain"/>
          </TouchableOpacity>
        </View>
      </>
      );
    };
    
    const styles = StyleSheet.create({
      container: {
        flexDirection: 'row',
        position: 'relative', 
        transition: 'width 0.3s ease',
      },
      sideBar: {
        backgroundColor: '#333',
        height: '100%',
      },
      button: {
        width: 35,
        height: 35,
        borderRadius: 25,
        zIndex: 100, 
        alignSelf: 'center',
        alingContent: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        position: 'relative',
        transition: 'left 0.3s ease',
        alingSelf: 'center',

      },
      buttonText: {
        color: 'white',
        tintColor: 'white',
        minHeight: 22,
        minWidth: 22,
      },
      buttonContainer: {
        width: 0.5,
        flexDirection: 'column',
        height: '100%',
        backgroundColor:'white',
        alingContent: 'center',
        justifyContent: 'left',
        textAlign: 'center',
        zIndex: 100,
        marginTop: 110
        

      }
    });
export default Collapsible;
