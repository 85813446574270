import React from 'react';
import {StyleSheet} from 'react-native';
import {Routes} from '../../configs';
import ProfileItem from './ProfileItem';
import Container from '../../components/Layout/Container';
import ButtonText from '../Buttons/ButtonText';
import locales_es from '../../locales/es';
import {useHistory} from 'react-router-dom';

const PasswordAdmin = () => {
  const history = useHistory();

  return (
    <Container style={styles.container}>
      <ProfileItem icon={'setting'} title={locales_es.password}>
        <ButtonText
          marginTop={30}
          title={locales_es.changePassword}
          style={styles.button}
          onPress={() => history.push(`/${Routes.ChangePassword}`)}
        />
      </ProfileItem>
    </Container>
  );
};

export default PasswordAdmin;

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  button: {
    borderWidth: 0,
    marginVertical: 10,
  },
});
