import React, { memo } from "react";
import { View, StyleSheet } from "react-native";
import Text from "../Text";
import { Colors } from "../../configs";
import ButtonLinear from "../Buttons/ButtonLinear";
import locales_es from "../../locales/es";
import Layout from "../Layout/Layout";

interface ModalInfoProps {
  title: string;
  message: string;
  action: any;
  onPressDefault: any;
  width?: number;
  cancelAction?: any;
  okText?: string;
  cancelText?: string;
}

const ModalAction = memo((props: ModalInfoProps) => {
  const { title, message, action, onPressDefault, width = "100%", cancelAction, okText, cancelText } = props;

  return (
    <View style={styles.container}>
      <Layout
        style={{
          borderRadius: 16,
          paddingHorizontal: 24,
          paddingVertical: 32,
          width: width,
        }}
      >
        <Text center size={17} lineHeight={20} bold>
          {title}
        </Text>
        <Text center size={13} lineHeight={22} marginTop={16}>
          {message}
        </Text>
        <View style={styles.centerButton}>
          <ButtonLinear
            white
            title={(cancelText) ? cancelText : locales_es.cancel}
            onPress={() => {
              onPressDefault();
              if (cancelAction) {
                cancelAction();
              }
            }}
            style={{ width: "95%" }}
            colors={[Colors.Red, Colors.RedNeonFuchsia]}
            styleButton={{ width: "45%" }}
          />
          <ButtonLinear
            white
            title={(okText) ? okText : locales_es.accept}
            onPress={() => {
              action();
              onPressDefault();
            }}
            styleButton={{ width: "45%" }}
            style={{ width: "95%" }}
          />
        </View>
      </Layout>
    </View>
  );
});

export default ModalAction;

const styles = StyleSheet.create({
  container: {
    backgroundColor: Colors.Black68,
    ...StyleSheet.absoluteFillObject,
    paddingHorizontal: 24,
    justifyContent: "center",
    alignItems: "center",
    position: "absolute",
    zIndex: 100,
    with: "100%",
  },
  centerButton: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    marginTop: 24,
    marginLeft: "2.5%",
    alignItems: "center",
    width: "100%",
  },
});
