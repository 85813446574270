import React from "react";
import {
  StyleSheet,
  Modal,
  View,
} from "react-native";
import locales_es from "../../../locales/es";
import scale from "../../../utils/scale";
import Text from "../../../components/Text";
import ButtonLinear from "../../../components/Buttons/ButtonLinear";
import ButtonBorder from "../../../components/Buttons/ButtonBorder";
import iconSentMsg from "../../../images/img_sent_message.png";

interface IValidateUserModalProps {
  visible?: boolean;
  onRequestClose: () => void;
  onClick: () => void;
}

export function ValidateUserModal({ visible, onRequestClose, onClick}: IValidateUserModalProps) {
return(<Modal
          visible={visible}
          onRequestClose={onRequestClose}
          transparent
          animationType={"fade"}
        >
          <View style={styles.modal}>
            <img
              alt={iconSentMsg}
              src={iconSentMsg}
              style={{ width: scale(120, true), height: scale(120, true) }}
            />
            <Text
              size={15}
              lineHeight={22}
              center
              marginTop={24}
              marginHorizontal={56}
            >
              {locales_es.confirmAccountValidationModal.title}
            </Text>
            <Text
              semibold
              size={15}
              lineHeight={22}
              center
              marginTop={12}
              marginHorizontal={56}
            >
              {locales_es.confirmAccountValidationModal.subtitle}
            </Text>
            <ButtonLinear
              white
              style={{
                paddingHorizontal: 20,
              }}
              title={locales_es.confirmAccountValidationModal.accept}
              onPress={onRequestClose}
            />
            <ButtonBorder
              style={{
                paddingHorizontal: 20,
                marginVertical: 10,
              }}
              placeholder
              title={locales_es.confirmAccountValidationModal.resend}
              onPress={onClick}
            />
          </View>
        </Modal>)
}

export default ValidateUserModal;

const styles = StyleSheet.create<any>({
  modal: {
    flex: 1,
    backgroundColor: "#fff",
    justifyContent: "center",
    alignItems: "center",
  },
});
