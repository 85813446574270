import React, {memo, useEffect, useState} from 'react';
import {
  View,
  StyleSheet,
  Image,
  ViewStyle,
  TouchableOpacity,
} from 'react-native';
import Text from '../../components/Text';
import Theme from '../../style/Theme';
import {SOURCE_ICON} from '../../images';
import Line from '../../components/Layout/Line';
import {getBottomSpace} from 'react-native-iphone-x-helper';
import { set } from 'react-native-reanimated';

interface ListItemProps {
  item: any;
  style?: ViewStyle;
  onPress?: () => void;
}

const ListItem = memo(({item, style, onPress}: ListItemProps) => {

  const [fontSizes, setFontSizes] = useState<any>();
  const [widthItem, setWidthItem] = useState<any>();

  const fontSize = () => {
    if (style) {
      setFontSizes(style.fontSize);
      setWidthItem(style.width);
    }else{
      setFontSizes(15);
      setWidthItem('100%');
    }
  };

  useEffect(() => {
    fontSize();
  }, []);

  return (
    <View>
      <TouchableOpacity
        key={item.id}
        style={[
          styles.controlItem,
          // index !== searchControl.length - 1 && styles.border,
        ]}
        onPress={() => onPress && onPress()}>
        <View style={{...Theme.flexRow}}>
          {/*<LinearColors
                colors={[Colors.TealBlue, Colors.TurquoiseBlue]}
                style={{
                  width: 40,
                  height: 40,
                  borderRadius: 8,
                  ...Theme.center,
                  marginRight: 16,
                }}>
              <Image
                  source={item.icon}
                  style={{tintColor: Colors.White}}
              />
            </LinearColors>*/}
          <Text size={fontSizes} lineHeight={18} style={{width: widthItem}}>
            {item.name}
          </Text>
        </View>
        <Image source={SOURCE_ICON.arrowRight} />
      </TouchableOpacity>
      {
        // index === searchControl.length - 1 ? null : <Line />
      }
    </View>
  );
});

export default ListItem;

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  searchSpecial: {
    marginTop: 4,
    paddingHorizontal: 24,
  },
  content: {
    marginTop: 24,
    borderRadius: 16,
    paddingVertical: 8,
    ...Theme.shadow,
  },
  controlItem: {
    paddingHorizontal: 24,
    paddingVertical: 16,
    zIndex: 0,
    
  },
  border: {},
  scrollStyle: {
    paddingBottom: getBottomSpace() + 12,
  },
  header: {
    ...Theme.flexRowSpace,
    paddingRight: 24,
  },
  listItem: {
    display: 'block',
    
  },
});
