import {scale} from 'utils/scale';
import {getStatusBarHeight} from 'react-native-iphone-x-helper';
import {Dimensions, Platform} from 'react-native';

const Constants = {
  width: Dimensions.get('window').width,
  height: Dimensions.get('window').height,
  FONTS_APP: 'Mulish',
};
export const width = Dimensions.get('window').width;
export const height = Dimensions.get('window').height;
const topHeaderHeight = Platform.OS === 'ios' ? 0 : getStatusBarHeight();
export const HEADER_HEIGHT = 108 - topHeaderHeight;
export enum ConsultsType {
  Prescription = 'Prescription',
  PrescriptionRequest = 'PrescriptionRequest',
  Certificate = 'Certificate',
  QRAddPatient = 'QRAddPatient',
}

export enum ConsultsStatus {
  stillInProgress = 1,
  accepted = 2,
  unConFirmed = 3,
  completed = 4,
  patientCanceled = 5,
  iDeclined = 6,
  iCanceled = 7,
}

export default Constants;
