import React, { memo, useState, useEffect } from "react";
import {
  View,
  StyleSheet,
  Image,
  ActivityIndicator,
  TouchableOpacity,
} from "react-native";
import Text from "../../../components/Text";
import { Colors, Routes } from "../../../configs";
import Theme from "../../../style/Theme";
import { SOURCE_ICON } from "../../../images";
import { getBottomSpace } from "react-native-iphone-x-helper";
import { useHistory } from "react-router-dom";
import { ACCOUNT_IMAGE } from "../../../images/Account";
import ButtonText from "../../../components/Buttons/ButtonText";
import AccountItem from "../../../components/AccountItem/Index";
import { useTheme } from "../../../configs/Theme";
import Layout from "../../../components/Layout/Layout";
import Content from "../../../components/Layout/Content";
import { api, auth, helpers } from "../../../import";
import { RWebShare } from "react-web-share";
import locales_es from "../../../locales/es";
import { showModalInfo } from "../../../modules/ViewHelpers";
// import {process.env.REACT_APP_VERSION};
import { CommonStyles } from "../../../styles/CommonStyles";
import HeaderButton from "../../../components/HeaderButton";
import { EVENT_SHOW_MODAL_INFO } from "../../../constants/Events";
import { CONTACT_SUPPORT_LINK } from "../../../models/constants";
import LocalAuthService from "../../../modules/LocalAuthService";
import { EventRegister } from "../../../modules/EventRegister";
const ConfigurationMobile = memo(() => {
  const { theme } = useTheme();
  const history = useHistory();
  const [load, setLoad] = useState<boolean>(false);
  const [userData, setUserData] = useState<any>([]);
  const [countries, setCountries] = useState<any>([]);

  const onEditButton = () => {
    history.replace(`${Routes.BasicInformation}`, { profileMode: true });
  };

  const goToWebsite = () => {
    helpers.openLink("https://rcta.me");
  };

  const goToWebviewLoaderTerms = () => {
    let termsPage = "https://rcta.me";
    countries.map((c: any) => {
      if (c.id === userData.country_id) {
        termsPage =
          c.terms_and_conditions_url + "?v=" + process.env.REACT_APP_VERSION;
      }
    });
    helpers.openLink(termsPage);
  };

  const goToWebviewLoaderPrivacy = () => {
    let privacyPage = "https://rcta.me";
    countries.map((c: any) => {
      if (c.id === userData.country_id) {
        privacyPage =
          c.privacy_policies_url + "?v=" + process.env.REACT_APP_VERSION;
      }
    });
    helpers.openLink(privacyPage);
  };

  const contactUs = () => {
    helpers.openLink(CONTACT_SUPPORT_LINK);
  };

  useEffect(() => {
    auth
      .getRemoteUserData()
      .then((res: any) => {
        setUserData(res.data.user);
      })
      .catch((err: any) => {
        helpers.processError(err);
      });

    api
      .getCountries()
      .then((res) => {
        setCountries(res.data);
      })
      .catch((err) => {
        EventRegister.emit(EVENT_SHOW_MODAL_INFO, {
          title: locales_es.errorModal.title,
          message: helpers.getErrorMsg(err),
        });
      });
  }, [load]);

  return (
    <Content
      style={styles.container}
      scrollEventThrottle={16}
      bounces={false}
      showsVerticalScrollIndicator={false}
      contentContainerStyle={styles.contentContainerStyle}
    >
      <Layout style={styles.header}>
        <HeaderButton
          style={{
            padding: 26,
            marginLeft: 24,
          }}
        />
        <Text bold size={16} lineHeight={18} style={{ flex: 1 }}>
          {locales_es.configuration}
        </Text>
      </Layout>
      <TouchableOpacity onPress={() => onEditButton()} style={styles.infoView}>
        {userData && countries.length ? (
          <View style={Theme.flexRow}>
            <Image
              source={{ uri: userData.full_profile_image }}
              style={styles.avatar}
            />
            <View>
              <Text size={15} bold marginBottom={4}>
                {userData.name} {userData.lastname}
              </Text>
              <Text style={styles.email}>{userData.email}</Text>
            </View>
          </View>
        ) : (
          <ActivityIndicator style={CommonStyles.activityIndicator} />
        )}
      </TouchableOpacity>

      <Layout style={styles.content}>
        <AccountItem
          style={styles.firstView}
          icon={SOURCE_ICON.home}
          name={locales_es.about}
          lineBottom
          onPress={goToWebsite}
        />
        <AccountItem
          style={styles.middleView}
          icon={SOURCE_ICON.help}
          name={locales_es.support}
          onPress={contactUs}
          lineBottom
        />
        <AccountItem
          style={styles.middleView}
          icon={SOURCE_ICON.term}
          name={locales_es.terms}
          onPress={goToWebviewLoaderTerms}
          lineBottom
        />
        <AccountItem
          style={styles.middleView}
          icon={SOURCE_ICON.term}
          name={locales_es.privacyPolicy}
          onPress={goToWebviewLoaderPrivacy}
          lineBottom
        />
        <View style={styles.lastView}>
          <View style={Theme.flexRow}>
            <Image style={styles.imageInvite} source={ACCOUNT_IMAGE.invite} />
            <View>
              <Text style={styles.text}>{locales_es.inviteToApp}</Text>
              <RWebShare
                data={{
                  text: locales_es.shareAppInfoTextWeb,
                  url: "https://rcta.me",
                  title: locales_es.shareAppInfoTitle,
                }}
                closeText="Cerrar"
                onClick={() => console.log("shared successfully!")}
              >
                <ButtonText
                  borderColor={theme.backgroundItem}
                  white
                  title={locales_es.invite}
                  style={styles.button}
                />
              </RWebShare>
            </View>
          </View>
        </View>
        <View style={[styles.lastView, styles.justifyContentCenter]}>
          <View style={Theme.flexRow}>
            <Text>
              {locales_es.version} {process.env.REACT_APP_VERSION}
              {process.env.REACT_APP_ENV === "dev" ? "-test" : ""}
            </Text>
          </View>
        </View>
      </Layout>
    </Content>
  );
});

export default ConfigurationMobile;

const styles = StyleSheet.create({
  header: {
    ...Theme.flexRowSpace,
  },
  container: {
    flex: 1,
  },
  infoView: {
    ...Theme.flexRowSpace,
    ...Theme.flexOne,
    marginHorizontal: 24,
    maxHeight: 100,
  },
  avatar: {
    width: 64,
    height: 64,
    marginRight: 16,
    borderRadius: 16,
  },
  icon: {
    tintColor: Colors.White,
    backgroundColor: Colors.DodgerBlue,
    alignSelf: "center",
  },
  name: {
    fontSize: 15,
    fontWeight: "bold",
    marginBottom: 4,
  },
  email: {
    fontSize: 13,
    marginBottom: 8,
  },
  type: {
    fontSize: 13,
    color: Colors.GrayBlue,
  },
  content: {
    marginHorizontal: 24,
    marginVertical: 8,
    borderRadius: 16,
  },
  firstView: {
    ...Theme.flexRowSpace,
    paddingTop: 24,
    paddingHorizontal: 24,
    paddingBottom: 16,
  },
  middleView: {
    ...Theme.flexRowSpace,
    paddingTop: 16,
    paddingHorizontal: 24,
    paddingBottom: 16,
  },
  lastView: {
    ...Theme.flexRowSpace,
    paddingTop: 16,
    paddingHorizontal: 24,
    paddingBottom: 24,
    paddingRight: 16,
  },
  justifyContentCenter: {
    justifyContent: "center",
  },
  button: {
    width: 100,
    height: 30,
    marginVertical: 10,
    borderRadius: 8,
    backgroundColor: Colors.DodgerBlue,
  },
  text: {
    flexDirection: "row",
    flexWrap: "wrap",
    width: 200,
    lineHeight: 25,
  },
  contentContainerStyle: {
    // paddingBottom: getBottomSpace() + 100,
    paddingBottom: getBottomSpace(),
    flex: 1,
  },
  imageInvite: {
    marginRight: 18,
    width: 80,
    height: 80,
  },
});
