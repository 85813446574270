import React, { memo } from "react";
import useAddPatientPage from "../shared/useAddPatientPage";
import {
  View,
  StyleSheet,
  Image,
  Modal,
  ActivityIndicator,
  ScrollView,
} from "react-native";
import Text from "../../../components/Text";
import Theme from "../../../style/Theme";
import scale from "../../../utils/scale";
import { Routes } from "../../../configs";
import ButtonLinear from "../../../components/Buttons/ButtonLinear";
import HeaderButton from "../../../components/HeaderButton";
import { getBottomSpace } from "react-native-iphone-x-helper";
import InputApp from "../../../components/InputApp";
import GenderItem from "../../../components/UpdateProfile/BasicInformation/GenderItem";
import ModalSelect from "../../../components/ModalSelect";
import ButtonChangeCode from "../../../components/ButtonChangeCode";
import TextInput from "../../../components/TextInput";
import ButtonChangeCategory from "../../../components/ButtonChangeCategory";
import ModalSlideBottom from "../../../components/ModalSlideBottom";
import ModalSearchStrings from "../../../components/ModalSearchStrings";

import locales_es from "../../../locales/es";
import FullScreenLoading from "../../../components/fullscreenLoading";
import { CommonStyles } from "../../../styles/CommonStyles";

import ModalChangePhoneCode from "../../../components/SignUp/ModalChangePhoneCode";
import Layout from "../../../components/Layout/Layout";
import { useHistory } from "react-router-dom";
import ImgLoadAndCrop from "../../../components/imgLoadAndCrop";
import Form from "../../../components/form";
import iconNext from "../../../images/ic_next@3x.png";
import {
  InitForm,
  handleValidatorInputsTypes,
} from "../../../utils/validation/forms";
import RequireData from "../../../components/RequireData";
import RequireIndicator from "../../../components/RequireIndicator";
import { helpers } from "../../../import";

interface AddPatientProps {}

const AddPatient = memo((props: AddPatientProps) => {
  const history = useHistory();
  
  const {
    removeFromErrors,
    selectedDate,
    errors,
    loading,
    loadingText,
    patientData,
    screenTitle,
    profileImage,
    onSaveCrop,
    setCropping,
    cropping,
    name,
    setName,
    theme,
    lastnameRef,
    onBlur,
    dateRef,
    emailRef,
    phoneNumberRef,
    identificationRef,
    setIdentification,
    identification,
    medicalInsuranceNumberRef,
    setLastname,
    lastname,
    identificationTypesList,
    identificationType,
    openCountries,
    openMedicalInsuranceModal,
    openMenuOption,
    gendersList,
    setGender,
    gender,
    handleDateChange,
    codeArea,
    setPhoneNumber,
    phoneNumber,
    email,
    setEmail,
    translations,
    medicalInsuranceList,
    medicalInsurance,
    medicalInsurancePlan,
    setMedicalInsurancePlan,
    medicalInsuranceNumber,
    setMedicalInsuranceNumber,
    visibleCountries,
    visibleMedicalInsuranceModal,
    visibleMenuOption,
    patientId,
    closeMenuOption,
    handleSelectIdentificationTypeMenuOption,
    closeCountries,
    onChangeCode,
    countries,
    onChangeMedicalInsurance,
    closeMedicalInsuranceModal,
    inputRef,
    comment,
    onSend,
    setComment,
    maxDocLength,
    minDocLength,
    externalIdParam,
    enableButton,
    setErrors,
    setErrorMessages,
    errorMessages,
    setEnableButton,
    initValues,
    initForm,
    initValuesAdittional,
  } = useAddPatientPage();

  const inputs = [
    {
      // label: locales_es.birthDate,
      placeholder: locales_es.birthDate,
      onBlur: () => removeFromErrors("birthDate"),
      id: 5,
      state: "selectedDate",
      value: selectedDate,
      type: "date",
      required: false,
      wrapperCustomClassName: "",
      customClassName: errors.includes("selectedDate") && props.classNameError,
      maxDate: new window.Date().getTime(),
      minDate: new window.Date(1903, 1, 1).getTime(),
      // hideCalendar: true,
    },
  ];

  return (
    <>
      {loading && <FullScreenLoading text={loadingText} />}
      {!cropping && (
        <Layout style={CommonStyles.header}>
          <HeaderButton
            style={{
              // marginTop: 26,
              marginLeft: 24,
            }}
            onPress={
              patientData && !externalIdParam
                ? () =>
                    history.replace(Routes.AddPatientHub, {
                      historyBackSteps: 2,
                    })
                : history.goBack
            }
          />
          <Text
            // marginTop={getStatusBarHeight()}
            marginHorizontal={24}
            bold
            size={24}
            lineHeight={28}
            style={{
              marginHorizontal: 24,
              // marginBottom: 24,
              flex: 1,
            }}
          >
            {screenTitle || "Añadir Paciente"}
          </Text>
        </Layout>
      )}
      <ScrollView
        style={CommonStyles.container}
        showsVerticalScrollIndicator={false}
        extraHeight={100}
        extraScrollHeight={100}
        contentContainerStyle={styles.contentContainerStyle}
      >
        <ImgLoadAndCrop
          image={profileImage}
          inputLabel={"Imagen de perfil"}
          imageId={"profileImage"}
          imageState={"profileImage"}
          inputWrapperCustomClassName="col-12 pl-md-0"
          canvasCustomClassName="profile"
          aspect={1}
          saveCrop={onSaveCrop}
          deleteCrop={() => console.log("deleteCrop")}
          deletedImage={() => console.log("deleteImage")}
          onCropping={() => setCropping(true)}
          onStopCropping={() => setCropping(false)}
        />
        <RequireData />
        {cropping ? null : (
          <>
            <InputApp
              testID="inputName"
              title={locales_es.fullname}
              placeholder={locales_es.fullname}
              value={name}
              marginTop={scale(5)}
              onChangeText={(value: string) =>
                handleValidatorInputsTypes(
                  initForm,
                  value,
                  "letter",
                  setName,
                  "name",
                  2
                )
              }
              borderColor={
                errors.includes("name")
                  ? theme.lineErrorColor
                  : theme.innearColor
              }
              onBlur={() => onBlur("name")}
              // keyboardType="email-address"
              blurOnSubmit={true}
              autoCapitalize="words"
              returnKeyType="next"
              autoFocus={false}
              onSubmitEditing={() => {
                lastnameRef.current.focus();
              }}
              showRequired={true}
              maxLength={20}
            />
            {errors.includes("name") && (
              <Text
                size={13}
                lineHeight={16}
                color="red"
                marginTop={5}
                marginLeft={5}
              >
                {errorMessages.name}
              </Text>
            )}
            <InputApp
              testID="inputLastname"
              forwardRef={lastnameRef}
              title={locales_es.lastname}
              placeholder={locales_es.lastname}
              marginTop={24}
              value={lastname}
              onChangeText={(value: string) =>
                handleValidatorInputsTypes(
                  initForm,
                  value,
                  "letter",
                  setLastname,
                  "lastname",
                  4
                )
              }
              borderColor={
                errors.includes("lastname")
                  ? theme.lineErrorColor
                  : theme.innearColor
              }
              onBlur={() => onBlur("lastname")}
              // keyboardType="email-address"
              blurOnSubmit={true}
              autoCapitalize="words"
              returnKeyType="next"
              autoFocus={false}
              onSubmitEditing={() => {
                identificationRef.current.focus();
              }}
              showRequired={true}
              maxLength={20}
            />
            {errors.includes("lastname") && (
              <Text
                size={13}
                lineHeight={16}
                color="red"
                marginTop={5}
                marginLeft={5}
              >
                {errorMessages.lastname}
              </Text>
            )}
            {identificationTypesList && identificationTypesList ? (
              <InputApp
                testID="inputIdentificationType"
                title={"Tipo de Documento"}
                marginTop={24}
                value={identificationType}
                editable={false}
                onPress={openMenuOption}
                showRequired={true}
              />
            ) : (
              <ActivityIndicator style={CommonStyles.activityIndicator} />
            )}
            <InputApp
              testID="inputIdentification"
              forwardRef={identificationRef}
              title={locales_es.identification}
              placeholder={locales_es.identification}
              marginTop={24}
              value={identification}
              onChangeText={(value: string) =>
                handleValidatorInputsTypes(
                  initForm,
                  value,
                  "number",
                  setIdentification,
                  "identification",
                  minDocLength
                )
              }
              borderColor={
                errors.includes("identification")
                  ? theme.lineErrorColor
                  : theme.innearColor
              }
              onBlur={() => onBlur("identification")}
              // keyboardType="email-address"
              blurOnSubmit={true}
              autoCapitalize="words"
              returnKeyType="next"
              autoFocus={false}
              onSubmitEditing={() => {
                emailRef.current.focus();
                dateRef.current.onPressDate();
              }}
              showRequired={true}
              maxLength={maxDocLength}
            />
            {errors.includes("identification") && (
              <Text
                size={13}
                lineHeight={16}
                color="red"
                marginTop={5}
                marginLeft={5}
              >
                {errorMessages.identification}
              </Text>
            )}
            <Text size={13} lineHeight={16} medium marginTop={24}>
              Sexo <RequireIndicator />
            </Text>
            <View style={styles.genders}>
              {gendersList && gendersList.length ? (
                gendersList.map((i, index) => {
                  const onPress = () => {
                    setGender(i);
                  };
                  return (
                    <GenderItem
                      {...i}
                      testID={i.name}
                      isChoose={i.id == gender.id}
                      isLastItem={index === gendersList.length - 1}
                      onPress={onPress}
                      key={i.id.toString()}
                    />
                  );
                })
              ) : (
                <ActivityIndicator style={CommonStyles.activityIndicator} />
              )}
            </View>

            <Text size={13} lineHeight={16} medium marginTop={24}>
              Fecha de Nacimiento
            </Text>
            <Form inputs={inputs} handleDateChange={handleDateChange} />

            <Text size={13} lineHeight={16} marginTop={24}>
              Teléfono Móvil
            </Text>
            <View style={styles.phoneView}>
              {codeArea ? (
                <ButtonChangeCode codeArea={codeArea} onPress={openCountries} />
              ) : (
                <ActivityIndicator />
              )}
              <TextInput
                testID="inputPhoneNumber"
                forwardRef={phoneNumberRef}
                value={phoneNumber}
                onChangeText={(value: string) =>
                  handleValidatorInputsTypes(
                    medicalInsurance &&
                      !medicalInsurance.is_none &&
                      medicalInsurance.name !== "Sin cobertura"
                      ? { ...initForm, initValues: initValuesAdittional }
                      : initForm,
                    value,
                    "number",
                    setPhoneNumber,
                    "phoneNumber",
                    6,
                    true
                  )
                }
                style={[
                  styles.phoneNumber,
                  {
                    borderColor: errors.includes("phoneNumber")
                      ? theme.lineErrorColor
                      : theme.innearColor,
                  },
                ]}
                editable={true}
                keyboardType="numeric"
                onBlur={() => onBlur("phoneNumber")}
                blurOnSubmit={true}
                returnKeyType="next"
                autoFocus={false}
                onSubmitEditing={() => {
                  emailRef.current.focus();
                }}
                placeholder="Número de teléfono"
                maxLength={12}
              />
            </View>
            {errors.includes("phoneNumber") && (
              <Text
                size={13}
                lineHeight={16}
                color="red"
                marginTop={5}
                marginLeft={5}
              >
                {errorMessages.phoneNumber}
              </Text>
            )}

            <InputApp
              testID="inputEmail"
              forwardRef={emailRef}
              title={locales_es.email}
              placeholder={locales_es.email}
              marginTop={24}
              value={email}
              onChangeText={(value: string) =>
                handleValidatorInputsTypes(
                  medicalInsurance &&
                    !medicalInsurance.is_none &&
                    medicalInsurance.name !== "Sin cobertura"
                    ? { ...initForm, initValues: initValuesAdittional }
                    : initForm,
                  value,
                  "email",
                  setEmail,
                  "email",
                  8,
                  true
                )
              }
              borderColor={
                errors.includes("email")
                  ? theme.lineErrorColor
                  : theme.innearColor
              }
              onBlur={() => onBlur("email")}
              keyboardType="email-address"
              blurOnSubmit={true}
              autoCapitalize="none"
              returnKeyType="next"
              autoFocus={false}
              onSubmitEditing={() => {
                // lastnameRef.current.focus();
              }}
              maxLength={30}
            />
            {errors.includes("email") && (
              <Text
                size={13}
                lineHeight={16}
                color="red"
                marginTop={5}
                marginLeft={5}
              >
                {errorMessages.email}
              </Text>
            )}
            <Text size={13} lineHeight={16} marginTop={24} marginBottom={6}>
              {translations && translations.medicalInsurance ? (
                translations.medicalInsurance
              ) : (
                <>Cobertura Médica</>
              )}
            </Text>
            <ButtonChangeCategory
              testID="buttonMedicalInsurance"
              category={medicalInsurance}
              onPress={openMedicalInsuranceModal}
            />
            {medicalInsurance &&
              !medicalInsurance.is_none &&
              medicalInsurance.name !== "Sin cobertura" && (
                <>
                  <InputApp
                    testID="inputMedicalInsurancePlan"
                    title={
                      translations && translations.medicalInsurancePlan
                        ? translations.medicalInsurancePlan
                        : locales_es.medicalInsurancePlan
                    }
                    placeholder={
                      translations && translations.medicalInsurancePlan
                        ? translations.medicalInsurancePlan
                        : locales_es.medicalInsurancePlan
                    }
                    marginTop={24}
                    value={medicalInsurancePlan}
                    onChangeText={(value: string) =>
                      handleValidatorInputsTypes(
                        { ...initForm, initValues: initValuesAdittional },
                        value,
                        "",
                        setMedicalInsurancePlan,
                        "medicalInsurancePlan",
                        0,
                        true
                      )
                    }
                    blurOnSubmit={true}
                    autoCapitalize="words"
                    returnKeyType="next"
                    autoFocus={false}
                    onSubmitEditing={() => {
                      medicalInsuranceNumberRef.current.focus();
                    }}
                    maxLength={20}
                    showRequired={false}
                  />
                  {errors.includes("medicalInsurancePlan") && (
                    <Text
                      size={13}
                      lineHeight={16}
                      color="red"
                      marginTop={5}
                      marginLeft={5}
                    >
                      {errorMessages.medicalInsurancePlan}
                    </Text>
                  )}
                  <InputApp
                    testID="inputMedicalInsuranceNumber"
                    forwardRef={medicalInsuranceNumberRef}
                    title={
                      translations && translations.medicalInsuranceNumber
                        ? translations.medicalInsuranceNumber
                        : locales_es.medicalInsuranceNumber
                    }
                    placeholder={
                      translations && translations.medicalInsuranceNumber
                        ? translations.medicalInsuranceNumber
                        : locales_es.medicalInsuranceNumber
                    }
                    marginTop={24}
                    returnKeyType="go"
                    value={medicalInsuranceNumber}
                    onChangeText={(value: string) =>
                      handleValidatorInputsTypes(
                        { ...initForm, initValues: initValuesAdittional },
                        value,
                        "",
                        setMedicalInsuranceNumber,
                        "medicalInsuranceNumber"
                      )
                    }
                    borderColor={
                      errors.includes("medicalInsuranceNumber")
                        ? theme.lineErrorColor
                        : theme.innearColor
                    }
                    showRequired={true}
                    maxLength={20}
                  />
                  {errors.includes("medicalInsuranceNumber") && (
                    <Text
                      size={13}
                      lineHeight={16}
                      color="red"
                      marginTop={5}
                      marginLeft={5}
                    >
                      {errorMessages.medicalInsuranceNumber}
                    </Text>
                  )}
                </>
              )}
            <Text type="H6" semibold marginTop={24}>
              Observaciones
            </Text>

            <TextInput
              placeholderTextColor={theme.placeholder}
              forwardRef={inputRef}
              value={comment}
              placeholder={"Comentarios adicionales sobre el paciente"}
              style={{
                fontFamily: "Muli_400Regular",
                fontSize: 15,
                lineHeight: 18,
                color: theme.text,
                height: scale(160),
                marginTop: 12,
              }}
              onChangeText={setComment}
              multiline={true}
              editable={true}
              maxLength={250}
            />

            {/*<Text type="H6" semibold marginTop={24} marginBottom={12}>
              Adjuntos (Opcional)
            </Text>*/}

            {/*<Photos data={images} onRemove={onRemoveAttachment}/>*/}

            {/*<AddAttachment
            imageCropPickerOptions={IMAGE_CROP_PICKER_OPTIONS_LOGO_IMAGE}
            title={'Añadir Adjunto'}
            onSelect={onSelectAttachment}
            onRemove={onRemoveAttachment}
          />*/}

            {/*<View
            style={{
              height: 1,
              backgroundColor: '#ddd',
              width: '100%',
              marginTop: 32,
              marginBottom: 24,
            }}
          />*/}
          </>
        )}
      </ScrollView>

      {!cropping && (
        <View style={styles.bottomFixedWrapper}>
          <ButtonLinear
            testID="buttonAddPatient"
            white
            title={
              externalIdParam
                ? "Guardar en Mis Pacientes"
                : patientId
                ? "Guardar Cambios"
                : "Añadir Paciente"
            }
            children={<Image source={iconNext} style={styles.buttonChildren} />}
            disabled={enableButton}
            onPress={onSend}
            style={styles.buttonLinear}
          />
        </View>
      )}

      {visibleMenuOption ? (
        <Modal
          visible={true}
          onRequestClose={closeMenuOption}
          transparent
          animationType={"fade"}
        >
          <ModalSelect
            onPressItem={handleSelectIdentificationTypeMenuOption}
            choices={identificationTypesList}
            close={closeMenuOption}
          />
        </Modal>
      ) : null}

      {visibleCountries ? (
        <Modal
          visible={true}
          onRequestClose={closeCountries}
          transparent
          animationType={"none"}
        >
          <ModalSlideBottom
            onClose={closeCountries}
            transY={helpers.isMobile() ? -661 : -1100}
          >
            <ModalChangePhoneCode
              onChangeCode={onChangeCode}
              countries={countries}
            />
          </ModalSlideBottom>
        </Modal>
      ) : null}

      {visibleMedicalInsuranceModal ? (
        <Modal
          visible={true}
          onRequestClose={closeMedicalInsuranceModal}
          transparent
          animationType={"none"}
        >
          <ModalSlideBottom
            onClose={closeMedicalInsuranceModal}
            transY={helpers.isMobile() ? -661 : -1100}
          >
            <ModalSearchStrings
              data={medicalInsuranceList}
              onChangeCategory={onChangeMedicalInsurance}
              placeholder={locales_es.search + "..."}
            />
          </ModalSlideBottom>
        </Modal>
      ) : null}

      {/*{visibleUploadAvatarOption ?
          <Modal
            visible={true}
            onRequestClose={closeUploadAvatarMenuOption}
            transparent
            animationType={'fade'}>
            <ModalSelect
              onPressItem={handleSelectMenuOption}
              choices={imageSourcesOptions}
              close={closeUploadAvatarMenuOption}
            />
          </Modal>
          : null}*/}
    </>
  );
});

export default AddPatient;

const styles = StyleSheet.create({
  header: {
    ...Theme.flexRowSpace,
  },
  container: {
    ...Theme.flexOne,
  },
  contentContainerStyle: {
    // paddingHorizontal: 24,
    // paddingTop: 40,
    paddingBottom: getBottomSpace() + 160,
  },
  buttonChildren: {
    ...Theme.icons,
    marginLeft: 8,
  },
  inputApp: {
    marginTop: 24,
  },
  name: {
    marginTop: scale(38),
  },
  homeAddress: {
    marginTop: 32,
  },
  buttonLinear: {
    // marginTop: 52,
  },
  bottomFixedWrapper: {
    position: "absolute",
    bottom: 0,
    zIndex: 100,
    width: "100%",
    paddingHorizontal: 24,
    paddingBottom: 52,
    backgroundColor: "#fff",
  },
  genders: {
    marginTop: 24,
    ...Theme.flexRow,
    ...Theme.center,
  },
  phoneView: {
    ...Theme.flexRow,
    marginTop: 4,
  },
  phoneNumber: {
    marginLeft: 8,
    flex: 1,
  },
});
