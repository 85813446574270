import React, {memo} from 'react';
import {Image, StyleSheet, View, TouchableOpacity} from 'react-native';
import {Colors} from '../../../../configs';
import Theme from '../../../../style/Theme';
import Text from '../../../../components/Text';
import {useTheme} from '../../../../configs/Theme';
import DateTimeService from '../../../../modules/DateTimeService';

interface PhotosItemProps {
  image?: any;
  onRemove?: () => void;
  // onPress?: () => void;
}

export default memo(({image, onRemove}: PhotosItemProps) => {
  const {theme} = useTheme();
  const dateTimeService = new DateTimeService();
  return (
    <View
      // style={[styles.container, {backgroundColor: theme.backgroundItem}]}
      style={[styles.container]}
      // onPress={onPress}
      activeOpacity={0.54}>
      <TouchableOpacity
        onPress={onRemove}
        style={{
          position: 'absolute',
          right: 20,
          top: 20,
        }}>
        <Image
          source={require('../../../../images/ic_close.png')}
          style={{tintColor: 'red'}}
        />
      </TouchableOpacity>
      <Image
        style={styles.image}
        source={{
          uri:
            image.full_image || 'data:' + image.mime + ';base64,' + image.data,
        }}
      />
      <View style={styles.view}>
        {image.filename && (
          <Text marginBottom={8} bold>
            {image.filename}
          </Text>
        )}
        {image.image && (
          <Text marginBottom={8} bold>
            {image.image.split('/')[6]}
          </Text>
        )}
        {image.creationDate && (
          <Text marginBottom={8} color={Colors.GrayBlue}>
            {dateTimeService.parseDateToConventionalAPIString(
              new Date(Number(image.creationDate)),
            )}
          </Text>
        )}
        {image.created_at && (
          <Text marginBottom={8} color={Colors.GrayBlue}>
            {dateTimeService.parseDateToConventionalAPIString(
              new Date(image.created_at),
            )}
          </Text>
        )}
        {image.size && <Text color={Colors.GrayBlue}>{image.size} bytes</Text>}
      </View>
    </View>
  );
});

const styles = StyleSheet.create({
  container: {
    ...Theme.flexRow,
    borderRadius: 16,
    marginBottom: 12,
    shadowColor: 'rgba(0, 64, 128, 0.04)',
    shadowOffset: {width: 0, height: 5},
    shadowRadius: 10,
    shadowOpacity: 1,
    elevation: 2,
  },
  image: {
    marginVertical: 24,
    marginRight: 24,
    marginLeft: 16,
    width: 78,
    height: 64,
    borderRadius: 4,
  },
  view: {
    marginVertical: 24,
  },
});
