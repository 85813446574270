import React, { Component } from "react";

export default class FormImageInput extends Component {

  cleanValue = (ev) => {
    ev.target.value = '';
  }

  render() {
    const { imageInputData } = this.props;
    const { handleImageChange} = this.props;

    const {
      id,
      required,
      disabled,
      customClassName,
      wrapperCustomClassName,
      onBlur,
      state,
      label,
    } = imageInputData;


    const inputClassName = "form-control rounded custom-file-input ";
    //label
    const defaultClassName = "custom-file-label ";
    const className = customClassName
      ? defaultClassName + customClassName
      : defaultClassName;

    const defaultWrapperClassName = "form-group hidden ";
    const wrapperClassName = wrapperCustomClassName
      ? defaultWrapperClassName + wrapperCustomClassName
      : defaultWrapperClassName;

    return (
      <div className={wrapperClassName}>
        <div className="custom-file">
          <input
            id="selectImageInputFile"
            type="file"
            accept="image/*"
            onChange={handleImageChange}
            className={inputClassName}
            key={id}
            required={required}
            disabled={disabled}
            onBlur={onBlur}
            onClick={this.cleanValue}
          />
          <label className={className} htmlFor={id}>
            {label} {required ? "*" : ""}
          </label>
        </div>
      </div>
    );
  }
}