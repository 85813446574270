import React, {useCallback, useEffect, useState} from 'react';
import {
  View,
  StyleSheet,
  Dimensions,
  Image,
  ScrollView,
} from 'react-native';
// @ts-ignore
import Theme from '../../style/Theme';
import {Colors, Routes} from '../../configs';
import {Route, useHistory} from 'react-router-dom';
import Container from '../../components/Layout/Container';
import Text from '../../components/Text';
import ButtonIconHeader from '../../components/ButtonIconHeader';
import {useTheme} from '../../configs/Theme';
import locales_es from '../../locales/es';
import Toast from 'react-native-toast-message';
import ButtonLinear from '../../components/Buttons/ButtonLinear';
import {DYNAMIC_TEXT_REGEX} from '../../models/constants';
import {ConsultsType} from '../../configs/Const';
import PrescriptionItem from '../../components/PrescriptionItem';
import scale from '../../utils/scale';
import iconClose from '../../images/ic_close.png';
import iconNext from '../../images/ic_next.png';
import ButtonText from "../../components/Buttons/ButtonText";
import {RWebShare} from "react-web-share";
import Overlay from '../../components/Overlay';
import SendDocuments from "../SendDocuments";
import pdfBackground from '../../images/MyWork/pdf-background.png';
import Helpers from "../../modules/helpers";
import FeatureItem from "../../components/FeatureItem";
import imgLoadDocument from "../../images/MyWork/img_work_profile@3x.png";
import useDimensions from '../../hooks/useDimensions';

interface DocumentVisorProps {
}

const DocumentVisor = (props: DocumentVisorProps) => {
  const { isDesktop } = useDimensions();
  const {theme} = useTheme();
  const history = useHistory();
  const params = history.location.state;
  const helpers = new Helpers();
  const [activePdf, setActivePdf] = useState(null);
  const [urlToShare, setURLToShare] = useState('');
  const [shareMessage, setShareMessage] = useState('');
  const {
    emails = [{value: ''}],
    selectionType,
    hideToast,
  } : any = props ? props : params ? params : {documents: []};
  const documents = props && props.documents ? props.documents : params && params.documents ? params.documents : [];

  //realizamos una funcion que nos permita abrir una sola vez una pestana nueva por cada documento que se encuentre en el array documents
  useEffect(() => {
    if (documents && documents.length) {
      documents.map((doc:any) => {
        if (doc.pdfSource && doc.pdfSource.uri) {
          helpers.openLink(doc.pdfSource.uri);
        }
      });
    }
    
  }, [document]);
  // We receive the patientId because we need to pass it to subsequent screens
  const [overlay, setOverlay] = useState<any>(null);

  const goToSendDocuments = () => {
    // history.push(`${Routes.SendDocuments}`, {documents, emails, selectionType});
    setOverlay(
      <SendDocuments
        showMainModal={(title, content, actions, customStyles, contentCustomClassName) => props.showModal(title, content, actions, customStyles, contentCustomClassName)}
        hideMainLoading={props.hideMainLoading}
        documents={documents}
        emails={emails}
        selectionType={selectionType}
      />
    )
  };

  useEffect(() => {
    let shareMsg = 'Hola, le envío sus prescripciones: \n';
    documents.map((doc: any, i: number) => {
      if (i == 0) {
        setURLToShare(doc.pdfSource.uri);
      }
      if (documents.length > 1) {
        shareMsg += 'Documento ' + (i + 1) + ': ' + doc.pdfSource.uri + '.\n';
      }
    });

    setShareMessage(shareMsg);

    return history.listen((location:any, action:any) => {
      if (action === "POP") {
        if (params.selectionType === 'Certificate'){
          history.replace(Routes.AddCertificate,{})
          return
        }
        if (params.selectionType === 'Prescription'){
          history.replace(Routes.AddPrescription,{})
          return
        } else {
          history.replace(Routes.Home)
          return
        }
      }
    });
  }, []);
  
  const goBack = () => {
    
    if (params.selectionType === 'Certificate'){
     history.push({pathname:`${Routes.Certificates}`,state:[]})
     return
    }
    if (params.selectionType === 'Prescription'){
     history.push({pathname:`${Routes.Prescriptions}`,state:[]})
     return
    }
    else {
     history.push({pathname:`${Routes.Home}`,state:[]})
     return
    }
   }

  const goHome = () => {
    history.push(`${Routes.Home}`);
  };

  const isDocumentPlural = documents.length > 1;

  const showToast = () => {
    let text = isDocumentPlural
      ? locales_es.toast[selectionType].createdPlural
      : locales_es.toast[selectionType].created;

    text = text.replace(DYNAMIC_TEXT_REGEX, documents.length);

    Toast.show({
      type: 'success',
      text1: locales_es.successModal.title,
      // text2: text + ' 💊',
      text2: text,
      autoHide: true,
      visibilityTime: 10000,
      // topOffset: getStatusBarHeight() * 4,
      onPress: () => Toast.hide(),
    });
  };

  useEffect(() => {
    if (!hideToast) {
      showToast();
    }
  }, [documents]);

  const checkDocumentsIds = () => {
    let passed = true;
    documents.map((doc:any) => {
      if (!doc.prescriptionId) {
        passed = false;
      }
    });
    return passed;
  };

  const renderPrescription = (doc:any) => {
    // if (!overlay) {
    //   helpers.openLink(doc.pdfSource.uri); // Always load document
    // }
    const item = {
        img: imgLoadDocument,
        title: locales_es.viewDocument,
        onPress: () => helpers.openLink(doc.pdfSource.uri),
        style: {
          width: '100%',
          minWidth: 300
        }
      };
    return (
      doc.pdfSource && doc.pdfSource.uri ?
        /*<object data={doc.pdfSource.uri} type="application/pdf" style={styles.pdf}>*/
        <div style={styles.pdfBackground}>
          {isDesktop ? <iframe src={`${doc.pdfSource.uri}#view=FitH&toolbar=0&statusbar=0&messages=0&navpanes=0&scrollbar=0`}
                  style={{ display: "block", height: "70vh", width: "100%" }}></iframe> :
          <FeatureItem {...item} key={item.title} />}
        </div>
        /*</object>*/
        : <p>No se ha podido cargar el documento correctamente.</p>
    );
  };

  const showEmailButton = documents && documents.length && checkDocumentsIds();

  

  return (
    <>
      {overlay && <Overlay>{overlay}</Overlay>}
      <View style={{flexDirection: 'row', justifyContent: 'space-between', padding: 10}}>
        <ButtonIconHeader onPress={goBack}/>
        <ButtonIconHeader
          onPress={goHome}
          icon={iconClose}
          marginRight={6}
          styleIcon={{
            width: 14,
            height: 14,
          }}
        />
      </View>
      <Container style={styles.container}>
        {activePdf ? (
          <>
            <View style={styles.headerBack}>
              <Text
                blueLight
                size={18}
                lineHeight={18}
                center={true}
                color={Colors.BlueCrayola}
                marginTop={10}
                semibold
                onPress={() => {
                  // props.navigation.setOptions({headerShown: true});
                  setActivePdf(null);
                }}>
                {locales_es.close}
              </Text>
            </View>
            <div style={{
              top: 40,
              height: '100%',
              position: 'relative',
              width: '100%',
            }}>
              {renderPrescription(activePdf)}
            </div>
          </>
        ) : documents && documents.length ? (
          documents.length === 1 ? (
              renderPrescription(documents[0])
          ) : (
            <ScrollView>
              {documents.map((doc: any, index: number) => {
                return (
                  <PrescriptionItem
                    {...doc}
                    count={index + 1}
                    onPressItem={() => {
                      // props.navigation.setOptions({headerShown: false});
                      setActivePdf(doc);
                    }}
                    key={doc.prescriptionId.toString()}
                    type={selectionType ?? ConsultsType.Prescription}
                  />
                );
              })}
            </ScrollView>
          )
        ) : (
          <Text>{locales_es.documentVisorLoadedFailed}.</Text>
        )}
      </Container>

      {!overlay &&
        <View style={styles.bottomFixedWrapper}>
          {showEmailButton && (
            <ButtonLinear
              white
              title={
                isDocumentPlural
                  ? locales_es.sendByEmailPlural
                  : locales_es.sendByEmail
              }
              children={
                <Image
                  source={iconNext}
                  style={styles.buttonChildren}
                />
              }
              onPress={goToSendDocuments}
              // style={styles.buttonLinear}
            />
          )}
          <RWebShare
            data={{
              text: shareMessage,
              url: urlToShare,
              title: locales_es.sharePrescriptionsTitle,
            }}
            closeText="Cerrar"
            onClick={() => console.log("shared successfully!")}
            disableNative={true}
            
          >
            <ButtonText
              borderColor={theme.backgroundItem}
              title={isDocumentPlural
                ? locales_es.shareByExternalPlural
                : locales_es.shareByExternal}
              style={styles.buttonUpdate}
            />
          </RWebShare>
        </View>
      }
    </>
  );
};

export default DocumentVisor;

const styles = StyleSheet.create({
  container: {
    
  },
  pdf: {
    // width: Dimensions.get('window').width,
    // height: Dimensions.get('window').height,
    width: '100%',
    height: '100%',
    position: 'absolute',
    top: 0,
    display: 'block',
  },
  headerTitle: {
    justifyContent: 'center',
    alignItems: 'flex-start',
    flex: 1,
  },
  bottomButton: {
    paddingTop: 12,
    paddingBottom: 21,
    paddingHorizontal: 24,
  },
  header: {
    ...Theme.headerNavigationStyle,
  },
  bottomFixedWrapper: {
    zIndex: 100,
    width: '100%',
    paddingHorizontal: 24,
    paddingBottom: 52,
    marginTop: 40,
    flexDirection: 'column',
    justifyContent: 'flex-end',
    justifyItems: 'center',
  },
  buttonUpdate: {
    marginVertical: 12,
    paddingTop: 24,
  },
  headerBack: {
    height: 35,
    borderRadius: 5,
    position: 'absolute',
    top: 0,
    zIndex: 100,
    width: '100%',
    backgroundColor: '#fff',
    marginTop: 0,
    border: '1px rgb(37, 116, 255, 0.4) solid',
  },
  pdfBackground: {
    width: '100%',
    height: '100%',
    // backgroundImage: `url(${pdfBackground})`,
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 200,
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }
});
