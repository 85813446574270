import React from 'react';
import {
  View,
  StyleSheet,
  TouchableOpacity,
  Image,
  ImageSourcePropType,
} from 'react-native';
import {Colors} from '../../../configs';
import scale from '../../../utils/scale';
import Theme from '../../../style/Theme';
import Text from '../../../components/Text';
import {themes, useTheme} from '../../../configs/Theme';
import maleIcon from '../../../images/ic_male@3x.png';
import femaleIcon from '../../../images/ic_female@3x.png';
import otherIcon from '../../../images/ic_account_normal@3x.png';

interface GenderItemProps {
  isChoose?: boolean;
  isLastItem?: boolean;
  icon: ImageSourcePropType;
  title?: string;
  onPress: () => void;
}

const getImageSource = id => {
  switch (id) {
    case 1:
      return maleIcon;
      break;
    case 2:
      return femaleIcon;
      break;
    default:
      return otherIcon;
  }
};

const GenderItem = (props: GenderItemProps) => {
  const {theme} = useTheme();
  return (
    <View
      testID={props.testID}
      style={[styles.container, !props.isLastItem && styles.notLastItem]}>
      <TouchableOpacity
        style={[
          styles.touch,
          props.isChoose && styles.touchActive,
          {borderColor: theme.innearColor},
          props.stylesOverride || {}
        ]}
        activeOpacity={0.54}
        onPress={props.onPress}>
        <Image
          source={getImageSource(props.id)}
          style={[
            styles.icon,
            props.isChoose ? styles.activeIcon : styles.inactiveIcon,
            {
              tintColor: props.isChoose ? theme.text : theme.innearColor,
            },
          ]}
        />
      </TouchableOpacity>
      <Text
        size={13}
        lineHeight={22}
        bold={props.isChoose}
        color={props.isChoose ? Colors.DarkJungleGreen : Colors.GrayBlue}
        center
        marginTop={16}>
        {props.name}
      </Text>
    </View>
  );
};

export default GenderItem;

const styles = StyleSheet.create({
  container: {},
  notLastItem: {
    marginRight: 48,
  },
  touch: {
    width: scale(72),
    height: scale(72),
    borderRadius: 24,
    borderColor: Colors.Platinum,
    borderWidth: 1,
    ...Theme.center,
  },
  touchActive: {
    backgroundColor: Colors.DodgerBlue,
    borderColor: Colors.DodgerBlue,
  },
  icon: {
    width: 32,
    height: 32,
  },
  inactiveIcon: {
    tintColor: Colors.DarkNavy,
  },
  activeIcon: {
    tintColor: Colors.White,
  },
});
