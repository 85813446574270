import React from 'react';
import {Text, View, StyleSheet, Image} from 'react-native';
import LinearGradient from 'react-native-linear-gradient';
import {userDataLocalStorage} from '../../models/constants';
import Routes from './../../configs/Routes';
import locales_es from '../../locales/es';
import logo from '../../images/logo.png';
import CountryService from '../../modules/CountryService';
import Helpers from "../../modules/helpers";

class AuthLoading extends React.Component {
  constructor(props) {
    super(props);
    this.countryService = new CountryService();
    this.helpers = new Helpers();
  }

  componentDidMount(): void {
    this._bootstrapAsync();
  }

  backPressed = () => {
    return true;
  };

  // Fetch the token from storage then navigate to our appropriate place
  _bootstrapAsync = async () => {
    // let userData = await AsyncStorage.getItem(userDataLocalStorage);
    let userData = localStorage.getItem(userDataLocalStorage);
    userData = JSON.parse(userData);
    if (userData) {
      this.countryService
        .getData()
        .then(() => {
          this.goToPaths(userData, Routes.Home);
        })
        .catch((err: any) => {
          this.goToPaths(userData, Routes.Home, Routes.CountrySelector);
          console.log(err);
        });
    } else {
      this.goToPaths(userData, Routes.CountrySelector);
    }
  };

  goToPaths = async (userData, loggedIn, loggedOut) => {
    // WARN: in localhost, this is always true
    if (this.helpers.isRCTAApp()) {
      this.props.history.replace(userData && userData.token ? loggedIn : (loggedOut || loggedIn))
    } else {
      this.props.history.replace(Routes.Services);
    }
  };

  // Antes mostrabamos un <Loading /> aca...
  // Render any loading content that you like here
  render() {
    return (
      <LinearGradient
        locations={[0.15, 1]}
        colors={['rgb(255,255,255)', 'rgb(20,176,182)']}
        style={styles.shadowBg}>
        <View style={{flex: 1, alignItems: 'center', justifyContent: 'center'}}>
          <View style={{width: 300, height: 260}}>
            <Image
              source={logo}
              style={{width: 300, height: 260}}
              resizeMode="contain"
            />
            <Text style={{color: '#fff', textAlign: 'center'}}>
              {locales_es.loading}...
            </Text>
          </View>
        </View>
      </LinearGradient>
    );
  }
}

export default AuthLoading;

const styles = StyleSheet.create({
  shadowBg: {
    // width: Metrics.WIDTH,
    // height: Metrics.HEIGHT,
    flex: 1,
    position: 'absolute',
    top: 0,
    right: 0,
    left: 0,
    bottom: 0,
  },
});
