import React, { useState, } from 'react';
import {
    StyleSheet,
  } from "react-native";
// import '../../index.css'

interface Props {
    text?: string,
    children?: React.ReactNode
}

export const Tooltip = ({text,children}:Props) => {

    const [isVisible, setIsVisible] = useState<boolean>(false)

  return (
    <div 
        style={styles.tooltipContainer}
        onMouseEnter={() => setIsVisible(true)}
        onMouseLeave={() => setIsVisible(false)}
    >
        { children }
        { isVisible && 
            <div style={styles.tooltip}>
                {text}
            </div>
        }
        
    </div>
  )
}

const styles = StyleSheet.create<any>({
    tooltipContainer: {
        position: 'relative',
        display: 'inline-block',
       
    },
    tooltip: {
        position: 'absolute',
        textAlign: 'center',
        width: '120px',
        top: '100%',
        left: '50%',
        transform: 'translateX(-50%)',
        padding: '0.5rem',
        backgroundColor: '#000',
        color: '#fff',
        borderRadius: '0.5rem',
        fontSize: '0.75rem',
        zIndex: 1,
        '&::after': {
            content: '""',
            position: 'absolute',
            top: '0',
            left: '50%',
            transform: 'translateX(-50%)',
            width: '0',
            height: '0',
            borderLeft: '0.5rem solid transparent',
            borderRight: '0.5rem solid transparent',
            borderBottom: '0.5rem solid #000',
        }
    }
});

export default Tooltip;
