import APIService from "./apiService";
import locales_es from "../locales/es";
import {clinicDataExpireDateLocalStorage, clinicDataLocalStorage} from "../models/constants";

class ConfigService {

    constructor(){
        this.api = new APIService();
    }

    async getLocalClinicData() {
        return new Promise((resolve, reject) => {
            this.getRemoteClinicData()
                .then(res => resolve(res))
                .catch(err => reject(err));
        });
    }

    setClinicDataExpireDate(date, ttl) {
        localStorage.setItem(clinicDataExpireDateLocalStorage, (date.getTime() + ttl));
    }

    async getRemoteClinicData() {
        return new Promise((resolve, reject) => {
            this.api.getClinic(window.location.host).then(res => {
                const clinicData = res.data;
                localStorage.setItem(clinicDataLocalStorage, JSON.stringify(clinicData || {}));
                resolve(clinicData);
            }).catch(err => {
                // this.props.showMainModal(locales_es.errorModal.title, this.helpers.getErrorMsg(err));
                reject(err);
            });
            /*this.api.getClinics().then(res => {
                const clinicData = res.data.filter(clinic => Number(clinic.id) === Number(process.env.REACT_APP_CLINIC_ID))[0];
                localStorage.setItem(clinicDataLocalStorage, JSON.stringify(clinicData || {}));
                resolve(clinicData);
            }).catch(err => {
                // this.props.showMainModal(locales_es.errorModal.title, this.helpers.getErrorMsg(err));
                reject(err);
            });*/
        });
    }

    clearLocalClinicData() {
        localStorage.removeItem(clinicDataLocalStorage);
    }

    getAppIdByAppName(AppName) {
        return new Promise((resolve, reject) => {
            this.api.getAppIdByAppName(AppName)
                .then((res)=>{
                    resolve(res);
                }).catch((err)=>{
                    console.error('Get Initial Config - Fatal Error');
                    reject(new Error(err));
            });
        });
    }
}

export default ConfigService;
