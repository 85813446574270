import React, {useCallback, useState} from 'react';
import {View, StyleSheet} from 'react-native';
import Text from '../../../components/Text';
import Theme from '../../../style/Theme';
import scale from '../../../utils/scale';
import {Routes} from '../../../configs';
import ButtonLinear from '../../../components/Buttons/ButtonLinear';
import Container from '../../../components/Layout/Container';
import Lottie from 'react-lottie';
import ButtonBorder from '../../../components/Buttons/ButtonBorder';
import APIService from '../../../modules/apiService';
import Helpers from '../../../modules/helpers';
import locales_es from '../../../locales/es';
import {showModalInfo} from '../../../modules/ViewHelpers';
import FullScreenLoading from '../../../components/fullscreenLoading';
import {ConsultsType} from "../../../configs/Const";
import animationData from "../../../animations/creating-your-first-recipe.json";
import {useHistory} from "react-router-dom";

interface CreatingYourFirstPrescriptionProps {
}

const CreatingYourFirstPrescription = (
  props: CreatingYourFirstPrescriptionProps,
) => {
  const history = useHistory();
  const params = history.location.state;
  const emails =
    params && params.emails ? params.emails : [{value: ''}];
  const prescriptionProfileId =
    params && params.prescriptionProfileId ? params.prescriptionProfileId : 0;
  const api = new APIService();
  const helpers = new Helpers();
  const [loading, setLoading] = useState(false);

  const goToDocumentVisor = pdfSource => {
    history.push(`/${Routes.DocumentVisor}`, {
      documents: [
        {
          pdfSource,
        },
      ],
      selectionType: ConsultsType.Prescription,
      statusName: locales_es.testPrescription,
      hideToast: true,
      emails,
    });
  };

  const onConfirmConfiguration = useCallback(() => {
    history.replace(`/${Routes.Home}`);
  }, [history]);

  const onCreateTestPrescription = () => {
    setLoading(true);
    api
      .testPrescription(prescriptionProfileId)
      .then((res: any) => {
        setLoading(false);
        goToDocumentVisor({uri: res.data.url});
      })
      .catch((err: any) => {
        setLoading(false);
        helpers.processError(err);
      });
  };

  const animationSize = 200;
  const animationOptions = {
    loop: false,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice"
    }
  };

  return (
    <Container style={styles.container}>
      {loading && <FullScreenLoading/>}
      <View
        style={{
          height: '30%',
          width: '100%',
          marginLeft: '5%',
        }}>
        <Lottie
          options={animationOptions}
          height={animationSize}
          width={animationSize}
        />
      </View>
      <Text size={20} lineHeight={24} bold marginTop={scale(55)}>
        ¡Generando tu receta de prueba!
      </Text>
      <Text size={15} lineHeight={24} marginTop={16} center>
        Creamos una receta de prueba con su configuración para que pueda
        observar el resultado final
      </Text>
      <ButtonBorder
        style={{marginTop: 10, paddingHorizontal: 32}}
        title={'Ver Receta de Prueba'}
        onPress={onCreateTestPrescription}
        placeholder
      />
      <ButtonLinear
        white
        title={'Confirmar Configuración y Continuar'}
        style={{paddingHorizontal: 32}}
        onPress={onConfirmConfiguration}
      />
      {/*<Text
          size={15}
          lineHeight={24}
          color={Colors.DodgerBlue}
          style={{marginTop: 60, paddingHorizontal: 32}}
          onPress={onConfirmConfiguration}>
          Confirmar Configuración y Continuar
        </Text>*/}
    </Container>
  );
};

export default CreatingYourFirstPrescription;

const styles = StyleSheet.create({
  container: {
    ...Theme.container,
    ...Theme.center,
  },
});
