import React, {memo} from 'react';
import {
  View,
  StyleSheet,
  Image,
  ViewStyle,
  TouchableOpacity,
  Text as NativeText,
} from 'react-native';
import Text from '../../components/Text';
import Theme from '../../style/Theme';
import Layout from '../../components/Layout/Layout';
import {useTheme} from '../../configs/Theme';
import {SOURCE_ICON} from '../../images';
import {Colors} from '../../configs';
import iconDown from '../../images/down.png';

interface PracticeItemProps {
  item: any;
  style?: ViewStyle;
  dark?: boolean;
  onPress?: () => void;
  selected?: boolean;
}

const PracticeItem = memo(
  ({item, style, dark, onPress, selected}: PracticeItemProps) => {
    const {theme} = useTheme();

    return (
      <TouchableOpacity onPress={onPress} style={{
        width: '80%',
        alignSelf: 'center',
      }}>
        <Layout
          style={[
            styles.container,
            style,
            {
              borderColor: theme.lineColor,
              backgroundColor: dark ? theme.userInfo : theme.backgroundItem,
            },
          ]}>
          <View style={[Theme.flexRow, styles.iconWrapper]}>
            {selected ? <Image style={{width: 25, height: 25}} source={SOURCE_ICON.checkMark} /> : null}
          </View>
          <View style={[Theme.flexOne, {marginLeft: 10}]}>
            <Text
              size={15}
              lineHeight={18}
              bold
              marginBottom={8}
              style={{textAlign: 'left'}}>
              {item.code ? item.code + ' - ' : ''}
              {item.name}
            </Text>
          </View>
          <Image
            source={iconDown}
            style={styles.arrowRight}
          />
          {item.clinic_id ? (
            <View style={Theme.flexOne}>
              <View style={styles.labelWrapper}>
                <NativeText style={styles.label}>Institucional</NativeText>
              </View>
            </View>
          ) : null}
        </Layout>
      </TouchableOpacity>
    );
  },
);

export default PracticeItem;

const styles = StyleSheet.create({
  container: {
    padding: 24,
    marginBottom: 12,
    borderRadius: 16,
    ...Theme.shadow,
    ...Theme.flexRow,
  },
  iconWrapper: {
    borderWidth: 1,
    borderColor: Colors.BlueCrayola,
    borderRadius: 100,
    width: 40,
    height: 40,
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center',
  },
  labelWrapper: {
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  label: {
    backgroundColor: Colors.DodgerBlue,
    padding: 5,
    marginHorizontal: 5,
    color: Colors.Snow,
    borderRadius: 5,
    overflow: 'hidden',
  },
  arrowRight: {
    width: 25,
    height: 25,
    transform: [{rotate: '-90deg'}],
  },
});
