import React, { useCallback, useEffect, useState } from "react";
import { View, StyleSheet } from "react-native";
import Theme from "../../../style/Theme";
import { Routes } from "../../../configs";
import ButtonLinear from "../../../components/Buttons/ButtonLinear";
import Container from "../../../components/Layout/Container";
import APIService from "../../../modules/apiService";
import Helpers from "../../../modules/helpers";
import locales_es from "../../../locales/es";
import { showModalInfo } from "../../../modules/ViewHelpers";
import FullScreenLoading from "../../../components/fullscreenLoading";
import { useHistory } from "react-router-dom";
import { RWebShare } from "react-web-share";
import ButtonText from "../../../components/Buttons/ButtonText";
import { useTheme } from "../../../configs/Theme";

interface CreatingYourFirstPrescriptionProps {}

const CreatingYourFirstPrescription = (
  props: CreatingYourFirstPrescriptionProps
) => {
  const { theme } = useTheme();
  const [url, setUrl] = useState();
  const history = useHistory();
  const params = history.location.state;
  const prescriptionProfileId =
    params && params.prescriptionProfileId ? params.prescriptionProfileId : 0;
  const api = new APIService();
  const helpers = new Helpers();
  const [loading, setLoading] = useState(false);

  const onConfirmConfiguration = useCallback(() => {
    history.replace(`/${Routes.Home}`);
  }, [history]);

  useEffect(() => {
    api
      .testPrescription(prescriptionProfileId)
      .then((res: any) => {
        setLoading(false);
        setUrl(res.data.url);
      })
      .catch((err: any) => {
        setLoading(false);
        helpers.processError(err);
      });
  }, []);

  const shareMsg =
    "Hola, le envío sus prescripciones: \n" +
    "Documento " +
    1 +
    ": " +
    url +
    ".\n";

  return (
    <Container style={styles.container}>
      {(loading || !url) && <FullScreenLoading />}
      {url && (
        <iframe
          src={`${url}#view=FitH&toolbar=0&statusbar=0&messages=0&navpanes=0&scrollbar=0`}
          title="testPdf"
          style={{ display: "block", height: "100vh", width: "100%" }}
        />
      )}
      <View style={{display: 'flex', flexDirection: 'row', gap: 16, flexWrap: 'wrap', marginTop: 24, alignItems: 'baseline', justifyContent: 'center',}}>
        <RWebShare
          data={{
            text: shareMsg,
            url: window.location.origin,
            title: locales_es.sharePrescriptionsTitle,
          }}
          closeText="Cerrar"
          onClick={() => console.log("shared successfully!")}
        >
          <ButtonText
            borderColor={theme.backgroundItem}
            title={locales_es.shareByExternal}
          />
        </RWebShare>
        <ButtonLinear
          white
          title={"Confirmar Configuración y Continuar"}
          style={{ paddingHorizontal: 32 }}
          onPress={onConfirmConfiguration}
        />
      </View>
    </Container>
  );
};

export default CreatingYourFirstPrescription;

const styles = StyleSheet.create({
  container: {
    ...Theme.container,
    ...Theme.center,
  },
});
