import React, {memo, useState} from 'react';
import {View, StyleSheet, Image} from 'react-native';
import Text from '../Text';
import {Colors, Routes} from '../../configs';
import ButtonLinear from '../Buttons/ButtonLinear';
import locales_es from '../../locales/es';
import Layout from '../Layout/Layout';
import InputApp from '../InputApp';



interface ModalInfoProps {
  title: string;
  action: any;
  onPressDefault: any;
  placeholder?: string;
  subTitle?: string;
  width?: string;
  cancelAction: any;
}

const ModalWithInput = memo((props: ModalInfoProps) => {

  const [message, setMessage] = useState('');
  const {title, action,onPressDefault, placeholder, subTitle, width, cancelAction} = props;
  
  return (
    <View style={styles.container}>
      <Layout
        style={{
          borderRadius: 16,
          paddingHorizontal: 24,
          paddingVertical: 32,
          width: width ?? '90%',
          alignSelf: 'center',
        }}>
        <Text center size={17} lineHeight={20} bold>
          {title}
        </Text>
        {
          subTitle && subTitle.length > 0 && (<>
          <Text center size={13} lineHeight={22} marginTop={16}>
            {subTitle}
          </Text>
          </>)
        }
        <InputApp 
          placeholder={ placeholder ? placeholder : ''}
          value={message}
          onChangeText={setMessage}
          multiline={true}
          editable={true}
          maxLength={250}
        />
        <View style={styles.centerButton}>
        <ButtonLinear
            white
            title={locales_es.cancel}
            onPress={()=>{
              onPressDefault();
              cancelAction();
            }}
            style={{width: '95%'}}
            colors={[Colors.Red, Colors.RedNeonFuchsia]}
            styleButton={{width: '45%'}}
          />
          <ButtonLinear
            white
            title={locales_es.accept}
            onPress={()=>{
              action(message);
              onPressDefault();
            }}
            style={{width: '95%'}}
            styleButton={{width: '45%'}}
          />
          
        </View>
      </Layout>
    </View>
  );
});

export default ModalWithInput;

const styles = StyleSheet.create({
  container: {
    backgroundColor: Colors.Black68,
    ...StyleSheet.absoluteFillObject,
    paddingHorizontal: 24,
    justifyContent: 'center',
    position: 'absolute',
    zIndex: 100,
  },
  centerButton: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    marginTop: 24,
    marginLeft: '2.5%',
    alignItems: 'center',
  }
});
