import React, { useState } from "react";
import Text from "../../components/Text";
import ProfileItem from "./ProfileItem";
import Container from "../../components/Layout/Container";
import ButtonIcon from "../Buttons/ButtonIcon";
import { Colors, Routes } from "../../configs";
import { useHistory } from "react-router-dom";
import Layout from "../Layout/Layout";
import AccountItem from "../AccountItem/Index";
import { SOURCE_ICON } from "../../images";
import { showModalInfo } from "../../modules/ViewHelpers";
import { api, helpers } from "../../import";
import locales_es from "../../locales/es";
import { ConsultsType } from "../../configs/Const";
import Theme from "../../style/Theme";
import { EventRegister } from "../../modules/EventRegister";
import { EVENT_SHOW_MODAL_INFO } from "../../constants/Events";
import { StyleSheet, View, Image, TouchableOpacity } from "react-native";
import { API_FRONT_URL } from "../../models/front-urls";
import ic_copy from "../../images/ic_copy@3x.png";
import { Tooltip } from "../../components/Tooltip";
import Toast from "react-native-toast-message";

interface AboutProps {}

const About = (props: AboutProps) => {
  const history = useHistory();
  const { onCopyClipboard } = props;

  const openQRWebView = () => {
    api
      .getQRToShow()
      .then((res: any) => {
        if (res.data && res.data.qr_full_url) {
          EventRegister.emit(EVENT_SHOW_MODAL_INFO, {
            title: locales_es.infoModal.title,
            message:
              "Muestre este QR al paciente para que pueda darse de alta en su ficha de pacientes.",
            actions: [
              {
                text: "Ver QR",
                onPress: () => window.open(res.data.qr_full_url, "_blank"),
                white: true,
              },
            ],
          });
          // alert('Muestre este QR al paciente para que pueda darse de alta en su ficha de pacientes.');
          // history.push(`/${Routes.WebviewLoader}`, {uri: res.data.qr_full_url});
        } else {
          showModalInfo(
            locales_es.errorModal.title,
            "No se pudo cargar el QR."
          );
        }
      })
      .catch((err: any) => {
        helpers.processError(err);
      });
  };
  const sendMailQR = () => {
    history.push(`/${Routes.SendDocuments}`, {
      documents: [],
      emails: [{ value: "" }],
      selectionType: ConsultsType.QRAddPatient,
    });
  };

  const openLink = () => {
    let link = `${API_FRONT_URL}/p/${props.slug}`;
    window.open(link, "_blank");
  };

  return (
    <>
      <Text
        lineHeight={20}
        size={17}
        bold
        marginLeft={16}
        marginTop={20}
        marginBottom={20}
      >
        QR para su consultorio
      </Text>
      <Layout style={styles.content}>
        <AccountItem
          style={styles.firstView}
          icon={SOURCE_ICON.qrPrint}
          name={"Imprimir QR"}
          lineBottom
          onPress={sendMailQR}
        />
        <AccountItem
          style={styles.firstView}
          icon={SOURCE_ICON.qrShow}
          name={"Mostrar QR desde la app"}
          onPress={openQRWebView}
        />
      </Layout>
      <Container style={styles.container}>
        <Text
          lineHeight={20}
          size={17}
          bold
          marginLeft={16}
          marginTop={40}
          marginBottom={20}
        >
          Datos de Mi Perfil
        </Text>
        <ProfileItem
          icon={"doctor"}
          title={"Información Básica"}
          rightButton={
            <ButtonIcon
              onPress={() => {
                history.push(`/${Routes.BasicInformation}`, {
                  profileMode: true,
                });
              }}
              icon={"edit"}
              style={styles.buttonEdit}
            />
          }
        >
          <Text marginTop={24} marginLeft={24} size={13} lineHeight={16}>
            Prefijo
          </Text>
          <Text marginTop={8} marginLeft={24} bold size={15} lineHeight={18}>
            {props.professional_prefix.name}
          </Text>
          <Text marginTop={24} marginLeft={24} size={13} lineHeight={16}>
            Nombre
          </Text>
          <Text marginTop={8} marginLeft={24} bold size={15} lineHeight={18}>
            {props.name}
          </Text>
          <Text marginTop={24} marginLeft={24} size={13} lineHeight={16}>
            Apellido
          </Text>
          <Text marginTop={8} marginLeft={24} bold size={15} lineHeight={18}>
            {props.lastname}
          </Text>
          <Text marginTop={24} marginLeft={24} size={13} lineHeight={16}>
            Tipo de Identificación
          </Text>
          <Text marginTop={8} marginLeft={24} bold size={15} lineHeight={18}>
            {props.identificationType && props.identificationType.name
              ? props.identificationType.name
              : "No informado"}
          </Text>
          <Text marginTop={24} marginLeft={24} size={13} lineHeight={16}>
            Número de Identificación
          </Text>
          <Text marginTop={8} marginLeft={24} bold size={15} lineHeight={18}>
            {props.identification ? props.identification : "No informado"}
          </Text>
          <Text marginTop={24} marginLeft={24} size={13} lineHeight={16}>
            Teléfono Móvil
          </Text>
          <Text marginTop={8} marginLeft={24} bold size={15} lineHeight={18}>
            {props.prefix_cell_phone} {props.cell_phone}
          </Text>
          <Text marginTop={24} marginLeft={24} size={13} lineHeight={16}>
            {locales_es.configuration_page.user_data.frontUri}
          </Text>
          <View
            style={{
              flexDirection: "row",
              alignItems: "left",
              justifyContent: "left",
            }}
          >
            <Text
              marginTop={8}
              marginLeft={24}
              bold
              size={15}
              lineHeight={18}
              style={{
                color: Colors.BlueCrayola,
                textDecorationLine: "underline",
              }}
              onPress={openLink}
            >
              {` ${API_FRONT_URL}/p/${props.slug}`}
            </Text>

            <TouchableOpacity onPress={onCopyClipboard}>
              <Tooltip text="Copiar link de pago">
                <Image
                  source={ic_copy}
                  resizeMode="contain"
                  style={{
                    tintColor: Colors.Black,
                    minHeight: 25,
                    minWidth: 25,
                    marginHorizontal: 10,
                  }}
                />
              </Tooltip>
            </TouchableOpacity>
          </View>
        </ProfileItem>
      </Container>
    </>
  );
};

export default About;

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  content: {
    marginVertical: 8,
    borderRadius: 16,
  },
  firstView: {
    ...Theme.flexRowSpace,
    paddingTop: 24,
    paddingHorizontal: 24,
    paddingBottom: 16,
  },
  buttonEdit: {
    width: 32,
    height: 32,
    backgroundColor: null,
  },
});
