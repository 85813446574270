import React from "react";
import {
  View,
  StyleSheet,
  Image,
  Modal,
  TouchableOpacity,
  ImageBackground,
  ActivityIndicator,
} from "react-native";
import Text from "../../../components/Text";
import HeaderButton from "../../../components/HeaderButton";
import Theme from "../../../style/Theme";
import InputApp from "../../../components/InputApp";
import scale from "../../../utils/scale";
import { Colors } from "../../../configs";
import TagItem from "../../../components/WorkProfile/TagItem";
import useWorkProfilePage from "../shared/useWorkProfilePage";
import ButtonLinear from "../../../components/Buttons/ButtonLinear";
import Container from "../../../components/Layout/Container";
import locales_es from "../../../locales/es";
import {
  getBottomSpace,
  getStatusBarHeight,
} from "react-native-iphone-x-helper";
import Content from "../../../components/Layout/Content";
import FullScreenLoading from "../../../components/fullscreenLoading";
import ButtonAdd from "../../../components/Buttons/ButtonAdd";
import ModalSelect from "../../../components/ModalSelect";
import { USER_STATUS_APPROVED } from "../../../models/constants";

import iconSearch from "../../../images/ic_search_normal@3x.png";
import iconNext from "../../../images/ic_next@3x.png";
import ImgLoadAndCrop from "../../../components/imgLoadAndCrop";
import RequireIndicator from "../../../components/RequireIndicator";
import RequireData from "../../../components/RequireData";

interface WorkProfileProps {}

const WorkProfile = (props: WorkProfileProps) => {
  const {
    stepTitle,
    theme,
    searchSpecialtiesResults,
    onSearchSpecialtiesChange,
    searchSpecialties,
    profession,
    setProfession,
    errors,
    onBlur,
    onPressSearchResult,
    renderListItem,
    specialties,
    onPressSpecialtyX,
    translations,
    prescriptionProfileAdditionalFields,
    renderAdditionalField,
    userData,
    imgLoadAndCrop,
    uploadImage,
    identificationImage,
    setCropping,
    cropping,
    openCrop,
    onSubmit,
    visibleAdditionalFieldMenuOption,
    closeAdditionalFieldMenuOption,
    additionalFieldsOptions,
    onChangeInput,
    loading,
    loadingText,
    hiddenText,
    medicalLicenseConfiguration
  } = useWorkProfilePage();
  return (
    <Container style={styles.container}>
      {loading && <FullScreenLoading text={loadingText}/>}
      <Content
        style={styles.container}
        keyboardShouldPersistTaps="always"
        contentContainerStyle={{
          paddingHorizontal: 24,
          paddingBottom: getBottomSpace() + 16,
          paddingTop: getStatusBarHeight() + 22,
        }}>
        {!cropping && <>
          <HeaderButton/>
            <Text size={13} lineHeight={16} bold marginTop={32}>
            {stepTitle || 'Paso 2 de 3'}
            </Text>
            <Text size={24} lineHeight={28} bold marginTop={16}>
              Información Profesional
            </Text>
            <RequireData/>
            {!hiddenText && (
              <Text size={13} lineHeight={22} marginTop={16}>
                {locales_es.workProfile.intro}
              </Text>
            )}
            <InputApp
              title={'Profesión'}
              placeholder={'Médico Cirujano'}
              marginTop={24}
              value={profession}
              onChangeText={setProfession}
              borderColor={
              errors.includes('profession')
                  ? theme.lineErrorColor
                  : theme.innearColor
              }
              onBlur={() => onBlur('profession')}
              blurOnSubmit={true}
              showRequired = {true}
            />
            <InputApp
              title={'Especialidades'}
              placeholder="Busque o escriba sus especialidades..."
              value={searchSpecialties}
              onChange={onSearchSpecialtiesChange}
              borderColor={
                errors.includes('searchSpecialties')
                  ? theme.lineErrorColor
                  : theme.innearColor
              }
              marginTop={scale(40)}
              isShowIconLeft
              iconLeft={
                <Image
                  source={iconSearch}
                  style={styles.iconSearch}
                />
              }
              autoCorrect={false}
              autoCapitalize="words"
              onBlur={() => onBlur('searchSpecialties')}
              showRequired = {true}
            />
            <View
              style={[
                {backgroundColor: 'white'},
                (searchSpecialtiesResults && searchSpecialtiesResults.length)
                  ? {
                      borderColor: theme.innearColor,
                      borderWidth: 1,
                      borderRadius: 8,
                    }
                  : null,
              ]}>
              {searchSpecialtiesResults && searchSpecialtiesResults.length ? (
                searchSpecialtiesResults.map(result => renderListItem(result.name))
                ) : null}
            </View>
            <View style={styles.spec}>
              {specialties && specialties.length ? (
                specialties.map((specialty, index) => (
                  <TagItem
                    title={specialty}
                    key={index.toString()}
                    onPressX={() => onPressSpecialtyX(index)}
                  />
                ))
              ) : (
                <Text style={{color: 'red'}}>
                  Escriba al menos una especialidad
                </Text>
              )}
            </View>
            <Text size={15} lineHeight={18} bold marginTop={32}>
              {translations ? translations.medicLicense : ''}
            </Text>
            {medicalLicenseConfiguration === null ? (
              <ActivityIndicator/>
            ) : medicalLicenseConfiguration &&
              medicalLicenseConfiguration.length ? (
              medicalLicenseConfiguration.map(additionalField =>
                renderAdditionalField(additionalField),
              )
            ) : null}
        </>}

        {userData && userData.status === USER_STATUS_APPROVED ? null : (
          <>
            <ImgLoadAndCrop
              ref={imgLoadAndCrop}
              silentMode={true}
              image={identificationImage}
              inputLabel={"Imagen"}
              imageId={"identificationImage"}
              imageState={"identificationImage"}
              inputWrapperCustomClassName="col-12 pl-md-0"
              canvasCustomClassName="profile"
              saveCrop={(image) => {
                uploadImage(image)
              }}
              deleteCrop={() => console.log('deleteCrop')}
              deletedImage={() => console.log('deleteImage')}
              onCropping={() => setCropping(true)}
              onStopCropping={() => setCropping(false)}
            />
            {!cropping &&
              <View>
                <Text size={15} lineHeight={18} bold marginTop={32}>
                  {translations ? <>
                  {translations.medicLicensePicture}
                  <RequireIndicator/>
                  </>: ''}
                </Text>
                <Text size={13} lineHeight={22} marginTop={16}>
                  {translations ? translations.medicLicensePictureDescription : ''}
                </Text>
                <View>
                  {identificationImage === null ? (
                    <ActivityIndicator/>
                  ) : identificationImage ? (
                    <>
                      <TouchableOpacity
                        onPress={() => openCrop()}
                        style={styles.licenseImage}>
                        <ImageBackground
                          resizeMode="contain"
                          style={styles.image}
                          source={identificationImage}
                        />
                      </TouchableOpacity>
                    </>
                  ) : (
                    <ButtonAdd title={'Agregar Imagen'} onPress={() => openCrop()}/>
                  )}
                </View>
              </View>
            }
          </>
        )}

        {!cropping &&
          <ButtonLinear
            white
            title={'Continuar'}
            children={
              <Image
                source={iconNext}
                style={styles.buttonChildren}
              />
            }
            onPress={onSubmit}
          />
        }

        {visibleAdditionalFieldMenuOption &&
          <Modal
            visible={true}
            onRequestClose={closeAdditionalFieldMenuOption}
            transparent
            animationType={'fade'}>
            <ModalSelect
              onPressItem={item => {
                onChangeInput(item.value, item);
              }}
              choices={additionalFieldsOptions}
              close={closeAdditionalFieldMenuOption}
            />
          </Modal>
        }
      </Content>
    </Container>
  );
};

export default WorkProfile;

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  phoneView: {
    ...Theme.flexRow,
    marginTop: 4,
  },
  phoneNumber: {
    marginLeft: 8,
    flex: 1,
  },
  iconSearch: {
    ...Theme.icons,
    tintColor: Colors.DodgerBlue,
  },
  spec: {
    marginTop: 8,
    ...Theme.flexRow,
    flexWrap: 'wrap',
    paddingBottom: 32,
    borderBottomColor: Colors.TealBlue,
    borderBottomWidth: 1,
  },
  specLanguage: {
    marginTop: 8,
    ...Theme.flexRow,
    flexWrap: 'wrap',
    paddingBottom: 52,
  },
  insurancePlans: {
    borderRadius: 8,
    borderWidth: 1,
    paddingHorizontal: 16,
    paddingVertical: 12,
    marginTop: 4,
  },
  buttonChildren: {
    ...Theme.icons,
    marginLeft: 8,
  },
  buttonAdd: {
    marginTop: 24,
    borderWidth: 1,
    borderColor: Colors.Platinum,
    height: 36,
    ...Theme.center,
    borderRadius: 8,
    ...Theme.flexDirection,
  },
  iconRight: {
    width: 16,
    height: 16,
    marginRight: 8,
  },
  bottomContent: {
    paddingTop: 32,
    borderTopColor: Colors.TealBlue,
    borderTopWidth: 1,
    marginTop: 32,
  },
  licenseImage: {
    marginTop: 24,
    minHeight: 160,
    borderRadius: 8,
    backgroundColor: '#fff',
    paddingHorizontal: scale(52),
  },
  image: {
    flex: 1,
    justifyContent: 'center',
  },
});
