import React, {useState, useEffect} from 'react';
import {View, StyleSheet, Image} from 'react-native';
import Text from '../../components/Text';
import {Colors} from '../../configs';
import Theme from '../../style/Theme';
import scale from '../../utils/scale';
import InputApp from '../../components/InputApp';
import validationEmail from '../../utils/validation/email';
import ButtonLinear from '../../components/Buttons/ButtonLinear';
import HeaderButton from '../../components/HeaderButton';
import locales_es from '../../locales/es';
import {useTheme} from '../../configs/Theme';
import {validateEmptyFields,handleValidatorInputsTypes,InitForm} from '../../utils/validation/forms';
import FullScreenLoading from '../../components/fullscreenLoading';
import AuthService from '../../modules/authService';
import Footer from "../../components/footer";
import useDimensions from '../../hooks/useDimensions';
import Helpers from '../../modules/helpers';
import {EventRegister} from '../../modules/EventRegister';
import {EVENT_SHOW_MODAL_INFO, EVENT_SHOW_MODAL_INFO_REGISTER} from '../../constants/Events';
import {showModalInfo} from '../../modules/ViewHelpers';

interface RecoveryProps {
}

const Recovery = (props: RecoveryProps) => {
  const {isDesktop} = useDimensions();
  const auth = new AuthService();
  const helpers = new Helpers();
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState<any>([]);
  const [email, setEmail] = useState('');
  const [isValidEmail, setIsValidEmail] = useState(false);
  const [enableButton, setEnableButton] = useState(true);
  const [errorMessages, setErrorMessages] = useState({
    email: ''});

  useEffect(() => {
    const validation = validationEmail(email);
    setIsValidEmail(validation);
  }, [email]);

  const onSendEmail = () => {
    const errors = validateEmptyFields({email});
    if (errors.length) {
      setErrors(errors);
    } else if (!validationEmail(email)) {
      errors.push('email');
      setErrors(errors);
    } else {
      setLoading(true);
      auth
        .recovery(email)
        .then((res: any) => {
          // Email no registrado
          setLoading(false);
          
          EventRegister.emit(EVENT_SHOW_MODAL_INFO, {
            title: '',
            message: res.message,
          });
        })
        .catch((err: any) => {
          setLoading(false);
          EventRegister.emit(EVENT_SHOW_MODAL_INFO, {
            title: locales_es.errorModal.title,
            message: helpers.getErrorMsg(err),
          });
        });
    }
  };

  const onBlur = (inputStr: string, email: string) => {
    maxLength(email)
    handleValidatorInputsTypes(initForm, email, 'email',setEmail,'email',8);
    // setErrors(errors.filter(item => item !== inputStr));
  };

  const initValues = {
    email,
  }

  const initForm: InitForm = {
    setErrors,
    errors,
    setErrorMessages,
    errorMessages,
    setEnableButton,
    initValues,
  }

  const maxLength = (value: string) => {
    if (value.length >= 50) {
      setErrorMessages({...errorMessages, email: "El correo no puede tener más de 50 caracteres"});
      setErrors([...errors, 'email']);
    }
  }

  const {theme} = useTheme();

  return (
    <div
      className="w-100 p-3 p-md-0"
      style={{flex: 1, backgroundColor: isDesktop ? Colors.Snow : theme.backgroundItem}}>
      {loading && <FullScreenLoading/>}
      <div className="row h-100">
        <div className="d-none d-md-inline-flex col-6">
          <div className="rcta-bg-logo"/>
        </div>
        <div className="col-12 col-md-6 align-self-md-center text-center">
          <View style={[styles.content, isDesktop && styles.desktopContent]}>
            <HeaderButton style={{
              marginBottom: isDesktop ? 8 : 30 
            }} />
            <Text type="Title" bold>
              <span style={isDesktop? styles.desktopTitle: {}}>{locales_es.recoveryPassword}</span>
            </Text>
            <Text type="P4" style={{marginVertical: 16}}>
              <span style={isDesktop ? styles.desktopSubtitle: {}}>{locales_es.recoveryPasswordInfo}</span>
            </Text>
            <InputApp
              borderColor={
                errors.includes('email') ? theme.lineErrorColor : theme.innearColor
              }
              title={locales_es.email}
              placeholder={locales_es.email}
              value={email}
              onChangeText={(value:string)=>{handleValidatorInputsTypes(initForm, value, 'email',setEmail,'email',8);maxLength(value)}}
              onBlur={() => onBlur('email', email)}
              icon={
                <Image
                  source={require('../../images/ic_accept.png')}
                  style={styles.icon}
                />
              }
              isShowIcon={isValidEmail}
              keyboardType="email-address"
              blurOnSubmit={true}
              autoCapitalize="none"
              returnKeyType="next"
              autoFocus={false}
              style={{backgroundColor: 'white'}}
              maxLength={50}
            />
              {
                errors.includes('email') && ( 
                  <Text size={13} lineHeight={16} marginTop={4} color={Colors.Red}>{errorMessages.email}</Text> 
                )
              }
            <ButtonLinear white title={locales_es.sendEmail} onPress={onSendEmail} disabled={enableButton}/>
          </View>
        </div>
        <Footer className="app-footer-auth"/>
      </div>
    </div>
  );
};

export default Recovery;

const styles = StyleSheet.create({
  container: {
    ...Theme.container,
    marginTop: scale(40),
    grid: 'column',
    flex: 1
  },
  backButton: {
    width: 40,
    height: 40,
    borderRadius: 8,
    borderWidth: 1,
    borderColor: Colors.Platinum,
    ...Theme.center,
  },
  content: {
    flex: 1,
    justifyContent: 'center',
    display: 'flex',
    padding: 10,
    margin: 12
  },
  description: {
    marginTop: 20,
  },
  recoveryContainer: {
    flex: 1,
    display: 'flex'
  },
  logoStyle: {
    height: '100vh',
    width: 990,
    justifyContent: 'center',
  },
  containerLogo: {
    flexDirection: 'row',
    flex: 1,
    justifyContent: 'center'
  },
  containerContent: {
    flex: 1,
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  // Desktop styles
  desktopTitle: {
    fontSize: 30,
  },
  desktopSubtitle: {
    fontSize: 18,
    fontWeight: '600'
  },
  desktopInputLabel: {
    fontWeight: '600'
  },
  desktopContent: {
    margin: 'auto',
    maxWidth: 550,
    gap: 4,
  },
});
