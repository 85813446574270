// import AsyncStorage from '@react-native-async-storage/async-storage';

export const getFromStorage = (key: string): string | null => {
  return localStorage.getItem(key);
};

export const setInStorage = (key: string, value: string): string | null => {
  return localStorage.setItem(key, value);
};
export const removeFromStorage = (key: string): string | null => {
  return localStorage.removeItem(key);
};

export const AsyncStorageEnum = {
  HISTORY_SEARCHPRACTICES: 'HISTORY_SEARCHPRACTICES',
  PRESCRIPTION_PROFILE_ID: 'PRESCRIPTION_PROFILE_ID',
};
