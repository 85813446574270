import React, {useState, useEffect} from 'react';
import {StyleSheet, Image, Alert, Text, TouchableOpacity} from 'react-native';
import ProfileItem from '../WorkProfileManagement/ProfileItem';
import Container from '../Layout/Container';
import locales_es from '../../locales/es';
import ButtonIcon from '../Buttons/ButtonIcon';
import {Colors, Routes} from '../../configs';
import PatientMedicalInsuranceItem from './PatientMedicalInsuranceItem';
import ButtonLinear from '../Buttons/ButtonLinear';
import {SOURCE_ICON} from '../../images';
import {EventRegister} from "../../modules/EventRegister";
import {
  EVENT_SHOW_MODAL_INFO
} from '../../constants/Events';
import ic_add from '../../images/ic_add_16@3x.png';
import { showModalInfo } from '../../modules/ViewHelpers';
import patientsData from '../../pages/patientsData';
interface Props {
  style?: any;
  patientMedicalInsurances: any[];
  onPressAdd?: () => void;
  onPressEdit?: (e:any, index?:any) => void;
  onCheck?: (e:any, index?:any) => void;
  onMedicalInsuranceSelect?: (Id: string, idSelected?: number) => void;
  onPressDelete?: (mI: any) => void;
  edit?: boolean;
  hidenModalChange?: boolean;
  onUserEdit?: () => void;
  patientInsuranceSelected?: number;
  permissions?: any;
  insurancePermission?: boolean;
  onInsuranceChange?: (selectedInsuranceId: number | null) => void;
  onTieneCoberturaVoid?: (selectedInsuranceId: number | null) => void;
  onEnableNoReplacement?: (oldInsu:any | null, newInsu:any | null) => void;

}
const PatientMedicalInsurancesList = (props: Props) => {
  const [medicalInsuranceChecked, setMedicalInsuranceChecked] = useState(null);
  const [data, setData] = useState([]);

  useEffect(() => {

    setTimeout(() => {

      let insurances = props.patientMedicalInsurances;
      if (props.patientInsuranceSelected) {
        insurances = insurances.map((insurance, index: any) => {
          insurance.medical_insurance_selected = (insurance.medical_insurance_id === props.patientInsuranceSelected) ? 1 : 0;
          if (insurance.medical_insurance_id === props.patientInsuranceSelected) {
            setMedicalInsuranceChecked(index);
          }
          return insurance;
        });
        props.onMedicalInsuranceSelect(-1, props.patientInsuranceSelected);
      }
      setData(insurances);

    },2000);

  }, [props.patientMedicalInsurances, props.patientInsuranceSelected]);

  const deletePatientMedicalInsurance = (mI: any) => {

    EventRegister.emit(EVENT_SHOW_MODAL_INFO, {
      title: locales_es.deletePatientMedicalInsurance.title,
      message: locales_es.deletePatientMedicalInsurance.subtitle,
      actions: [
        {
          text: locales_es.deletePatientMedicalInsurance.primaryAction,
          onPress: () => {
            props.onPressDelete(mI);
          },
          white: true,
          styleButton: {
            width: '60%',
            margin: 'auto',
            marginVertical: 10
          }
        },
        {
          text: locales_es.cancel,
          colors: [Colors.Tomato, Colors.Tomato],
          white: true,
          styleButton: {
            width: '60%',
            margin: 'auto',
            marginVertical: 10
          }
        },
      ],
    });

  };

  const onMedicalInsuranceSelect = (index: number) => {
    const beforeSelected = props.patientMedicalInsurances.find((mI: any) => mI.medical_insurance_selected === 1);
    const newSelected = props.patientMedicalInsurances[index];
  //Buscamos la lista de financiadores
    const patientSelected:any = localStorage.getItem("patientSelected");
    const patient = JSON.parse(patientSelected);
    let idFinanciador = 0;
    let tieneCobertura = 0;
    let isFinanciador = false;
    localStorage.setItem("tieneCobertura",'0');

    if(patientSelected){

      for (let i = 0; i < patient.patients_medical_insurances.length; i++) {
        const p = patient.patients_medical_insurances[i];
        if(data[index]['medical_insurance_id'] == p.medical_insurance_id){

          idFinanciador = p.medical_insurance.idFinanciador?p.medical_insurance.idFinanciador:0;
          tieneCobertura = p.medical_insurance.tieneCobertura?p.medical_insurance.tieneCobertura:null;


            //Vademecun Especial
          if(idFinanciador > 0 && tieneCobertura == 1){
            isFinanciador = true;
          }
          break;
        }

      }
    }



     // Modal cambiar la cobertura
   if(isFinanciador && props.hidenModalChange == false){
      EventRegister.emit(EVENT_SHOW_MODAL_INFO, {
        title: locales_es.confirmChangueCobertura.title,
        message: locales_es.confirmChangueCobertura.subtitle,
        actions: [

          {
            text: locales_es.confirmChangueCobertura.accept,
            onPress: () => {
              props.onMedicalInsuranceSelect(index)
              setMedicalInsuranceChecked(index);
              localStorage.setItem('medical_insurance_id_check',data[index]['medical_insurance_id']);

              if (props.onInsuranceChange) {
                props.onInsuranceChange(null);
              }

              localStorage.setItem('tieneCobertura','1');
              if (props.onTieneCoberturaVoid) {
                props.onTieneCoberturaVoid(1);
              }

            },
            white: true,

          },
          {
            text: locales_es.confirmChangueCobertura.cancel,
            onPress: () => console.log("Cancel Pressed"),
            white: true,
            colors: [Colors.Tomato, Colors.Red]
          },
        ],
      });
    }else{
      props.onMedicalInsuranceSelect(index)
      setMedicalInsuranceChecked(index);
      if (props.onEnableNoReplacement) {
        props.onEnableNoReplacement(beforeSelected, newSelected);
      }
      localStorage.setItem('tieneCobertura','0');
      if (props.onTieneCoberturaVoid) {
        props.onTieneCoberturaVoid(0);
      }
    }
  };

  const renderPatientMedicalInsure = (mI: any, index: number) => {

    if(medicalInsuranceChecked === index){

      const patientSelected:any = localStorage.getItem("patientSelected");
      const patient = JSON.parse(patientSelected);
      let idFinanciador = 0;
      let tieneCobertura = 0;
      localStorage.setItem("tieneCobertura",'0');


      if(patient){
       for (let i = 0; i < patient.patients_medical_insurances.length; i++) {
        const p = patient.patients_medical_insurances[i];
        if(data[index]['medical_insurance_id'] == p.medical_insurance_id){

          idFinanciador = p.medical_insurance.idFinanciador?p.medical_insurance.idFinanciador:0;
          tieneCobertura = p.medical_insurance.tieneCobertura?p.medical_insurance.tieneCobertura:null;

            //Vademecun Especial

          if(idFinanciador > 0 && tieneCobertura == 1){
            localStorage.setItem('tieneCobertura','1');
            if (props.onTieneCoberturaVoid) {
              props.onTieneCoberturaVoid(1);
            }
          }
          break;
        }

        }
      }
    }


    return (
      <PatientMedicalInsuranceItem
        key={index}
        medical_insurance={mI}
        {...(props.onMedicalInsuranceSelect && {
          onMedicalInsuranceSelect: () => onMedicalInsuranceSelect(index),
        })}
        onPressEditMedicalInsurance={() => props.onPressEdit(mI, index)}
        checked={medicalInsuranceChecked === index}
        onPressBtnDelete={() => deletePatientMedicalInsurance(mI)}
        edit={props.edit}
      />
    );
  };

  const onPressHelp = () => {
    showModalInfo(
      locales_es.medicalInsuraceModalInfo.title,
      locales_es.medicalInsuraceModalInfo.description
    )
  }

  return (

    <Container style={[styles.container, props.style]}>
      <ProfileItem
        icon={'exp'}
        title={'Coberturas Médicas'}
        rightButton={
          props.edit === false ? <ButtonIcon icon={'help'} style={styles.buttonEdit} onPress={onPressHelp} imgStyle={{width: 25,height: 25}}/> : <TouchableOpacity onPress={props.onUserEdit}>
          <Image
            source={SOURCE_ICON.edit}
            style={{
              tintColor: Colors.TealBlue,
              minHeight: 20, minWidth: 20
            }}
            resizeMode="contain"
          />
        </TouchableOpacity>
        }>

        {data.length > 0 &&
         data.map((mI: any, index) => {
            if (mI.medical_insurance_selected === 1) {
              localStorage.setItem('medical_insurance_id_check', mI.medical_insurance_id);
            }
            return renderPatientMedicalInsure(mI, index);
          })
        }

         {!props.onMedicalInsuranceSelect ? (
          <ButtonLinear
            white
            leftChildren={
              <Image style={{...styles.iconEdit,minWidth: 25, minHeight: 25, marginHorizontal: 10}} source={ic_add} />
            }
            style={{...styles.btnAdd, minWidth: 25, minHeight: 25}}
            colors={[Colors.BlueCrayola, Colors.Blue]}
            title={locales_es.addMedicalInsurance}
            onPress={props.onPressAdd}
          />
        ) : null }
        {
          props.onMedicalInsuranceSelect && data.length === 0 && !props.edit ? (
            <ButtonLinear
              white
              leftChildren={
                <Image style={{...styles.iconEdi,minWidth: 25, minHeight: 25, marginHorizontal: 10}} source={ic_add} />
              }
              style={styles.btnAdd}
              colors={[Colors.BlueCrayola, Colors.Blue]}
              title={locales_es.addMedicalInsurance}
              onPress={props.onPressAdd}
            />
          ) : null
        }
      </ProfileItem>
    </Container>

  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  buttonEdit: {
    width: 24,
    height: 24,
    backgroundColor: null,
  },
  btnAdd: {
    marginHorizontal: 24,
    marginVertical: 12,
  },
});
export default PatientMedicalInsurancesList;
