import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { api, helpers } from "../../../import";
import { showModalInfo } from "../../../modules/ViewHelpers";
import locales_es from "../../../locales/es";
import { EVENT_RELOAD_PRESCRIPTIONS } from "../../../constants/Events";
import { EventRegister } from "../../../modules/EventRegister";

const usePatientPrescriptionsPage = () => {
  const history = useHistory();
  const params = history.location.state;
  const [load, setLoad] = useState(false);
  const [medicalPrescriptions, setMedicalPrescriptions] = useState<
    any[] | null
  >(null);
  const { patientId, prescriptionType } = params ? params : null;
  const [nextPageUrl, setNextPageUrl] = useState("");
  const [refreshing, setRefreshing] = useState(false);
  const [total, setTotal] = useState(0);

  const loadDocuments = (pageUrl?: string) => {
    api
      .getPatientPrescriptionsPaginated(patientId, pageUrl)
      .then((res: any) => {
        setMedicalPrescriptions(
          medicalPrescriptions
            ? medicalPrescriptions.concat(res.data)
            : res.data
        );
        setNextPageUrl(res.next_page_url);
        setRefreshing(false);
        setTotal(res.total);
      })
      .catch((err: any) => {
        helpers.processError(err);
        setRefreshing(false);
      });
  };

  const onLoadMore = () => {
    if (nextPageUrl && !refreshing) {
      setRefreshing(true);
      loadDocuments(nextPageUrl);
    }
  };

  useEffect(() => {
    loadDocuments();
  }, [load]);

  useEffect(() => {
    const listener = EventRegister.addEventListener(
      EVENT_RELOAD_PRESCRIPTIONS,
      (data) => {
        loadDocuments();
      }
    );

    return function cleanup() {
      EventRegister.removeEventListener(listener);
    };
  });

  return {
    onLoadMore,
    loadDocuments,
    setTotal,
    total,
    setRefreshing,
    refreshing,
    nextPageUrl,
    setNextPageUrl,
    prescriptionType,
    patientId,
    medicalPrescriptions,
    setMedicalPrescriptions,
    load,
    setLoad,
  };
};

export default usePatientPrescriptionsPage;
