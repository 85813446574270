import React, {useState, useEffect} from 'react';
import {
  View,
  StyleSheet,
  ActivityIndicator,
  FlatList,
  SafeAreaView,
  Modal,
} from 'react-native';
import Text from '../../components/Text';
import SearchBox from '../../components/Home/SearchBox';
import {Colors, Routes} from '../../configs';
import Theme from '../../style/Theme';
import {getBottomSpace, getStatusBarHeight} from 'react-native-iphone-x-helper';
import {SOURCE_ICON} from '../../images';
import Layout from '../../components/Layout/Layout';
import HeaderButton from '../../components/HeaderButton';
import {api, helpers} from '../../import';
import {showModalInfo} from '../../modules/ViewHelpers';
import {CLINIC_PATIENTS_SERVICE_TAG} from '../../models/constants';
import locales_es from '../../locales/es';
import {CommonStyles} from '../../styles/CommonStyles';
import Container from '../../components/Layout/Container';
import UserInfo from '../../components/ConsultDetail/UserInfo';
import InputApp from '../../components/InputApp';
import {useTheme} from '../../configs/Theme';
import useModalWithKeyboard from '../../hooks/useModalWithKeyboard';
import ModalSelect from '../../components/ModalSelect';
import {useHistory} from "react-router-dom";

interface SearchClinicPatientsProps {
}

let nativeEventKeySearch = '';
const SearchClinicPatients = ({}: SearchClinicPatientsProps) => {
  const {theme} = useTheme();
  const history = useHistory();
  const [keySearch, setKeySearch] = useState('');
  const [searchResults, setSearchResults] = useState(false);
  const [clinics, setClinics] = useState(null);
  const [clinic, setClinic] = useState({});

  const {
    visible: visibleClinicsMenuOption,
    open: openClinicsMenuOption,
    close: closeClinicsMenuOption,
  } = useModalWithKeyboard(false);

  const onKeySearchChange = e => {
    nativeEventKeySearch = e.nativeEvent.text;
    setKeySearch(e.nativeEvent.text);
  };

  const onKeySearchClear = () => {
    nativeEventKeySearch = '';
    setKeySearch('');
  };

  useEffect(() => {
    if (!clinics) {
      // Load available clinics
      api
        .getMyClinics({
          service_tag: CLINIC_PATIENTS_SERVICE_TAG,
        })
        .then((res: any) => {
          if (res && res.data && res.data.length) {
            setClinics(res.data);
            setClinic(res.data[0]);
          }
        })
        .catch((err: any) => {
          helpers.processError(err);
        });
    }

    if (keySearch) {
      setSearchResults(null);
      const objData = {
        q: keySearch,
        clinic_id: clinic.id,
      };
      setTimeout(() => {
        if (keySearch.length === nativeEventKeySearch.length) {
          api
            .searchClinicPatients(objData)
            .then((res: any) => {
              var auxData: any[] = [];
              if (res.data && !Array.isArray(res.data)) {
                auxData.push(res.data);
                setSearchResults(auxData);  
              } else {
                setSearchResults(res.data);
              }
            })
            .catch((err: any) => {
              setSearchResults([]);
            });
        } else {
          // setSearchResults([]);
        }
      }, 300); // 800
    }
  }, [keySearch, clinics, clinic]);

  const renderItem = ({item}) => {
    return (
      <UserInfo
        onPress={() => {
          history.push(`/${Routes.AddPatient}`, {
            externalIdParam: item.external_id,
            clinicIdParam: clinic.id,
            patientData: item,
          });
        }}
        key={item.id}
        user={item}
        style={{
          marginTop: 24,
          marginHorizontal: 6,
        }}
      />
    );
  };

  return (
    <>
      <SafeAreaView>
        <Layout style={styles.header}>
          <HeaderButton
            icon={SOURCE_ICON.close}
            style={{
              marginTop: 26,
              marginLeft: 24,
            }}
          />
          {clinics === null ? (
            <View
              style={{
                paddingRight: 24,
                flex: 1,
                alignContent: 'flex-end',
                paddingTop: 26,
              }}>
              <ActivityIndicator color={Colors.BlueCrayola}/>
            </View>
          ) : clinics && clinics.length ? (
            <InputApp
              styleView={{flex: 1, paddingLeft: 12}}
              key="clinic"
              title={
                clinics && clinics.length && clinics.length > 1
                  ? 'Elija Institución...'
                  : ''
              }
              marginTop={6}
              marginBottom={4}
              value={clinic && clinic.name ? clinic.name : 'Seleccione...'}
              editable={false}
              onPress={() => {
                openClinicsMenuOption();
              }}
              borderColor={theme.innearColor}
            />
          ) : (
            <Text>No hay instituciones disponibles</Text>
          )}
        </Layout>
        <Layout style={styles.header}>
          {clinic && clinic.id ? (
            <SearchBox
              placeholder={locales_es.searchPatient + '...'}
              value={keySearch}
              onChange={onKeySearchChange}
              borderColor={Colors.TealBlue}
              shadow={false}
              style={styles.searchBox}
              autoFocus={true}
              onClear={onKeySearchClear}
            />
          ) : null}
        </Layout>
      </SafeAreaView>
      <Container style={styles.container}>
        <View
          style={{
            ...Theme.flexOne,
            marginBottom: 20,
            justifyContent: 'flex-start',
          }}>
          {!clinics ? null : !keySearch ? (
            <Text size={13} lineHeight={16} bold center>
              {locales_es.typeToSearch}...
            </Text>
          ) : searchResults === null ? (
            <ActivityIndicator style={CommonStyles.activityMonitor}/>
          ) : searchResults && searchResults.length ? (
            <FlatList
              data={searchResults}
              renderItem={renderItem}
              // keyExtractor={keyExtractor}
              scrollEventThrottle={16}
              contentContainerStyle={styles.contentContainerStyle}
              showsVerticalScrollIndicator={false}
            />
          ) : (
            <Text size={13} lineHeight={16} bold>
              {locales_es.searchNotFound}
            </Text>
          )}
        </View>

        <Modal
          visible={visibleClinicsMenuOption}
          onRequestClose={closeClinicsMenuOption}
          transparent
          animationType={'fade'}>
          <ModalSelect
            onPressItem={item => {
              // onChangeInput(item.value, item);
              setClinic(item);
            }}
            choices={clinics}
            close={closeClinicsMenuOption}
          />
        </Modal>
      </Container>
    </>
  );
};

export default SearchClinicPatients;

const styles = StyleSheet.create({
  container: {
    paddingTop: 32,
    paddingHorizontal: 24,
    paddingBottom: getBottomSpace() + 12,
    ...Theme.flexOne,
  },
  scrollView: {
    flex: 1,
  },
  header: {
    ...Theme.flexRowSpace,
    paddingRight: 24,
    paddingBottom: 12,
  },
  cancelButton: {
    height: 48,
    marginTop: getStatusBarHeight(),
  },
  searchBox: {
    marginLeft: 24,
    flex: 1,
    marginTop: 12,
  },
  icon: {
    width: 40,
    height: 40,
    borderRadius: 8,
    ...Theme.center,
    backgroundColor: Colors.DodgerBlue,
    marginRight: 16,
  },
});
