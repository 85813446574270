import { showModalInfo } from "./ViewHelpers";
import locales_es from "../locales/es";

class Helpers {

	validateEmail(email) {
		let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,10})+$/;
		return reg.test(email);
	};

	/*
	* Takes an Integer and returns a String as a "24hs." format.
	* */
	getTwentyFourFormatAsString(int) {
		return String(int < 10 ? "0" + int : int);
	};

	/*
	* TIME HTML PARSING
	* Takes a event date and returns it as a parsed custom html format output
	* */
	parseEventTime(eventDate, isAmPm, hasToAddStrings, utcOn) {
		const date = new Date(eventDate);

		let result = '';

		const hours = this.getTwentyFourFormatAsString(utcOn ? date.getUTCHours() : date.getHours());
		const minutes = this.getTwentyFourFormatAsString(utcOn ? date.getUTCMinutes() : date.getMinutes());

		const parsedTime = this.parseTime(hours + ':' + minutes, isAmPm, hasToAddStrings);

		result += parsedTime.hours + '';
		result += ':' + parsedTime.minutes;

		return result;
	};

	/*
	* Takes a time string and returns it as a JSON object
	* */
	parseTime(timeToParse, isAmPm, hasToAddStrings) {

		if (!timeToParse) {
			return {};
		}

		// Ejemplo de un string de tiempo: "09:00:00"
		const hours = timeToParse.substr(0, 2); // 09
		const minutes = timeToParse.substr(3, 2); // 00
		if (isAmPm) {
			const ampm = hasToAddStrings ? hours > 11 ? ' PM' : ' AM' : '';
			return {
				'hours': (hours % 12 || 12), // converts to correct hours am/pm,
				'minutes': minutes + ampm
			};
		} else {
			const hs = hasToAddStrings ? 'hs.' : '';
			return {
				'hours': hours,
				'minutes': minutes + hs
			};
		}

	}

	/*
	*
	* Utility to get ErrorMessages from ApiService responses
	 */
	/*getErrorMsg(err) {
		if(err.response && err.response.status && err.response.status === 500) {
			return 'Contáctate con el administrador por favor para que podamos ayudarte.'
		}

		return err.response && err.response.data && err.response.data.message
		  ? err.response.data.message : err.message;
	}*/
	getErrorMsg(err) {
		
		if (
			err &&
			err.response &&
			err.response.status &&
			err.response.status === 500
		) {
			return 'Ha ocurrido un error inesperado. Por favor, intente nuevamente en unos minutos.';
		}

		let msg = '';

		msg =
			err && err.response && err.response.data && err.response.data.message
				? err.response.data.message
				: err.message;
		msg = msg.replace(/^Error:\s*/, '');
		if (
			err &&
			err.response &&
			err.response.data &&
			err.response.data.errors &&
			err.response.data.errors.length
		) {
			err.response.data.errors.map(e => {
				/* eslint-disable no-unused-expressions */
				e !== msg ? (msg += '. ' + e) : null;
			});
		}

		return msg;
	}

	processError(err) {
		if (err && err.response && err.response.status == 401 || err.response.status == 403) {
		  return;
		}
		showModalInfo(locales_es.errorModal.title, this.getErrorMsg(err));
	  }

	/*
   *
   * Utility to check if a profile is complete
   */
	profileValidator(profile) {
		return (
			!profile.profession ||
			!profile.specialties ||
			!profile.specialties.length ||
			!profile.additional_fields ||
			!profile.additional_fields.inputs ||
			!profile.additional_fields.inputs.length ||
			!profile.address
		);
	}

	goBack() {
		window.history.back();
	}

	/*
	*
	* Method to detect mobile devices
	 */
	isMobile() {
		return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
	}

	isAndroid() {
		return /Android/i.test(navigator.userAgent);
	}

	isIOS() {
		return /iPhone|iPad|iPod/i.test(navigator.userAgent);
	}

	/*
	*
	* Method to detect we are on app.rcta.me, and that we are not a custom clinic frontend.
	 */
	isRCTAApp() {
		const regex = /app.rcta.me|app.dev.rcta.me|app.qa.rcta.me|app.stg.rcta.me|app-temp|app.hml.rcta.me|app-tmp.rcta.me|rctaweb.woopi.com.ar|localhost|3001/gm;
		if (regex.test(window.location.port)) {
			return false;
		}
		return regex.test(window.location.hostname);
	}

	openLink(url) {
		return new Promise((resolve, reject) => {
			try {
				window.open(url, '_blank')
				resolve();
			} catch (err) {
				reject(err);
			}
		});
	}
}

//unregister();

export default Helpers;
