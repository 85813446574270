const colors = {
  background: '#1F0808',
  clear: 'rgba(0,0,0,0)',
  facebook: '#3b5998',
  transparent: 'rgba(0,0,0,0)',
  silver: '#F7F7F7',
  steel: '#CCCCCC',
  error: 'rgba(200, 0, 0, 0.8)',
  ricePaper: 'rgba(255,255,255, 0.75)',
  frost: '#D8D8D8',
  cloud: 'rgba(200,200,200, 0.35)',
  windowTint: 'rgba(0, 0, 0, 0.4)',
  panther: '#161616',
  charcoal: '#595959',
  coal: '#2d2d2d',
  bloodOrange: '#fb5f26',
  snow: 'white',
  black: 'black',
  ember: 'rgba(164, 0, 48, 0.5)',
  fire: '#e73536',
  drawer: 'rgba(30, 30, 29, 0.95)',
  eggplant: '#251a34',
  border: '#483F53',
  banner: '#5F3E63',
  text: '#E0D7E5',
  twitter:'#55acee',
  loginBlue:'#0691ce',
  loginGreen:'#4cd964',
  txtgrey:'#8e9396',
  whites:'#E8E8E8',
  greys:'#cccccc',
  darktext:'#6f6f6f',
  shadows: '#b7b7b7',
  lighttxt:'#929597',
  hintblue:'#c9b0c1',
  lightgrey:'#e2e2e2',
  backgrey:'#e6e6e6',
  blacktxt:'#363636',
  skyblue:'#71d4ff',
  txtsky:'#83c8e7',

  //WooCommerce Color
  veryLightGray: "#dcdcdc",
  red: 'red',
  white: 'white',
  lightGray: '#959595',
  yellow: '#ffc700',
  lightBlack: '#111111',
  gray: '#d8d8d8',
}

export default colors
