// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React, { useState, useEffect, useMemo } from "react";
import { Routes } from "../../../configs";
import { Route, Router, useHistory } from "react-router-dom";
import APIService from "../../../modules/apiService";
import Helpers from "../../../modules/helpers";
import {
  EVENT_LOAD_SIGNATURE,
  EVENT_RELOAD_PRESCRIPTION_PROFILES,
} from "../../../constants/Events";
import locales_es from "../../../locales/es";
import { EventRegister } from "../../../modules/EventRegister";
import { showModalAction, showModalInfo } from "../../../modules/ViewHelpers";
import { userTemporalDataPostRegister } from "../../../models/constants";
import { AsyncStorageEnum, getFromStorage } from "../../../utils/storage";
import TranslationService from "../../../locales";
import { handleValidateEmptyFields } from "../../../utils/validation/forms";

const usePaymentInformationPage = () => {
  const history = useHistory();
  const params: any = history.location.state;
  const userData = params && params.userData ? params.userData : null;
  const [nameStep, setNameStep] = useState(false);
  const [name, setName] = useState("");
  const [translations, setTranslations] = useState(null);
  const translationService = new TranslationService();

  const [currentPrescriptionProfile, setCurrentPrescriptionProfile] =
    useState<any>({});
  const [activeAccount, setActiveAccount] = useState<boolean>(false);
  const [enableButton, setEnableButton] = useState<boolean>(true);
  const [oneAccountActive, setOneAccountActive] = useState<boolean>(false);
  const [clinicFlag, setClinicFlag] = useState<boolean>(false);
  const [clinicData, setClinicData] = useState({});
  const [load, setLoad] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [errors, setErrors] = useState<any>([]);
  const [payLink, setPayLink] = useState<any>("");
  const [reason, setReason] = useState<any>("");
    const [fieldsOptional, setFieldsOptional] = useState(false);
    const [amount, setAmount] = useState<any>("");
  const [isClinicPaylink, setIsClinicPaylink] = useState<boolean>(false);
  const [errorMessages, setErrorMessages] = useState({
    payLink: "",
    reason: "",
    amount: "",
    required: "El Campo es obligatorio",
  });
  const [withPaymentData, setWithPaymentData] = useState<boolean>(true);

  const prescriptionIdFromStorage = getFromStorage(
    AsyncStorageEnum.PRESCRIPTION_PROFILE_ID
  );

  let prescriptionProfileId =
    params && params.prescriptionProfileId
      ? params.prescriptionProfileId
      : prescriptionIdFromStorage;

  const api = useMemo(() => new APIService(), []);
  const helpers = useMemo(() => new Helpers(), []);

  let initValues: any = {
    payLink,
    reason,
    amount,
  };

  const onVerifyAccount = async () => {
    await api
      .getMyPrescriptionProfiles()
      .then((res: any) => {
        if (res && res.data && res.data.length) {
          const activeAccount = res.data.filter(
            (item: any) =>
              item.active_charger_account === 1 &&
              item.id !== prescriptionProfileId && 
              item.clinic_id === null
          );
          if (activeAccount.length > 0) {
            setOneAccountActive(true);
          }
        } else {
          setOneAccountActive(false);
        }
      })
      .catch((err: any) => {
        helpers.processError(err);
      });
  };

  useEffect(() => {
    translationService.translate().then((translation) => {
      setTranslations(translation);
    });
    if (prescriptionProfileId) {
      setLoading(true);
      api
        .getPrescriptionProfile(prescriptionProfileId)
        .then((res: any) => {
          if (res.status) {
            setLoading(false);
            setName(res.data.name);
            setCurrentPrescriptionProfile(res.data);
            const clinicPaylinkEnabledToMedic = (res.data.clinic && res.data.clinic.paylink_enabled === 1);
            const clinicPaylinkEnabled = (res.data.clinic && res.data.clinic.paylink_enabled && (res.data.clinic.paylink_url !== '' && res.data.clinic.paylink_url !== null));
            setActiveAccount(
              ((res.data.active_charger_account === 1 && res.data.clinic === null) || clinicPaylinkEnabledToMedic || clinicPaylinkEnabled) ? true : false
            );
            setIsClinicPaylink(clinicPaylinkEnabled);
            const payLinkValue = clinicPaylinkEnabled ? res.data.clinic.paylink_url : res.data.charger_accounts?.pay_link;
            setPayLink(payLinkValue);
            setReason(clinicPaylinkEnabled ? res.data.clinic.paylink_reason : res.data.charger_accounts?.reason);
            const amount = clinicPaylinkEnabled ? res.data.clinic.paylink_amount : res.data.charger_accounts?.amount;
            setAmount(amount);
            setLoad(true);
            setClinicFlag(res.data.clinic_id!==null);
            setClinicData(res.data.clinic ? res.data.clinic : null);
            setEnableButton(false);
            if ((amount !== 0 && amount !== null) && payLink) {
              onMinAmount(amount);
            }
            if (!payLinkValue && clinicData) {
              setFieldsOptional(true);
            }
          } else {
            setLoading(false);
            showModalInfo(
              locales_es.errorModal.title,
              helpers.getErrorMsg(res)
            );
          }
        })
        .catch((error: any) => {
          setLoading(false);
          showModalInfo(
            locales_es.errorModal.title,
            helpers.getErrorMsg(error)
          );
        });
    }
    onVerifyAccount();
  }, []);

  const onMinAmount = (amount: any, optional: boolean = false) => {
    if (!amount && !fieldsOptional) {
      setErrorMessages({
          ...errorMessages,
          amount: 'El Campo es obligatorio',
      });
      setErrors([...errors, 'amount']);
      setEnableButton(true);
    }
  };

  const onMaxlink = (link: any) => {
    if (link.length >= 255) {
      setErrorMessages({
        ...errorMessages,
        payLink: "El link debe tener máximo 255 caracteres",
      });
      setErrors([...errors, "payLink"]);
      return;
    }
  };

  const setComma = () => {
    if (amount === null || amount === 0) return;
    // si el monto no icluye punto, se le agrega una con valor 00
    if ((Number(amount) == 0 || amount) && amount.indexOf(".") === -1) {
      setAmount(amount + ".00");
    }

    // el valor incluye coma, la reemplazamos por punto

    if ((Number(amount) == 0 || amount) && amount.indexOf(",") !== -1) {
      setAmount(amount.replace(",", "."));
    }

    // si el valor incluye punto, verificamos que solo tenga dos decimales
    if ((Number(amount) == 0 || amount) && amount.indexOf(".") !== -1) {
      const amountSplit = amount.split(".");
      if (amountSplit[1].length > 2) {
        setAmount(amountSplit[0] + "." + amountSplit[1].substring(0, 2));
      }
    }
  };
  
  const sendEvent = () => {
    EventRegister.emit(EVENT_RELOAD_PRESCRIPTION_PROFILES);
  };

  const clearPostRegister = async () => {
    await localStorage.removeItem(userTemporalDataPostRegister);
  };

  const onSendAccount = () => {
    setShowModal(false);
    setComma();
    setNameStep(true);
  };

  const onDelete = () => {
    setWithPaymentData(false);  
    setShowModal(false);
    setNameStep(true);
  };

  const onSubmit = () => {
    if (isClinicPaylink) {
      goToNextStep();
    } else if (!errors.length) {
      setLoading(true);
      const objData = {
        prescription_profile_id: prescriptionProfileId,
        profession: currentPrescriptionProfile.profession,
        specialties: currentPrescriptionProfile.specialties,
        additional_fields: currentPrescriptionProfile.additional_fields,
        medical_license: currentPrescriptionProfile.medical_licenses,
        name,
        address: currentPrescriptionProfile.address,
        stamp: currentPrescriptionProfile.stamp,
        personalized_stamp: currentPrescriptionProfile.personalized_stamp,
        optional_fields: [], // NOT SUPPORTED YET
        active_charger_account: (withPaymentData) ? ((!(isClinicPaylink || (clinicData && !clinicData.paylink_enabled))) ? !activeAccount ? 0 : 1 : null) : null,
        charger_account: (withPaymentData) ? {
          amount: (Number(amount) == 0 || amount) ? parseFloat(amount).toFixed(2) : 0,
          reason: reason ?? '',
          pay_link: payLink ?? '',
        } : null,
      };

      api
        .putPrescriptionProfile(prescriptionProfileId, objData)
        .then((res: any) => {
          if (res.status) {
            setLoading(false);
            clearPostRegister();
            sendEvent();
            goToNextStep();
          } else {
            setLoading(false);
            showModalInfo(
              locales_es.errorModal.title,
              helpers.getErrorMsg(res)
            );
          }
        })
        .catch((error: any) => {
          setLoading(false);
          showModalInfo(
            locales_es.errorModal.title,
            helpers.getErrorMsg(error)
          );
        });
    } else {
      if (errors.length > 0) {
        showModalInfo(
          locales_es.infoModal.title,
          locales_es.errorModal.checkData
        );
        return;
      }
    }
  };

  const goToNextStep = () => {
    const emails = [
      {
        value: userData && userData.email ? userData.email : "",
      },
    ];
    history.push(`/${Routes.CreatingYourFirstPrescription}`, {
      emails,
      prescriptionProfileId,
    });
  };

  const onBlur = (inputStr: string) => {
    setErrors(errors.filter((item: string) => item !== inputStr));
  };

  useEffect(() => {
    const listener = EventRegister.addEventListener(
      EVENT_LOAD_SIGNATURE,
      () => {
        setLoad(!load);
      }
    );

    return function cleanup() {
      EventRegister.removeEventListener(listener);
    };
  });

  const handleSave = (isMobile: Boolean = false) => {
    if (!activeAccount) {
      if (currentPrescriptionProfile.active_charger_account === 0 || (clinicData && !clinicData.paylink_enabled)) {
        setNameStep(true);
      } else if (currentPrescriptionProfile.active_charger_account === 1) {
        showModalAction(
          locales_es.inactiveChargerAccount.title,
          locales_es.inactiveChargerAccount.subtitle,
          () => setNameStep(true),
          isMobile ? "100%" : "50%"
        );
      }
    } else if (activeAccount) {
      if (!oneAccountActive || clinicFlag) setNameStep(true);
      else if (oneAccountActive) {
        setShowModal(true);
      }
    }
  };

  const onBlurAmount = (amounts: string) => {
    //verificamos que no contenga punto o coma
    if ((amount && amount != 0) && amount.includes(".") === false && amount.includes(",") === false) {
      setAmount(amount + ".00");
    }
  };

  return {
    activeAccount,
    amount,
    enableButton,
    errors,
    errorMessages,
    load,
    loading,
    name,
    nameStep,
    payLink,
    reason,
    showModal,
    translations,
    clinicData,
    isClinicPaylink,
    fieldsOptional,
    initValues,
    goToNextStep,
    handleSave,
    setActiveAccount,
    setAmount,
    setEnableButton,
    setErrors,
    setErrorMessages,
    setName,
    setNameStep,
    setPayLink,
    setReason,
    setShowModal,
    onBlur,
    onBlurAmount,
    onDelete,
    onMaxlink,
    onMinAmount,
    onSubmit,
    onSendAccount,
    onVerifyAccount,
    setFieldsOptional,
    setWithPaymentData
  };
};

export default usePaymentInformationPage;
