import React, {memo, useState, useCallback, useEffect} from 'react';
import {
  View,
  StyleSheet,
  Image,
  TouchableOpacity,
  Platform,
  Keyboard,
} from 'react-native';
import Text from '../../components/Text';
import {Colors, Routes} from '../../configs';
import InputApp from '../../components/InputApp';
import FullScreenLoading from '../../components/fullscreenLoading';
import ButtonLinear from '../../components/Buttons/ButtonLinear';
import {useHistory} from "react-router-dom";
import validationEmail from '../../utils/validation/email';
import {useTheme} from '../../configs/Theme';
// @ts-ignore
import locales_es from '../../locales/es';
import AuthService from '../../modules/authService';
import Helpers from '../../modules/helpers';
import {validateEmptyFields, handleValidatorInputsTypes,InitForm} from '../../utils/validation/forms';
import {EventRegister} from '../../modules/EventRegister';
import {EVENT_SHOW_MODAL_INFO, EVENT_SHOW_MODAL_INFO_REGISTER} from '../../constants/Events';
import eyeOnIcon from '../../images/ic_eye_on.png';
import "./styles.css";
import Footer from "../../components/footer";

import RequireData from '../../components/RequireData';
import useDimensions from '../../hooks/useDimensions';

interface LoginProps {
}

const Login = memo((props: LoginProps) => {
  const {isDesktop} = useDimensions();
  const auth = new AuthService();
  const helpers = new Helpers();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState<any>([]);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [visiblePassword, setVisiblePassword] = useState(false);
  const [isValidEmail, setIsValidEmail] = useState(false);
  const {theme} = useTheme();
  const onShowHidePassword = useCallback(() => {
    setVisiblePassword(prev => !prev);
  }, []);

  const onSignUp = useCallback(() => {
    /*EventRegister.emit(EVENT_SHOW_MODAL_INFO_REGISTER, {
      title: 'Registro de nuevo médico',
      message: 'Para registrarse, descarga la aplicación en tu teléfono',
    });*/
    history.push(`/${Routes.SignUp}`);
    // history.push(`/${Routes.BasicInformation}`);
  }, [history]);
  const onLogin = () => {
    // history.push(`/${Routes.MainTab}`);
    // history.push(`/${Routes.Home}`);
    const errors = validateEmptyFields({email, password});
    if (errors.length) {
      setErrors(errors);
    } else {
      setLoading(true);
      auth
        .login({email, password})
        .then(() => {
          setLoading(false);
          history.push(`/${Routes.Home}`);
          /*props.navigation.reset({
            index: 0,
            routes: [{name: Routes.Home}],
          });*/
        })
        .catch((err: any) => {
          setLoading(false);
          EventRegister.emit(EVENT_SHOW_MODAL_INFO, {
            title: locales_es.errorModal.title,
            message: helpers.getErrorMsg(err),
          });
        });
    }
  };
  const onForgotPassword = useCallback(() => {
    history.push(`/${Routes.Recovery}`);
  }, [history]);

  const onBlurEmail = (inputStr: string,email: string) => {
    maxLengthEmail(email)
    handleValidatorInputsTypes(initForm, email, 'email',setEmail,'email',8);
    // setErrors(errors.filter(item => item !== inputStr));
  };

  useEffect(() => {
    const validation = validationEmail(email);
    setIsValidEmail(validation);
  }, [email]);

  useEffect(() => {
    if (auth.isUnauthorized()) {
      auth.removeUnauthorized();
      EventRegister.emit(EVENT_SHOW_MODAL_INFO, {
        title: locales_es.errorModal.noneTitle,
        message: locales_es.errorModal.unauthorizedError,
      });
    }
  })

  const passwordRef = React.createRef();

  const [errorMessages, setErrorMessages] = useState({
    email: '',
    password: ''
  });
  const [enableButton, setEnableButton] = useState(true);

  const initValues = {
    email,
    password,
  }

  const initForm: InitForm = {
    setErrors,
    errors,
    setErrorMessages,
    errorMessages,
    setEnableButton,
    initValues,
  }

  const emailMaxLength = 75;

  const maxLengthEmail = (value: string) => {
    if (value.length >= emailMaxLength) {
      setErrorMessages({...errorMessages, email: `El correo no puede tener más de ${emailMaxLength} caracteres`});
      setErrors([...errors, 'email']);
    }
  };

  return (
    <div
      testID="loginScreen"
      className="w-100 p-3 p-md-0"
      style={{flex: 1, backgroundColor: isDesktop ? Colors.Snow : theme.backgroundItem}}>
      {loading && <FullScreenLoading/>}
      <div className="row h-100">
        <div className="d-none d-md-inline-flex col-6">
          <div className="rcta-bg-logo"/>
        </div>
        <div className="col-12 col-md-6 align-self-center text-center" style={{...(isDesktop? styles.desktopContent : {})}}>
          <div className={!isDesktop ? 'text-center' : ''} style={{...styles.textStyle, ...(isDesktop ? styles.desktopTitle : {})}}>
            {locales_es.login_page.welcome}
          </div>
          <div className="p-3">
            <View style={styles.inputLogin}>
              <RequireData />
              <InputApp
                testID="email"
                borderColor={
                  errors.includes('email')
                    ? theme.lineErrorColor
                    : theme.innearColor
                }
                title={locales_es.email}
                placeholder={locales_es.email}
                value={email}
                onChangeText={(value:string)=>{handleValidatorInputsTypes(initForm, value, 'email',setEmail,'email',8); maxLengthEmail(value)}}
                onBlur={() => {
                  onBlurEmail('email',email);
                  passwordRef.current.focus();
                }}
                icon={
                  <Image
                    source={require('../../images/ic_accept.png')}
                    style={styles.icon}
                  />
                }
                isShowIcon={isValidEmail}
                keyboardType="email-address"
                blurOnSubmit={true}
                onSubmitEditing={() => {
                  // this.secondTextInput.focus();
                  //  alert(JSON.stringify(passwordRef));
                  passwordRef.current.focus();
                }}
                autoCapitalize="none"
                returnKeyType="next"
                autoFocus={false}
                marginTop={24}
                showRequired={true}
                maxLength={emailMaxLength}
              />
              {
                errors.includes('email') && (
                  <Text size={13} lineHeight={16} marginTop={4} color={Colors.Red}>{errorMessages.email}</Text>
                )
              }
              <InputApp
                placeholder={locales_es.password}
                testID="password"
                forwardRef={passwordRef}
                borderColor={
                  errors.includes('password')
                    ? theme.lineErrorColor
                    : theme.innearColor
                  }
                  title={locales_es.password}
                value={password}
                onChangeText={(value:string)=>{handleValidatorInputsTypes(initForm, value, '',setPassword,'password',0);}}
                secureTextEntry={!visiblePassword}
                marginTop={24}
                icon={
                  <Image
                    source={eyeOnIcon}
                    style={styles.icon}
                  />
                }
                isShowIcon
                iconPress={onShowHidePassword}
                blurOnSubmit={Platform.OS === 'android'}
                onSubmitEditing={() => {
                  // this.secondTextInput.focus();
                  //  alert(JSON.stringify(passwordRef));
                  Keyboard.dismiss();
                  onLogin();
                }}
                autoCapitalize="none"
                returnKeyType="go"
                autoCorrect={false}
                showRequired={true}
                maxLength={15}
              />
              {
                errors.includes('password') && (
                  <Text size={13} lineHeight={16} marginTop={4} color={Colors.Red}>{errorMessages.password}</Text>
                )
              }
            </View>
            <ButtonLinear testID="login-submit" disabled={enableButton} white title={'Iniciar Sesión'} onPress={onLogin}/>
            <TouchableOpacity style={styles.forgot} onPress={onForgotPassword}>
              <Text type="H3" color={Colors.GrayBlue} style={{...styles.textUnderline, ...(isDesktop? styles.desktopForgot : {})}}>
                {locales_es.forgotYourPassword}
              </Text>
            </TouchableOpacity>
          </div>
          <div style={styles.signUp}>
            <Text type="H3" color={Colors.GrayBlue}>
              <span style={isDesktop ? styles.desktopRegister:{}}>{locales_es.login_page.do_not_have_account}{' '}</span>
              <Text
                blueLight
                type="H3"
                color={Colors.BlueCrayola}
                bold
                onPress={onSignUp}>
                 <span style={isDesktop ? styles.desktopRegister:{}}>{locales_es.login_page.register_link}</span>
              </Text>
            </Text>
          </div>
        </div>
      </div>
      <Footer className={helpers.isMobile() ? "" : "app-footer-auth"}/>
    </div>
  );
});

export default Login;

const styles = StyleSheet.create({
  inputLogin: {
    marginTop: 16,
  },
  icon: {
    width: 24,
    height: 24,
  },
  margin24: {
    marginTop: 24,
  },
  forgot: {
    alignSelf: 'center',
    marginTop: 32,
  },
  signUp: {
    alignSelf: 'center',
    padding: '1rem',
    textAlign: 'center',
  },
  textUnderline: {
    textAlign: 'center',
    textDecorationLine: 'underline',
  },
  textStyle: {
    fontSize: '2rem',
    padding: '1rem',
  },
  containerLogin: {
    flex: 1,
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center'
  },
  // Desktop styles
  desktopTitle: {
    fontSize: 28,
    fontWeight: 'bold',
    paddingBottom: 0,
    textAlign: 'left'
  },
  desktopContent: {
    margin: 'auto',
    maxWidth: 550,
    gap: 4,
  },
  desktopForgot: {
    fontSize: 18
  },
  desktopRegister: {
    fontSize: 18
  }
});
