import { useState } from "react";

function useLocalStorage(key, initialValue) {
  const getStoredValue = () => {
    try {
      const item = localStorage.getItem(key);
      return item ? JSON.parse(item) : initialValue;
    } catch (error) {
      console.error(error);
      return initialValue;
    }
  };

  const [value, setValue] = useState(getStoredValue);

  const setStoredValue = (newValue) => {
    try {
      if (newValue) {
        setValue(newValue);
        localStorage.setItem(key, JSON.stringify(newValue));
      }
    } catch (error) {
      console.error(error);
    }
  };

  return [value, getStoredValue, setStoredValue];
}

export default useLocalStorage;
