import React, {
    useLayoutEffect,
    useState,
    useEffect,
} from 'react';
import locales_es from '../../../locales/es';
import UserInfo from '../../../components/ConsultDetail/UserInfo';
import APIService from '../../../modules/apiService';
import Helpers from '../../../modules/helpers';
import {useHistory} from 'react-router-dom';
import {showModalInfo} from '../../../modules/ViewHelpers';
import PrescriptionsList from '../../../components/PrescriptionsList';
import Container from '../../../components/Layout/Container';

import ScrollableTab from '../../../components/ScrollableTab';


let nativeEventKeySearch = '';

export const useDebounce = (value, delay) => {
    const [debouncedValue, setDebouncedValue] = useState(value);
   
    useEffect(() => {
       const handler = setTimeout(() => {
         setDebouncedValue(value);
       }, delay);
   
       return () => {
         clearTimeout(handler);
       };
    }, [value, delay]);
   
    return debouncedValue;
};

interface useSearchPatientCertificatesProps {
    keySearch?: string;
}
const useSearchPatientCertificates = (props: useSearchPatientCertificatesProps) => {
    const history = useHistory();
    const api = new APIService();
    const helpers = new Helpers();
    const params: any = history.location.state;
    const onPressItem = props && props.onPressItem ? props.onPressItem : params && params.onPressItem ? params.onPressItem : () => {
    };
    const [searchResults, setSearchResults] = useState([]);
    const [page,setPage] = useState(1);
    const [hasMore, setHasMore] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const [total,setTotal] = useState(0);
    const [keySearch, setKeySearch] = useState('');
    const debouncedSearchTerm = useDebounce(keySearch, 700);

    useEffect(() => {
        if (props.keySearch?.length > 0) {

            setKeySearch(props.keySearch);
            setHasMore(true);
        }
        
            setHasMore(true);
            setSearchResults([])
            setPage(1)
            setIsLoading(false);
        
        
    }, [props.keySearch]);

    const loadMore = async ()=>{
        if (isLoading|| !hasMore) return;
        const objData = {
        q: keySearch,
        };
        await api
        .getSearchCertificates(objData, page)
        .then((res: any) => {

            if (res.data.current_page === res.data.last_page) {
                setHasMore(false);
            }

            setSearchResults(currentSearchResults => currentSearchResults ? currentSearchResults.concat(res.data.data) : res.data.data);
            setTotal(res.data.total);
            setIsLoading(false);
            
        })
        .catch((err: any) => {
            showModalInfo(
                locales_es.infoModal.title,
                helpers.getErrorMsg(err),
            );
            setIsLoading(false);

        });
    }

    useEffect(() => {

        if (debouncedSearchTerm) {
            setHasMore(true);
            setSearchResults([])
            setPage(1)
            setIsLoading(true);
            loadMore();
        }
     }, [debouncedSearchTerm]);


     const onLoadMore = () => {
        if (!isLoading) {
            setIsLoading(true);
            setPage(page+1);
            loadMore(); 

        }
     }


    return {searchResults,onLoadMore,total,hasMore,isLoading};
}
 
export default useSearchPatientCertificates;