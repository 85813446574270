import {width} from '../../configs/Const';
import React, {memo} from 'react';
import {
  View,
  StyleSheet,
  TouchableOpacity,
  ScrollView,
  SafeAreaView,
  ViewStyle,
} from 'react-native';
import {getBottomSpace, getStatusBarHeight} from 'react-native-iphone-x-helper';
import Theme from '../../style/Theme';
import Text from '../../components/Text';
import {Colors} from '../../configs';
import Layout from '../../components/Layout/Layout';
import {useTheme} from '../../configs/Theme';
import Line from '../../components/Layout/Line';

interface ModalPresentationsSelectProps {
  close: () => void;
  choices: {id: number; name: string; color?: string}[];
  onPressItem: (item: any) => void;
  style?: ViewStyle;
}

const ModalPresentationsSelect = memo(
  ({close, choices, onPressItem, style}: ModalPresentationsSelectProps) => {
    const {theme} = useTheme();
    return (
      <SafeAreaView style={styles.container}>
        <TouchableOpacity
          style={StyleSheet.absoluteFillObject}
          onPress={close}
        />
        <View
          style={{
            paddingTop: getStatusBarHeight() + 80,
            paddingBottom: getBottomSpace() + 12,
            width: !style ? width : style.width,
            zIndex: 10,
            paddingHorizontal: 16,
            marginLeft: !style ? 0 : 'auto',
            marginRight: !style ? 0 : 'auto',
          }}>
          <ScrollView style={[styles.topBorder, styles.bottomBorder]}>
            {choices &&
              choices.map((item, index) => {
                const onPress = () => {
                  onPressItem && onPressItem(item);
                  close();
                };
                return (
                  <View key={item}>
                    <TouchableOpacity
                      activeOpacity={0.54}
                      style={[
                        styles.selectItem,
                        index === 0 && styles.topBorder,
                        index === choices.length - 1 && styles.bottomBorder,
                        {backgroundColor: theme.backgroundItem},
                      ]}
                      {...{onPress}}>
                      <Text
                        color={item.color && item.color}
                        size={15}
                        lineHeight={18}
                        semibold>
                        {item && item.name ? item.name : item}
                      </Text>
                    </TouchableOpacity>
                    {index !== choices.length - 1 ? <Line /> : null}
                  </View>
                );
              })}
          </ScrollView>
          <Layout
            style={[
              styles.selectItem,
              styles.topBorder,
              styles.bottomBorder,
              {marginTop: 24}
            ]}>
            <TouchableOpacity
              style={{...StyleSheet.absoluteFillObject, ...Theme.center}}
              onPress={close}
              activeOpacity={0.54}>
              <Text size={15} lineHeight={18} semibold color={Colors.GrayBlue}>
                Cancelar
              </Text>
            </TouchableOpacity>
          </Layout>
          <Line width={'90%'} />
        </View>
      </SafeAreaView>
    );
  },
);

export default ModalPresentationsSelect;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#00000054',
    justifyContent: 'flex-end',
    width: '100%'
  },
  selectItem: {
    height: 50,
    ...Theme.center,
  },
  topBorder: {
    borderTopLeftRadius: 16,
    borderTopRightRadius: 16,
  },
  bottomBorder: {
    borderBottomLeftRadius: 16,
    borderBottomRightRadius: 16,
  },
});
