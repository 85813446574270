import React, {memo, useCallback} from 'react';
import {View, StyleSheet} from 'react-native';
import Text from '../../components/Text';
import Theme from '../../style/Theme';
import scale from '../../utils/scale';
import ButtonLinear from '../../components/Buttons/ButtonLinear';
import Container from '../../components/Layout/Container';
import Lottie from 'react-lottie';
import { Routes } from '../../configs';
import {useHistory} from "react-router-dom";
import animationData from "../../animations/email-successfully-sent.json";

const SentDocumentSuccessful = memo(() => {
  const history = useHistory();

  const onAccept = useCallback(() => {
    history.push(`${Routes.Home}`);
  }, [history]);

  const animationSize = 250;
  const animationOptions = {
    loop: false,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice"
    }
  };

  return (
    <Container style={styles.container}>
      <View
        style={{
          height: '35%',
          width: '100%',
        }}>
        <Lottie
          options={animationOptions}
          height={animationSize}
          width={animationSize}
        />
      </View>
      <Text size={20} lineHeight={24} bold marginTop={scale(55)}>
        ¡Email enviado!
      </Text>
      <Text size={15} lineHeight={24} marginTop={16} center>
        Recuerde revisar también la carpeta de SPAM y Correo No deseado
      </Text>
      <ButtonLinear
        white
        title={'Aceptar'}
        style={{paddingHorizontal: 32}}
        onPress={onAccept}
      />
    </Container>
  );
});

export default SentDocumentSuccessful;

const styles = StyleSheet.create({
  container: {
    ...Theme.container,
    ...Theme.center,
  },
});
