import React, {memo} from 'react';
import {View, Image, StyleSheet} from 'react-native';
import Text from '../../components/Text';
import {Colors} from '../../configs';
import scale from '../../utils/scale';
import Theme from '../../style/Theme';
import Layout from '../../components/Layout/Layout';

interface ProfileManagementProps {}

const ProfileManagement = memo((props: ProfileManagementProps) => {
  return (
    <Layout style={styles.container}>
      <View style={styles.box}>
        <View>
          <Text bold size={15} lineHeight={18} marginTop={8}>
            {props.name} {props.lastname}
          </Text>
          <Text marginBottom={8} marginTop={8} size={13} lineHeight={16}>
            {props.email}
          </Text>
          <Text size={13} lineHeight={16} bold>
            Médico
          </Text>
        </View>
        <View style={styles.avatarView}>
          <Image
            style={styles.avatar}
            source={{uri: String(props.full_profile_image)}}
          />
          {/*<View style={styles.statusView}>
              <View
                style={[
                  styles.circle,
                  {
                    backgroundColor: online
                      ? Colors.Malachite
                      : Colors.RedNeonFuchsia,
                  },
                ]}
              />
            </View>*/}
        </View>
      </View>
    </Layout>
  );
});

export default ProfileManagement;

const styles = StyleSheet.create({
  container: {
    borderRadius: 16,
    top: -180,
    shadowOffset: {width: 0, height: 5},
    shadowRadius: 10,
    shadowColor: Colors.boxShadow,
    shadowOpacity: 1,
    position: 'absolute',
    alignSelf: 'center',
    width: '94%',
  },
  box: {
    paddingLeft: '40%',
    paddingTop: 20,
    paddingBottom: 28,
    paddingRight: 24,
    // borderBottomWidth: 1,
    // borderBottomColor: Colors.Snow,
  },
  avatar: {
    width: 88,
    height: 88,
    borderRadius: 28,
  },
  avatarView: {
    position: 'absolute',
    top: 18,
    left: 24,
  },
  item: {
    paddingTop: 16,
    paddingHorizontal: 16,
    height: '30%',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  statusView: {
    width: 14,
    height: 14,
    borderRadius: 14,
    backgroundColor: Colors.White,
    shadowOffset: {width: 0, height: 5},
    shadowRadius: 10,
    shadowOpacity: 0.5,
    position: 'absolute',
    right: 0,
    ...Theme.center,
  },
  circle: {
    width: 10,
    height: 10,
    borderRadius: 10,
  },
});
