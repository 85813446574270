import React, { Component } from "react";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import "./styles.css";

import {
  image64ToCanvasRef,
  extractImageFileExtensionFromBase64,
} from "../../modules/imageCropperHelpers";
import FormImageInput from "../formImageInput";
import { Image, TouchableOpacity, View, StyleSheet } from "react-native-web";
import { Colors } from "../../configs";
import Theme from "../../style/Theme";
import scale from "../../utils/scale";
import avatarProfileImage from "../../images/avatar/ic_account_normal1@3x.png";
import Text from "../Text";
import locales_es from "../../locales/es";

export default class imgLoadAndCrop extends Component {
  constructor(props) {
    super(props);

    this.imagePreviewCanvasRef = React.createRef();

    this.state = {
      selectedImage: null,
      selectedImageSizes: {},
      crop: {
        unit: "%",
        width: 50,
        height: 50,
        x: 25,
        y: 25,
      },
      cropCompleted: false,
      showPreview: false,
      croppedImage: null,
      fileName: "",
      fileExtension: "",
    };
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.deletedImage !== this.props.deletedImage &&
      this.props.deletedImage === true
    ) {
      this.setState({
        cropCompleted: false,
        editCrop: false,
        showPreview: false,
        selectedImage: null,
        fileName: "",
        fileExtension: "",
      });
    }
  }

  //Convert image to base64
  handleImageChange = (ev) => {
    this.setState({ cropCompleted: false, showPreview: false });
    if (ev.target.files && ev.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener("load", () => {
        this.setState(
          {
            selectedImage: reader.result.toString() || "",
            fileName: ev.target.files[0].name,
          },
          () => {
            const fileExtension = extractImageFileExtensionFromBase64(
              this.state.selectedImage
            );
            this.setState({fileExtension: fileExtension});
          }
        );
      });
      reader.readAsDataURL(ev.target.files[0]);
      this.props.onCropping && this.props.onCropping();
    }
  };

  //Save in state loaded image sizes
  handleImageLoad = (ev) => {
    const { width, naturalWidth, height, naturalHeight } = ev.currentTarget;

    this.setState({
      selectedImageSizes: {
        width: width,
        height: height,
        naturalWidth: naturalWidth,
        naturalHeight: naturalHeight,
      },
    });
  };

  //React crop event handler
  handleCropChange = (crop) => {
    this.setState({ crop });
  };

  handleEditCrop = () => {
    this.setState({
      cropCompleted: false,
      editCrop: false,
      showPreview: false
    });
    this.props.onCropping && this.props.onCropping();
  };

  //confirm crop and show preview
  handleConfirmCrop = () => {
    this.setState(
      {
        cropCompleted: true,
        showPreview: true,
        editCrop: false,
      },
      () => {
        const canvasRef = this.imagePreviewCanvasRef.current;
        const img = this.state.selectedImage;

        image64ToCanvasRef(
          canvasRef,
          img,
          this.state.crop,
          this.state.selectedImageSizes
        ).then((croppedImage64) => {
          this.setState(
            {
              croppedImage: croppedImage64,
            },
            () => {
              this.props.saveCrop(this.state.croppedImage, this.props.imageId);
            }
          );
        });
      }
    );
  };

  handleCancelCrop = () => {
    this.setState({
      cropCompleted: false,
      editCrop: false,
      showPreview: false,
      selectedImage: null,
      fileName: "",
      fileExtension: "",
    });
    this.props.onStopCropping && this.props.onStopCropping();
  };

  selectImageFile = () => {
    document.getElementById("selectImageInputFile").click();
  };

  render() {
    const imageInputData = {
      label: this.state.fileName ? this.state.fileName : this.props.inputLabel,
      id: this.props.imageId,
      state: this.props.imageState,
      required: false,
      wrapperCustomClassName: this.props.inputWrapperCustomClassName,
    };

    return (
      <>
        <FormImageInput
          imageInputData={imageInputData}
          handleImageChange={this.handleImageChange}
        />

        <View style={[styles.container, this.props.containerStyles || {}]}>
          {this.state.selectedImage !== null &&
            this.state.cropCompleted === false && (
              <div className="rcta-main-overlay text-center h-100">
                <div className="cropper-container h-100">
                  <div className="image-cropper h-100">
                    <h4>Recortar imagen</h4>
                    <ReactCrop
                      src={this.state.selectedImage}
                      crop={this.state.crop}
                      onChange={this.handleCropChange}
                      onComplete={this.handleCropChange}
                      maxWidth="720"
                      maxHeight="720"
                      aspect={this.props.aspect}
                      style={{ margin: "10px 0" }}
                    >
                      <img
                        src={this.state.selectedImage}
                        alt="Recortar imagen..."
                        onLoad={this.handleImageLoad}
                        style={{ width: "720px", margin: "10px 0" }}
                      />
                    </ReactCrop>
                    <div className="btn-container">
                      <button
                        onClick={this.handleConfirmCrop}
                        className="btn btn-primary"
                      >
                        {locales_es.accept}
                      </button>
                      {!this.state.editCrop && (
                        <button
                          onClick={() => this.handleCancelCrop()}
                          className="btn btn-secondary ml-4"
                        >
                          {locales_es.cancel}
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </div>
          )}
          <>
            {this.state.showPreview ? (
              <View
                style={[
                  styles.avatar,
                  this.props.avatarStyles,
                  this.props.silentMode ? { display: "none" } : {},
                ]}
              >
                <canvas
                  onClick={this.handleEditCrop}
                  ref={this.imagePreviewCanvasRef}
                  className={`crop-preview ${this.props.canvasCustomClassName}`}
                ></canvas>
              </View>
            ) : (
              <TouchableOpacity
                style={[
                  styles.avatar,
                  this.props.silentMode ? { display: "none" } : {},
                  this.props.avatarStyles || {},
                ]}
                onPress={this.selectImageFile}
              >
                <Image
                  source={this.props.image || avatarProfileImage}
                  // style={StyleSheet.absoluteFillObject}
                  style={{
                    width: this.props.silentMode ? "100%" : "80%",
                    height: "80%",
                    alignSelf: "flex-start",
                    borderRadius: 32,
                  }}
                  resizeMode="contain"
                />
              </TouchableOpacity>
            )}
          </>
          {this.props.silentMode ? null : (
            <View style={Theme.flexOne}>
              <TouchableOpacity
                style={styles.buttonUpload}
                onPress={this.selectImageFile}
              >
                <Text white size={15} lineHeight={16} bold color={Colors.White}>
                  Subir Imagen de Perfil
                </Text>
              </TouchableOpacity>
              <Text
                size={16}
                lineHeight={18}
                marginTop={12}
                color={Colors.GrayBlue}
              >
                {this.props.addPatient
                  ? "Se usará para reconocer al paciente más rápidamente, aunque no es obligatorio"
                  : "Se usará para que su paciente lo reconozca más fácilmente, aunque no es obligatorio"}
              </Text>
            </View>
          )}
        </View>
      </>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    marginTop: scale(10),
    ...Theme.flexRow,
  },
  avatar: {
    width: scale(112),
    height: scale(112),
    borderRadius: 16,
    overflow: "hidden",
    justifyContent: "center",
  },
  buttonUpload: {
    height: 50,
    marginRight: 19,
    backgroundColor: Colors.TealBlue,
    ...Theme.center,
    borderRadius: 16,
  },
});
