import React, {Component} from 'react';

export default class FullscreenLoading extends Component {

    render() {
        return (
          <div className="app-loader main-loader">
              <div className="loader-box">
                  <div className="bounceball"></div>
                  {this.props.hideLogo ? null : <div className="text">RCTA<span>web</span></div>}
              </div>
          </div>
        )
    }
}
