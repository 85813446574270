import React, {memo, useCallback} from 'react';
import {View, StyleSheet, Image} from 'react-native';
import Text from '../../components/Text';
import {Colors, Routes} from '../../configs';
import ButtonLinear from '../../components/Buttons/ButtonLinear';
import locales_es from '../../locales/es';
import Layout from '../../components/Layout/Layout';
import AuthService from '../../modules/authService';
import {useHistory} from 'react-router-dom';

interface ModalInfoProps {
  title: string;
  message: string;
  onPressDefault: any;
  logoutModal?: boolean;
  actions?: any;
  width?: number;
}

const ModalInfo = memo((props: ModalInfoProps) => {
  const auth = new AuthService();
  const history = useHistory();
  const {title, message, onPressDefault, logoutModal,width, actions} = props;
  const logout = () => {
    // navigate(Routes.Login);
    auth.logout(true);
    /*navigation.reset({
      index: 0,
      routes: [{name: Routes.Login}],
    });*/
    onPressDefault();
  };

  return (
    <View style={styles.container}>
      <Layout
        style={{
          borderRadius: 16,
          paddingHorizontal: 24,
          paddingVertical: 32,
          width: width? width :  '100%',
          maxWidth: width ? undefined:  640,
          textAlign: 'center',
          margin: 'auto',
        }}>
        {/*<Image
          source={require('../../images/error-icon.png')}
          style={{alignSelf: 'center', width: '25%'}}
          resizeMode="contain"
        />*/}
        <Text center size={17} lineHeight={20} bold>
          {title}
        </Text>
        <Text center size={13} lineHeight={22} marginTop={16}>
          {message}
        </Text>
        {actions && actions.length ?
          actions.map((action, i) =>
            <ButtonLinear
              key={"action-" + i}
              white={action.white}
              title={action.text}
              onPress={() => {
                action.onPress && action.onPress();
                onPressDefault();
              }}
              styleButton={action.styleButton}
              colors={action.colors}
            />
          )
          : (
          <ButtonLinear
            white
            title={locales_es.accept}
            onPress={logoutModal ? logout : onPressDefault}
            style={{width: '60%', alignSelf: 'center', marginTop: 15}}
            
          />
        )}
      </Layout>
    </View>
  );
});

export default ModalInfo;

const styles = StyleSheet.create({
  container: {
    backgroundColor: Colors.Black68,
    ...StyleSheet.absoluteFillObject,
    paddingHorizontal: 24,
    justifyContent: 'center',
    zIndex: 1000,
  },
});
