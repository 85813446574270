import React from "react";
import { View, Image, StyleSheet } from "react-native";
import Text from "../../../components/Text";

interface IProps {
  color: string;
  title?: string;
  icon?: string;
  number: number;
}

function Stat({ color, title, icon, number }: IProps) {
  return (
    <View style={styles.wrapper}>
      <View style={[styles.externalBorder, { borderColor: color }]}>
        <View style={[styles.internalBorder, { borderColor: color }]}>
          {icon && (
            <Image
              source={{ uri: icon, width: 35, height: 35 }}
              style={[styles.icon, { tintColor: color }]}
            />
          )}
          <Text color={color} bold size={36} style={styles.text}>
            {number}
          </Text>
        </View>
      </View>
      {title && (
        <Text marginTop={16} size={18}>
          {title}
        </Text>
      )}
    </View>
  );
}

export default Stat;

const styles = StyleSheet.create({
  wrapper: { alignItems: "center", flex: 1 },
  externalBorder: {
    width: 160,
    height: 160,
    borderWidth: 1,
    borderRadius: 80,
    justifyContent: "center",
    alignItems: "center",
  },
  internalBorder: {
    width: 150,
    height: 150,
    borderWidth: 8,
    borderRadius: 75,
    justifyContent: "center",
    alignItems: "center",
  },
  icon: { width: 35, height: 35, marginBottom: 8 },
  text: {
    textAlign: "center",
    maxWidth: 120,
    maxHeight: 80,
    overflow: "hidden",
  },
});
