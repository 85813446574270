import Helpers from './helpers';
import locales_es from '../locales/es';
import {Alert, Share} from 'react-native';
import AuthService from './authService';
export default class Sharer {
  constructor() {
    this.helpers = new Helpers();
    this.auth = new AuthService();
  }

  patientsShareMedicsWhatsApp(name, lastname) {
    this.auth
      .getAndSaveRemoteConfigData()
      .then(res => {
        const text =
          locales_es.whatsapp_share_text_intro_patients +
          ' ' +
          name +
          ' ' +
          lastname +
          '*, ' +
          this.replaceLinks(res, locales_es.whatsapp_share_text_patients);
        this.helpers
          .openLink(`whatsapp://send?text=${text}`)
          .then(() => {
            Alert.alert(
              locales_es.global.successTitle,
              locales_es.whatsapp_share_text_success,
            );
          })
          .catch(err => {
            console.log(err);
            Alert.alert(
              locales_es.global.errorTitle,
              locales_es.whatsapp_share_text_error,
            );
          });
      })
      .catch(err => {
        Alert.alert(
          locales_es.global.errorTitle,
          locales_es.whatsapp_share_config_error,
        );
      });
  }

  medicsSharePatientsWhatsApp(name, lastname) {
    this.auth
      .getAndSaveRemoteConfigData()
      .then(res => {
        const text =
          locales_es.whatsapp_share_text_intro +
          ' ' +
          name +
          ' ' +
          lastname +
          '*, ' +
          this.replaceLinks(res, locales_es.whatsapp_share_text);
        this.helpers
          .openLink(`whatsapp://send?text=${text}`)
          .then(() => {
            Alert.alert(
              locales_es.global.successTitle,
              locales_es.whatsapp_share_text_success,
            );
          })
          .catch(err => {
            console.log(err);
            Alert.alert(
              locales_es.global.errorTitle,
              locales_es.whatsapp_share_text_error,
            );
          });
      })
      .catch(err => {
        Alert.alert(
          locales_es.global.errorTitle,
          locales_es.whatsapp_share_config_error,
        );
      });
  }

  replaceLinks(appConfig, text) {
    const regexIos = /\['url-ios-store'\]/gm;
    const regexAndroid = /\['url-play-store'\]/gm;

    text = text.replace(regexIos, appConfig['url-ios-store']);
    text = text.replace(regexAndroid, appConfig['url-play-store']);
    return text;
  }

  onShare = async () => {
    try {
      const result = await Share.share({
        message: locales_es.shareAppInfoText,
      });
      if (result.action === Share.sharedAction) {
        if (result.activityType) {
          // shared with activity type of result.activityType
          // Alert.alert('Éxito', 'Se ha compartido correctamente');
        } else {
          // shared
          // Alert.alert('Éxito', 'Se ha compartido correctamente');
        }
      } else if (result.action === Share.dismissedAction) {
        // dismissed
        /*Alert.alert(
          'Información',
          'Puede volver a esta pantalla para enviar la receta cuando lo necesite',
        );*/
      }
    } catch (error) {
      alert(error.message);
    }
  };
}
