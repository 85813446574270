import React, {Component} from 'react';

export default class Spinner extends Component {

    render() {
        return (
            <div className="app-loader"><i className="icofont-spinner-alt-4 rotate"></i></div>
        )
    }
}
