export const EVENT_SHOW_MODAL_INFO_REGISTER = "showModalInfoRegister";
export const EVENT_SHOW_MODAL_INFO = "showModalInfo";
export const EVENT_SHOW_OVERLAY = "showOverlay";
export const EVENT_SHOW_OVERLAY_CROP = "showOverlayCrop";
export const EVENT_LOAD_SIGNATURE = "loadSignature";
export const EVENT_RELOAD_PATIENTS = "reloadPatients";
export const EVENT_RELOAD_PATIENT = "reloadPatient";
export const EVENT_RELOAD_COMBOS = "reloadCombos";
export const EVENT_RELOAD_COMBOS_PRACTICES = "reloadCombosPractices";
export const EVENT_RELOAD_PRESCRIPTION_PROFILES = "reloadPrescriptionProfiles";
export const EVENT_LOADED_COUNTRY = "loadedCountry";
export const EVENT_RELOAD_PRESCRIPTIONS = "reloadPrescriptions";
export const EVENT_RELOAD_CERTIFICATES = "reloadCertificates";
export const EVENT_UPLOAD_PROFILE_IMAGE = "uploadProfileImage";
export const EVENT_FORBIDDEN = "401";
export const EVENT_CLEAR_PATIENT = "clearPatient";
export const EVENT_VALIDATE_PATIENT = "validatePatient";
export const EVENT_SHOW_MODAL_ACTION = "showModalAction";
export const EVENT_SHOW_MODAL_WITH_INPUT = "showModalWithInput";
export const EVENT_USER_CHANGED = "userChanged";

