import React from "react";
import {
  View,
  StyleSheet,
  Modal,
  Image,
  ActivityIndicator,
} from "react-native";
import Text from "../../../components/Text";
import { useHistory } from "react-router-dom";
import ButtonIconHeader from "../../../components/ButtonIconHeader";
import { Colors, Routes } from "../../../configs";
import UserInfo from "../../../components/ConsultDetail/UserInfo";
import ButtonBorder from "../../../components/Buttons/ButtonBorder";
import ButtonLinear from "../../../components/Buttons/ButtonLinear";
import ModalChangePlan from "../../../components/ConsultDetail/Modals/ModalChangePlan";
import ModalSendReason from "../../../components/ConsultDetail/Modals/ModalSendReason";
import Theme from "../../../style/Theme";
import Content from "../../../components/Layout/Content";
import Layout from "../../../components/Layout/Layout";
import Line from "../../../components/Layout/Line";
import AdditionalItem from "../../../components/ConsultDetail/AdditionalItem";
import Photos from "../../../components/MyWorkLibrary/Photos";

import { dateTimeService } from "../../../import";
import { FAKE_EMAIL_REGEX } from "../../../models/constants";
import locales_es from "../../../locales/es";
import { CommonStyles } from "../../../styles/CommonStyles";
import Container from "../../../components/Layout/Container";
import ListButton from "../../../components/ListButton";
import { ConsultsType } from "../../../configs/Const";

import medicationIcon from "../../../images/ic_medication@3x.png";
import accountNormalIcon from "../../../images/ic_account_normal@3x.png";
import expIcon from "../../../images/ic_exp@3x.png";
import eyeOnIcon from "../../../images/ic_eye_on@2x.png";
import photoLibraryIcon from "../../../images/ic_photo_library@3x.png";
import additionalInformationIcon from "../../../images/ic_additional_information@3x.png";
import usePatientProfilePage from "../shared/usePatientProfilePage";

interface PatientProfileMobileProps {}

interface routeType {
  key: string;
  name: string;
  params: {
    patientId: number;
  };
}

const PatientProfileMobile = (props: PatientProfileMobileProps) => {
  const history = useHistory();
  const {
    historyBackSteps,
    userData,
    patientId,
    images,
    readOnly,
    onRemoveAttachment,
    onUserDelete,
    onUserEdit,
    onCreateCertificate,
    onCreatePrescription,
    visible,
    close,
    translateY,
    visibleModalChange,
    closeChange,
  } = usePatientProfilePage();

  return (
    <>
      <View style={styles.headerTitle}>
        <View>
          <ButtonIconHeader
            marginLeft={24}
            onPress={() => {
              if (historyBackSteps) {
                // const popAction = StackActions.pop(historyBackSteps);
                // props.navigation.dispatch(popAction);
                history.go(-historyBackSteps);
              } else {
                history.goBack();
              }
            }}
          />
        </View>
        <View style={{ flex: 1 }}>
          <Text size={17} lineHeight={20} bold marginRight={50} center>
            Perfil del Paciente
          </Text>
        </View>
      </View>
      <Container style={styles.wrapper}>
        <Content
          style={styles.container}
          contentContainerStyle={{ paddingHorizontal: 12, paddingBottom: 32 }}
        >
          {userData ? (
            <UserInfo user={userData} style={{ marginTop: 16 }} />
          ) : (
            <ActivityIndicator style={CommonStyles.activityIndicator} />
          )}
          <Layout
            style={[styles.containerAdditionalInfo, { paddingBottom: 12 }]}
          >
            <View
              style={{
                ...Theme.flexRow,
                paddingBottom: 16,
                paddingHorizontal: 24,
              }}
            >
              <View
                style={{
                  width: 32,
                  height: 32,
                  borderRadius: 8,
                  backgroundColor: Colors.TealBlue20,
                  ...Theme.center,
                  marginRight: 16,
                }}
              >
                <Image source={medicationIcon} style={styles.icons} />
              </View>
              <Text size={15} lineHeight={18} bold>
                Prescripciones
              </Text>
            </View>
            <Line />
            <ListButton
              title={locales_es.prescriptions}
              onPress={() =>
                history.push(`/${Routes.PatientPrescriptions}`, {
                  patientId,
                  prescriptionType: ConsultsType.Prescription,
                })
              }
            />
            <ListButton
              title={locales_es.certificates}
              onPress={() =>
                history.push(`/${Routes.PatientCertificates}`, {
                  patientId,
                  prescriptionType: ConsultsType.Certificate,
                })
              }
            />
          </Layout>
          {userData ? (
            <Layout style={[styles.containerAdditionalInfo, props.style]}>
              <View
                style={{
                  ...Theme.flexRow,
                  paddingBottom: 16,
                  paddingHorizontal: 24,
                }}
              >
                <View
                  style={{
                    width: 32,
                    height: 32,
                    borderRadius: 8,
                    backgroundColor: Colors.TealBlue20,
                    ...Theme.center,
                    marginRight: 16,
                  }}
                >
                  <Image source={accountNormalIcon} style={styles.icons} />
                </View>
                <Text size={15} lineHeight={18} bold>
                  Datos del Perfil
                </Text>
              </View>
              <Line />
              <View style={{ paddingHorizontal: 24, paddingTop: 24 }}>
                <AdditionalItem
                  title={locales_es.birthDate}
                  value={dateTimeService.reverseAPIStringDate(
                    userData.birth_date
                  )}
                />
                <AdditionalItem
                  title={locales_es.identificationType}
                  value={userData.identification_type.name}
                />
                <AdditionalItem
                  title={locales_es.identification}
                  value={userData.identification}
                />
                <AdditionalItem
                  title={locales_es.email}
                  value={
                    !FAKE_EMAIL_REGEX.test(userData.email) && userData.email
                  }
                />
              </View>
            </Layout>
          ) : (
            <ActivityIndicator style={CommonStyles.activityIndicator} />
          )}
          {userData ? (
            <Layout style={[styles.containerAdditionalInfo, props.style]}>
              <View
                style={{
                  ...Theme.flexRow,
                  paddingBottom: 16,
                  paddingHorizontal: 24,
                }}
              >
                <View
                  style={{
                    width: 32,
                    height: 32,
                    borderRadius: 8,
                    backgroundColor: Colors.TealBlue20,
                    ...Theme.center,
                    marginRight: 16,
                  }}
                >
                  <Image source={expIcon} style={styles.icons} />
                </View>
                <Text size={15} lineHeight={18} bold>
                  Datos de su Cobertura Médica
                </Text>
              </View>
              <Line />
              <View style={{ paddingHorizontal: 24, paddingTop: 24 }}>
                <AdditionalItem
                  title={"Cobertura Médica"}
                  value={userData.medical_insurance.name}
                />
                <AdditionalItem
                  title={"Plan"}
                  value={userData.medical_insurance_plan}
                />
                <AdditionalItem
                  title={"Nº de Afiliado"}
                  value={userData.medical_insurance_number}
                />
              </View>
            </Layout>
          ) : (
            <ActivityIndicator style={CommonStyles.activityIndicator} />
          )}
          {userData ? (
            <Layout style={[styles.containerAdditionalInfo, props.style]}>
              <View
                style={{
                  ...Theme.flexRow,
                  paddingBottom: 16,
                  paddingHorizontal: 24,
                }}
              >
                <View
                  style={{
                    width: 32,
                    height: 32,
                    borderRadius: 8,
                    backgroundColor: Colors.TealBlue20,
                    ...Theme.center,
                    marginRight: 16,
                  }}
                >
                  <Image source={eyeOnIcon} style={styles.icons} />
                </View>
                <Text size={15} lineHeight={18} bold>
                  Observaciones
                </Text>
              </View>
              <Line />
              <View style={{ paddingHorizontal: 24, paddingTop: 24 }}>
                {userData === null ? (
                  <ActivityIndicator style={CommonStyles.activityIndicator} />
                ) : userData && userData.comment ? (
                  <Text size={15} lineHeight={18}>
                    {userData.comment}
                  </Text>
                ) : (
                  <Text size={15} lineHeight={18}>
                    Sin observaciones.
                  </Text>
                )}
              </View>
            </Layout>
          ) : (
            <ActivityIndicator style={CommonStyles.activityIndicator} />
          )}
          {userData ? (
            <Layout style={[styles.containerAdditionalInfo, props.style]}>
              <View
                style={{
                  ...Theme.flexRow,
                  paddingBottom: 16,
                  paddingHorizontal: 24,
                }}
              >
                <View
                  style={{
                    width: 32,
                    height: 32,
                    borderRadius: 8,
                    backgroundColor: Colors.TealBlue20,
                    ...Theme.center,
                    marginRight: 16,
                  }}
                >
                  <Image source={photoLibraryIcon} style={styles.icons} />
                </View>
                <Text size={15} lineHeight={18} bold>
                  Adjuntos
                </Text>
              </View>
              <Line />
              <View style={{ paddingHorizontal: 24, paddingTop: 24 }}>
                {images === null ? (
                  <ActivityIndicator style={CommonStyles.activityIndicator} />
                ) : images && images.length ? (
                  <Photos data={images} onRemove={onRemoveAttachment} />
                ) : (
                  <Text size={15} lineHeight={18}>
                    No hay imágenes adjuntas
                  </Text>
                )}
              </View>
            </Layout>
          ) : (
            <ActivityIndicator style={CommonStyles.activityIndicator} />
          )}
          {userData ? (
            <>
              {readOnly === true ? null : (
                <Layout style={[styles.containerAdditionalInfo, props.style]}>
                  <View
                    style={{
                      ...Theme.flexRow,
                      paddingBottom: 16,
                      paddingHorizontal: 24,
                    }}
                  >
                    <View
                      style={{
                        width: 32,
                        height: 32,
                        borderRadius: 8,
                        backgroundColor: Colors.TealBlue20,
                        ...Theme.center,
                        marginRight: 16,
                      }}
                    >
                      <Image
                        source={additionalInformationIcon}
                        style={styles.icons}
                      />
                    </View>
                    <Text size={15} lineHeight={18} bold>
                      Otras acciones
                    </Text>
                  </View>
                  <Line />
                  <View style={[Theme.flexRow, { marginTop: 20 }]}>
                    <ButtonBorder
                      placeholder
                      style={{ flex: 1, marginHorizontal: 8 }}
                      title={"Editar"}
                      onPress={onUserEdit}
                    />
                    <ButtonLinear
                      placeholder
                      colors={[Colors.RedNeonFuchsia, Colors.Tomato]}
                      styleButton={{ flex: 1, marginHorizontal: 8 }}
                      style={{ marginTop: 0 }}
                      white={true}
                      title={"Inactivar Paciente"}
                      onPress={onUserDelete}
                    />
                  </View>
                </Layout>
              )}
              {readOnly === true ? null : (
                <Layout style={styles.bottomButton}>
                  <View>
                    <ButtonLinear
                      style={{ marginTop: 0 }}
                      white
                      title={locales_es.createPrescription}
                      onPress={onCreatePrescription}
                    />
                    <ButtonBorder
                      style={{ marginTop: 10 }}
                      title={locales_es.otherPrescriptions}
                      onPress={onCreateCertificate}
                    />
                  </View>
                </Layout>
              )}
            </>
          ) : (
            <ActivityIndicator style={CommonStyles.activityIndicator} />
          )}
        </Content>
        {visibleModalChange ? (
          <Modal
            visible={true}
            onRequestClose={closeChange}
            transparent
            animationType={"fade"}
          >
            <ModalChangePlan close={closeChange} />
          </Modal>
        ) : null}
        {/*<Modal
        visible={visibleModalDecline}
        onRequestClose={closeDecline}
        transparent
        animationType={'fade'}>
        <ModalDeclineAlert
          close={closeDecline}
          user={item.user}
          openSendReason={open}
        />
      </Modal>*/}
        {visible ? (
          <Modal
            visible={true}
            onRequestClose={close}
            transparent
            animationType={"fade"}
          >
            <ModalSendReason close={close} translateY={translateY} />
          </Modal>
        ) : null}
      </Container>
    </>
  );
};

export default PatientProfileMobile;

const styles = StyleSheet.create({
  wrapper: {
    ...Theme.flexOne,
  },
  container: {
    flex: 1,
  },
  headerTitle: {
    ...Theme.headerNavigationStyle,
    justifyContent: "center",
    alignContent: "center",
    alignItems: "center",
    flexDirection: "row",
  },
  bottomButton: {
    paddingTop: 12,
    paddingBottom: 21,
    paddingHorizontal: 24,
    borderRadius: 16,
    marginTop: 24,
    marginBottom: 24,
  },
  header: {
    ...Theme.headerNavigationStyle,
  },
  containerAdditionalInfo: {
    borderRadius: 16,
    paddingTop: 16,
    paddingBottom: 24,
    marginTop: 24,
    ...Theme.shadow,
  },
  icons: { width: 20, height: 20 },
});
