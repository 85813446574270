import React, {
    useLayoutEffect,
    useState,
    useEffect,
    useRef
  } from 'react';
  import {
    View,
    StyleSheet,
    ActivityIndicator,
    FlatList,
    scrollView,
  } from 'react-native';
  import Text from '../../components/Text';
  import SearchBox from '../../components/Home/SearchBox';
  import {Colors} from '../../configs';
  import Theme from '../../style/Theme';
  import {SOURCE_ICON} from '../../images';
  import Layout from '../../components/Layout/Layout';
  import HeaderButton from '../../components/HeaderButton';
  import locales_es from '../../locales/es';
  import {CommonStyles} from '../../styles/CommonStyles';
  import Container from '../../components/Layout/Container';
  import {getBottomSpace, getStatusBarHeight} from 'react-native-iphone-x-helper';
  import useSearchPatientCertificates from '../SearchPatientCertificates/shared/useSearchPatientCertificates';
  import PrescriptionsList from '../../components/PrescriptionsList';
  import {ConsultsType, width} from '../../configs/Const';

 

  interface SearchPatientCertificatesProps{
    keySearch?: string;
  }

const SearchPatientCertificates = (props: SearchPatientCertificatesProps) => {
    const {
        searchResults,
        onLoadMore,
        total,
        hasMore,
        isLoading
      } = useSearchPatientCertificates({keySearch: props.keySearch});

      const renderSpinner = () => {
        return (
          <View style={{flex: 1, width: width}}>
            <ActivityIndicator style={CommonStyles.activityIndicator}/>
          </View>
        );
      };
    return ( 
        <>
        <Container style={styles.container}>
          {props.keySearch && !searchResults ? renderSpinner() :
              <PrescriptionsList 
              type={ConsultsType.Certificate}
              prescriptions={searchResults}
              emptyText={'No tienes recetas generadas hoy'}
              title={'Prescripciones'}
              refreshing={isLoading}
              onLoadMore={onLoadMore}
              total={total}    
      />}
        </Container>
      </>
    );
}  

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
});
 
export default SearchPatientCertificates;