import React, { createContext } from "react"
import { useHubspotChat } from "../hooks/useHubspotChat"

export const HubspotChatContext = createContext<any>(undefined)

export const HubspotChatProvider: React.FC<{
  portalId: string
  children: React.ReactElement | React.ReactElement[]
}> = ({ portalId, children }) => {
  const chat = useHubspotChat(portalId)

  return (
    <HubspotChatContext.Provider value={chat}>
      {children}
    </HubspotChatContext.Provider>
  )
}
