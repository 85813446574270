import React, {memo} from 'react';
import {
  View,
  StyleSheet,
  TouchableOpacity,
  Image,
  ViewStyle,
} from 'react-native';
import Text from '../../components/Text';
import {Colors} from '../../configs';
import Theme from '../../style/Theme';
import iconClose from '../../images/ic_close@3x.png';

interface TagItemProps {
  title: string;
  style?: ViewStyle;
  iconColor?: ViewStyle;
  onPressX?: () => void;
}

const TagItem = memo((props: TagItemProps) => {
  return (
    <View
      style={[
        styles.container,
        props.onPressX
          ? {minHeight: 30, paddingRight: 22}
          : {minHeight: 24, paddingRight: 12},
        props.style,
      ]}>
      <Text white size={12} lineHeight={14} bold color={Colors.White}>
        {props.title}
      </Text>
      {props.onPressX ? (
        <TouchableOpacity
          style={styles.touch}
          onPress={() => props.onPressX && props.onPressX()}>
          <Image
            source={iconClose}
            style={[props.iconColor, styles.icon]} 
          />
        </TouchableOpacity>
      ) : null}
    </View>
  );
});

export default TagItem;

const styles = StyleSheet.create({
  container: {
    paddingLeft: 12,
    marginRight: 5,
    paddingVertical: 5,
    // justifyContent: 'center',
    backgroundColor: Colors.DodgerBlue,
    borderRadius: 4,
    ...Theme.flexRow,
    // marginRight: 8,
    marginTop: 8,
    flexWrap: 'wrap',
  },
  touch: {
    width: 16,
    height: 16,
    position: 'absolute',
    right: 4,
  },
  icon: {
    width: 16,
    height: 16,
    
  },
});
