import {
  StyleSheet,
  Text,
  View,
  Animated,
  Easing,
} from "react-native";
import React from "react";

const SlideItem = ({ item }: {item: any}) => {
  const translateYImage = new Animated.Value(40);

  Animated.timing(translateYImage, {
    toValue: 0,
    duration: 1000,
    useNativeDriver: true,
    easing: Easing.bounce,
  }).start();

  return (
    <View style={styles.container}>
      <Animated.Image
        source={item.image}
        resizeMode="contain"
        style={[
          styles.image,
          {
            transform: [
              {
                translateY: translateYImage,
              },
            ],
          },
        ]}
      />

      <View style={styles.content}>
        <Text style={styles.title}>{item.desc}</Text>
      </View>
    </View>
  );
};

export default SlideItem;

const styles = StyleSheet.create({
  container: {
    width: '100vw',
    height: '50vh',
    alignItems: "center",
    backgroundImage: 'linear-gradient(#33CFBE, #39A4D3)',
  },
  image: {
    flex: 1,
    width: "100%",
  },
  content: {
    position:'absolute',
    bottom: 100,
    textAlign: 'center',
    maxWidth: 375
  },
  title: {
    fontFamily: 'Mulish',
    fontWeight: 'bold',
    color: 'white',
    fontSize: 20,
    lineHeight: 32
  },
  description: {
    fontSize: 18,
    marginVertical: 12,
    color: "#333",
  },
  price: {
    fontSize: 32,
    fontWeight: "bold",
  },
});
