import React, {memo, useState, useCallback} from 'react';
import {
  View,
  StyleSheet,
  Image,
  FlatList,
  TouchableOpacity,
} from 'react-native';
import Text from '../../components/Text';
import TextInput from '../../components/TextInput';
import scale from '../../utils/scale';
import Theme from '../../style/Theme';
import {Colors} from '../../configs';
import {categoryList} from '../../type/category';
import {countryFlag} from '../../configs/Data';

interface ModalSearchStringsProps {
  onChangeCategory: (item: categoryList) => void;
  flagImg?: boolean;
}

const ModalSearchStrings = (props: ModalSearchStringsProps) => {
  const [keyword, setKeyword] = useState('');
  const [data, setData] = useState(props.data);
  const renderItem = ({item}) => {
    const onPress = () => {
      props.onChangeCategory && props.onChangeCategory(item);
    };
    return (
      <TouchableOpacity
        testID={item.name}
        key={item.id}
        style={styles.item}
        onPress={onPress}>
        {props.flagImg && (
          <Image source={countryFlag[item.code]} style={styles.flag} />
        )}
        <Text size={15} lineHeight={24} color={Colors.DarkJungleGreen}>
          {item.name}
        </Text>
      </TouchableOpacity>
    );
  };

  const onChangeKeyword = useCallback(
    (text: string) => {
      const regex = new RegExp(text, 'i'); // add 'i' modifier that means "ignore case"

      let strings = JSON.parse(JSON.stringify(props.data));
      strings = strings.filter(string => {
        if (regex.test(string.name)) {
          return string;
        }
      });
      setData(strings.length ? strings : props.data);
      setKeyword(text);
    },
    [data, keyword],
  );

  return (
    <View style={styles.container}>
      <TextInput
        iconLeft={
          <Image
            source={require('../../images/ic_search_normal.png')}
            style={styles.iconSearch}
          />
        }
        isShowIconLeft={true}
        value={keyword}
        onChangeText={onChangeKeyword}
        backgroundColor={Colors.Isabelline}
        borderColor={Colors.Isabelline}
        placeholder={props.placeholder}
        editable={true}
      />
      <FlatList
        data={data}
        renderItem={renderItem}
        contentContainerStyle={styles.contentContainerStyle}
        showsVerticalScrollIndicator={false}
        keyExtractor={item => item.id.toString()}
      />
    </View>
  );
};

export default ModalSearchStrings;

const styles = StyleSheet.create({
  container: {
    paddingTop: 30,
    paddingHorizontal: 24,
    height: scale(493, true),
  },
  iconSearch: {
    width: 20,
    height: 20,
  },
  item: {
    ...Theme.flexRow,
    paddingVertical: 16,
  },
  flag: {
    width: 32,
    height: 20,
    marginRight: 16,
  },
  textCode: {
    width: 76,
  },
  contentContainerStyle: {
    paddingVertical: 18,
    paddingHorizontal: 16,
  },
});
