import React, { useState, useEffect } from "react";
import { Alert, ActivityIndicator, StyleSheet } from "react-native";
import Theme from "../../style/Theme";
import { Colors, Routes } from "../../configs";
import { useTheme } from "../../configs/Theme";
import locales_es from "../../locales/es";
import Text from "../../components/Text";
import APIService from "../../modules/apiService";
import Helpers from "../../modules/helpers";
import { CommonStyles } from "../../styles/CommonStyles";
import ButtonIcon from "../../components/Buttons/ButtonIcon";
import CountryService from "../../modules/CountryService";
import Footer from "../../components/footer";
import { useHistory } from "react-router-dom";
import { FaGooglePlay, FaApple } from "react-icons/fa6";

import "./styles.css";

export const MobileDownload = () => {
  const { theme } = useTheme();
  const api = new APIService();
  const helpers = new Helpers();
  const [config, setConfig] = useState(null);

  const [isAndroid, setIsAndroid] = useState(false);
  const [isIOS, setIsIOS] = useState(false);
  const [isAnotherPlatform, setIsAnotherPlatform] = useState(false);

  useEffect(() => {
    if (!config) {
      setIsAndroid(helpers.isAndroid());
      setIsIOS(helpers.isIOS());
      setIsAnotherPlatform(!helpers.isAndroid() && !helpers.isIOS());
      api
        .getConfig()
        .then((res: any) => {
          setConfig(res.data);
        }).catch(error => {
          console.log('error', {error});
        });
    }
  }, [config]);

  const download = (platform = '') => {
    const androidStore = (config) ? config['url-play-store'] : null;
    const iosStore = (config) ? config['url-ios-store'] : null;
    if ((helpers.isAndroid() && androidStore)||(platform == 'android')) {
      window.open(androidStore, '_blank');
    } else if ((helpers.isIOS() && iosStore)||(platform == 'ios')) {
      window.open(iosStore, '_blank');
    }
  }

  return (
    <>
      <div
        testID="mobileDownloadScreen"
        className="w-100 p-md-0 container-fluid"
        style={{ flex: 1, backgroundColor: theme.backgroundItem }}
      >
        <div className="row h-100">
          <div className="col-12 col-md-12 align-self-center text-center">
            <div className="text-center" style={styles.welcomeStyle}>
              {locales_es.mobileDownloadSection.title}
            </div>
            <div className="text-center">
              <Text
                size={20}
                lineHeight={24}
                center
              >
                <span style={styles.subtitle}>{locales_es.mobileDownloadSection.description}</span>
              </Text>
              {(isIOS || isAnotherPlatform) && 
                  <button
                    onClick={() => download('ios')}
                    className="btn btn-secondary"
                    style={{
                      height: 55,
                      width: "100%",
                      maxWidth: 400,
                      borderRadius: 16,
                      margin: "auto",
                      marginTop: 56,
                      marginBottom: 10,
                      fontSize: '18px',
                      fontWeight: '600',
                      color: '#fff'
                    }}
                  >
                    <FaApple size={24} /><span style={{marginLeft: '10px'}}>{locales_es.mobileDownloadSection.downloadIOS}</span>
                  </button>
              }
              {(isAndroid || isAnotherPlatform) &&
                  <button
                    onClick={() => download('android')}
                    className="btn btn-secondary"
                    style={{
                      height: 55,
                      width: "100%",
                      maxWidth: 400,
                      borderRadius: 16,
                      margin: "auto",
                      marginTop: 10,
                      marginBottom: 10,
                      fontSize: '18px',
                      fontWeight: '600',
                      color: '#fff'
                    }}
                  >
                    <FaGooglePlay size={24} /><span style={{marginLeft: '10px'}}>{locales_es.mobileDownloadSection.downloadAndroid}</span>
                  </button>
              }
            </div>
          </div>
          <Footer className={helpers.isMobile() ? "" : "app-footer-auth"} />
        </div>
      </div>
    </>
  );
};

const styles = StyleSheet.create({
  container: {
    ...Theme.flexOne,
    backgroundColor: Colors.White,
  },
  content: {
    display: "flex",
    flexDirection: "row",
    height: "100vh",
  },
  sectionContainer: {
    marginTop: 32,
    paddingHorizontal: 24,
  },
  sectionTitle: {
    fontSize: 24,
    fontWeight: "600",
  },
  sectionDescription: {
    marginTop: 8,
    fontSize: 18,
    fontWeight: "400",
  },
  highlight: {
    fontWeight: "700",
  },
  formStyle: {
    flex: 1,
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  bgStyle: {
    flex: 0.5,
    display: "flex",
  },
  welcomeStyle: {
    fontSize: "2.5rem",
    fontWeight: "bold",
    marginBottom: "4rem"
  },
  subtitle: {
    fontWeight: 600,
    display: 'block'
  },
});
