import React from "react";
import { View, Image, StyleSheet,ViewStyle } from "react-native";
import Text from "../../components/Text";
import { Colors } from "../../configs";

interface IProps {
  header?: {
    title?: string;
    icon?: string;
    action?: React.ReactNode;
  };
  children: React.ReactNode;
  footer?: React.ReactNode;
  style?: ViewStyle;
}

export function Icon({ icon }: { icon: string }) {
  return (
    <View
      style={{
        backgroundColor: "#c6f4f4",
        padding: 4,
        borderRadius: 8,
        height: 30,
      }}
    >
      <Image
        source={{ uri: icon, width: 20, height: 20 }}
        style={{ width: 20, height: 20, tintColor: "#00c1c1" }}
      />
    </View>
  );
}

function Card({ header, children, style, footer }: IProps) {
  
  return (
    <View style={{...styles.wrapper, zIndex: style && style.zIndex }}>
      {header && (
        <View style={styles.headerWrapper}>
          {header.icon && <Icon icon={header.icon} />}
          {header.title && (
            <Text size={17} marginLeft={16} bold style={styles.title}>
              {header.title}
            </Text>
          )}
          {header.action}
        </View>
      )}
      <View style={{
        ...styles.content, 
        paddingBottom: footer ? 0 : 24,
        }}>{children}</View>

      {
        footer && (
        <View 
          style={{
            height: '25%',
            // width: "100%",
            bottom: 0,
            margin: 0,
            padding: 0,
          }}
          >
            {footer}
        </View>
        )
      }
    </View>
  );
}

export default Card;

const styles = StyleSheet.create({
  wrapper: {
    shadowOffset: { width: 0, height: 2 },
    shadowRadius: 7,
    shadowColor: Colors.WhiteGray,
    shadowOpacity: 1,
    flex: 1,
    backgroundColor: "white",
    borderRadius: 16,
    display: "flex",
  },
  headerWrapper: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    paddingVertical: 16,
    paddingHorizontal: 24,
    borderColor: "#ededed",
    borderBottomWidth: 1,
  },
  title: { flex: 1 },
  content: { padding: 24, flex: 1, maxHeight: "100%" },
});
