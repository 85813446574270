import React, {memo, useEffect, useRef, useState} from 'react';
import {
  Animated,
  Easing,
  Image,
  StyleSheet,
  TouchableWithoutFeedback,
  View,
} from 'react-native';
import Text from '../Text';
import {Colors} from '../../configs';
import Collapsible from 'react-collapsible';
import iconArrowDown from '../../images/ic_arr_down@3x.png';

interface Props {
  item: any;
  title: string;
}

const CustomCollapsible = memo(({item, title}: Props) => {
  const [isCollapsed, setIsCollapsed] = useState(true);
  const animatedIconRotation = useRef(new Animated.Value(0)).current;
  useEffect(() => {
    Animated.timing(animatedIconRotation, {
      duration: 150,
      easing: Easing.linear,
      toValue: isCollapsed ? 0 : 180,
      useNativeDriver: true,
    }).start();
  }, [isCollapsed]);
  const renderCollapsible = item => {
    return item.items && item.items.length ? (
      <View style={styles.collapsibleProfileListIcon}>
        <TouchableWithoutFeedback
          onPress={() => {
            setIsCollapsed(!isCollapsed);
          }}>
          <View
            style={{
              flex: 1,
              alignItems: 'flex-start',
              flexDirection: 'row',
            }}>
            <Text
              size={15}
              lineHeight={18}
              marginTop={8}
              color={Colors.BlueCrayola}>
              {title}
            </Text>
            <Animated.View
              style={{
                marginTop: 5,
                marginLeft: 5,
                transform: [
                  {
                    rotate: animatedIconRotation.interpolate({
                      inputRange: [0, 360],
                      outputRange: ['0deg', '-360deg'],
                    }),
                  },
                ],
              }}>
              <Image
                source={iconArrowDown}
                style={{
                  tintColor: isCollapsed
                    ? Colors.MediumTurquoise
                    : Colors.Platinum,
                  width: 20,
                  height: 20,
                }}
                resizeMode="contain"
              />
            </Animated.View>
          </View>
        </TouchableWithoutFeedback>
        <Collapsible trigger open={!isCollapsed}>
          {item.items.map(renderItem)}
        </Collapsible>
      </View>
    ) : null;
  };

  const renderItem = (item, index) => {
    return (
      <>
        <View style={{flexDirection: 'row'}}>
          <View style={{flex: 1}}>
            <View
              key={index.toString()}
              style={{
                // paddingHorizontal: 24,
                marginVertical: 2,
                zIndex: 1,
              }}>
              <Text size={15} lineHeight={24} medium>
                {item.name}
              </Text>
            </View>
          </View>
        </View>
      </>
    );
  };
  return <>{renderCollapsible(item)}</>;
  /*return <Text>asdas{JSON.stringify(item)}</Text>;*/
});

export default CustomCollapsible;

const styles = StyleSheet.create({
  collapsibleProfileListIcon: {
    paddingRight: 56,
    paddingLeft: 72,
    justifyContent: 'flex-start',
  },
});
