import React, {memo, useCallback} from 'react';
import {
  View,
  StyleSheet,
  Image,
  ImageSourcePropType,
  TouchableOpacity,
  ViewStyle,
} from 'react-native';
import Text from '../../components/Text';
import Theme from '../../style/Theme';
import {Colors, Constants} from '../../configs';
import {useHistory} from 'react-router-dom';
import {useTheme} from '../../configs/Theme';

interface ItemProps {
  img: ImageSourcePropType;
  title: string;
  numberNew?: number;
  route?: string;
  params?: any;
  onPress?: () => void;
  sharer?: boolean;
  style?: ViewStyle;
  imgStyle?: ViewStyle;
  textSize?: number;
}

export default memo((props: ItemProps) => {
  const history = useHistory();
  const onPress = useCallback(() => {
    props.onPress
      ? props.onPress()
      : props.route && history.push(`/${props.route}`, props.params);
  }, [props.route, props.onPress, history]);
  const {theme} = useTheme();
  const renderContent = () => {
    return (
      <>
        {props.img && <img src={props.img} style={props.imgStyle}/>}
        <Text marginTop={16} size={props.textSize ? props.textSize : 13} lineHeight={18} center>
          {props.title}
        </Text>
        {props.numberNew && (
          <Text size={13} lineHeight={18} center color={Colors.GrayBlue}>
            {props.numberNew > 150 ? '150+' : props.numberNew} new
          </Text>
        )}
      </>
    )
  }

  return (
    <div className="rcta-main-control__feature-item">
      {props.sharer ? <View style={[
          styles.container,
          props.style,
          {backgroundColor: theme.backgroundItem},
        ]}>{renderContent()}</View> :
        <TouchableOpacity
          style={[
            styles.container,
            props.style,
            {backgroundColor: theme.backgroundItem},
          ]}
          onPress={onPress}>
          {props.img && <img src={props.img} style={props.imgStyle}/>}
          <Text marginTop={16} size={props.textSize ? props.textSize : 13} lineHeight={18} center>
            {props.title}
          </Text>
          {props.numberNew && (
            <Text size={13} lineHeight={18} center color={Colors.GrayBlue}>
              {props.numberNew > 150 ? '150+' : props.numberNew} new
            </Text>
          )}
        </TouchableOpacity>
      }
    </div>
  );
});

const styles = StyleSheet.create({
  container: {
    paddingTop: 16,
    paddingBottom: 16,
    // width: (Constants.width - 64) / 2,
    // height: (Constants.width - 64) / 2,
    ...Theme.center,
    marginBottom: 16,
    borderRadius: 16,
    ...Theme.shadow,
    width: '80%',
    height: '80%',
  },
  content: {},
});
