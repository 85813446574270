//Create image preview in canvas ref
export const image64ToCanvasRef = async (
  canvasRef,
  image64,
  crop,
  selectedImageSizes,
  fileExtension
) => {
  const canvas = canvasRef;
  const ctx = canvas.getContext("2d");

  const image = new Image();
  image.src = image64;
  //original image sizes
  const imgWidth = selectedImageSizes.width;
  const imgNatWidth = selectedImageSizes.naturalWidth;
  const imgHeight = selectedImageSizes.height;
  const imgNatHeight = selectedImageSizes.naturalHeight;
  //scale for preview image
  const scaleX = imgNatWidth / imgWidth;
  const scaleY = imgNatHeight / imgHeight;

  //preview image sizes
  let previewImgWidth = 720 * scaleX;
  let previewImgHeight = 720 * scaleY;

  if(crop.x && crop.y !== undefined) {
    previewImgWidth = crop.width * scaleX;
    previewImgHeight = crop.height * scaleY;
  }

  //canvas sizes
  canvas.width = previewImgWidth;
  canvas.height = previewImgHeight;

  await drawImage(
    ctx,
    image,
    crop,
    scaleX,
    scaleY,
    previewImgWidth,
    previewImgHeight
  );

  const croppedImage64 = canvasRef.toDataURL("image/" + fileExtension);

  return croppedImage64;
};

const drawImage = (
  ctx,
  image,
  crop,
  scaleX,
  scaleY,
  previewImgWidth,
  previewImgHeight
) => {
  return new Promise((resolve, reject) => {
    let x = 0;
    let y = 0;

    if(crop.x && crop.y !== undefined) {
      x = crop.x * scaleX;
      y = crop.y * scaleY;
    }

    image.onload = () => {
      ctx.drawImage(
        image,
        x,
        y,
        previewImgWidth,
        previewImgHeight,
        0,
        0,
        previewImgWidth,
        previewImgHeight
      );
      resolve(true);
    };
  });
};

//convert a base64-encoded string to a file object
export const base64StringToFile = (base64String, filename) => {
  let arr = base64String.split(",");
  let mime = arr[0].match(/:(.*?);/)[1];
  let bstr = atob(arr[1]);
  let n = bstr.length;
  let u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  return new File([u8arr], filename, { type: mime });
};

//download base64 to file
export const downloadBase64File = (base64Data, filename) => {
  var element = document.createElement("a");
  element.setAttribute("href", base64Data);
  element.setAttribute("download", filename);
  element.style.display = "none";
  document.body.appendChild(element);
  element.click();
  document.body.removeChild(element);
};

//grab de file extension
export const extractImageFileExtensionFromBase64 = (base64Data) => {
  return base64Data.substring(
    "data:image/".length,
    base64Data.indexOf(";base64")
  );
};