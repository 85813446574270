import React, {useState} from 'react';
import Text from "../../components/Text";
import { SOURCE_ICON } from "../../images";
import {
    View,
    StyleSheet,
    TouchableOpacity,
    Image,
    ActivityIndicator,
    ScrollView,
  } from "react-native";
import Card from "../../components/Card";
import {WEBSITE_URL,} from "../../models/constants";
import { helpers } from "../../import";
import { useHistory } from "react-router-dom";

interface AboutProps {
    userData?: any;
    countries?: any;

}

const About = (props : AboutProps) => {
    const history = useHistory();
    const params = history.location.state;
    const [userData,setUserData] = useState(params.userData ? params.userData : null);
    const [countries, setCountries] = useState(params.countries ? params.countries : null);

    const policy = () => {
        
            const currentCountry = countries?.find(
              (c: any) => c.id === userData?.countryId
            );
            if (!currentCountry) {
              return helpers.openLink(WEBSITE_URL);
            }
            else {
        
              const termsPage = `${currentCountry.privacy_policies_url}?v=${process.env.REACT_APP_VERSION}`;
              helpers.openLink(termsPage);
              
            }
          }
          const terms = () => {
            const currentCountry = countries?.find(
              (c) => c.id === userData?.countryId
            );
            if (!currentCountry) return helpers.openLink(WEBSITE_URL);
      
            const termsPage = `${currentCountry.terms_and_conditions_url}?v=${process.env.REACT_APP_VERSION}`;
            helpers.openLink(termsPage);
          }

    return ( 
        <View style={styles.container}>
            <Text marginBottom={10} left size={36} bold> Sobre RCTA</Text>
        <View
            style={{
                
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
                gap: 30
            }}
        >
            <View style={{
                gap: 30,
                width: '43%',
                
            }}>
                <View
                style={{width: '100%', height:'30%'}}>

                    <Card>
                        <Text size={21} bold marginBottom={20}> ¿Quiénes somos?</Text>
                        <Text lineHeight={25} size={14} style={{justifyContent: 'left', textAlign:'left'}}>
                        Nuestro equipo está comprometido en el desarrollo de soluciones tecnológicas para la industria de la salud. Contamos con especialistas en medicina y tecnología, enfocados en crear soluciones simples, confiables y prácticas que aprovechen los avances continuos de las tecnologías digitales. RCTA es un producto creado por Innovamed S.A.
                        </Text>
                    </Card>
                </View>
                <View
            style={{width: '100%', height:'30%'}}>

                <Card>
                    <Text size={21} bold marginBottom={20}> ¿Qué es RCTA?</Text>
                    <Text lineHeight={25} size={14} style={{justifyContent: 'left', textAlign:'left'}}>
                    RCTA es una herramienta completa, práctica y fácil de usar, que permite optimizar el trabajo de nuestros colegas, ahorrando tiempo valioso y mejorando la atención brindada. Las recetas y prescripciones generadas en RCTA son totalmente seguras y cumplen con todas las normativas legales vigentes, según lo establecido por la Ley 27553. {"\n"}
                    Para más información, puedes consultar nuestras {' '}
                    <TouchableOpacity onPress={() => policy()}>
                        <Text style={{color: 'blue'}}>Políticas de Privacidad</Text>
                    </TouchableOpacity> 
                    {' '} y {' '}
                     <TouchableOpacity onPress={() => terms()}>
                        <Text style={{color: 'blue'}}>Términos y Condiciones</Text>
                    </TouchableOpacity>.
                    </Text>
                    
                </Card>
                </View>
            </View>
            <View style={{justifyContent: 'center', alingItems:'center', width:'45%'}}>
            
                <Image source={SOURCE_ICON.about} style={{
                    width: '499px',height: '636px',alingSelf: 'center' 
                }}
                resizeMode='contain'
                />
            </View>
            
            
        </View>




        </View>
    );

}
 
export default About;

const styles = StyleSheet.create({
    container: {
        flex: 1,
        alignItems: "left",
        justifyContent: 'left',
        marginTop:40,
        marginLeft: 20,
        gap: 20,
        backGround: 'white',
        width: '90%',
        height: '100%',
    }
});
