import {coachmarkLS} from '../models/constants';

const initialObject = {};

class CoachMarksService {
  setInitialData() {
    this.getLocalData()
      .then(res => {
        if (!res) {
          localStorage.setItem(coachmarkLS, JSON.stringify(initialObject));
        }
      })
      .catch(err => {
        console.log(err);
      });
  }

  clearData() {
    localStorage.removeItem(coachmarkLS);
  }

  setShownScreen(screen) {
    this.getLocalData()
      .then(res => {
        res[screen] = true;
        localStorage.setItem(coachmarkLS, JSON.stringify(res));
      })
      .catch(err => {
        console.log(err);
      });
  }

  setNotShownScreen(screen) {
    this.getLocalData()
      .then(res => {
        res[screen] = false;
        localStorage.setItem(coachmarkLS, JSON.stringify(res));
      })
      .catch(err => {
        console.log(err);
      });
  }

  getLocalData = async () => {
    try {
      const value = await localStorage.getItem(coachmarkLS);
      const json = JSON.parse(value);
      return json;
    } catch (error) {
      // Error retrieving data
      console.log(error);
      return error;
    }
  };

  checkIfHasToShow(screen) {
    return new Promise((resolve, reject) => {
      this.getLocalData()
        .then(res => {
          resolve(res[screen]);
        })
        .catch(err => {
          console.log(err);
          reject(err);
        });
    });
  }
}

export default CoachMarksService;
