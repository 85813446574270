import {useTheme} from '../../configs/Theme';
import React from 'react';
import {
  View as DefaultView,
  ViewProps,
  ViewStyle,
  PressableStateCallbackType,
} from 'react-native';
interface Props extends ViewProps {
  children?:
    | React.ReactNode
    | ((state: PressableStateCallbackType) => React.ReactNode);
  style?: ViewStyle;
  marginBottom?: number;
  marginTop?: number;
  height?: number;
  width?: number | string;
}

const Line = (props: Props) => {
  const {theme} = useTheme();
  return (
    <DefaultView
      style={[
        {
          height: props.height || 1,
          backgroundColor: theme.lineColor,
          marginBottom: props.marginBottom,
          marginTop: props.marginTop,
          justifyContent: 'center',
          width: props.width ? props.width : '100%',
          alignSelf: 'center',
        },
        props.style,
      ]}>
      {props.children}
    </DefaultView>
  );
};
export default Line;
