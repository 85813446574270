import React from 'react';
import {View, StyleSheet} from 'react-native';
import scale from '../../../utils/scale';
import {Routes} from '../../../configs';
import Text from '../../../components/Text';
import {getBottomSpace, getStatusBarHeight} from 'react-native-iphone-x-helper';
import HeaderButton from '../../../components/HeaderButton';
import Content from '../../../components/Layout/Content';
import {useTheme} from '../../../configs/Theme';
import locales_es from '../../../locales/es';
import SignUpForm from '../shared/SignUpForm';
import { useParams } from 'react-router-dom';

interface SignUpProps {
}

const SignUp = (props: SignUpProps) => {
  const {theme} = useTheme();
  let {country} = useParams();
  return (
    <View style={[styles.container, {backgroundColor: theme.background}]}>
        <SignUpForm countryIn={country} />
    </View>
  );
};

export default SignUp;

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
});
