import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { api, helpers } from "../../../import";
import { showModalInfo } from "../../../modules/ViewHelpers";
import locales_es from "../../../locales/es";
import { EVENT_RELOAD_CERTIFICATES, EVENT_RELOAD_PRESCRIPTIONS } from "../../../constants/Events";
import { EventRegister } from "../../../modules/EventRegister";

const usePatientCertificatesPage = () => {
  const history = useHistory();
  const params = history.location.state;
  const [load, setLoad] = useState(false);
  const [certificates, setCertificates] = useState(null);
  const patientId = params && params.patientId ? params.patientId : null;
  const [nextPageUrl, setNextPageUrl] = useState('');
  const [refreshing, setRefreshing] = useState(false);
  const [total, setTotal] = useState(0);

  const loadDocuments = (nextPageUrl: string) => {
    api
      .getPatientCertificatesPaginated(patientId, nextPageUrl)
      .then((res: any) => {
        setCertificates(
          certificates ? certificates.concat(res.data) : res.data,
        );
        setNextPageUrl(res.next_page_url);
        setRefreshing(false);
        setTotal(res.total);
      })
      .catch((err: any) => {
        helpers.processError(err);
        setRefreshing(false);
      });
  };

  const onLoadMore = () => {
    if (nextPageUrl && !refreshing) {
      setRefreshing(true);
      loadDocuments(nextPageUrl);
    }
  };

  useEffect(() => {
    loadDocuments();
  }, [load]);

  useEffect(() => {
    const listener = EventRegister.addEventListener(
      EVENT_RELOAD_CERTIFICATES,
      data => {
        loadDocuments();
      },
    );

    return function cleanup() {
      EventRegister.removeEventListener(listener);
    };
  });

  return {
    onLoadMore,
    loadDocuments,
    setTotal,
    total,
    setRefreshing,
    refreshing,
    nextPageUrl,
    setNextPageUrl,
    load,
    setLoad,
    certificates
  };
};

export default usePatientCertificatesPage;