import React, { memo, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  View,
  StyleSheet,
  Image,
  Platform,
  Animated,
  FlatList,
  ActivityIndicator,
  Alert,
} from "react-native";
import { IMAGE } from "../../images/Images";
import { ConsultsType, width } from "../../configs/Const";
import { Colors, Routes } from "../../configs";
import ProfileManagement from "../../components/WorkProfileManagement/ProfileManagement";
import About from "../../components/WorkProfileManagement/About";
import ReviewsMyServices from "../../components/WorkProfileManagement/ReviewsMyServices";
import {
  getBottomSpace,
  getStatusBarHeight,
} from "react-native-iphone-x-helper";
import Theme from "../../style/Theme";
import ButtonIconHeader from "../../components/ButtonIconHeader";
import keyExtractor from "../../utils/keyExtractor";
import ScrollTabButton from "../../components/ScrollableTab/ScrollTabButton";
import Layout from "../../components/Layout/Layout";
import Container from "../../components/Layout/Container";
import { useTheme } from "../../configs/Theme";
import AnimatedCircle from "../../components/WorkProfileManagement/AnimatedCircle";

import APIService from "../../modules/apiService";
import AuthService from "../../modules/authService";
import Helpers from "../../modules/helpers";
import locales_es from "../../locales/es";
import { showModalInfo } from "../../modules/ViewHelpers";
import FullScreenLoading from "../../components/fullscreenLoading";
import { CommonStyles } from "../../styles/CommonStyles";
import ButtonBorder from "../../components/Buttons/ButtonBorder";
import PasswordAdmin from "../../components/WorkProfileManagement/PasswordAdmin";
import PrescriptionProfiles from "../../components/WorkProfileManagement/PrescriptionProfiles";
import { EventRegister } from "../../modules/EventRegister";
import {
  EVENT_RELOAD_PRESCRIPTION_PROFILES,
  EVENT_SHOW_MODAL_INFO,
} from "../../constants/Events";
import { API_FRONT_URL } from "../../models/front-urls";
import Toast from "../../components/Toast";

export default memo((props) => {
  const api = new APIService();
  const auth = new AuthService();
  const helpers = new Helpers();
  const [loading, setLoading] = useState(false);
  // const {setOptions, navigate} = useNavigation();
  const history = useHistory();
  const [load, setLoad] = useState<boolean>(false);
  const [about, setAbout] = useState<any>({});
  const [prescriptionProfiles, setPrescriptionProfiles] = useState(null);
  const [reviewsMyServices, setReviewsMyServices] = useState<any>({});
  const [tabActive, setTabActive] = useState<any>(0);
  const [userData, setUserData] = useState<any>(null);
  const [stats, setStats] = useState<any>(null);
  const { theme } = useTheme();
  const scrollY = React.useRef(new Animated.Value(0)).current;
  const [showToast, setShowToast] = useState(false);
  const [textToaster, setTextToaster] = useState<string>("");
  const [variantToaster, setVariantToaster] = useState<string>("success");

  useEffect(() => {
    auth
      .getRemoteUserData()
      .then((res: any) => {
        const _userData = res.data.user;
        auth
          .getLocalUserData()
          .then((res2: any) => {
            const _countryId = res2.user.country_id;
            api
              .getIdentificationTypesByCountryId(_countryId)
              .then((res3: any) => {
                if (res3 && res3.data && res3.data.length) {
                  const userIdType = res.data.user.identification_type_id;
                  if (userIdType) {
                    const idType = res3.data.filter(
                      (type: any) => Number(type.id) === Number(userIdType)
                    )[0];
                    _userData.identificationType = idType;
                  }
                }
                setUserData(_userData);
              })
              .catch((err2: any) => {
                setUserData(_userData);
                EventRegister.emit(EVENT_SHOW_MODAL_INFO, {
                  title: locales_es.infoModal.title,
                  message: helpers.getErrorMsg(err2),
                });
              });
          })
          .catch((err: any) => {
            showModalInfo(
              locales_es.errorModal.title,
              helpers.getErrorMsg(err)
            );
          });
      })
      .catch((err: any) => {
        helpers.processError(err);
      });
    // setUserData(PROFILE_MANAGEMENT);
    // setAbout(ABOUT);
    // setReviewsMyServices(REVIEWS_MY_SERVICES);
  }, [load]);

  const DATA = [{ id: 0 }, { id: 1 }, { id: 2 }];
  // const TABS = ['Mi Perfil', 'Reviews My Services'];
  const TABS = [];
  const scrollDistance = 300;

  const heightAnim = scrollY.interpolate({
    inputRange: [0, scrollDistance / 2, scrollDistance],
    // outputRange: [0, 0, 108 - getStatusBarHeight()],
    outputRange: [0, 0, 108 - getStatusBarHeight()],
    extrapolate: "clamp",
  });

  const marginTopAnim = scrollY.interpolate({
    inputRange: [0, scrollDistance / 2, scrollDistance],
    // outputRange: [0, 0, 108 - getStatusBarHeight()],
    outputRange: [0, 0, Platform.OS === "ios" ? getStatusBarHeight() + 10 : 10],
    extrapolate: "clamp",
  });

  const opacityAnim = scrollY.interpolate({
    inputRange: [0, scrollDistance / 5, scrollDistance],
    outputRange: [1, 0, 0],
    extrapolate: "clamp",
  });

  const opacityAnim1 = scrollY.interpolate({
    inputRange: [0, scrollDistance / 1.5, scrollDistance],
    outputRange: [0, 0, 1],
    extrapolate: "clamp",
  });

  const opacity = { opacity: opacityAnim };

  useEffect(() => {
    api
      .getMyStats()
      .then((res: any) => {
        // setStats(res.data);
        const data = res.data;
        const parsedStats = [
          {
            circleColor: Colors.TealBlue,
            percentage: (data.patients_quantity * 100) / 200,
            percent: data.patients_quantity,
            title: locales_es.patients,
          },
          {
            circleColor: Colors.DodgerBlue,
            percentage: (data.medical_prescriptions_quantity * 100) / 5000,
            percent: data.medical_prescriptions_quantity,
            title: locales_es.prescriptions,
          },
          {
            circleColor: Colors.Orange,
            percentage: (data.medical_certificates_quantity * 100) / 5000,
            percent: data.medical_certificates_quantity,
            title: locales_es.otherPrescriptions,
          },
        ];
        setStats(parsedStats);
      })
      .catch((err: any) => {
        helpers.processError(err);
      });
    loadPrescriptionProfiles();
  }, [load]);

  const loadPrescriptionProfiles = async () => {
    await api
      .getMyPrescriptionProfiles()
      .then((res: any) => {
        setPrescriptionProfiles(res.data);
        if (res && res.data && res.data.length) {
          // delete res.data[0].name;
        } else {
          showModalInfo(
            locales_es.errorModal.title,
            "No hay un perfil de receta disponible"
          );
        }
      })
      .catch((err: any) => {
        helpers.processError(err);
      });
  };

  const onPressUpdateMyProfile = React.useCallback(() => {
    history.push(`/${Routes.BasicInformation}`);
  }, [useHistory]);

  const handleChangeTab = React.useCallback((index) => {
    setTabActive(index);
  }, []);

  // const handlePressShare = React.useCallback(() => {}, []);

  const goToDocumentVisor = (pdfSource) => {
    history.push(`/${Routes.DocumentVisor}`, {
      data: {
        documents: [{ pdfSource }],
        selectionType: ConsultsType.Prescription,
        statusName: locales_es.testPrescription,
        hideToast: true,
        overlay: true,
      },
    });
  };

  const onLogout = () => {
    EventRegister.emit(EVENT_SHOW_MODAL_INFO, {
      title: locales_es.logoutModal.title,
      message: locales_es.logoutModal.subtitle,
      actions: [
        {
          text: locales_es.logout,
          onPress: () => {
            auth.logout(true);
          },
          white: true,
        },
        {
          text: locales_es.cancel,
          onPress: () => console.log("Cancel Pressed"),
          style: "cancel",
          colors: ["#fff", "#fff"],
        },
      ],
    });
  };

  const onCopyClipboard = () => {
    const copy = window.navigator.clipboard.writeText(
      `${API_FRONT_URL}/p/${userData?.slug}`
    );

    if (!copy) {
      setTextToaster("No se pudo copiar el link");
      setVariantToaster("error");
      setShowToast(true);
      setTimeout(() => {
        setShowToast(false);
      }, 5000);
    } else {
      setTextToaster("Link copiado al portapapeles");
      setVariantToaster("success");
      setShowToast(true);
      setTimeout(() => {
        setShowToast(false);
      }, 5000);
    }
  };

  const renderItem = React.useCallback(
    ({ item }) => {
      if (item.id === 0) {
        return (
          <Animated.View>
            <Image style={styles.background} source={IMAGE.background} />
            <Layout style={styles.box}>
              <ProfileManagement {...userData} />
            </Layout>
          </Animated.View>
        );
      } else if (item.id === 1) {
        return (
          <Layout style={styles.tabView}>
            {TABS.map((item, index) => {
              return (
                <ScrollTabButton
                  labelStyle={styles.labelStyle}
                  key={index}
                  title={item}
                  onPressTab={() => handleChangeTab(index)}
                  focus={tabActive === index}
                />
              );
            })}
          </Layout>
        );
      } else if (item.id === 2) {
        return tabActive === 0 ? (
          <>
            {stats === null ? (
              <ActivityIndicator style={CommonStyles.activityIndicator} />
            ) : (
              <View style={styles.topView}>
                <AnimatedCircle key={1} startAnim={true} {...stats[0]} />
                <AnimatedCircle key={2} startAnim={true} {...stats[1]} />
                <AnimatedCircle key={3} startAnim={true} {...stats[2]} />
              </View>
            )}
            {userData && (
              <View style={styles.section}>
                <About {...userData} onCopyClipboard={onCopyClipboard} />
              </View>
            )}
            {prescriptionProfiles && (
              <View style={styles.section}>
                <PrescriptionProfiles
                  editable={true}
                  prescriptionProfiles={prescriptionProfiles}
                  loadPrescriptionProfiles={loadPrescriptionProfiles}
                />
              </View>
            )}
            <View style={styles.section}>
              <PasswordAdmin />
            </View>
            <ButtonBorder
              style={[styles.buttonUpdate, { marginTop: 30 }]}
              placeholder
              title={locales_es.logout}
              onPress={onLogout}
            />
          </>
        ) : (
          <ReviewsMyServices {...reviewsMyServices} />
        );
      }
    },
    [about, prescriptionProfiles, userData, tabActive, stats]
  );

  useEffect(() => {
    const listener = EventRegister.addEventListener(
      EVENT_RELOAD_PRESCRIPTION_PROFILES,
      (data) => {
        loadPrescriptionProfiles();
      }
    );

    return function cleanup() {
      EventRegister.removeEventListener(listener);
    };
  });

  return (
    <>
      {loading && <FullScreenLoading />}
      {showToast && (
        <Toast variant={`${variantToaster}`} text={textToaster} mobile={true} />
      )}
      <Container style={styles.container}>
        <FlatList
          data={DATA}
          renderItem={renderItem}
          keyExtractor={keyExtractor}
          bounces={false}
          showsVerticalScrollIndicator={false}
          scrollEventThrottle={16}
          stickyHeaderIndices={[1]}
          contentContainerStyle={styles.contentContainerStyle}
          onScroll={Animated.event(
            [{ nativeEvent: { contentOffset: { y: scrollY } } }],
            { useNativeDriver: false }
          )}
        />
        <Animated.View style={[styles.header, opacity]}>
          <ButtonIconHeader
            backgroundColor={Colors.DarkJungleGreenOpacity}
            style={styles.buttonHeader}
            tintColor={Colors.White}
          />
        </Animated.View>
      </Container>
    </>
  );
});

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  background: {
    width: width,
    height: 253,
  },
  box: {
    paddingHorizontal: 16,
  },
  iconEdit: {
    marginRight: 12,
  },
  buttonUpdate: {
    marginTop: 0,
    marginHorizontal: 24,
  },
  labelStyle: {
    fontSize: 15,
    fontWeight: "bold",
  },
  contentContainerStyle: {
    paddingTop: -1,
    paddingBottom: getBottomSpace() + 38,
  },
  header: {
    ...Theme.flexRowSpace,
    position: "absolute",
    width: width,
    paddingHorizontal: 24,
    top: Platform.OS === "ios" ? getStatusBarHeight() + 22 : 22,
  },
  tabView: {
    ...Theme.flexRow,
    paddingHorizontal: 16,
  },
  buttonHeader: {
    borderWidth: 0,
    borderRadius: 12,
  },
  headerAnim: {
    ...Theme.flexRowSpace,
    paddingRight: 24,
  },
  topView: {
    ...Theme.flexRowSpace,
    marginTop: 40,
    marginHorizontal: 30,
    paddingBottom: 20,
  },
  section: {
    margin: 24,
  },
});
