import APIService from './modules/apiService';
import AuthService from './modules/authService';
import Helpers from './modules/helpers';
import DateTimeService from './modules/DateTimeService';
import Sharer from './modules/Sharer';
import AppHelpers from './modules/AppHelpers';

export const api = new APIService();
export const auth = new AuthService();
export const helpers = new Helpers();
export const dateTimeService = new DateTimeService();
export const sharer = new Sharer();
export const appHelpers = new AppHelpers();
