import { useState, useEffect, useRef, useCallback } from "react";
import { api } from "../import";
import moment from "moment/moment";

import {hubspotToken, hubspotTokenDateLabel, hubspotTokenLabel, userDataLocalStorage} from "../models/constants";
import useLocalStorage from "./useLocalStorage";
import {EventRegister} from "../modules/EventRegister";
import {EVENT_SHOW_MODAL_INFO, EVENT_USER_CHANGED} from "../constants/Events";

window.hsConversationsSettings = {
  loadImmediately: false,
};
export const useHubspotChat = (portalId) => {
  const [hasLoaded, setHasLoaded] = useState(false);
  const [activeConversation, setActiveConversation] = useState(false);
  const eventRef = useRef(null);
  const [loggedUserData, getLoggedUserData, setLoggedUserData] = useLocalStorage(userDataLocalStorage);
  const [hubspotToken, getHubspotToken, setHubspotToken] = useLocalStorage(hubspotTokenLabel);
  const [hubspotTokenDate, getHubspotTokenDate, setHubspotTokenDate] = useLocalStorage(hubspotTokenDateLabel);

  useEffect(() => {
    window.hsConversationsOnReady = [
      () => {
        setHasLoaded(true);
        loadWidget();
      },
    ]

    // Create script component.
    let script = document.createElement("script");
    script.src = `//js.hs-scripts.com/${portalId}.js`;
    script.defer = true;
    script.async = true;

    document.body.appendChild(script);

    const userChangedListerner = EventRegister.addEventListener(
        EVENT_USER_CHANGED,
        (data) => {
          const userData = getLoggedUserData();
          setLoggedUserData(userData);
        }
    );

    return () => {
      document.body.removeChild(script);
      window.hsConversationsOnReady = [];
      userChangedListerner.removeListener();
    };
  }, []);

  // Subscripe to conversation events.
  useEffect(() => {
    eventRef.current = (payload) => {
      setActiveConversation(payload.conversation.conversationId);
    };

    if (hasLoaded) {
      window.HubSpotConversations.on("conversationStarted", eventRef.current);
    }

    return () => {
      window.HubSpotConversations.off("conversationStarted", eventRef.current);
    };
  }, [hasLoaded]);

  useEffect(() => {

    if (loggedUserData) {
      api.hubspotIdentifiedVisitorGetToken(loggedUserData, hubspotToken, hubspotTokenDate).then(async (data) => {
        const {token} = data;
        if (token !== hubspotToken) {
          setHubspotToken(token);
          setHubspotTokenDate(moment().format('YYYY-MM-DD HH:mm:ss'));
        }
        window.hsConversationsSettings = {
          identificationEmail: loggedUserData.user.email,
          identificationToken: token,
        };
      });
    } else {
      window.hsConversationsSettings = {
        loadImmediately: false,
      };
    }

  }, [loggedUserData]);

  const openHandler = useCallback(() => {
    if (hasLoaded) {
      window.HubSpotConversations.widget.open();
    }
  }, [hasLoaded]);

  const closeHandler = useCallback(() => {
    if (hasLoaded) {
      window.HubSpotConversations.widget.close();
    }
  }, [hasLoaded]);

  const removeWidget = useCallback(() => {
    window.HubSpotConversations.widget.remove();
  }, []);

  const loadWidget = useCallback(() => {
    window.HubSpotConversations.widget.load();
  }, []);

  return {
    hasLoaded,
    activeConversation,
    openHandler,
    closeHandler,
    removeWidget,
    loadWidget,
  };
};
