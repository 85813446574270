import APIService from '../modules/apiService';
import Helpers from '../modules/helpers';
import {
  EVENT_SHOW_MODAL_INFO,
  EVENT_UPLOAD_PROFILE_IMAGE,
} from '../constants/Events';
import locales_es from '../locales/es';
import {EventRegister} from "./EventRegister";

const api = new APIService();
const helpers = new Helpers();

export const uploadMyProfileImage = image => {
  return new Promise((resolve, reject) => {
    api
      .updateProfileImage(image)
      .then(res => {
        /*EventRegister.emit(EVENT_SHOW_MODAL_INFO, {
          title: locales_es.infoModal.title,
          message: res.message,
        });*/
        EventRegister.emit(EVENT_UPLOAD_PROFILE_IMAGE, {});
        resolve(res);
      })
      .catch(err => {
        EventRegister.emit(EVENT_SHOW_MODAL_INFO, {
          title: locales_es.infoModal.title,
          message: helpers.getErrorMsg(err),
        });
        reject(err);
      });
  });
};

export const uploadLogoImage = (prescriptionProfileId, image) => {
  return new Promise((resolve, reject) => {
    api
      .updateLogoImage(
        prescriptionProfileId,
        image
      )
      .then(res => {
        /*EventRegister.emit(EVENT_SHOW_MODAL_INFO, {
          title: locales_es.infoModal.title,
          message: res.message,
        });*/
        resolve(res);
      })
      .catch(err => {
        EventRegister.emit(EVENT_SHOW_MODAL_INFO, {
          title: locales_es.infoModal.title,
          message: helpers.getErrorMsg(err),
        });
        reject(err);
      });
  });
};

export const deleteLogoImage = prescriptionProfileId => {
  return new Promise((resolve, reject) => {
    api
      .deleteLogoImage(prescriptionProfileId)
      .then(res => {
        /*EventRegister.emit(EVENT_SHOW_MODAL_INFO, {
          title: locales_es.infoModal.title,
          message: res.message,
        });*/
        resolve(res);
      })
      .catch(err => {
        EventRegister.emit(EVENT_SHOW_MODAL_INFO, {
          title: locales_es.infoModal.title,
          message: helpers.getErrorMsg(err),
        });
        reject(err);
      });
  });
};

export const uploadIdentificationImage = image => {
  return new Promise((resolve, reject) => {
    api
      .updateIdentificationImage(image)
      .then(res => {
        /*EventRegister.emit(EVENT_SHOW_MODAL_INFO, {
            title: locales_es.infoModal.title,
            message: res.message,
          });*/
        resolve(res);
      })
      .catch(err => {
        EventRegister.emit(EVENT_SHOW_MODAL_INFO, {
          title: locales_es.infoModal.title,
          message: helpers.getErrorMsg(err),
        });
        reject(err);
      });
  });
};

export const deleteSignatureImage = prescriptionProfileId => {
  return new Promise((resolve, reject) => {
    api
      .deleteSignatureImage(prescriptionProfileId)
      .then(res => {
        /*EventRegister.emit(EVENT_SHOW_MODAL_INFO, {
          title: locales_es.infoModal.title,
          message: res.message,
        });*/
        resolve(res);
      })
      .catch(err => {
        EventRegister.emit(EVENT_SHOW_MODAL_INFO, {
          title: locales_es.infoModal.title,
          message: helpers.getErrorMsg(err),
        });
        reject(err);
      });
  });
};
