import React, {
    useLayoutEffect,
    useState,
    useEffect,
  } from 'react';
  import {
    View,
    StyleSheet,
    ActivityIndicator,
    FlatList,
  } from 'react-native';
  import Text from '../../components/Text';
  import SearchBox from '../../components/Home/SearchBox';
  import {Colors} from '../../configs';
  import Theme from '../../style/Theme';
  import {getBottomSpace, getStatusBarHeight} from 'react-native-iphone-x-helper';
  import {SOURCE_ICON} from '../../images';
  import Layout from '../../components/Layout/Layout';
  import HeaderButton from '../../components/HeaderButton';
  import locales_es from '../../locales/es';
  import {CommonStyles} from '../../styles/CommonStyles';
  import Container from '../../components/Layout/Container';
  import useSearchPatientPrescriptions from './Shared/useSearchPatientPrescription';
  import PrescriptionsList from '../../components/PrescriptionsList';
  import {ConsultsType, width} from '../../configs/Const';
interface SearchPatientPrescriptionsProps {
  onGoback? : void;
  keySearch?: string;

}

const SearchPatientPrescriptions = (props: SearchPatientPrescriptionsProps) => {
    const {
      searchResults,
      onLoadMore,
      total,
      hasMore,
      loadingMore
      } = useSearchPatientPrescriptions({keySearch: props.keySearch});
    
    const renderSpinner = () => {
        return (
          <View style={{flex: 1, width: width}}>
            <ActivityIndicator style={CommonStyles.activityIndicator}/>
          </View>
        );
      };

    return (
        <>
        <Container style={styles.container}>
          {props.keySearch && !searchResults ?  renderSpinner() :
              <PrescriptionsList 
              type={ConsultsType.Prescription}
              prescriptions={searchResults}
              emptyText={'No tienes recetas generadas hoy'}
              title={'Prescripciones'}
              refreshing={loadingMore}
              onLoadMore={onLoadMore}
              total={total}    
      />}
        </Container>
        </>
      );
}
    

export default SearchPatientPrescriptions;

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
});