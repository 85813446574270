import ES_AR from './ar';
import ES_EC from './ec';
import ES_PY from './py';
import CountryService from '../modules/CountryService';

class TranslationService {
  translate = async () => {
    const countryService = new CountryService();
    const translationObjects = {
      AR: ES_AR,
      EC: ES_EC,
      PY: ES_PY,
    };

    let result = translationObjects.AR;
    await countryService
      .getData()
      .then(country => {
        if (country && country.code) {
          result = translationObjects[country.code.toUpperCase()];
        }
      })
      .catch(err => {
        console.log(err);
      });
    return result;
  };
}

export default TranslationService;
