import React, {Component} from 'react';
import {View, Image, TouchableOpacity} from 'react-native';
import Text from './../Text';

// Images
import styles from './styles';
import Colors from '../../configs/Colors';
import arrowRightIcon from '../../images/ic_arrow_right@3x.png';
export default class ListButton extends Component {

  render() {
    return (
      <TouchableOpacity
        onPress={() => this.props.onPress && this.props.onPress()}>
        <View style={styles.container}>
          <View style={styles.col}>
            <Text bold size={18}>
              {this.props.title}
            </Text>
          </View>
          <View>
            <Image
              source={arrowRightIcon}
              style={{
                tintColor: Colors.MediumTurquoise,
                width: 20,
                height: 20,
              }}
              resizeMode="contain"
            />
          </View>
        </View>
      </TouchableOpacity>
    );
  }
}
