import React, { useState, useCallback, useEffect } from "react";
import { Routes } from "../../../configs";
import { useHistory } from "react-router-dom";
import useModalWithKeyboard from "../../../hooks/useModalWithKeyboard";
import { uploadMyProfileImage } from "../../../modules/ApiHelpers";

import APIService from "../../../modules/apiService";
import AuthService from "../../../modules/authService";
import Helpers from "../../../modules/helpers";
import { EVENT_SHOW_MODAL_INFO } from "../../../constants/Events";
import locales_es from "../../../locales/es";
import { validateEmptyFields } from "../../../utils/validation/forms";
import { EventRegister } from "../../../modules/EventRegister";
import { useTheme } from "../../../configs/Theme";
import { showModalInfo } from '../../../modules/ViewHelpers';


export interface IUserData {
  professional_prefix?: { name: string };
  name: string;
  lastname: string;
  country_id: number;
  email: string;
  email_verified_at: string;
  identificationType?: { name: string };
  identification?: string;
  prefix_cell_phone?: string;
  cell_phone?: string;
}

const useBasicInformationPage = () => {
  const history = useHistory();
  const params = history.location.state;
  const {theme} = useTheme();

  const api = new APIService();
  const auth = new AuthService();
  const helpers = new Helpers();
  const [errors, setErrors] = useState([]);
  const [load, setLoad] = useState(false);
  const [profileMode, setProfileMode] = useState(
    params && params.profileMode ? params.profileMode : false,
  );
  const hasSignUp = params && params.hasSignup
  const [locked, setLocked] = useState(false);
  const [cropping, setCropping] = useState(false);
  const [professionalPrefixId, setProfessionalPrefixId] = useState(null);
  const [name, setName] = useState('');
  const [lastname, setLastname] = useState('');
  const [cellphone, setCellphone] = useState('');
  const [countryId, setCountryId] = useState(null);
  const [identificationTypes, setIdentificationTypes] = useState([]);
  const [identification, setIdentification] = useState('');
  const [identificationType, setIdentificationType] = useState(null);
  const [professionalPrefixes, setProfessionalPrefixes] = useState<{
    id?: number | null;
    country_id?: number | null;
    name: string;
    created_at: string | null;
    updated_at: string | null;
    deleted_at: string | null;
  }>({
    id: null,
    country_id: null,
    name: '',
    created_at: '',
    updated_at: '',
    deleted_at: '',
  });

  const [loading, setLoading] = useState(false);
  const [loadingText, setLoadingText] = useState('');
  const [profileImage, setProfileImage] = useState('');

  const nameRef = React.createRef();
  const lastnameRef = React.createRef();
  const identificationRef = React.createRef();

  const [errorMessages, setErrorMessages] = useState({
    name: '',
    lastname: '',
    identification: '',
    required: 'El Campo es obligatorio',
  });
  const [enableButton, setEnableButton] = useState(true);

  const [SISAVerification, setSISAVerification] = useState<boolean>(false);

  const [minDocLength, setMinDocLength] = useState(6);
  const [maxDocLength, setMaxDocLength] = useState(8);

  const onPressPrefixSelectorItem = useCallback(
    (professionalPrefixId: number) => {
      setErrors(errors.filter(item => item !== 'professionalPrefixId'));
      setProfessionalPrefixId(professionalPrefixId);
    },
    [],
  );

  const onSubmit = () => {
    const errors = validateEmptyFields({
      professionalPrefixId,
      name,
      lastname,
      identification,
    });
    if (errors.length) {
      setErrors(errors);
    } else {
      setLoading(true);
      const objData = {
        cell_phone: cellphone,
        professional_prefix_id: professionalPrefixId,
        name,
        lastname,
        identification_type_id: identificationType.id,
        identification,
      };
      auth
        .updateUser(objData)
        .then((res) => {
          setLoading(false);
          profileMode ? history.goBack() : history.push(`/${Routes.WorkProfile}`, {hasSignup: !!hasSignUp, firstProfile: true});
          EventRegister.emit(EVENT_SHOW_MODAL_INFO, {
            title: locales_es.successModal.title,
            message: res.message,
          });
        })
        .catch(err => {
          setLoading(false);
          EventRegister.emit(EVENT_SHOW_MODAL_INFO, {
            title: locales_es.infoModal.title,
            message: helpers.getErrorMsg(err),
          });
        });
    }
  };

  const handleValidatorInputsTypes = (value:any,type: string = '', setState: any, id:string, minLength: number = 0) => {
    var allErrors = errors.filter(item => item !== id);
    setErrors(errors.filter(item => item !== id));
    if(value.length > 0 && value.length >= minLength && value.trim().length > 0){
      if(type === 'letter'){
        const regex = /^[a-zA-ZÀ-ÿ\u00f1\u00d1\u014c\u014d\u016a\u016b\u016c\u016d\u016e\u016f\u0170\u0171\u0172\u0173\u0174\u0175\u0176\u0177\u0178\u0179\u017a\u017b\u017c\u017d\u017e\u012a\u012b\u012c\u012d\u012e\u012f\u0130\u0131 ']*$/;
        if (!regex.test(value)) {
          setState(value);
          setErrors([...errors, id]);
          allErrors.push(id);
          setErrorMessages({...errorMessages, [id]: 'El Campo solo puede contener letras'});
          setEnableButton(handleValidateEmptyFields(allErrors))
          return;
        }
        setState(value);
        setEnableButton(handleValidateEmptyFields(allErrors))
        return;
      }
      if(type === 'number'){
        const regex = /^[0-9]*$/;
        if (!regex.test(value)) {
          setState(value);
          setErrors([...errors, id]);
          allErrors.push(id);
          setErrorMessages({...errorMessages, [id]: 'El Campo solo puede contener números'});
          setEnableButton(handleValidateEmptyFields(allErrors))
          return;
        }
        setState(value);
        setEnableButton(handleValidateEmptyFields(allErrors))
        return;
      }
      setState(value);
      setEnableButton(handleValidateEmptyFields(allErrors))
      return;
    }
    else if(value.length > 0 && value.length < minLength && value.trim().length > 0){
      if(type === 'letter'){
        const regex = /^[a-zA-ZÀ-ÿ\u00f1\u00d1 ]*$/;
        if (!regex.test(value)) {
          setState(value);
          setErrors([...errors, id]);
          allErrors.push(id);
          setErrorMessages({...errorMessages, [id]: 'El Campo solo puede contener letras y debe contener al menos '+minLength+' caracteres'});
          setEnableButton(handleValidateEmptyFields(allErrors))
          return;
        }
      }
      if(type === 'number'){
        const regex = /^[0-9]*$/;
        if (!regex.test(value)) {
          setState(value);
          setErrors([...errors, id]);
          allErrors.push(id);
          setErrorMessages({...errorMessages, [id]: 'El Campo solo puede contener números y debe contener al menos '+minLength+' caracteres'});
          setEnableButton(handleValidateEmptyFields(allErrors))
          return;
        }
      }
      setState(value);
      setErrors([...errors, id]);
      allErrors.push(id);
      setErrorMessages({...errorMessages, [id]: 'El Campo debe contener al menos '+minLength+' caracteres'});
      setEnableButton(handleValidateEmptyFields(allErrors))
      return;
    }
    setState('');
    setErrors([...errors, id]);
    allErrors.push(id);
    setErrorMessages({...errorMessages, [id]: 'El Campo es obligatorio'});
    setEnableButton(handleValidateEmptyFields(allErrors))
  }

  const handleValidateEmptyFields = (errors:any)=>{
    const getErrors = validateEmptyFields({
      name,
      lastname,
      identification
    });
    if (getErrors.length > 0 || errors.length > 0) {
      return true;
    }
    return false;
  }

  const {
    visible: visibleIdentificationTypesMenuOption,
    open: openIdentificationTypesMenuOption,
    close: closeIdentificationTypesMenuOption,
  } = useModalWithKeyboard(false);

  const onBlur = (inputStr: string) => {
    setErrors(errors.filter((item) => item !== inputStr));
  };

  useEffect(() => {
    api
      .getUserByMe()
      .then(res => {
        if (res && res.data && res.data.user) {
          const _countryId = res.data.user.country_id;
          const _userData = res.data.user;
          setProfileImage({uri: _userData.full_profile_image});
          setProfessionalPrefixId(_userData.professional_prefix_id);
          setName(_userData.name);
          setLastname(_userData.lastname);
          setCellphone(_userData.cell_phone);
          setCountryId(_countryId);
          setIdentification(_userData.identification);
          let activate = _userData.name &&  _userData.lastname && _userData.identification&& _userData.cell_phone;
          if(activate){
            setEnableButton(false);
          }
          if(_countryId === 1 && !activate){
            showModalSISAInfo();
          } 
          api
            .getIdentificationTypesByCountryId(_countryId)
            .then((res2: any) => {
              if (res2 && res2.data && res2.data.length) {
                setIdentificationTypes(res2.data);

                const userIdType = _userData.identification_type_id;
                if (userIdType) {
                  const idType = res2.data.filter(
                    (type: any) => Number(type.id) === Number(userIdType),
                  )[0];
                  setIdentificationType(idType);
                  setMinDocLength(idType.min);
                  setMaxDocLength(idType.max);
                } else {
                  setIdentificationType(res2.data[0]);
                  setMinDocLength(res2.data[0].min);
                  setMaxDocLength(res2.data[0].max);
                }
              }
            })
            .catch((err2: any) => {
              EventRegister.emit(EVENT_SHOW_MODAL_INFO, {
                title: locales_es.infoModal.title,
                message: helpers.getErrorMsg(err2),
              });
            });
        }
      })
      .catch(err => {
        console.log(err);
      });
    api
      .getUserProfessionalPrefixes()
      .then(res => {
        if (res && res.data) {
          setProfessionalPrefixes(res.data);
        }
      })
      .catch(err => {
        console.log(err);
      });
  }, [load]);

  const showNonEditableInfo = () => {
    EventRegister.emit(EVENT_SHOW_MODAL_INFO, {
      title: locales_es.basicProfileInfo.title,
      message: locales_es.basicProfileInfo.content,
    });
  };

  const uploadProfileImage = (image) => {
    setCropping(false);
    setLoading(true);
    setLoadingText(locales_es.uploadingImage);
    uploadMyProfileImage(image)
      .then(() => {
        setLoading(false);
        setLoadingText('');
        setProfileImage({
          uri: image
        });
      })
      .catch(() => {
        setLoading(false);
        setLoadingText('');
      });
  }

  const showModalSISAInfo = () => {
    showModalInfo(
      locales_es.SISAInformation.title,
      locales_es.SISAInformation.subtitle,
    );
  }

  const handleSISAVerification = (value:string) => {
    setSISAVerification(false);
    auth.verificationSISA(value).then((res:any) => {
      if(res.status){
        setSISAVerification(false);
        return;
      }
      setSISAVerification(true);
    }).catch((err:any) => {
      console.log(err);
    })
  }

  return {
    profileMode,
    profileImage,
    uploadProfileImage,
    setCropping,
    professionalPrefixes,
    professionalPrefixId,
    onPressPrefixSelectorItem,
    errors,
    identificationType,
    showNonEditableInfo,
    openIdentificationTypesMenuOption,
    onBlur,
    identificationRef,
    identification,
    nameRef,
    setIdentification,
    setName,
    name,
    lastnameRef,
    lastname,
    setLastname,
    onSubmit,
    visibleIdentificationTypesMenuOption,
    closeIdentificationTypesMenuOption,
    setIdentificationType,
    locked,
    identificationTypes,
    loading,
    loadingText,
    cropping,
    hasSignUp: !!hasSignUp,
    handleSISAVerification,
    handleValidatorInputsTypes,
    enableButton,
    SISAVerification,
    minDocLength,
    maxDocLength,
    countryId,
    showModalSISAInfo,
    setMinDocLength,
    setMaxDocLength,
    setSISAVerification,
    errorMessages
  };
};

export default useBasicInformationPage;
