import React, {useEffect, useRef, useState} from 'react';
import {
  StyleSheet,
  View,
  TouchableOpacity,
  Image,
  ScrollView,
  ActivityIndicator,
  Modal,
  FlatList,
} from 'react-native';
import {getBottomSpace, getStatusBarHeight} from 'react-native-iphone-x-helper';
import { SOURCE_ICON } from "../../images"
import Layout from '../../components/Layout/Layout';
import HeaderButton from '../../components/HeaderButton';
import Theme from '../../style/Theme';
import Text from '../../components/Text';
import {api, helpers} from '../../import';
import Container from '../../components/Layout/Container';
import locales_es from '../../locales/es';
import {showModalInfo} from '../../modules/ViewHelpers';
import {Colors} from '../../configs';
import {
  EVENT_RELOAD_COMBOS,
  EVENT_RELOAD_COMBOS_PRACTICES,
  EVENT_SHOW_MODAL_INFO,
} from '../../constants/Events';
import CustomCollapsible from '../../components/CustomCollapsible';
import {EventRegister} from "../../modules/EventRegister";
import iconTrash from '../../images/ic_trash.png';
import {useHistory} from 'react-router-dom';
import ButtonLinear from "../../components/Buttons/ButtonLinear";
import iconFeedActive from '../../images/ic_feed_active.png';
import iconClose from '../../images/ic_close.png';
import SearchBox from '../../components/Home/SearchBox';
import PracticeItem from '../../components/PracticeItem';
import {MAX_CERTIFICATE_PRESCRIPTIONS} from '../../models/constants';
import iconEdit from '../../images/ic_edit.png';
import TextInput from '../../components/TextInput';
import InputApp from '../../components/InputApp';
import Input from 'react-select/dist/declarations/src/components/Input';
import { set } from 'react-native-reanimated';
import Card from '../../components/Card';
import DiagnosticAutocompleteItem from '../../components/DiagnosticAutocomplete';
import { height } from '../../configs/Const';

let nativeEventKeySearch = '';
const MyPractices = (props) => {
  const history = useHistory();
  const params = history.location.state;
  const {prescriptionProfileId, onComplete, comboIdToView, nameComboToView} = props || params;
  const {items, keyItemsToSearch, selectedPractices} =
    props ? props : params ? params : {};
  const [practices, setPractices] = useState([]);
  const [practicesSelected, setPracticesSelected] = useState([]);
  const [loading, setLoading] = useState(false);
  const [visibleSearchPractice, setVisibleSearchPractice] = useState(false);
  const practice : any = [];
  const [keySearch, setKeySearch] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [indication, setIndication] = useState('');
  const [editCombo, setEditCombo] = useState(false);
  const [name, setName] = useState('');
  const searchInputRef: any = useRef();
  const [diagnostic, setDiagnostic] = useState("");
  const [diagnosticCod, setDiagnosticCod] = useState('');
  const [medicines, setMedicines] = useState([]);
  const [showPracticesLimit, setShowPracticesLimit] = useState(false);


  const onKeySearchChange = e => {
    nativeEventKeySearch = e.nativeEvent.text;
    setKeySearch(e.nativeEvent.text);
  };

  const onKeySearchClear = () => {
    nativeEventKeySearch = '';
    setKeySearch('');
  };

  const cancelEditCombo = () => {
    setEditCombo(false);
    getMyPracticesCombos();
    
  }

  useEffect(() => {
    getMyPracticesCombos();
  }, []);

  const onPressSearchResult = (item, index) => {
    if (item && index == -1) {
      setDiagnostic(`${item.coddiagnostico} - ${item.descdiagnostico}`);
      setDiagnosticCod(item.coddiagnostico);
    } else if (item && index != -1) {
      const _medicines = [...medicines];
      _medicines[index].diagnostic = `${item.coddiagnostico} - ${item.descdiagnostico}`;
      _medicines[index].diagnosticCod = item.coddiagnostico;
      setMedicines(_medicines);
    }
  };
  const getMyPracticesCombos = (reload?: boolean) => {
    setLoading(true);
    
    api
      .getPracticeByCombos(comboIdToView)
      .then((res: any) => {

        res.data.practices.map((item: any) => {
          practice.push(item);
        });
        setIndication(res.data.combo.indications);
        setName(res.data.combo.name ? res.data.combo.name : nameComboToView);
        setPractices(practice)
        if (res.data.combo.diagnostic !== null || res.data.combo.diagnostic !== undefined) {
          setDiagnostic(res.data.combo.diagnostic) 
        }
        

        if (reload) {
          EventRegister.emit(EVENT_RELOAD_COMBOS_PRACTICES);
        }
        setLoading(false);
      })
      .catch((err: any) => {
        showModalInfo(locales_es.infoModal.title, helpers.getErrorMsg(err));
        setLoading(false);
      });
  };

  const deletePractica = (practiceId:number) => {
    EventRegister.emit(EVENT_SHOW_MODAL_INFO, {
      title: '¿Eliminar práctica?',
      message: '¿Quiere eliminar esta práctica?',
      actions: [
        {
          text: locales_es.accept,
          onPress: () => {
            confirmDeleteCombo(practiceId);
          },
          white: true,
           styleButton: {
            width: '60%',
            margin: 'auto',
            marginVertical: 10
          }
        },
        {
          text: locales_es.cancel,
          colors: [Colors.WhiteGray, Colors.WhiteGray],
          white: true,
           styleButton: {
            width: '60%',
            margin: 'auto',
            marginVertical: 10
          }
        },
      ],
    });
  };

  const confirmDeleteCombo = (practiceId: number) => {
    setLoading(true);
    api
      .deletePractice(comboIdToView, practiceId)
      .then((res: any) => {
        setLoading(false);
        showModalInfo(locales_es.successModal.title, res.message);
        getMyPracticesCombos(true);
      })
      .catch((err: any) => {
        helpers.processError(err);
        setLoading(false);
      });
  };

  const deleteAllPractices = () => {
    EventRegister.emit(EVENT_SHOW_MODAL_INFO, {
      title: '¿Eliminar todas las prácticas?',
      message: '¿Quiere eliminar todas las prácticas?',
      actions: [
        {
          text: locales_es.accept,
          onPress: () => {
            confirmDeleteAllPractices();
          },
          white: true,
           styleButton: {
            width: '60%',
            margin: 'auto',
            marginVertical: 10
          }
        },
        {
          text: locales_es.cancel,
          colors: [Colors.WhiteGray, Colors.WhiteGray],
          white: true,
           styleButton: {
            width: '60%',
            margin: 'auto',
            marginVertical: 10
          }
        },
      ],
    });
  }  

  const confirmDeleteAllPractices = () => {
    setLoading(true);
    api
      .deleteAllPractice(comboIdToView)
      .then((res: any) => {
        setLoading(false);
        showModalInfo(locales_es.successModal.title, res.message);
        getMyPracticesCombos(true);
      })
      .catch((err: any) => {
        helpers.processError(err);
        setLoading(false);
      });
  };

  const addPractice = () => {

    const objectData = {
      combo_id: comboIdToView,
      practices: practicesSelected
    };
  
    setLoading(true);
    api.addPractice(objectData).then((res: any) => {
      if (res.status) {
        showModalInfo(locales_es.successModal.title, res.message);
        getMyPracticesCombos(true);
        setVisibleSearchPractice(false);
        setKeySearch('');
        setSearchResults([]);
        setPracticesSelected([]);
      }
    }
    ).catch((err: any) => {
      helpers.getErrorMsg(err);
      setLoading(false);
    });
  };

  const renderComboList = ({item}) => {
    
      return <Layout style={{...styles.comboContainer, padding: 10}}>
        <TouchableOpacity
          {...item}
          key={item.id.toString()}
          onPress={() => {
            // onComplete(item.practices.map((i: any) => i.medical_practice));
          }}>
            
          <View style={styles.comboTopBox}>
            <View style={{width: '100%', flexDirection: 'row'}}>
              <View style={styles.comboRounded}>
                <Image
                  style={styles.comboIcon}
                  source={SOURCE_ICON.additionalInformation}
                />
              </View>
              <View style={{flex: 1, marginTop: 8}}>
                <Text marginLeft={16} size={15} lineHeight={18} bold>
                  {item.medical_practice.name}
                </Text>
              </View>
              <View style={styles.comboItemView}>
                <TouchableOpacity
                  onPress={() => deletePractica(item.medical_practice_id)}>
                  <Image
                    source={iconTrash}
                    style={{
                      tintColor: Colors.Tomato,
                      width: 24,
                      height: 24,
                    }}
                    resizeMode="contain"
                  />
                </TouchableOpacity>
              </View>
              
            </View>
          </View>
        </TouchableOpacity>

      </Layout>
    
      // return _combos;
    
  };

  const showPracticesLimitReachedMessage = () => {

    return (
     <Modal visible={true} transparent animationType={'fade'}>
      <View style={styles.modalInfoContainer}>
      <Layout
        style={{
          borderRadius: 16,
          paddingHorizontal: 24,
          paddingVertical: 32,
          width: '100%',
          maxWidth: 640,
          textAlign: 'center',
          margin: 'auto',
        }}>
        {/*<Image
          source={require('../../images/error-icon.png')}
          style={{alignSelf: 'center', width: '25%'}}
          resizeMode="contain"
        />*/}
        <Text center size={17} lineHeight={20} bold>
          {locales_es.infoModal.title}
        </Text>
        <Text center size={13} lineHeight={22} marginTop={16}>
          {locales_es.max_practices_limit}
        </Text>
 
          <ButtonLinear
            white
            title={locales_es.accept}
            onPress={() => {
              setVisibleSearchPractice(true)
              setShowPracticesLimit(false);

            }}
            style={{width: '60%', alignSelf: 'center', marginTop: 15}}
            
          />
      </Layout>
    </View>
    </Modal>
    )
  };

  const practiceAlreadySelected = (item:any) => {
    let _index = null;

    practicesSelected.map((practice, index) => {
      if (practice.id === item.id) {
        _index = index;
      }
    });

    return _index !== null;
  };

  const removePractice = item => {
    const _practices = JSON.parse(JSON.stringify(practicesSelected));
    let _index = null;
    practicesSelected.map((practice, index) => {
      if (Number(practice.id) === Number(item.id)) {
        _index = index;
      }
    });
    if (_index !== null) {
      _practices.splice(_index, 1);
      setPracticesSelected(_practices);
    }
  };

  const onPressItem = (item: any, index?: number) => {
    if (practiceAlreadySelected(item)) {
        removePractice(item);
      return;
    }
    
    const _practices = JSON.parse(JSON.stringify(practicesSelected));
   
    if (
      _practices.length >= MAX_CERTIFICATE_PRESCRIPTIONS ||  _practices.length + practices.length >=
      MAX_CERTIFICATE_PRESCRIPTIONS
    ) {

      setShowPracticesLimit(true);
      setVisibleSearchPractice(false);
      return;
    }

    _practices.push(item);
    setPracticesSelected(_practices);
  };
  
  const onPressSave = () =>{
    //validamos si las en las nuevas practicas seleccionadas ya se encuentran en las practicas viejas

    const exist = practices.map((item: any) => {
      //search in array practicesSelected if exist the practice
      if(practicesSelected.find((item2: any) => {return item2.id === item.medical_practice_id})){
        return item;
      }
    });

    if (exist.length > 0) {
      setVisibleSearchPractice(false);
      const noUndefines = exist.filter((item: any) => {return item !== undefined});
      if (noUndefines.length >= 1) {
        showModalInfo(locales_es.infoModal.title, `La práctica ${noUndefines.map((item: any) => item.medical_practice.name).join(', ')} ya se encuentra${noUndefines.length > 1 ? 'n':''} en el combo`);
        return;
      }
    }

    setVisibleSearchPractice(false);
    addPractice();
  };

  const renderItem = ({item}) => {
    return (
      <PracticeItem
        item={item}
        onPress={() => onPressItem(item)}
        selected={practiceAlreadySelected(item)}
      />
    );
  };

  const searchPractices = () => {
    if (keySearch) {
      if (items) {
        // local search
        setSearchResults(null);
        const regex = new RegExp(keySearch, 'i'); // add 'i' modifier that means "ignore case"

        let _items = JSON.parse(JSON.stringify(items));
        const result: any = [];
        _items.map((_item: any) => {
          let founded = false;
          keyItemsToSearch.map((key: string) => {
            if (
              !founded &&
              (regex.test(_item[key]) ||
                keySearch
                  .toLowerCase()
                  .indexOf(String(_item[key]).toLowerCase().trim()) > -1)
            ) {
              result.push(_item);
              founded = true;
            }
          });
        });
        setSearchResults(result);
      } else {
        // remote search
        setSearchResults(null);
        const objData = {
          q: keySearch,
          prescription_profile_id: prescriptionProfileId,
        };
        setTimeout(() => {
          if (keySearch.length === nativeEventKeySearch.length) {
            api
              .searchMedicalPractices(objData)
              .then((res: any) => {
                setSearchResults(res.data);
              })
              .catch((err: any) => {
                showModalInfo(
                  locales_es.infoModal.title,
                  helpers.getErrorMsg(err),
                );
              });
          } else {
            // setSearchResults([]);
          }
        }, 800); // 300
      }
    }
  };
  
  const renderModalFooterBtn = () => {
    return (
      <ButtonLinear
        white
        style={{

          justifyContent: 'center',
          paddingLeft: 40,
          paddingRight: 10,
        }}
        title={`Ver práctica${practicesSelected.length > 1 ? 's' : ''} seleccionada${
          practicesSelected.length > 1 ? 's' : ''
        } `}
        children={
          visibleSearchPractice ? (
            <View
              style={{
                justifyContent: 'center',
                alignItems: 'center',
                flex: 1,
              }}
            />
          ) : (
            <View style={styles.circle}>
              <Text style={{color: '#fff', textAlign: 'center'}}>
                {practicesSelected.length}
              </Text>
            </View>
          )
        }
        onPress={() => setVisibleSearchPractice(true)}
        // style={styles.buttonLinear}
      />
    );
  };

  const updateCombo = () => {


    if (name === '' || name === null || name === undefined) {
      showModalInfo(locales_es.errorModal.title, 'El nombre del combo es requerido');
      return;
    }

    const objectData = {
      name: name,
      indications: indication,
      diagnostic: diagnostic,
    };

    setLoading(true);
    api.updateCombo(comboIdToView,objectData).then((res: any) => {
      if (res.status === true) {
        setEditCombo(false);
        showModalInfo(locales_es.successModal.title, res.message);
        getMyPracticesCombos(true);
      }
    }
    ).catch((err: any) => {
      showModalInfo(locales_es.errorModal.title, helpers.getErrorMsg(err));
      setLoading(false);
    });
  };

  useEffect(() => {
    searchPractices();
  }, [keySearch]);

  return (
    <>
      <Layout style={styles.header}>
        <HeaderButton
          icon={SOURCE_ICON.back}
          style={{
            marginTop: 26,
            marginLeft: 24,
          }}
        />
      </Layout>
      <Container style={{...styles.container}}>
      <View style={{flexDirection: 'row',width: '100%',justifyContent:'space-around'}}>
        <View style={{
                marginTop: 0,
                justifyContent: 'center',
                flexDirection: 'row',
                alignItems: 'center',
                width: '43%',
                textAlign: 'center',
                alignSelf: 'center',
                marginBottom: 20,
              }}>
                
                <Card
                  
                   header={{
                     title: name,
                     icon: SOURCE_ICON.additionalInformation,
                     action: editCombo ? null : (
                      <TouchableOpacity 
                      onPress={() => setEditCombo(true)}>
                        <Image
                          source={{
                            uri: SOURCE_ICON.edit,
                            width: 20,
                            height: 20,
                          }}
                          style={styles.cardAction}
                        />
                      </TouchableOpacity>
                    )
                   }}
                   style={{
                    width: '50%',
                   }}>
                   <View style={{ height: 200, }}>
                   <Text size={15} lineHeight={10} style={{ textAlign: 'left',marginBottom:'2%' }}>
                  Indicaciones del combo

                  </Text>
                  <TextInput
                    maxLenght={250}
                    placeholderTextColor={'gray'}
                    placeholder={"Indicaciones para el combo de prácticas..."}
                    value={indication}
                    style={{
                      fontFamily: "Muli_400Regular",
                      fontSize: 15,
                      lineHeight: 18,
                      textAlign: 'left',
                      textAlignVertical: 'top',
                      color: Theme.text,
                      // height: scale(160),
                      height: 180,
                      width: '100%',
                      borderWidth: 1,
                      alignSelf: 'center',
                     
                      // borderColor: errors.includes("text")
                      //   ? theme.lineErrorColor
                      //   : theme.innearColor,
                    }}
                    onChangeText={setIndication}
                    multiline={true}
                    editable={editCombo ? true : false}
                    disabled={editCombo? false : true} />
                   </View>
               </Card>
        </View>
        <View style={{
                marginTop: 0,
                justifyContent: 'center',
                flexDirection: 'row',
                alignItems: 'center',
                width: '43%',
                textAlign: 'center',
                alignSelf: 'center',
                marginBottom: 20,}} >
               <Card
                   header={{
                     title: locales_es.diagnosis,
                     icon: SOURCE_ICON.additionalInformation,
                     action: editCombo ? null : (
                      <TouchableOpacity 
                      onPress={() => setEditCombo(true)}>
                        <Image
                          source={{
                            uri: SOURCE_ICON.edit,
                            width: 20,
                            height: 20,
                          }}
                          style={styles.cardAction}
                        />
                      </TouchableOpacity>
                      )
                   }}
                 >
                   <View style={{ height: 200 }}>
                  { editCombo ?
                     <DiagnosticAutocompleteItem
                       diagnosticIn={diagnostic}
                       diagnosticCodIn={diagnosticCod}
                       buttonIconFlag={true}
                       errorsIn={[]}
                       tagIn={'prescription-medicine'}
                       paddingRightFlagIn={true}
                       onPress={(item) =>  onPressSearchResult(item, -1)}
                       onChange={(text) => setDiagnostic(text)}
                         // widthLetter={'100%'}
                     />
                     : <Text size={15} lineHeight={18} style={{ textAlign: 'left',marginBottom:'2%' }}>
                      {diagnostic}
                      </Text>
                     }
                   </View>
                 </Card>
          </View>
        </View>
        { editCombo ?  <>
                <View style={{
                justifyContent: 'center',
                flexDirection: 'row',
                alignItems: 'center',
                gap: 20}}>
                  <ButtonLinear
                  style={{ padding: 22 }}
                  title={'Guardar'}
                  onPress={() => updateCombo()}
                  white /><ButtonLinear
                    style={{ padding: 22 }}
                    colors={[Colors.Tomato, Colors.Tomato]}
                    title={'Cancelar'}
                    onPress={() => cancelEditCombo()}
                    white />
                </View>
                    </> : null  }
              
          {loading ? <ActivityIndicator/> :
                        <View style={{zIndex: -1}}>
                          <FlatList
                            data={practices}
                            renderItem={renderComboList}
                            // keyExtractor={keyExtractor}
                            scrollEventThrottle={16}
                            contentContainerStyle={styles.contentContainerStyle}
                            showsVerticalScrollIndicator={false}
                          />
                        </View> 
                          }


          {
            visibleSearchPractice && (
              <Modal visible={true} transparent animationType={'fade'}>
                <>
                  <View style={styles.modalShowPracticesContainer}>
                    <Layout style={{
                    borderRadius: 16,
                    paddingHorizontal: 24,
                    paddingVertical: 32,
                    height: '65%',
                    width: '65%',
                    paddingBottom: 100,
                    alignSelf: 'center',
                  }}>
                      <View style={{flexDirection: 'row'}}>
                        <View style={{width: '90%', flexDirection: 'row'}}>
                          <SearchBox
                            style={{marginTop:0, ...styles.searchBox}}
                            forwardRef={searchInputRef}
                            placeholder={'Buscar prácticas...'}
                            value={keySearch}
                            onChange={onKeySearchChange}
                            borderColor={Colors.TealBlue}
                            shadow={false}
                            autoFocus={true}
                            onClear={onKeySearchClear}
                          />
                        </View>
                        <View style={{width: '10%'}}>
                          <TouchableOpacity
                            onPress={() => {setVisibleSearchPractice(false); setKeySearch(''); setSearchResults([])}}>
                            <Image
                              source={iconClose}
                              style={{width: 20, height: 20, alignSelf: 'center'}}
                            />
                          </TouchableOpacity>
                        </View>
                      </View>
                      <ScrollView style={{paddingVertical: 20}}>
                      {
                        searchResults && searchResults.length > 0 && (
                          <FlatList
                            data={searchResults}
                            renderItem={renderItem}
                            // keyExtractor={keyExtractor}
                            scrollEventThrottle={16}
                            contentContainerStyle={styles.contentContainerStyle}
                            showsVerticalScrollIndicator={false}
                          />
                        )
                      }
                      </ScrollView>
                    </Layout>
                  </View>
                  {
                    practicesSelected.length > 0 && (
                      <View
                        style={[styles.bottomFixedWrapper, {backgroundColor: '#fff'}]}>
                          <ButtonLinear
                            white
                            colors={['#00d75b', '#00d75b']}
                            title={locales_es.confirm_selected}
                            onPress={() => onPressSave()}
                          />
                      </View>
                    )
                  }
                </>
              </Modal>
            )
          }
        {
          
            !loading && (
              <View style={{
                marginTop: 30,
                justifyContent: 'center',
                flexDirection: 'row',
                alignItems: 'center',
              }}>

                <View style={{
                  margin: 20,
                }}>
                  { editCombo ? null : 
                  <ButtonLinear
                  style={{padding: 20,}}
                    title={locales_es.addPractice}
                    onPress={() => setVisibleSearchPractice(true)}
                    white
                  />
                  }
                </View>

                {
                  practices.length > 0 && !editCombo && (
                      
                    <View style={{
                      margin: 20
                    }}>
                      <ButtonLinear
                      
                      style={{backgroundColor: Colors.Tomato, padding: 10}}
                        title={locales_es.deleteAllPractices}
                        onPress={() => deleteAllPractices()}
                        colors={[Colors.Tomato, Colors.Tomato]}
                        white
                      />
                    </View>       
                  )
                }


              </View>
            )
          }

          { practicesSelected.length > 0 && (
              <View style={{
                position: 'fixed',
                bottom: 0,
                zIndex: 100,
                width: '50%',
                paddingBottom: 20,
                alignSelf: 'center',
                backgroundColor: 'rgba(255,255,255,1)',}}>
                  {renderModalFooterBtn()}
              </View>
            )
          }



      </Container>
      {showPracticesLimit && showPracticesLimitReachedMessage()}
    </>
  );
};

const styles = StyleSheet.create({
  container: {
    paddingTop: 132,
    paddingHorizontal: 24,
    paddingBottom: getBottomSpace() + 12,
    ...Theme.flexOne,
    
  },
  header: {
    ...Theme.flexRowSpace,
    paddingRight: 24,
    paddingTop: getStatusBarHeight(),
    position: 'fixed',
    top: 0,
    width: '100%',
  },
  editItemsView:{

    marginBottom: 20,
    justifyContent: 'left',
    width: '100%',
  },
  textInputIndications:{
    marginBottom: 20,
    width: '60%',
    height: '20%',
  },
  headerModal: {
    ...Theme.flexRowSpace,
    position: 'fixed',
    top: 50,
    width: '80%',
  },
  practiceText: {
    ...Theme.flexOne,
  },
  scrollView: {
    flex: 1,
  },
  rightColumn: {
    alignItems: 'flex-end',
    justifyContent: 'center',
    height: 40,
    borderRadius: 8,
  },
  comboContainer: {
    borderRadius: 16,
    // paddingBottom: 24,
    paddingBottom: 12,
    // marginHorizontal: 16,
    // marginTop: 24,
    margin: 5,
    height: '90%'

  },
  comboButtonEdit: {
    width: 24,
    height: 24,
  },
  comboTopBox: {
    ...Theme.flexRowSpace,
    paddingTop: 16,
    paddingHorizontal: 24,
    paddingBottom: 16,
  },
  comboRounded: {
    width: 32,
    height: 32,
    borderRadius: 8,
    backgroundColor: Colors.OysterBay,
    ...Theme.center,
  },
  comboIcon: {
    tintColor: Colors.TiffanyBlue,
    width: 20,
    height: 20,
  },
  comboItemView: {
    flexDirection: 'row',
    alignItems: 'flex-end',
    alignSelf: 'flex-end',
    // marginRight: 20,
    marginTop: 10,
  },
  iconEdit: {
    width: 24,
    height: 24,
    display: 'flex',
    justifyContent: 'center',
  },
  comboNameTitle: {
    texAlign: 'center',
    fontZize: 15,
  },
  modalShowPracticesContainer: {
    backgroundColor: Colors.Black68,
    ...StyleSheet.absoluteFillObject,
    justifyContent: 'center',

  },
  searchBox: {
    marginLeft: 24,
    marginBottom: 12,
    flex: 1,
  },
  bottomFixedWrapper: {
    position: 'fixed',
    bottom: 0,
    zIndex: 100,
    width: '60%',
    alignSelf: 'center',
    paddingHorizontal: 24,
    paddingBottom: 52,
    backgroundColor: 'rgba(255,255,255,.75)',
    borderRadius: 5,
  },
  contentContainerStyle: {
    paddingHorizontal: 24,
    paddingTop: 30,
    paddingBottom: getBottomSpace(),
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
  },
  cardAction: { tintColor: "#00c1c1", color: "#00c1c1" },
  modalInfoContainer: {
    backgroundColor: Colors.Black68,
    ...StyleSheet.absoluteFillObject,
    paddingHorizontal: 24,
    justifyContent: 'center',
    zIndex: 1200,
  },
  });
export default MyPractices;
