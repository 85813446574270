import React, {memo} from 'react';
import {
  View,
  StyleSheet,
  TouchableOpacity,
  Image,
  ViewStyle, ImageSourcePropType,
} from 'react-native';
import {useHistory} from 'react-router-dom';
import Theme from '../../style/Theme';
import {Colors} from '../../configs';
import {useTheme} from '../../configs/Theme';
import iconBack from '../../images/ic_back.png';

interface HeaderButtonProps {
  icon?: ImageSourcePropType;
  style?: ViewStyle;
  onPress?: () => void;
}

const HeaderButton = memo((props: HeaderButtonProps) => {
  const {theme} = useTheme();
  const history = useHistory();
  return (
    <View style={[styles.container, props.style]}>
      <TouchableOpacity
        style={[styles.backButton, {borderColor: theme.innearColor}]}
        onPress={props.onPress || history.goBack}>
        <Image
          source={props.icon || iconBack}
          style={{
            width: '50%',
            height: '50%',
            tintColor: theme.activeTincolor,
          }}
        />
      </TouchableOpacity>
    </View>
  );
});

export default HeaderButton;

const styles = StyleSheet.create({
  container: {
    ...Theme.flexRowSpace,
  },
  backButton: {
    width: 40,
    height: 40,
    borderRadius: 8,
    borderWidth: 1,
    borderColor: Colors.Platinum,
    ...Theme.center,
  },
});
