import React, {useCallback, useState, useEffect} from 'react';
import {View, StyleSheet, ActivityIndicator, Platform} from 'react-native';
import Theme from '../../style/Theme';
import {Colors, Routes} from '../../configs';
import ScrollableTab from '../../components/ScrollableTab';
import {getBottomSpace, getStatusBarHeight} from 'react-native-iphone-x-helper';
import Container from '../../components/Layout/Container';

import {api, helpers, dateTimeService} from '../../import';
import {EVENT_RELOAD_PRESCRIPTIONS, EVENT_SHOW_MODAL_ACTION, EVENT_SHOW_MODAL_INFO} from '../../constants/Events';
import locales_es from '../../locales/es';
import {showModalInfo} from '../../modules/ViewHelpers';
import {CommonStyles} from '../../styles/CommonStyles';
import PrescriptionsList from './../../components/PrescriptionsList';
import {width} from '../../configs/Const';
import ButtonIcon from '../../components/Buttons/ButtonIcon';
import Layout from '../../components/Layout/Layout';
import HeaderButton from '../../components/HeaderButton';
import SearchBox from '../../components/Home/SearchBox';
import {EventRegister} from '../../modules/EventRegister';
import {useHistory} from 'react-router-dom';
import Overlay from "../../components/Overlay";
import SearchPatients from "../SearchPatients";
import SearchPatientPrescriptions from '../SearchPatientPrescriptions';
import k from '../../assets/js/raphael-min';
import { MEDICINES_PERMISSION } from '../../models/constants';

interface PrescriptionsProps {
}

const Prescriptions = (props: PrescriptionsProps) => {
  const history = useHistory();
  const [load, setLoad] = useState(false);
  const [overlay, setOverlay] = useState(false);
  const [todayPrescriptions, setTodayPrescriptions] = useState(null);
  const [pastPrescriptions, setPastPrescriptions] = useState(null);
  const [keySearch, setKeySearch] = useState('');
  const [patients, setPatients] = useState(null);

  const [nextPageUrlToday, setNextPageUrlToday] = useState('');
  const [refreshingToday, setRefreshingToday] = useState(false);
  const [totalToday, setTotalToday] = useState(0);

  const [nextPageUrlPast, setNextPageUrlPast] = useState('');
  const [refreshingPast, setRefreshingPast] = useState(false);
  const [totalPast, setTotalPast] = useState(0);

  const loadTodayDocuments = (pageUrl: string) => {
    api
      .getMyPrescriptionsPaginated(
        {
          from: dateTimeService.parseDateToAPIString(new Date()),
        },
        pageUrl,
      )
      .then((res: any) => {
        setTodayPrescriptions(
          todayPrescriptions ? todayPrescriptions.concat(res.data) : res.data,
        );
        setNextPageUrlToday(res.next_page_url);
        setRefreshingToday(false);
        setTotalToday(res.total);
      })
      .catch((err: any) => {
        helpers.processError(err);
        helpers.processError(err);
        setRefreshingToday(false);
      });
  };

  const loadPastDocuments = (pageUrl: string) => {
    api
      .getMyPrescriptionsPaginated(
        {
          to: dateTimeService.parseDateToAPIString(new Date()),
        },
        pageUrl,
      )
      .then((res: any) => {
        setPastPrescriptions(
          pastPrescriptions ? pastPrescriptions.concat(res.data) : res.data,
        );
        setNextPageUrlPast(res.next_page_url);
        setRefreshingPast(false);
        setTotalPast(res.total);
      })
      .catch((err: any) => {
        helpers.processError(err);
        setRefreshingPast(false);
      });
  };

  const loadPatients = () => {
    api
      .getMyPatients()
      .then((res: any) => {
        setPatients(res.data);
      })
      .catch((err: any) => {
        helpers.processError(err);
      });
  };

  const onLoadMoreTodayPrescriptions = () => {
    if (nextPageUrlToday && !refreshingToday) {
      setRefreshingToday(true);
      loadTodayDocuments(nextPageUrlToday);
    }
  };
  const onLoadMorePastPrescriptions = () => {
    if (nextPageUrlPast && !refreshingPast) {
      setRefreshingPast(true);
      loadPastDocuments(nextPageUrlPast);
    }
  };

  const loadPermissions = () => {
    return new Promise((resolve) => {
      api.getPermissionsMe().then((res: any) => {
        resolve(res);
      }).catch((err: any) => {
        helpers.processError(err);
      })
    })
  }

  const confirmPermissions = () => {
    history.goBack();
  }

  useEffect(() => {
    loadPermissions().then((res: any) => {
      if (res && res.some((perm: any) => perm.tag === MEDICINES_PERMISSION)) {
        loadTodayDocuments();
        loadPastDocuments();
        loadPatients();
        return;
      }
      EventRegister.emit(EVENT_SHOW_MODAL_INFO, {
        title: locales_es.modalPermission.title,
        message: locales_es.modalPermission.description,
        actions: [{text: 'Aceptar', onPress: confirmPermissions, white: true}]
      });
    });
  }, [load]);

  useEffect(() => {
    const listener = EventRegister.addEventListener(
      EVENT_RELOAD_PRESCRIPTIONS,
      data => {
        loadTodayDocuments();
        loadPastDocuments();
      },
    );

    return function cleanup() {
      EventRegister.removeEventListener(listener);
    };
  });

  const renderSpinner = () => {
    return (
      <View style={{flex: 1, width: width}}>
        <ActivityIndicator style={CommonStyles.activityIndicator}/>
      </View>
    );
  };

  const onPencilPress = useCallback(() => {
    history.push(`/${Routes.AddPrescription}`);
  }, []);

  return (
    <>
      <Layout style={styles.header}>
        <HeaderButton
          style={{
            marginTop: 26,
            marginLeft: 24,
          }}
          onPress={() => history.replace(Routes.Home)}
        />
        {patients && (
          <SearchBox
            placeholder={locales_es.searchPatient + '...'}
            value={keySearch}
            onChangeText={setKeySearch}
            shadow={false}
            style={{
              marginHorizontal: 24,
              marginBottom: 12,
              flex: 1,
            }}
            onClear={() => setKeySearch('')}
          />
        )}
      </Layout>
      { keySearch.length? 
        <SearchPatientPrescriptions keySearch={keySearch}/> 
        :
      <Container style={styles.container}>
        <ScrollableTab titles={['Nuevas', 'Anteriores']}>
          {todayPrescriptions === null ? (
            renderSpinner()
          ) : (
            <PrescriptionsList
              prescriptions={todayPrescriptions}
              emptyText={'No tienes recetas generadas hoy'}
              title={'Recetas hoy'}
              refreshing={refreshingToday}
              onLoadMore={onLoadMoreTodayPrescriptions}
              total={totalToday}
            />
          )}
          {pastPrescriptions === null ? (
            renderSpinner()
          ) : (
            <PrescriptionsList
              prescriptions={pastPrescriptions}
              emptyText={'No tienes recetas pasadas'}
              title={'Recetas pasadas'}
              refreshing={refreshingPast}
              onLoadMore={onLoadMorePastPrescriptions}
              total={totalPast}
            />
          )}
        </ScrollableTab>

        <ButtonIcon
          icon={'plus'}
          style={styles.filterView}
          imgStyle={styles.buttonIconPlusImage}
          tintColor="#fff"
          onPress={onPencilPress}
          text={locales_es.createPrescription}
        />
      </Container>
      }
    </>
  );
};

export default Prescriptions;

const styles = StyleSheet.create({
  header: {
    paddingTop: Platform.OS === 'ios' ? getStatusBarHeight() : 0,
    ...Theme.flexRowSpace,
    paddingBottom: 10,
  },
  container: {
    flex: 1,
  },
  filterView: {
    position: 'absolute',
    alignSelf: 'center',
    bottom: getBottomSpace() + 16,
    // right: 24,
    backgroundColor: Colors.BlueCrayola,
    // width: 200,
    height: 56,
    borderRadius: 16,
  },
  buttonIconPlusImage: {
    width: 24,
    height: 24,
  },
});
