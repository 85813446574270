import {Platform, StyleSheet} from 'react-native';
import {Colors} from '../configs';
import {getBottomSpace, getStatusBarHeight} from 'react-native-iphone-x-helper';
import scale from './../utils/scale';
import {HEADER_HEIGHT} from './../configs/Const';

export default StyleSheet.create({
  flexRowSpace: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  flexRow: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  flexColumn: {
    flexDirection: 'column',
    alignItems: 'center',
  },
  center: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  flexOne: {
    flex: 1,
  },
  flexDirection: {
    flexDirection: 'row',
  },
  container: {
    flex: 1,
    // backgroundColor: Colors.Snow,
    paddingHorizontal: 24,
    paddingBottom: getBottomSpace() + 16,
    paddingTop: getStatusBarHeight() + 22,
  },
  icons: {
    width: 24,
    height: 24,
  },
  icons32: {
    width: 32,
    height: 32,
  },
  icons16: {
    width: 16,
    height: 16,
  },
  alignSelfCenter: {
    alignSelf: 'center',
  },
  shadow: {
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 4,
    },
    shadowOpacity: 0.3,
    shadowRadius: 4,
    elevation: 4,
  },
  shadowHide: {
    shadowOpacity: 0,
    shadowOffset: {
      height: 0,
    },
    shadowRadius: 0,
    elevation: 0,
  },
  buttonSlider: {
    width: 48,
    height: 6,
    backgroundColor: Colors.Platinum,
    marginTop: 12,
    borderRadius: 3,
    alignSelf: 'center',
  },
  headerStyle: {
    elevation: 0,
    shadowOpacity: 0,
    borderBottomWidth: 0,
  },
  headerBackGround: {
    flex: 1,
    // backgroundColor: Colors.Snow,
  },
  headerNavigationStyle: {
    shadowColor: 'transparent',
    height: HEADER_HEIGHT,
  },
  header: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: 108 - getStatusBarHeight(),
    paddingTop: getStatusBarHeight(),
  },
  updateProfileContentScrollStyle: {
    paddingHorizontal: 24,
    paddingBottom: getBottomSpace() + 16,
    paddingTop: getStatusBarHeight() + 22,
  },
  headerComponent: {
    height: HEADER_HEIGHT,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    ...Platform.select({
      ios: {
        paddingTop: getStatusBarHeight(),
      },
      android: {},
    }),
  },
});
