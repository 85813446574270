import React, {
  useLayoutEffect,
  useState,
  useEffect,
} from 'react';
import {
  View,
  StyleSheet,
  ActivityIndicator,
  FlatList,
} from 'react-native';
import Text from '../../components/Text';
import SearchBox from '../../components/Home/SearchBox';
import {Colors} from '../../configs';
import Theme from '../../style/Theme';
import {getBottomSpace, getStatusBarHeight} from 'react-native-iphone-x-helper';
import {SOURCE_ICON} from '../../images';
import Layout from '../../components/Layout/Layout';
import HeaderButton from '../../components/HeaderButton';
import {useHistory} from 'react-router-dom';

import locales_es from '../../locales/es';
import {CommonStyles} from '../../styles/CommonStyles';
import Container from '../../components/Layout/Container';
import UserInfo from '../../components/ConsultDetail/UserInfo';
import APIService from '../../modules/apiService';
import Helpers from '../../modules/helpers';
import {showModalInfo} from '../../modules/ViewHelpers';


interface SearchPatientsProps {
}

let nativeEventKeySearch = '';
const SearchPatients = (props: SearchPatientsProps) => {
  const history = useHistory();
  const api = new APIService();
  const helpers = new Helpers();
  const params = history.location.state;
  const items = props && props.items ? props.items : params && params.items ? params.items : null;
  const keyItemsToSearch = props && props.keyItemsToSearch ? props.keyItemsToSearch : params && params.keyItemsToSearch ? params.keyItemsToSearch : null;
  const onPressItem = props && props.onPressItem ? props.onPressItem : params && params.onPressItem ? params.onPressItem : () => {
  };
  const [keySearch, setKeySearch] = useState('');
  const [searchResults, setSearchResults] = useState([]);

  const onKeySearchChange = e => {
    nativeEventKeySearch = e.nativeEvent.text;
    setKeySearch(e.nativeEvent.text);
  };

  const onKeySearchClear = () => {
    nativeEventKeySearch = '';
    setKeySearch('');
  };

  useEffect(() => {
    if (keySearch) {
      if (items) {
        setSearchResults(null);
        const regex = new RegExp(keySearch, 'i'); // add 'i' modifier that means "ignore case"

        let _items = JSON.parse(JSON.stringify(items));
        const result: any = [];
        _items.map((_item: any) => {
          let founded = false;
          keyItemsToSearch.map((key: string) => {
            if (
              !founded &&
              (regex.test(_item[key]) ||
                keySearch
                  .toLowerCase()
                  .indexOf(String(_item[key]).toLowerCase().trim()) > -1)
            ) {
              result.push(_item);
              founded = true;
            }
          });
        });
        setSearchResults(result);
      } else {
        
        setSearchResults(null);

        const objData = {
          q: keySearch,
        };

        setTimeout(() => {
          if (keySearch.length === nativeEventKeySearch.length) {
            api
              .searchPatients(objData)
              .then((res: any) => {
                setSearchResults(res.data);
              })
              .catch((err: any) => {
                showModalInfo(
                  locales_es.infoModal.title,
                  helpers.getErrorMsg(err),
                );
              });
          } else {
            // setSearchResults([]);
          }
        }, 500); // 800
      }
    }
  }, [keySearch]);

  const renderItem = ({item}) => {
    const onPressParsed = eval(onPressItem);
    return (
      <UserInfo
        onPress={() => onPressParsed(item)}
        key={item.id}
        user={item}
        style={{
          marginTop: 24,
          marginHorizontal: 6,
        }}
      />
    );
  };

  return (
    <>
      <Layout style={styles.header}>
        <HeaderButton
          icon={SOURCE_ICON.close}
          onPress={props.onGoBack}
          style={{
            marginTop: 26,
            marginLeft: 24,
          }}
        />
        <SearchBox
          placeholder={locales_es.searchPatient + '...'}
          value={keySearch}
          // onChangeText={onKeySearchChange}
          onChange={onKeySearchChange}
          borderColor={Colors.TealBlue}
          shadow={false}
          style={styles.searchBox}
          autoFocus={true}
          // onSubmitEditing={onSubmitEditing}
          // onSubmitEditing={onKeySearchChange}
          onClear={onKeySearchClear}
        />
      </Layout>
      <Container style={styles.container}>
        <View
          style={{
            ...Theme.flexOne,
            marginBottom: 20,
            justifyContent: 'flex-start',
          }}>
          {!keySearch ? (
            <Text size={13} lineHeight={16} bold center>
              {locales_es.typeToSearch}...
            </Text>
          ) : searchResults === null ? (
            <ActivityIndicator style={CommonStyles.activityMonitor}/>
          ) : searchResults && searchResults.length ? (
            <FlatList
              data={searchResults}
              renderItem={renderItem}
              // keyExtractor={keyExtractor}
              scrollEventThrottle={16}
              contentContainerStyle={styles.contentContainerStyle}
              showsVerticalScrollIndicator={false}
            />
          ) : (
            <Text size={13} lineHeight={16} bold>
              {locales_es.searchNotFound}
            </Text>
          )}
        </View>
      </Container>
    </>
  );
};

export default SearchPatients;

const styles = StyleSheet.create({
  container: {
    paddingTop: 120,
    paddingHorizontal: 24,
    paddingBottom: getBottomSpace() + 12,
    ...Theme.flexOne,
  },
  scrollView: {
    flex: 1,
  },
  header: {
    ...Theme.flexRowSpace,
    paddingRight: 24,
    position: 'fixed',
    width: '100%',
    zIndex: 100,
  },
  cancelButton: {
    height: 48,
    marginTop: getStatusBarHeight(),
  },
  searchBox: {
    marginLeft: 24,
    marginBottom: 12,
    flex: 1,
  },
  icon: {
    width: 40,
    height: 40,
    borderRadius: 8,
    ...Theme.center,
    backgroundColor: Colors.DodgerBlue,
    marginRight: 16,
  },
  contentContainerStyle: {
    paddingBottom: 30,
  }
});
