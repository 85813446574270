import React, {Dispatch, SetStateAction, memo} from 'react';
import {
  View,
  StyleSheet,
  ViewStyle,
  TouchableOpacity,
  ColorValue,
} from 'react-native';
import Text from '../../components/Text';
import TextInput from '../../components/TextInput';
import {useTheme} from '../../configs/Theme';
import locales_es from '../../locales/es';

interface InputAppProps {
  testID?: string;
  forwardRef?: any;
  isShowIcon?: boolean;
  icon?: any;
  style?: ViewStyle;
  styleView?: ViewStyle;
  title: string;
  label?: string;
  borderColor?: ColorValue | string;
  iconPress?: () => void;
  autoFocus?: boolean;
  isShowIconLeft?: boolean;
  iconLeft?: any;
  iconPressLeft?: () => void;
  marginTop?: number;
  multiline?: boolean;
  editable?: boolean;
  showRequired?: boolean;
  onPress?: () => void;
  styleInput?: ViewStyle;
  maxLength?: number;
  onFocus?: () => void;
  onBlur?: () => void;
}

const InputApp = ({
  testID,
  forwardRef,
  isShowIcon,
  icon,
  style,
  styleView,
  title,
  label,
  borderColor,
  iconPress,
  isShowIconLeft,
  iconLeft,
  iconPressLeft,
  marginTop,
  multiline,
  editable = true,
  showRequired = false,
  onPress,
  styleInput,
  maxLength,
  onFocus,
  onBlur,
  ...props
}: InputAppProps) => {
  const {theme} = useTheme();
  return (
    <TouchableOpacity
      testID={testID + '-wrapper'}
      style={[styleView, {marginTop: marginTop}]}
      onPress={onPress}
      // disabled={!onPress}
      activeOpacity={0.7}>
      <Text type="H6" semibold>
        {title}
        {showRequired && (
          <Text type="H3" center= {true} color={"gray"} semibold>
          {` ${locales_es.required}`}
        </Text>
        )}
      </Text>
      <TextInput
        {...{
          testID,
          forwardRef,
          isShowIcon,
          icon,
          borderColor: borderColor || theme.innearColor,
          iconPress,
          isShowIconLeft,
          iconLeft,
          iconPressLeft,
          multiline,
          editable,
          maxLength,
          onFocus,
          onBlur,
        }}
        {...props}
        style={{marginTop: 4, ...style}}
      />
      {label && (
        <Text type="H6" marginTop={6}>
          {label}
        </Text>
      )}
    </TouchableOpacity>
  );
};

export default InputApp;
