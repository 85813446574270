import React, {useEffect, useState} from 'react';
import {
  StyleSheet,
  View,
  TouchableOpacity,
  Image,
  ScrollView,
  ActivityIndicator,
} from 'react-native';
import {getBottomSpace, getStatusBarHeight} from 'react-native-iphone-x-helper';
import {SOURCE_ICON} from '../../images';
import Layout from '../../components/Layout/Layout';
import HeaderButton from '../../components/HeaderButton';
import Theme from '../../style/Theme';
import Text from '../../components/Text';
import {api, helpers} from '../../import';
import Container from '../../components/Layout/Container';
import locales_es from '../../locales/es';
import {showModalInfo} from '../../modules/ViewHelpers';
import {Colors} from '../../configs';
import {
  EVENT_RELOAD_COMBOS, EVENT_SHOW_MODAL_INFO,
} from '../../constants/Events';
import CustomCollapsible from '../../components/CustomCollapsible';
import {EventRegister} from "../../modules/EventRegister";
import iconTrash from '../../images/ic_trash.png';
import {useHistory} from 'react-router-dom';
import ButtonLinear from "../../components/Buttons/ButtonLinear";

const MyCombos = (props) => {
  const history = useHistory();
  const params = history.location.state;
  const {prescriptionProfileId, onComplete} = props || params;
  const [combos, setCombos] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getMyCombos();
  }, []);

  const getMyCombos = (reload?: boolean) => {
    
    
    setLoading(true);
    const objData = {
      prescription_profile_id: prescriptionProfileId,
    };
    api
      .getMyPracticesCombos(objData)
      .then((res: any) => {
        
        setCombos(res.data);
        if (reload) {
          EventRegister.emit(EVENT_RELOAD_COMBOS, res.data);
        }
        setLoading(false);
      })
      .catch((err: any) => {
        showModalInfo(locales_es.infoModal.title, helpers.getErrorMsg(err));
        setLoading(false);
      });
  };

  const deleteCombo = comboId => {
    EventRegister.emit(EVENT_SHOW_MODAL_INFO, {
      title: '¿Eliminar combo?',
      message: '¿Quiere eliminar este combo?',
      actions: [
        {
          text: locales_es.accept,
          onPress: () => {
            confirmDeleteCombo(comboId);
          },
          white: true,
           styleButton: {
            width: '60%',
            margin: 'auto',
            marginVertical: 10
          }
        },
        {
          text: locales_es.cancel,
          onPress: () => console.log('Cancel Pressed'),
          colors: [Colors.WhiteGray, Colors.WhiteGray],
          white: true,
           styleButton: {
            width: '60%',
            margin: 'auto',
            marginVertical: 10
          }
        },
      ],
    });
  };

  const confirmDeleteCombo = (comboId: number) => {
    setLoading(true);
    api
      .deleteCombo(comboId)
      .then((res: any) => {
        showModalInfo(locales_es.successModal.title, res.message);
        getMyCombos(true);
      })
      .catch((err: any) => {
        helpers.processError(err);
        setLoading(false);
      });
  };

  const renderComboList = () => {
    const _combos = combos.map(item => (
      <Layout style={styles.comboContainer}>
        <TouchableOpacity
          {...item}
          key={item.id.toString()}
          onPress={() => {
            onComplete(item.practices.map((i: any) => i.medical_practice));
          }}>
          <View style={styles.comboTopBox}>
            <View style={{width: '100%', flexDirection: 'row'}}>
              <View style={styles.comboRounded}>
                <Image
                  style={styles.comboIcon}
                  source={SOURCE_ICON.additionalInformation}
                />
              </View>
              <View style={{flex: 1}}>
                <Text marginLeft={16} size={15} lineHeight={18} bold>
                  {item.name}
                </Text>
              </View>
              <View style={styles.comboItemView}>
                <TouchableOpacity
                  onPress={() => deleteCombo(item.id)}>
                  <Image
                    source={iconTrash}
                    style={{
                      tintColor: Colors.Tomato,
                      width: 24,
                      height: 24,
                    }}
                    resizeMode="contain"
                  />
                </TouchableOpacity>
                <Image
                  source={SOURCE_ICON.arrowRight}
                  style={{tintColor: Colors.BlueCrayola, width: 24, height: 24}}
                />
              </View>
            </View>
          </View>
        </TouchableOpacity>
        <CustomCollapsible
          item={{
            items: item.practices.map(practice => {
              practice.name =
                practice && practice.medical_practice
                  ? (practice.medical_practice.code
                  ? practice.medical_practice.code + ' - '
                  : '') + practice.medical_practice.name
                  : locales_es.practiceDeprecated;
              return practice;
            }),
          }}
          title={`${item.practices.length} ${locales_es.practices}`}
        />
      </Layout>
    ));
    return _combos.length ? (
      <ScrollView showsVerticalScrollIndicator={false}>{_combos}</ScrollView>
    ) : (
      <View>
        <Text center>No se encontraron combos</Text>
      </View>
    );
  };
  return (
    <>
      <Layout style={styles.header}>
        <HeaderButton
          icon={SOURCE_ICON.back}
          style={{
            marginTop: 26,
            marginLeft: 24,
          }}
        />
      </Layout>
      <Container style={styles.container}>
        <View
          style={{
            ...Theme.flexOne,
            // marginBottom: 20,
            justifyContent: 'flex-start',
          }}>
          {loading ? <ActivityIndicator/> : renderComboList()}
        </View>
      </Container>
    </>
  );
};

const styles = StyleSheet.create({
  container: {
    paddingTop: 132,
    paddingHorizontal: 24,
    paddingBottom: getBottomSpace() + 12,
    ...Theme.flexOne,
  },
  header: {
    ...Theme.flexRowSpace,
    paddingRight: 24,
    paddingTop: getStatusBarHeight(),
    position: 'fixed',
    top: 0,
    width: '100%',
  },
  practiceText: {
    ...Theme.flexOne,
  },
  scrollView: {
    flex: 1,
  },
  rightColumn: {
    alignItems: 'flex-end',
    justifyContent: 'center',
    height: 40,
    borderRadius: 8,
  },
  comboContainer: {
    borderRadius: 16,
    // paddingBottom: 24,
    paddingBottom: 12,
    // marginHorizontal: 16,
    // marginTop: 24,
    margin: 5,
  },
  comboButtonEdit: {
    width: 24,
    height: 24,
  },
  comboTopBox: {
    ...Theme.flexRowSpace,
    paddingTop: 16,
    paddingHorizontal: 24,
    paddingBottom: 16,
  },
  comboRounded: {
    width: 32,
    height: 32,
    borderRadius: 8,
    backgroundColor: Colors.OysterBay,
    ...Theme.center,
  },
  comboIcon: {
    tintColor: Colors.TiffanyBlue,
    width: 20,
    height: 20,
  },
  comboItemView: {
    flexDirection: 'row',
    alignItems: 'flex-end',
    alignSelf: 'flex-end',
    // marginRight: 20,
    marginTop: 10,
  },
});
export default MyCombos;
