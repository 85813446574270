import React, {useState, useEffect, useCallback, memo} from 'react';
import {View, StyleSheet, Image, Keyboard, Platform} from 'react-native-web';
import Text from '../../components/Text';
import {Colors, Routes} from '../../configs';
import Theme from '../../style/Theme';
import scale from '../../utils/scale';
import InputApp from '../../components/InputApp';
import ButtonLinear from '../../components/Buttons/ButtonLinear';
import HeaderButton from '../../components/HeaderButton';
import locales_es from '../../locales/es';
import {useTheme} from '../../configs/Theme';
import {validateEmptyFields,handleValidatorInputsTypes,InitForm} from '../../utils/validation/forms';
import {EVENT_SHOW_MODAL_INFO} from '../../constants/Events';
import FullScreenLoading from '../../components/fullscreenLoading';
import AuthService from '../../modules/authService';
import Helpers from '../../modules/helpers';
import {EventRegister} from "../../modules/EventRegister";
import { useHistory } from "react-router-dom";
import eyeOnIcon from '../../images/ic_eye_on.png';


interface ChangePasswordProps {}

const ChangePassword = (props: ChangePasswordProps) => {
  const auth = new AuthService();
  const helpers = new Helpers();
  const [loading, setLoading] = useState(false);
  const [visiblePassword, setVisiblePassword] = useState(false);
  const [visibleNewPassword, setVisibleNewPassword] = useState(false);
  const [visibleNewPasswordRepeat, setVisibleNewPasswordRepeat] = useState(false);
  const [errors, setErrors] = useState<any>([]);
  const [password, setPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [newPasswordRepeat, setNewPasswordRepeat] = useState('');
  const [errorMessages,setErrorMessages] = useState({
    password: '',
    newPassword: '',
    newPasswordRepeat: ''
  })
  const [enableButton, setEnableButton] = useState(true);
  const initValues = {
    password,
    newPassword,
    newPasswordRepeat
  }
  const initForm: InitForm = {
    setErrors,
    errors,
    setErrorMessages,
    errorMessages,
    setEnableButton,
    initValues,
  }
  
  const maxLengthPassword = (value: string, type: string) => {
    if (value.length > 15) {
      if (type=== 'password')
      {
        setErrorMessages({...errorMessages, password: "La contraseña no puede tener más de 15 caracteres"});
        setEnableButton(true)
      }
      else if (type=== 'newPassword')
      {
        setErrorMessages({...errorMessages, newPassword: "La contraseña no puede tener más de 15 caracteres"});
        setEnableButton(true)
      }
      else if (type=== 'newPasswordRepeat')
      {
        setErrorMessages({...errorMessages, newPasswordRepeat: "La contraseña no puede tener más de 15 caracteres"});
        setEnableButton(true)
      }

      setErrors([...errors, `${type}`]);
    }
  };

  const samePasswordValidate = ()=> {
      
  
    if (newPasswordRepeat.length > 0){
      
      if (newPasswordRepeat !== newPassword && newPasswordRepeat.length > newPassword.length){
        
        setErrorMessages({...errorMessages,newPasswordRepeat: 'Las contraseñas no son iguales'})
        if (!errors.includes('newPasswordRepeat')) {
          setErrors([...errors, 'newPasswordRepeat']);
         }
        setEnableButton(true)
        
      }
      else if (newPasswordRepeat !== newPassword && newPasswordRepeat.length <= newPassword.length)  {
        setErrorMessages({...errorMessages,newPasswordRepeat: 'Las contraseñas no son iguales'})
        
        if (!errors.includes('newPasswordRepeat')) {
          setErrors([...errors, 'newPasswordRepeat']);
         }
        setEnableButton(true)
        
      }
      else if(newPasswordRepeat === newPassword){
        setErrors(errors.filter((item: any) => item !== 'newPasswordRepeat'));
        
        
        if (errors.length === 1 && errors == 'newPasswordRepeat' && newPasswordRepeat === newPassword){
          setEnableButton(false)
        }
        if (errors.length === 0 &&  newPasswordRepeat === newPassword){
          setEnableButton(false)
        }
      }

      
      // setErrors([...errors, 'newPassswordRepeat'])
    }
  }

  useEffect(() => {
    samePasswordValidate()
  },[newPassword,newPasswordRepeat])
  


  const history = useHistory();

  const onShowHidePassword = useCallback(() => {
    setVisiblePassword(prev => !prev);
  }, []);

  const onShowHideNewPassword = useCallback(() => {
    setVisibleNewPassword(prev => !prev);
  }, []);

  const onShowHideNewPasswordRepeat = useCallback(() => {
    setVisibleNewPasswordRepeat(prev => !prev);
  }, []);

  const onSend = () => {
    const errors = validateEmptyFields({
      password,
      newPassword,
      newPasswordRepeat,
    });
    if (errors.length) {
      setErrors(errors);
    } else {
      setLoading(true);
      const objData = {
        old_password: password,
        new_password: newPassword,
        new_password_confirmation: newPasswordRepeat,
      };
      auth
        .changePassword(objData)
        .then((res: any) => {
          setLoading(false);
          EventRegister.emit(EVENT_SHOW_MODAL_INFO, {
            title: locales_es.successModal.title,
            message: res.message,
            logoutModal: true
          });
        })
        .catch((err: any) => {
          setLoading(false);
          EventRegister.emit(EVENT_SHOW_MODAL_INFO, {
            title: locales_es.errorModal.title,
            message: helpers.getErrorMsg(err),
          });
        });
    }
  };

  const onBlur = (inputStr: string) => {
    setErrors(errors.filter(item => item !== inputStr));
  };

  const {theme} = useTheme();
  const passwordRef = React.createRef();
  const newPasswordRef = React.createRef();
  const newPasswordRepeatRef = React.createRef();

  return (
    <View style={styles.container}>
      {loading && <FullScreenLoading />}
      <HeaderButton />
      <View style={styles.content}>
        <Text type={'H2'} bold>
          {locales_es.changePassword}
        </Text>
        <Text type="P6" style={{marginVertical: 16}}>
          {locales_es.changePasswordInfo}
        </Text>
      </View>

      <InputApp
        maxLength={15}
        forwardRef={passwordRef}
        borderColor={
          errors.includes('password') ? theme.lineErrorColor : theme.innearColor
        }
        // onBlur={() => onBlur('password')}
        title={locales_es.actualPassword}
        placeholder={locales_es.actualPassword}
        value={password}
        onChangeText={setPassword}
        secureTextEntry={!visiblePassword}
        marginTop={24}
        icon={
          <Image
            source={eyeOnIcon}
            style={styles.icon}
          />
        }
        isShowIcon
        iconPress={onShowHidePassword}
        blurOnSubmit={Platform.OS === 'android'}
        onSubmitEditing={() => {
          newPasswordRef.current.focus();
        }}
        autoCapitalize="none"
        returnKeyType="next"
        autoCorrect={false}
      />
      <InputApp
        maxLength={15}
        forwardRef={newPasswordRef}
        label="Al menos 8 caracteres, letras mayúsculas y minúsculas, y al menos un caracter especial (por ejemplo '!')"
        borderColor={
          errors.includes('newPassword')
            ? theme.lineErrorColor
            : theme.innearColor
        }
        // onBlur={() => onBlur('newPassword')}
        title={locales_es.newPassword}
        placeholder={locales_es.newPassword}
        value={newPassword}
        onChangeText={(value:string)=>{handleValidatorInputsTypes(initForm, value, 'password',setNewPassword,'newPassword',0);maxLengthPassword(value,'newPassword')}}
        secureTextEntry={!visibleNewPassword}
        marginTop={24}
        icon={
          <Image
            source={eyeOnIcon}
            style={styles.icon}
          />
        }
        isShowIcon
        iconPress={onShowHideNewPassword}
        blurOnSubmit={Platform.OS === 'android'}
        onSubmitEditing={() => {
          newPasswordRepeatRef.current.focus();
        }}
        autoCapitalize="none"
        returnKeyType="next"
        autoCorrect={false}
      />
      {
        errors.includes('newPassword') && ( 
          <Text size={13} lineHeight={16} marginTop={4} color={Colors.Red}>{errorMessages.newPassword}</Text> 
        )
      }
      <InputApp
        maxLength={15}
        forwardRef={newPasswordRepeatRef}
        label="Al menos 8 caracteres, letras mayúsculas y minúsculas, y al menos un caracter especial (por ejemplo '!')"
        borderColor={
          errors.includes('newPasswordRepeat')
            ? theme.lineErrorColor
            : theme.innearColor
        }
        // onBlur={() => onBlur('newPasswordRepeat')}
        title={locales_es.repeatNewPassword}
        placeholder={locales_es.repeatNewPassword}
        value={newPasswordRepeat}
        onChangeText={(value:string)=>{handleValidatorInputsTypes(initForm, value, 'password',setNewPasswordRepeat,'newPasswordRepeat',0);maxLengthPassword(value,'newPasswordRepeat')}}
        secureTextEntry={!visibleNewPasswordRepeat}
        marginTop={24}
        icon={
          <Image
            source={eyeOnIcon}
            style={styles.icon}
          />
        }
        isShowIcon
        iconPress={onShowHideNewPasswordRepeat}
        blurOnSubmit={Platform.OS === 'android'}
        onSubmitEditing={() => {
          Keyboard.dismiss();
          onSend();
        }}
        autoCapitalize="none"
        returnKeyType="go"
        autoCorrect={false}
      />
      {
        errors.includes('newPasswordRepeat')  && ( 
          <Text size={13} lineHeight={16} marginTop={4} color={Colors.Red}>{errorMessages.newPasswordRepeat}</Text> 
        )
      }
      <ButtonLinear title={locales_es.updatePassword} onPress={onSend} disabled={enableButton} white/>
    </View>
  );
};

export default ChangePassword;

const styles = StyleSheet.create({
  container: {
    ...Theme.container,
  },
  icon: {
    width: 24,
    height: 24,
  },
  backButton: {
    width: 40,
    height: 40,
    borderRadius: 8,
    borderWidth: 1,
    borderColor: Colors.Platinum,
    ...Theme.center,
  },
  content: {
    marginTop: 40,
  },
  description: {
    marginTop: 16,
  },
});
