import React, {useEffect, useRef} from 'react';
import {Animated, View, StyleSheet, TouchableOpacity, Easing} from 'react-native';
import {Colors, Constants} from '../../configs';
// import {PanGestureHandler} from 'react-native-gesture-handler';
import {useTheme} from '../../configs/Theme';
import {helpers} from "../../import";

interface ModalSlideBottomProps {
  children: any;
  onClose: () => void;
  transY: Animated.Node<number>;
  style?: any;
}

const ModalSlideBottom = (props: ModalSlideBottomProps) => {
  const {theme} = useTheme();
  const animatedTranslate = useRef(new Animated.Value(0)).current;

  useEffect(() => {
    Animated.timing(animatedTranslate, {
      duration: 70,
      easing: Easing.linear,
      toValue: props.transY,
      useNativeDriver: true,
    }).start();
  }, []);

  return (
    <View style={styles.container}>
      <TouchableOpacity
        onPress={props.onClose}
        activeOpacity={1}
        style={StyleSheet.absoluteFillObject}
      />
      <Animated.View
        style={[
          styles.modal,
          {
            backgroundColor: theme.modalColor,
            bottom: props.transY,
          },
          {
            transform: [
              {
                // translateY: sub(0, props.transY),
                translateY: animatedTranslate,
              },
            ],
          },
          props.style || {}
        ]}>
        <View
          style={{
            position: 'absolute',
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            height: 36,
            // backgroundColor: 'red',
            borderTopLeftRadius: 24,
            borderTopRightRadius: 24,
          }}
        />
        {/*<PanGestureHandler onGestureEvent={props.onClose}>
          <View
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              height: 36,
              // backgroundColor: 'red',
              borderTopLeftRadius: 24,
              borderTopRightRadius: 24,
            }}
          />
        </PanGestureHandler>*/}
        <TouchableOpacity style={styles.buttonSlider} onPress={props.onClose} />
        {props.children}
      </Animated.View>
    </View>
  );
};

export default ModalSlideBottom;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: Colors.ModalBackground,
    justifyContent: 'flex-end',
  },
  modal: {
    position: 'absolute',
    borderTopLeftRadius: 24,
    borderTopRightRadius: 24,
    left: 0,
    right: 0,
    zIndex: 100,
    transition: 'all ease 1s',
  },
  buttonSlider: {
    width: 48,
    height: 6,
    backgroundColor: Colors.Platinum,
    marginTop: 12,
    borderRadius: 3,
    alignSelf: 'center',
  },
});
