import React from 'react';
import {TouchableOpacity, View} from 'react-native';

let TouchableOpacityHOC;
const ConditionalTouchableOpacity = () => {
  return ({children, ...props}) =>
    props && props.onPress ? (
      <TouchableOpacity onPress={props.onPress}>{children}</TouchableOpacity>
    ) : (
      <View>{children}</View>
    );
};
export default TouchableOpacityHOC = ConditionalTouchableOpacity(View);
