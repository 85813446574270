import LinearColors from '../../components/LinearColors';
import {Colors} from '../../configs';
import React, {memo} from 'react';
import {
  View,
  Image,
  StyleSheet,
  TouchableOpacity,
  ViewStyle,
  Switch,
} from 'react-native';
import Theme from '../../style/Theme';
import Text from '../../components/Text';
import {SOURCE_ICON} from '../../images';
import Line from '../../components/Layout/Line';

interface AccountItemProps {
  icon?: any;
  iconStyle?: ViewStyle;
  linearColorDisabled?: boolean;
  name?: string;
  isToggle?: boolean;
  style?: ViewStyle;
  nameStyle?: ViewStyle;
  onPress?: () => void;
  switchValue?: boolean;
  onValueChange?: () => void;
  lineBottom?: boolean;
  hideArrowAndToggle?: boolean;
}

const getIcon = (icon: any, iconStyle: ViewStyle) => {
  return <Image source={icon} style={[styles.icon, iconStyle]} resizeMode="contain" />;
};

const renderIcon = (
  icon: any,
  iconStyle: ViewStyle,
  linearColorDisabled: boolean,
) => {
  return linearColorDisabled !== true ? (
    <LinearColors
      colors={[Colors.TealBlue, Colors.TurquoiseBlue]}
      style={styles.contentIcon}>
      {getIcon(icon, iconStyle)}
    </LinearColors>
  ) : (
    getIcon(icon, iconStyle)
  );
};

const AccountItem = memo(
  ({
    icon,
    iconStyle,
    linearColorDisabled = false,
    name,
    isToggle,
    style,
    nameStyle,
    onPress,
    switchValue,
    lineBottom,
    onValueChange,
    hideArrowAndToggle = false
  }: AccountItemProps) => {
    return (
      <View>
        <TouchableOpacity style={style} onPress={onPress} activeOpacity={0.54}>
          <View style={Theme.flexRow}>
            {icon && renderIcon(icon, iconStyle, linearColorDisabled)}
            <Text size={15} marginLeft={16} style={nameStyle}>
              {name}
            </Text>
          </View>
          {!hideArrowAndToggle &&<View>
            {isToggle === true ? (
              <Switch value={switchValue} onValueChange={onValueChange} />
            ) : (
              <Image source={SOURCE_ICON.arrowRight} style={{width: 16, height: 16}} />
            )}
          </View>}
        </TouchableOpacity>
        {lineBottom ? <Line /> : null}
      </View>
    );
  },
);

export default AccountItem;

const styles = StyleSheet.create({
  icon: {
    tintColor: Colors.White,
    alignSelf: 'center',
    maxWidth: 40,
    maxHeight: 40,
    width: '50%',
    height: '50%',
  },
  contentIcon: {
    width: 40,
    height: 40,
    backgroundColor: Colors.DodgerBlue,
    borderRadius: 8,
    justifyContent: 'center',
  },
});
