import React, {Component} from 'react';

export default class InputSpinner extends Component {

  constructor(props) {
    super(props);
  }

  onChange(qty) {
    if (this.props.value + qty >= this.props.min && this.props.value + qty <= this.props.max) {
      this.props.onChange(qty)
    }
  }

  render() {

    let bg = this.props.color;

    if (this.props.colorMin && this.props.value === this.props.min) {
      bg = this.props.colorMin
    }

    if (this.props.colorMax && this.props.value === this.props.max) {
      bg = this.props.colorMax
    }

    const btnStyle = {
      backgroundColor: bg,
      color: '#fff',
      borderColor: bg,
    };
    return (
      <div className="input-spinner">
        <div className="input-group mb-3">
          <div className="input-group-prepend">
            <button onClick={() => this.onChange(-(this.props.step))} className="btn btn-warning"
                    style={btnStyle}
                    type="button">-
            </button>
          </div>
          <input min={this.props.min} max={this.props.max} value={Number(this.props.value)} type="number"
                 className="form-control text-center"
                 disabled={true}
                 style={{
                   backgroundColor: '#fff',
                   color: '#333',
                 }}
                 placeholder=""/>
          <div className="input-group-append">
            <button onClick={() => this.onChange(this.props.step)} className="btn btn-success" style={btnStyle} type="button">+
            </button>
          </div>
        </div>
      </div>
    )
  }
}
