import React, { memo, useCallback } from "react";
import { StyleSheet, ActivityIndicator, Modal, View } from "react-native";
import { Colors } from "../../../configs";
import Greeting from "../../../components/Home/Greeting";
import FullScreenLoading from "../../../components/fullscreenLoading";
import MainControl from "../../../components/Home/MainControl";
import { getStatusBarHeight } from "react-native-iphone-x-helper";
import ButtonIcon from "../../../components/Buttons/ButtonIcon";
import { useHistory } from "react-router-dom";
import Routes from "../../../configs/Routes";
import locales_es from "../../../locales/es";
import { CommonStyles } from "../../../styles/CommonStyles";
import Metrics from "../../../Themes/Metrics";
import { useTheme } from "../../../configs/Theme";
import scale from "../../../utils/scale";
import Text from "../../../components/Text";
import ButtonLinear from "../../../components/Buttons/ButtonLinear";
import ButtonBorder from "../../../components/Buttons/ButtonBorder";
import iconSentMsg from "../../../images/img_sent_message.png";
import useHomePage from "../shared/useHomePage";
import IncompleteProfileView from "../shared/IncompleteProfile";

const Home = memo((props) => {
  const { theme } = useTheme();
  const history = useHistory();
  const { onCompleteYourProfile, onPressAccountResend, incompleteProfile, userData, load, loading, setValidateUserModal, validateUserModal, services } = useHomePage();
  const onPencilPress = useCallback(() => {
    history.push(`/${Routes.AddPrescription}`);
  }, [history]);

  return (
    <div
      style={Object.assign(
        {
          flex: 1,
          height: "100%",
          paddingTop: Metrics.HEIGHT * 0.015,
          // marginTop: getStatusBarHeight() + 10,
        },
        { backgroundColor: theme.background }
      )}
    >
      {loading ? <FullScreenLoading /> : null}
      <div
        style={{
          height: "100%",
          paddingBottom: 16,
          paddingLeft: 24,
          paddingRight: 24,
          paddingTop: getStatusBarHeight(),
        }}
      >
        {userData === null ? (
          <ActivityIndicator style={CommonStyles.activityIndicator} />
        ) : (
          <Greeting {...userData} />
        )}
        {load ? (
          <ActivityIndicator size="large" style={{ marginTop: 60 }} />
        ) : (
          <MainControl services={services} />
        )}
      </div>

      {load ? (
        <ActivityIndicator />
      ) : (
        <div
          style={{
            position: "absolute",
            alignSelf: "center",
            justifyContent: "center",
            width: "100%",
            padding: 20,
            bottom: 0,
            display: "flex",
          }}
        >
          <ButtonIcon
            icon={"plus"}
            style={styles.filterView}
            imgStyle={styles.buttonIconPlusImage}
            tintColor="#fff"
            onPress={onPencilPress}
            text={locales_es.createPrescription}
          />
        </div>
      )}

      {validateUserModal ? (
        <Modal
          visible={validateUserModal}
          onRequestClose={() => setValidateUserModal(false)}
          transparent
          animationType={"fade"}
        >
          <View style={styles.modal}>
            <img
              src={iconSentMsg}
              style={{ width: scale(120, true), height: scale(120, true) }}
            />
            <Text
              size={15}
              lineHeight={22}
              center
              marginTop={24}
              marginHorizontal={56}
            >
              {locales_es.confirmAccountValidationModal.title}
            </Text>
            <Text
              semibold
              size={15}
              lineHeight={22}
              center
              marginTop={12}
              marginHorizontal={56}
            >
              {locales_es.confirmAccountValidationModal.subtitle}
            </Text>
            <ButtonLinear
              white
              style={{
                paddingHorizontal: 20,
              }}
              title={locales_es.confirmAccountValidationModal.accept}
              onPress={() => setValidateUserModal(false)}
            />
            <ButtonBorder
              style={{
                paddingHorizontal: 20,
                marginVertical: 10,
              }}
              placeholder
              title={locales_es.confirmAccountValidationModal.resend}
              onPress={onPressAccountResend}
            />
          </View>
        </Modal>
      ) : null}

      {incompleteProfile ? <IncompleteProfileView  onCompleteYourProfile={onCompleteYourProfile} /> : null}
    </div>
  );
});

export default Home;

const styles = StyleSheet.create({
  filterView: {
    // right: 24,
    backgroundColor: Colors.BlueCrayola,
    // width: 200,
    height: 56,
    borderRadius: 16,
  },
  buttonIconPlusImage: {
    width: 24,
    height: 24,
  },
  modal: {
    flex: 1,
    backgroundColor: "#fff",
    justifyContent: "center",
    alignItems: "center",
  },
});
