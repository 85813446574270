import {countryLS, userDataLocalStorage} from '../models/constants';
import APIService from './../modules/apiService';
// import {EventRegister} from "react-native-event-listeners";
// import {EVENT_LOADED_COUNTRY} from '../constants/Events';

class CountryService {
  constructor() {
    this.api = new APIService();
  }

  setData(country) {
    localStorage.setItem(countryLS, JSON.stringify(country));
    // EventRegister.emit(EVENT_LOADED_COUNTRY, country);
  }

  clearData() {
    localStorage.removeItem(countryLS);
  }

  getData = () => {
    return new Promise(async (resolve, reject) => {
      const value = localStorage.getItem(countryLS);
      const json = JSON.parse(value);
      if (json) {
        resolve(json);
        return;
      }

      let userData = localStorage.getItem(userDataLocalStorage);
      userData = JSON.parse(userData);

      if (userData) {
        this.api
          .getUserByMe()
          .then((res: any) => {
            if (res && res.data && res.data.user && res.data.user.country) {
              const country = res.data.user.country;
              this.setData(country);
              resolve(country);
            }
          })
          .catch((err: any) => {
            reject(err);
          });
      } else {
        reject(null);
      }
    });
  };

  getByCode(code) {
    return new Promise(async (resolve) => {
      this.api.getCountryByCode(code).then((res) => {
        this.setData(res.data);
        resolve(res.data)
      }).catch((err) => {
        resolve(false);
      })
    })
  }
}

export default CountryService;
