import React, { useRef } from "react";
import { Animated, View } from "react-native";
import useDimensions from "../../hooks/useDimensions";
import Pagination from "./Pagination";
import SlideItem from "./SlideItem";

const Slider = ({ slides }: { slides: any[] }) => {
  const { isDesktop } = useDimensions();
  const scrollRef = useRef(null);
  const scrollX = useRef(new Animated.Value(0)).current;

  const handleOnScroll = (event: any) => {
    Animated.event(
      [
        {
          nativeEvent: {
            contentOffset: {
              x: scrollX,
            },
          },
        },
      ],
      {
        useNativeDriver: false,
      }
    )(event);
  };

  const viewabilityConfig = useRef({
    itemVisiblePercentThreshold: 50,
  }).current;

  return (
    <View>
      <Animated.FlatList
        ref={scrollRef}
        data={slides}
        renderItem={({ item }) => <SlideItem item={item} />}
        horizontal
        pagingEnabled={!isDesktop}
        snapToAlignment="center"
        keyExtractor={(_, index) => index.toString()}
        showsHorizontalScrollIndicator={false}
        onScroll={handleOnScroll}
        viewabilityConfig={viewabilityConfig}
      />
      <Pagination data={slides} scrollX={scrollX} flatlistRef={scrollRef} />
    </View>
  );
};

export default Slider;
