import React, {Dispatch, SetStateAction, memo} from 'react';
import {
  View,
  StyleSheet,
  ViewStyle,
  TouchableOpacity,
  ColorValue,
} from 'react-native';
import Text from '../../components/Text';
import TextInput from '../../components/TextInput';
import {useTheme} from '../../configs/Theme';
import locales_es from '../../locales/es';
import { NumericFormat } from 'react-number-format';
import {Colors} from '../../configs';
import Theme from '../../style/Theme';

interface InputAppNumberProps {
  testID?: string;
  forwardRef?: any;
  isShowIcon?: boolean;
  icon?: any;
  style?: ViewStyle;
  styleView?: ViewStyle;
  title: string;
  label?: string;
  borderColor?: ColorValue | string;
  iconPress?: () => void;
  autoFocus?: boolean;
  isShowIconLeft?: boolean;
  iconLeft?: any;
  iconPressLeft?: () => void;
  marginTop?: number;
  multiline?: boolean;
  editable?: boolean;
  showRequired?: boolean;
  onPress?: () => void;
  styleInput?: ViewStyle;
  maxLength?: number;
  onChangeText: any;
  value: any;
  disabled: boolean;
}

const InputAppNumber = ({
  testID,
  forwardRef,
  isShowIcon,
  icon,
  style,
  styleView,
  title,
  label,
  borderColor,
  iconPress,
  isShowIconLeft,
  iconLeft,
  iconPressLeft,
  marginTop,
  multiline,
  editable = true,
  showRequired = false,
  onPress,
  styleInput,
  maxLength,
  onChangeText,
  value,
  disabled = false,
  ...props
}: InputAppNumberProps) => {
  const {theme} = useTheme();
  return (
    <TouchableOpacity
      testID={testID + '-wrapper'}
      style={[styleView, {marginTop: marginTop}]}
      onPress={onPress}
      activeOpacity={0.7}>
      <Text type="H6" semibold>
        {title}
        {showRequired && (
          <Text type="H3" center= {true} color={"gray"} semibold>
          {` ${locales_es.required}`}
        </Text>
        )}
      </Text>
      <View
      style={[
        styles.container,
        {
          borderColor: borderColor || theme.innearColor,
          borderWidth: 1,
          backgroundColor: theme.searchBox,
        },
        style,
        props.multiline && styles.muli,
      ]}>
      <NumericFormat 
        value={value} 
        placeholder={locales_es.amount} 
        thousandSeparator="." 
        decimalSeparator="," 
        decimalScale={2}
        onValueChange={onChangeText}
        placeholderTextColor={Colors.GrayBlue}
        style={{
          flex: 1,
          width: '100%',
          fontSize: 15,
          fontWeight: 600,
          fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif',
          height: '100%',
          color: theme.text,
          textAlignVertical: 'top',
          border: 'none'
        }}
        disabled={disabled}
        />
      </View>
    </TouchableOpacity>
  );
};

export default InputAppNumber;

const styles = StyleSheet.create({
  container: {
    minHeight: 48,
    justifyContent: 'center',
    paddingHorizontal: 16,
    borderRadius: 8,
    ...Theme.flexRow,
  }
});
