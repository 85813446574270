import React, {memo, useEffect, useRef} from "react";

interface SearchItemListProps {
    data: any;
    renderItem: any;
    contentContainerStyle: any;
}

const SearchItemList = memo(({data, renderItem, contentContainerStyle}: SearchItemListProps) => {
    
    return (<div style={contentContainerStyle} >
        {data && data.length && data.map((item: any) => {
            return renderItem({item});
        })}
    </div>);
});

export default SearchItemList;
