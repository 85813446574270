import React, {useCallback, useState, useEffect} from 'react';
import {View, StyleSheet, ActivityIndicator, Platform} from 'react-native';
import ButtonIconHeader from '../../components/ButtonIconHeader';
import Theme from '../../style/Theme';
import {Colors, Routes} from '../../configs';
import ScrollableTab from '../../components/ScrollableTab';
import {getBottomSpace, getStatusBarHeight} from 'react-native-iphone-x-helper';
import Container from '../../components/Layout/Container';
import {useHistory} from "react-router-dom";
import {api, helpers, dateTimeService} from '../../import';
import locales_es from '../../locales/es';
import {showModalInfo} from '../../modules/ViewHelpers';
import {CommonStyles} from '../../styles/CommonStyles';
import PrescriptionsList from './../../components/PrescriptionsList';
import {ConsultsType, width} from '../../configs/Const';
import ButtonIcon from '../../components/Buttons/ButtonIcon';
import {EVENT_RELOAD_CERTIFICATES, EVENT_SHOW_MODAL_ACTION, EVENT_SHOW_MODAL_INFO} from '../../constants/Events';
import {EventRegister} from "../../modules/EventRegister";
import Layout from "../../components/Layout/Layout";
import HeaderButton from "../../components/HeaderButton";
import SearchBox from "../../components/Home/SearchBox";
import Overlay from '../../components/Overlay';
import SearchPatients from "../SearchPatients";
import SearchPatientCertificates from '../SearchPatientCertificates';
import useSearchPatientCertificates from '../SearchPatientCertificates/shared/useSearchPatientCertificates';
import { PRACTICES_PERMISSION } from '../../models/constants';

interface CertificatesProps {
}

const Certificates = (props: CertificatesProps) => {
  const history = useHistory();
  const [load, setLoad] = useState(false);
  const [overlay, setOverlay] = useState(false);
  const [todayCertificates, setTodayCertificates] = useState(null);
  const [pastCertificates, setPastCertificates] = useState(null);
  const [keySearch, setKeySearch] = useState('');
  const [patients, setPatients] = useState(null);

  const [nextPageUrlToday, setNextPageUrlToday] = useState('');
  const [refreshingToday, setRefreshingToday] = useState(false);
  const [totalToday, setTotalToday] = useState(0);

  const [nextPageUrlPast, setNextPageUrlPast] = useState('');
  const [refreshingPast, setRefreshingPast] = useState(false);
  const [totalPast, setTotalPast] = useState(0);

  const {
    searchResults,
    onLoadMore,
    total,
    hasMore,
    isLoading
  } = useSearchPatientCertificates({keySearch: keySearch});

  const loadTodayCertificates = (pageUrl: string) => {
    api
      .getMyCertificatesPaginated(
        {
          from: dateTimeService.parseDateToAPIString(new Date()),
        },
        pageUrl,
      )
      .then((res: any) => {
        setTodayCertificates(
          todayCertificates ? todayCertificates.concat(res.data) : res.data,
        );
        setNextPageUrlToday(res.next_page_url);
        setRefreshingToday(false);
        setTotalToday(res.total);
      })
      .catch((err: any) => {
        helpers.processError(err);
        setRefreshingToday(false);
      });
  };

  const loadPastCertificates = (pageUrl: string) => {
    api
      .getMyCertificatesPaginated(
        {
          to: dateTimeService.parseDateToAPIString(new Date()),
        },
        pageUrl,
      )
      .then((res: any) => {
        setPastCertificates(
          pastCertificates ? pastCertificates.concat(res.data) : res.data,
        );
        setNextPageUrlPast(res.next_page_url);
        setRefreshingPast(false);
        setTotalPast(res.total);
      })
      .catch((err: any) => {
        helpers.processError(err);
        setRefreshingPast(false);
      });
  };

  const loadPatients = () => {
    api
      .getMyPatients()
      .then((res: any) => {
        setPatients(res.data);
      })
      .catch((err: any) => {
        helpers.processError(err);
      });
  };

  const onLoadMoreTodayCertificates = () => {
    if (nextPageUrlToday && !refreshingToday) {
      setRefreshingToday(true);
      loadTodayCertificates(nextPageUrlToday);
    }
  };
  const onLoadMorePastCertificates = () => {
    if (nextPageUrlPast && !refreshingPast) {
      setRefreshingPast(true);
      loadPastCertificates(nextPageUrlPast);
    }
  };

  const loadPermissions = () => {
    return new Promise((resolve) => {
      api.getPermissionsMe().then((res: any) => {
        resolve(res);
      }).catch((err: any) => {
        helpers.processError(err);
      })
    })
  }

  const confirmPermissions = () => {
    history.goBack();
  }

  useEffect(() => {
    loadPermissions().then((res: any) => {
      if (res && res.some((perm: any) => perm.tag === PRACTICES_PERMISSION)) {
        loadTodayCertificates();
        loadPastCertificates();
        loadPatients();
        return;
      }
      EventRegister.emit(EVENT_SHOW_MODAL_INFO, {
        title: locales_es.modalPermission.title,
        message: locales_es.modalPermission.description,
        actions: [{text: 'Aceptar', onPress: confirmPermissions, white: true}]
      });
    });
  }, [load]);

  useEffect(() => {
    const listener = EventRegister.addEventListener(
      EVENT_RELOAD_CERTIFICATES,
      data => {
        loadTodayCertificates();
        loadPastCertificates();
      },
    );

    return function cleanup() {
      EventRegister.removeEventListener(listener);
    };
  });

  const renderSpinner = () => {
    return (
      <View style={{flex: 1, width: width}}>
        <ActivityIndicator style={CommonStyles.activityIndicator}/>
      </View>
    );
  };

  const onPencilPress = useCallback(() => {
    history.push(`/${Routes.AddCertificate}`);
  }, []);

  return (
    <>
      {/* {overlay && <Overlay>{overlay}</Overlay>} */}
      <Layout style={styles.header}>
        <HeaderButton
          style={{
            marginTop: 26,
            marginLeft: 24,
          }}
          onPress={() => history.replace(Routes.Home)}
        />
        {patients && (
          <SearchBox
            onClear={() => setKeySearch('')}
            placeholder={locales_es.searchPatient + '...'}
            value={keySearch}
            onChangeText={setKeySearch}
            shadow={false}
            style={{
              marginHorizontal: 24,
              marginBottom: 12,
              flex: 1,
            }}
          />
        )}
      </Layout>
     { keySearch.length? 
      
    <SearchPatientCertificates keySearch={keySearch}/>

              : 
      <Container style={styles.container}>
        <ScrollableTab
          titles={['Nuevos', 'Anteriores']}
        >
          {todayCertificates === null ? (
            renderSpinner()
          ) : (
            <PrescriptionsList
              prescriptions={todayCertificates}
              emptyText={'No tienes prescripciones generadas hoy'}
              title={'Prescripciones hoy'}
              type={ConsultsType.Certificate}
              refreshing={refreshingToday}
              onLoadMore={onLoadMoreTodayCertificates}
              total={totalToday}
            />
          )}
          {pastCertificates === null ? (
            renderSpinner()
          ) : (
            <PrescriptionsList
              prescriptions={pastCertificates}
              emptyText={'No tienes prescripciones pasadas'}
              title={'Prescripciones pasadas'}
              type={ConsultsType.Certificate}
              refreshing={refreshingPast}
              onLoadMore={onLoadMorePastCertificates}
              total={totalPast}
            />
          )}
        </ScrollableTab>

        <ButtonIcon
          icon={'plus'}
          style={styles.filterView}
          imgStyle={styles.buttonIconPlusImage}
          tintColor="#fff"
          onPress={onPencilPress}
          text={locales_es.createCertificate}
        />
      </Container>}
    </>
  );
};

export default Certificates;

const styles = StyleSheet.create({
  header: {
    paddingTop: Platform.OS === 'ios' ? getStatusBarHeight() : 0,
    ...Theme.flexRowSpace,
    paddingBottom: 10,
  },
  container: {
    flex: 1,
  },
  filterView: {
    position: 'absolute',
    alignSelf: 'center',
    bottom: getBottomSpace() + 16,
    // right: 24,
    backgroundColor: Colors.BlueCrayola,
    // width: 200,
    height: 56,
    borderRadius: 16,
  },
  buttonIconPlusImage: {
    width: 24,
    height: 24,
  },
});
