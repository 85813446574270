import React from "react";
import { View, StyleSheet, ActivityIndicator, Platform } from "react-native";
import Theme from "../../../style/Theme";
import { Colors } from "../../../configs";
import ScrollableTab from "../../../components/ScrollableTab";
import {
  getBottomSpace,
  getStatusBarHeight,
} from "react-native-iphone-x-helper";
import Container from "../../../components/Layout/Container";
import locales_es from "../../../locales/es";
import { CommonStyles } from "../../../styles/CommonStyles";
import PrescriptionsList from "../../../components/PrescriptionsList";
import { ConsultsType, width } from "../../../configs/Const";
import Layout from "../../../components/Layout/Layout";
import HeaderButton from "../../../components/HeaderButton";
import usePatientCertificatesPage from "../shared/usePatientCertificatesPage";

interface PatientCertificatesProps {}

const PatientCertificates = (props: PatientCertificatesProps) => {
  const {certificates, refreshing, onLoadMore,total } = usePatientCertificatesPage()

  const renderSpinner = () => {
    return (
      <View style={{ flex: 1, width: width }}>
        <ActivityIndicator style={CommonStyles.activityIndicator} />
      </View>
    );
  };

  return (
    <>
      <Layout style={styles.header}>
        <HeaderButton
          style={{
            marginTop: 26,
            marginLeft: 24,
          }}
        />
      </Layout>
      <Container style={styles.container}>
        <ScrollableTab titles={[locales_es.otherPrescriptions]}>
          {certificates === null ? (
            renderSpinner()
          ) : (
            <PrescriptionsList
              prescriptions={certificates}
              emptyText={"No tiene prescripciones"}
              title={"Prescripciones generadas"}
              refreshing={refreshing}
              onLoadMore={onLoadMore}
              total={total}
              type={ConsultsType.Certificate}
            />
          )}
        </ScrollableTab>
      </Container>
    </>
  );
};

export default PatientCertificates;

const styles = StyleSheet.create({
  header: {
    paddingTop: Platform.OS === "ios" ? getStatusBarHeight() : 0,
    ...Theme.flexRowSpace,
    paddingBottom: 10,
  },
  container: {
    flex: 1,
  },
  filterView: {
    position: "absolute",
    alignSelf: "center",
    bottom: getBottomSpace() + 16,
    // right: 24,
    backgroundColor: Colors.BlueCrayola,
    // width: 200,
    height: 56,
    borderRadius: 16,
  },
});
