import React, {SetStateAction, Dispatch} from 'react';
import {
  View,
  TextInput,
  StyleSheet,
  ViewStyle,
  TouchableOpacity,
  Platform,
  ColorValue,
} from 'react-native';
import {Colors} from '../../configs';
import Theme from '../../style/Theme';
import Text from '../../components/Text';
import {useTheme} from '../../configs/Theme';

interface Props {
  testID?: string;
  forwardRef: any;
  value: string;
  onChangeText?: (text: string) => void | Dispatch<SetStateAction<string>>;
  placeholder?: string;
  isShowIcon?: boolean;
  icon?: any;
  secureTextEntry?: boolean;
  style?: ViewStyle;
  borderColor?: ColorValue | string;
  iconPress?: () => void;
  backgroundColor?: string;
  iconLeft?: any;
  isShowIconLeft?: boolean;
  iconPressLeft?: () => void;
  multiline?: boolean;
  editable?: boolean;
  disabled?: boolean;
  onPress?: () => void;
  maxLength?: number;
  onFocus?: () => void;
  onBlur?: () => void;

}

export default ({
  testID,
  forwardRef,
  value,
  placeholder,
  onChangeText,
  isShowIcon,
  icon,
  secureTextEntry,
  style,
  borderColor,
  backgroundColor,
  iconPress,
  isShowIconLeft,
  iconLeft,
  iconPressLeft,
  onPress,
  onBlur,
  onFocus,
  ...props
}: Props) => {
  const {theme} = useTheme();
  /*let [fontsLoaded] = useFonts({
    Muli_600SemiBold,
  });
  if (!fontsLoaded) {
    return <View />;
  }*/

  let lineHeight;
  if (props.multiline) {
    lineHeight = 24;
  }

  return (
    <View
      style={[
        styles.container,
        {
          borderColor: borderColor || theme.innearColor,
          borderWidth: 1,
          backgroundColor: theme.searchBox,
        },
        style,
        props.multiline && styles.muli,
      ]}>
      {isShowIconLeft && !!iconLeft && (
        <TouchableOpacity
          style={styles.iconLeftView}
          onPress={iconPressLeft}
          disabled={!iconPressLeft}>
          {iconLeft}
        </TouchableOpacity>
      )}
      {props.editable ? (
        <TextInput
          onBlur={onBlur}
          onFocus={onFocus}
          testID={testID}
          ref={forwardRef}
          placeholder={placeholder}
          value={value}
          onChangeText={onChangeText}
          style={{
            flex: 1,
            width: '100%',
            fontSize: 15,
            lineHeight: lineHeight,
            fontWeight: 600,
            height: '100%',
            color: theme.text,
            textAlignVertical: 'top',
          }}
          secureTextEntry={secureTextEntry}
          editable={props.editable}
          placeholderTextColor={Colors.GrayBlue}
          {...props}
          maxLength={props.maxLength ? props.maxLength : null}
        />
      ) : (
        <Text
          size={15}
          lineHeight={24}
          semibold
          style={{
            flex: 1,
            color: props.disabled ? theme.textDisabled : theme.text,
          }}
          onPress={onPress}>
          {value}
        </Text>
      )}
      {isShowIcon && !!icon && (
        <TouchableOpacity
          style={styles.iconView}
          onPress={iconPress}
          disabled={!iconPress}>
          {icon}
        </TouchableOpacity>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    minHeight: 48,
    justifyContent: 'center',
    paddingHorizontal: 16,
    borderRadius: 8,
    ...Theme.flexRow,
  },
  iconView: {
    width: 24,
    height: 24,
    position: 'absolute',
    right: 12,
    ...Theme.center,
  },
  iconLeftView: {
    width: 24,
    height: 24,
    ...Theme.center,
    marginRight: 16,
  },
  muli: {
    ...Platform.select({
      ios: {
        paddingBottom: 11,
      },
    }),
  },
});
