import React, {Component} from 'react';
import Input from './../formInput';
import FormSelect from "../formSelect";
import locales_es from "../../locales/es";
import FormNewDatePicker from "../formNewDatePicker";
import {HREF_PAGE_TERMS} from "../../models/constants";
import Select, { components } from 'react-select'

const NoOptionsMessage = props => {
    return (
      <components.NoOptionsMessage {...props}>
          <span className="custom-css-class">{locales_es.searchNotFound}</span>
      </components.NoOptionsMessage>
    );
};

export class Form extends Component {
    render() {
        const {handleChange, handleDateChange, handleReactSelectChange, onSubmit, onSubmitButtonText, inputs, showTerms} = this.props;
        return (
            <>
                {this.props.children}
                <>
                    <form onSubmit={(ev) => {
                        ev.preventDefault();
                        onSubmit && onSubmit();
                    }} className={this.props.style}>
                        <>
                            {inputs.map((input) => {

                                return input.visible !== false ? (
                                    
                                    input.type === 'select' ?
                                     <>
                                        <FormSelect
                                            value={input.value === null ? '' : input.value}
                                            label={input.label}
                                            type={input.type}
                                            key={input.id}
                                            id={input.id}
                                            required={input.required}
                                            disabled={input.disabled}
                                            options={input.options}
                                            wrapperCustomClassName={input.wrapperCustomClassName}
                                            customClassName={input.customClassName}
                                            handleChange={handleChange(input.state)}
                                            selectedValue={input.selectedValue}
                                            primaryAction={input.primaryAction}
                                            secondaryAction={input.secondaryAction}
                                            onBlur={input.onBlur}
                                            validate={input.validate}
                                            errorMessage={input.errorMessage}
                                            error={input.error}
                                            
                                            />
                                       
                                        </>
                                        : input.type === 'react-select' ?
                                        <>
                                        <div key={input.id} className={input.wrapperCustomClassName}>
                                            <label>{input.label} {input.required ? '*' : ''}</label><br/>
                                            <Select
                                                options={input.options}
                                                className={input.customClassName}
                                                classNamePrefix="select"
                                                value={input.value}
                                                isDisabled={input.disabled}
                                                isLoading={false}
                                                isClearable={false}
                                                isSearchable={true}
                                                name={input.id}
                                                placeholder={locales_es.select + '...'}
                                                onChange={handleReactSelectChange(input.state)}
                                                components={{ NoOptionsMessage }}
                                                onBlur={input.onBlur}
                                                validate={input.validate}
                                                errorMessage={input.errorMessage}
                                                error={input.error}
                                                />
                                            {this.props.advice &&
                                            <span className="form-text text-muted">{this.props.advice}</span>
                                            }
                                       
                                        </div>
                                                </>
                                        : input.type === 'date' ?
                                            <FormNewDatePicker
                                                value={input.value === null ? '' : input.value}
                                                label={input.label}
                                                placeholder={input.placeholder}
                                                type={input.type}
                                                key={input.id}
                                                id={input.id}
                                                required={input.required}
                                                disabled={input.disabled}
                                                wrapperCustomClassName={input.wrapperCustomClassName}
                                                customClassName={input.customClassName}
                                                handleChange={handleDateChange(input.state)}
                                                minDate={input.minDate}
                                                maxDate={input.maxDate}
                                                onBlur={input.onBlur}
                                                validate={input.validate}
                                                hideCalendar={input.hideCalendar}
                                                errorMessage={input.errorMessage}
                                                error={input.error}
                                            />
                                            
                                            :
                                            input.type === 'number' ?
                                            <>
                                                <Input
                                                    value={input.value === null ? '' : input.value}
                                                    label={input.label}
                                                    placeholder={input.placeholder}
                                                    type={input.type}
                                                    key={input.id}
                                                    id={input.id}
                                                    required={input.required}
                                                    disabled={input.disabled}
                                                    step={input.step}
                                                    min={input.min}
                                                    max={input.max}
                                                    wrapperCustomClassName={input.wrapperCustomClassName}
                                                    customClassName={input.customClassName}
                                                    handleChange={handleChange(input.state)}
                                                    onBlur={input.onBlur}
                                                    validate={input.validate}
                                                    errorMessage={input.errorMessage}
                                                    error={input.error}
                                                    />
                                               
                                                    </>
                                                :
                                                input.type === 'tel' ?
                                                <>
                                                        <Input
                                                            value={input.value === null ? '' : input.value}
                                                            label={input.label}
                                                            placeholder={input.placeholder}
                                                            type={input.type}
                                                            key={input.id}
                                                            id={input.id}
                                                            required={input.required}
                                                            disabled={input.disabled}
                                                            step={input.step}
                                                            min={input.min}
                                                            max={input.max}
                                                            wrapperCustomClassName={input.wrapperCustomClassName}
                                                            customClassName={input.customClassName}
                                                            handleChange={handleChange(input.state)}
                                                            onBlur={input.onBlur}
                                                            validate={input.validate}
                                                            errorMessage={input.errorMessage}
                                                            error={input.error}
                                                            >
                                                            {input.children}
                                                        </Input>
                                                       
                                                    </>
                                                    :
                                                    <>
                                                    <Input
                                                        value={input.value === null ? '' : input.value}
                                                        label={input.label}
                                                        placeholder={input.placeholder}
                                                        type={input.type}
                                                        key={input.id}
                                                        id={input.id}
                                                        required={input.required}
                                                        disabled={input.disabled}
                                                        wrapperCustomClassName={input.wrapperCustomClassName}
                                                        customClassName={input.customClassName}
                                                        handleChange={handleChange(input.state)}
                                                        advice={input.advice}
                                                        onBlur={input.onBlur}
                                                        validate={input.validate}
                                                        errorMessage={input.errorMessage}
                                                        error={input.error}
                                                        maxLength={input.maxLength ? input.maxLength : undefined}
                                                        />
                                                    
                                                   
                                                    
                                                        </>
                                                    
                                ) : null;
                                }
                            )
                            }
                        </>
                        {showTerms ?
                            <div className="form-group" style={{clear: 'both'}}>
                                <label className="kt-checkbox kt-checkbox--bold kt-checkbox--brand">
                                    <input id="inputCheckTerms" className="form-control"
                                           required={true} type="checkbox" defaultChecked/>
                                    <a href={HREF_PAGE_TERMS} target="_blank">
                                        {locales_es.iAcceptTermsAndConditions}
                                    </a>
                                    <span></span>
                                </label>
                            </div>
                            : null}
                        <div
                            className="clear flex-wrap justify-content-center justify-content-md-around">
                            {this.props.onSubmit &&
                            <button type="submit"
                                    className="btn btn-primary">{onSubmitButtonText}</button>
                            }
                            {this.props.secondaryButtonText && this.props.onClickSecondaryButton &&
                            <a href="#click" onClick={(e) => {
                                e.preventDefault();
                                this.props.onClickSecondaryButton();
                            }}
                               style={{marginRight: '10px'}}
                               className={"kt-link kt-link--brand m-3 " + this.props.secondaryButtonStyle}>
                                {this.props.secondaryButtonText}
                            </a>
                            }
                        </div>
                    </form>
                </>
            </>
        )
    }
}

export default Form
