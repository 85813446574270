import React from 'react';
import { View, Text, StyleSheet } from 'react-native';
import { width } from '../../configs/Const';

const EnvironmentLabel = () => {
  const env = process.env.REACT_APP_ENV;
  let label = '';
  let backgroundColor = '';
  let textColor = '';

  if (env === 'qa' || env === 'dev' || env === 'hml') {
    console.log('EnvironmentLabel', {env})
  }

  switch(env) {
    case 'dev':
      label = 'Ambiente DEV';
      backgroundColor = 'rgb(204, 255, 204)';
      textColor = 'green';
      break;
    case 'qa':
      label = 'Ambiente QA';
      backgroundColor = 'rgb(255, 238, 204)';
      textColor = 'orange';
      break;
    case 'hml':
      label = 'Ambiente HML';
      backgroundColor = 'rgb(255, 204, 204)';
      textColor = 'red';
      break;
  }

  return (
    <View style={[styles.labelContainer, { backgroundColor }]}>
      <Text style={[styles.labelText, { color: textColor }]}>{label}</Text>
    </View>
  );
};

const styles = StyleSheet.create({
  labelContainer: {
    padding: 5,
    position: 'absolute',
    zIndex: 100,
    width:'153px',
    borderRadius: 6,
    top: 15,
    left: -29,
    transform: [{ rotate: '-34deg' }],
  },
  labelText: {
    fontWeight: 'bold',
    textAlign: 'center', // Center align the text
  }
});

export default EnvironmentLabel;
