import React, {useState, useEffect} from 'react';
import {Routes} from '../../../configs';
import FeatureItem from '../../../components/FeatureItem';
import locales_es from '../../../locales/es';
import {
  MED_PRESCRIPTION_REQ_SERVICE_TAG,
  MED_CERTIFICATES_TAG,
} from '../../../models/constants';
import TranslationService from '../../../locales';

import imgPrescriptions from '../../../images/Home/img_consult_history.png';
import imgMyPatients from '../../../images/Home/img_patient_management.png';
import imgOtherPrescriptions from '../../../images/Home/img_other_prescriptions.png';
import imgConfiguration from '../../../images/Home/img_config.png';
import imgPrescriptionRequests from '../../../images/Home/img_free_question.png';

const _mainFeatures = [
  {
    img: imgPrescriptions,
    title: locales_es.menu.myPrescriptions,
    route: Routes.Prescriptions,
  },
  {
    img: imgMyPatients,
    title: locales_es.menu.myPatients,
    route: Routes.MyPatients,
  },
  {
    tag: MED_CERTIFICATES_TAG,
    img: imgOtherPrescriptions,
    title: locales_es.menu.otherPrescriptions,
    route: Routes.Certificates,
  },
  {
    img: imgConfiguration,
    title: locales_es.menu.configuration,
    route: Routes.Configuration,
    // numberNew: 1004,
  },
];

const MainControl = props => {
  const translationService = new TranslationService();
  const [mainFeatures, setMainFeatures] = useState(_mainFeatures);

  useEffect(() => {
    translationService.translate().then(translation => {
      if (translation && translation.menu) {
        const _features = JSON.parse(JSON.stringify(mainFeatures));
        _features.map((feature: any) => {
          if (
            feature.tag &&
            feature.tag === MED_CERTIFICATES_TAG &&
            translation.menu.otherPrescriptions
          ) {
            feature.title = translation.menu.otherPrescriptions;
          }
          return feature;
        });
        setMainFeatures(_features);
      }
    });
  }, []);

  const renderServices = () => {
    const _services = JSON.parse(JSON.stringify(props.services));
    const rendered = _services.map((service: any) => {
      if (service.tag === MED_PRESCRIPTION_REQ_SERVICE_TAG) {
        service.img = imgPrescriptionRequests;
        service.route = Routes.PrescriptionsRequests;
        return (
          <FeatureItem {...service} title={service.name} key={service.name} />
        );
      }
    });
    return rendered;
  };

  return (
      <div className="rcta-main-control">
          {mainFeatures && mainFeatures.length
            ? mainFeatures.map(item => <FeatureItem {...item} key={item.title} />)
            : null}
          {props.services && props.services.length ? renderServices() : null}
      </div>
  );
};

export default MainControl;