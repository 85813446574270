import React, {memo, useCallback} from 'react';
import {
  View,
  StyleSheet,
  Image,
  ImageSourcePropType,
  ViewStyle,
  TouchableOpacity,
} from 'react-native';
import Text from '../../components/Text';
import Theme from '../../style/Theme';
import Layout from '../../components/Layout/Layout';
import {useTheme} from '../../configs/Theme';
import {useNavigation} from '@react-navigation/native';
import Routes from '../../configs/Routes';
import {renderAge} from '../../modules/ViewHelpers';
import locales_es from '../../locales/es';
import {SOURCE_ICON} from '../../images';

interface MedicineItemProps {
  item: any;
  style?: ViewStyle;
  dark?: boolean;
  onPress?: () => void;
}

const MedicineItem = memo(({item, style, dark, onPress}: MedicineItemProps) => {
  const {theme} = useTheme();
  return (
    <TouchableOpacity onPress={onPress}>
      <Layout
        style={[
          styles.container,
          style,
          {
            borderColor: theme.lineColor,
            backgroundColor: dark ? theme.userInfo : theme.backgroundItem,
          },
        ]}>
        <View style={Theme.flexOne}>
          <Text size={15} lineHeight={18} bold marginBottom={8}>
            {item.commercial_name}
          </Text>
          <View style={{...Theme.flexRow, marginBottom: 8}}>
            <Text size={13} lineHeight={16} semibold marginRight={18}>
              {item.drug}
            </Text>
          </View>
        </View>
        <View style={Theme.flexRow}>
          <Image source={SOURCE_ICON.arrowRight} />
        </View>
      </Layout>
    </TouchableOpacity>
  );
});

export default MedicineItem;

const styles = StyleSheet.create({
  container: {
    padding: 24,
    marginHorizontal: 6,
    marginBottom: 12,
    borderRadius: 16,
    ...Theme.shadow,
    ...Theme.flexRow,
  },
});
