import {width} from '../../configs/Const';
import React, {memo} from 'react';
import {
  SafeAreaView,
  View,
  StyleSheet,
  TouchableOpacity,
  ScrollView,
  ViewStyle,
} from 'react-native';
import {getBottomSpace, getStatusBarHeight} from 'react-native-iphone-x-helper';
import Theme from '../../style/Theme';
import Text from '../../components/Text';
import {Colors} from '../../configs';
import Layout from '../../components/Layout/Layout';
import {useTheme} from '../../configs/Theme';
import Line from '../../components/Layout/Line';

interface ModalSelectProps {
  close: () => void;
  choices: { id: number; name: string; color?: string }[];
  customChoice?: { name: string; onPress: () => void, disabled?: boolean };
  customEditCombo?: { name: string; onPress: () => void };
  onPressItem: (item: any) => void;
  style?: ViewStyle;
}

const ModalSelect = memo(
  ({close, choices, onPressItem, customChoice,customEditCombo, style}: ModalSelectProps) => {
    const {theme} = useTheme();
    return (
      <SafeAreaView style={styles.container}>
        <TouchableOpacity
          style={StyleSheet.absoluteFillObject}
          onPress={close}
        />
        <View
          style={{
            paddingTop: getStatusBarHeight() + 80,
            paddingBottom: getBottomSpace() + 12,
            width: style ? style.width : width,
            zIndex: 10,
            paddingHorizontal: 16,
            marginLeft: style && style.marginLeft,
            marginRight: style && style.marginRight,
            marginBottom: style && style.marginBottom,
            alignSelf: style ? style.alignSelf : ''
          }}>
          <ScrollView style={[styles.topBorder, styles.bottomBorder]}
                      contentContainerStyle={{maxHeight: 300, overflow: 'scroll'}}>
            {choices &&
              choices.map((item, index) => {
                const onPress = () => {
                  onPressItem && onPressItem(item);
                  close();
                };
                return (
                  <View testID={item.name} key={item.id || item.name}>
                    <TouchableOpacity
                      activeOpacity={0.54}
                      style={[
                        styles.selectItem,
                        index === 0 && styles.topBorder,
                        index === choices.length - 1 && styles.bottomBorder,
                        {backgroundColor: theme.backgroundItem},
                      ]}
                      {...{onPress}}>
                      <Text
                        color={item.color && item.color}
                        size={15}
                        lineHeight={18}
                        center
                        semibold>
                        {item.name}
                      </Text>
                    </TouchableOpacity>
                    {index !== choices.length - 1 ? <Line/> : null}
                  </View>
                );
              })}
          </ScrollView>
          {customChoice && (
            <Layout
              style={[
                styles.selectItem,
                styles.topBorder,
                styles.bottomBorder,
                {marginTop: 24},
              ]}>
              <TouchableOpacity
                style={{...StyleSheet.absoluteFillObject, ...Theme.center, opacity: customChoice.disabled ? 0.5 : 1}}
                onPress={customChoice.onPress}
                activeOpacity={0.54}
                disabled={customChoice.disabled}
                >
                <Text size={15} lineHeight={18} semibold color={Colors.Black}>
                  {customChoice.name}
                </Text>
              </TouchableOpacity>
            </Layout>
          )}
          {customEditCombo && (
            <Layout
              style={[
                styles.selectItem,
                styles.topBorder,
                styles.bottomBorder,
                {marginTop: 24},
              ]}>
              <TouchableOpacity
                style={{...StyleSheet.absoluteFillObject, ...Theme.center}}
                onPress={customEditCombo.onPress}
                activeOpacity={0.54}>
                <Text size={15} lineHeight={18} semibold color={Colors.Black}>
                  {customEditCombo.name}
                </Text>
              </TouchableOpacity>
            </Layout>
          )}

          <Layout
            style={[
              styles.selectItem,
              styles.topBorder,
              styles.bottomBorder,
              {marginTop: 24},
            ]}>
            <TouchableOpacity
              style={{...StyleSheet.absoluteFillObject, ...Theme.center}}
              onPress={close}
              activeOpacity={0.54}>
              <Text size={15} lineHeight={18} semibold color={Colors.GrayBlue}>
                Cancelar
              </Text>
            </TouchableOpacity>
          </Layout>
          <Line width={'90%'}/>
        </View>
      </SafeAreaView>
    );
  },
);

export default ModalSelect;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#00000054',
    justifyContent: 'flex-end',
  },
  selectItem: {
    minHeight: 50,
    ...Theme.center,
    paddingHorizontal: 10,
  },
  topBorder: {
    borderTopLeftRadius: 16,
    borderTopRightRadius: 16,
  },
  bottomBorder: {
    borderBottomLeftRadius: 16,
    borderBottomRightRadius: 16,
  },
});
