import {LS_LOCAL_AUTH} from '../models/constants';

export default class LocalAuthService {
  constructor() {
    this.defaultConfig = false;
  }

  async setConfig(bool) {
    await localStorage.setItem(LS_LOCAL_AUTH, JSON.stringify(bool));
  }

  async clearData() {
    await localStorage.removeItem(LS_LOCAL_AUTH);
  }

  async getConfig() {
    try {
      const value = await localStorage.getItem(LS_LOCAL_AUTH);
      const result = value !== null ? JSON.parse(value) : this.defaultConfig;
      return result;
    } catch (error) {
      // Error retrieving data
      console.log(error);
      throw new Error(error);
    }
  }

  toggleConfig() {
    return this.getConfig()
      .then(res => {
        const newValue = !res;
        this.setConfig(newValue);
        return newValue;
      })
      .catch(err => {
        console.log(err);
      });
  }
}
