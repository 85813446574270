import React, { Component ,useState} from "react";
import {StyleSheet, View, ActivityIndicator} from "react-native";
import RequireIndicator from "../../components/RequireIndicator";
import GenderItem from "../../components/UpdateProfile/BasicInformation/GenderItem";
import Theme from "../../style/Theme";
import Header from "../../components/header";
import Footer from "../../components/footer";
import locales_es from "../../locales/es";
import Form from "../../components/form";
import APIService from "../../modules/apiService";
import Helpers from "../../modules/helpers";
import SearchMedicines from "../../components/searchMedicines";
import InputSpinner from "../../components/inputSpinner";
import Colors from "../../configs/Colors";
import ConfigService from "../../modules/configService";
import { showModalInfo } from "../../modules/ViewHelpers";
import Text from "../../components/Text";
import { CommonStyles } from "../../styles/CommonStyles";

export default class PrescriptionRequestPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      name: "",
      lastname: "",
      email: "",
      phoneNumber: "",
      identification: "",
      identificationTypeId: "1",
      medicalInsuranceId: "",
      medicalInsurancePlan: "",
      medicalInsuranceNumber: "",
      medicalInsuranceName: "",
      medicId: { id: "" },
      comment: "",
      medicines: [],
      errors: [],
      sentRequest: false,
      visible: false,
      modalTitle: "",
      modalContent: "",
      loadingConfig: true,
      clinicData: {},
      identificationOptions: [],
      medicalInsurancesOptions: [],
      clinicMedics: [],
      prescriptionProfile: {},
      gender:'',
      gendersList: [],
      amount: 0,
      amountFormatted: ''
    };


    this.nroFinanciador = 0;

    this.api = new APIService();
    this.helpers = new Helpers();
    this.configService = new ConfigService();
  }



  componentDidMount() {
    // Oculta Intercom mediante CSS
    if (window && window.document) {
      window.document.body.classList.add("rcta-hide-intercom");
    }

    this.load();
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.clinicData.hasMedicPaylinkEnabled && this.state.medicId.id !== prevState.medicId.id) {
      this.api.getPrescriptionsProfiles(this.state.medicId.id).then((res5) => {
        const prescriptionProfiles = res5.data;
        const [paymentAccount] = prescriptionProfiles.filter(
          (item) => (item.active_charger_account === 1 && (item.clinic_id == this.state.clinicData.id))
        );
        this.setState({
          prescriptionProfile: paymentAccount,
          amount: (this.state.clinicData && this.state.clinicData.hasPaylink) ? this.state.clinicData.paylink_amount : paymentAccount.charger_accounts.amount,
          amountFormatted: (this.state.clinicData && this.state.clinicData.hasPaylink) ? this.formatAmount(this.state.clinicData.paylink_amount, 2, ',', '.') : this.formatAmount(paymentAccount.charger_accounts.amount, 2, ',', '.')
        });
      });
    }
  }

  formatAmount(number, decimals, decPoint, thousandsSep) {

    number = (number + '').replace(/[^0-9+\-Ee.]/g, '')
    const n = !isFinite(+number) ? 0 : +number
    const prec = !isFinite(+decimals) ? 0 : Math.abs(decimals)
    const sep = typeof thousandsSep === 'undefined' ? ',' : thousandsSep
    const dec = typeof decPoint === 'undefined' ? '.' : decPoint
    let s = ''

    const toFixedFix = function (n, prec) {
      if (('' + n).indexOf('e') === -1) {
        return +(Math.round(n + 'e+' + prec) + 'e-' + prec)
      } else {
        const arr = ('' + n).split('e')
        let sig = ''
        if (+arr[1] + prec > 0) {
          sig = '+'
        }
        return (+(Math.round(+arr[0] + 'e' + sig + (+arr[1] + prec)) + 'e-' + prec)).toFixed(prec)
      }
    }

    // @todo: for IE parseFloat(0.55).toFixed(0) = 0;
    s = (prec ? toFixedFix(n, prec).toString() : '' + Math.round(n)).split('.')
    if (s[0].length > 3) {
      s[0] = s[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, sep)
    }
    if ((s[1] || '').length < prec) {
      s[1] = s[1] || ''
      s[1] += new Array(prec - s[1].length + 1).join('0')
    }

    return s.join(dec)
  }

  load() {
    this.api
    .getGenders()
    .then((res) => {
      const data = res.data
      this.setState({gendersList: data, gender: data[2]})
    })
    .catch((err) => {
      this.props.showMainModal(
        locales_es.errorModal.title,
        this.helpers.getErrorMsg(err)
      );
    });

    this.configService
      .getLocalClinicData()
      .then((res) => {
        this.setState(
          {
            clinicData: {
              id: res.id,
              countryId: res.country_id,
              name: res.name,
              logo: res.full_logo_image,
              hasPaylink: (res.paylink_enabled && res.paylink_url),
              hasMedicPaylinkEnabled: (res.paylink_enabled && !res.paylink_url),
              paylink_enabled: res.paylink_enabled,
              paylink_url: res.paylink_url,
              paylink_reason: res.paylink_reason,
              paylink_amount: res.paylink_amount
            },
            amount: (res.paylink_enabled && res.paylink_url) ? res.paylink_amount :
                (this.state.prescriptionProfile && this.state.prescriptionProfile.charger_accounts) ? this.state.prescriptionProfile.charger_accounts.amount : 0,
            amountFormatted: (res.paylink_enabled && res.paylink_url) ? this.formatAmount(res.paylink_amount, 2, ',', '.') :
            (this.state.prescriptionProfile && this.state.prescriptionProfile.charger_accounts) ? this.formatAmount(this.state.prescriptionProfile.charger_accounts.amount, 2, ',', '.') : ''
          },
          () => {
            this.api
              .getIdentificationTypesByCountryId(
                this.state.clinicData.countryId
              )
              .then((res2) => {
                this.setState(
                  {
                    identificationOptions: res2.data,
                  },
                  () => {
                    this.api
                      .getMedicalInsurances()
                      .then((res3) => {
                        if (res3.data.length > 0) {
                          res3.data[0].name = 'Sin cobertura';
                        }
                        this.setState(
                          {
                            medicalInsurancesOptions: res3.data.map((item) => {
                              item.label = item.name;
                              item.value = item.id;
                              return item;
                            }),
                          },
                          () => {
                            this.api
                              .getMedicsByClinic(this.state.clinicData.id)
                              .then((res4) => {
                                this.setState(
                                  {
                                    clinicMedics: res4.data.map((item) => {
                                      //TODO implementar item.professional_prefix_id
                                      item.label = `${item.name} ${item.lastname}`;
                                      item.value = item.id;
                                      return item;
                                    }),
                                  },
                                  () => {
                                    this.props.hideMainLoading();
                                    this.props.parseTelInputs();
                                  }
                                );
                              })
                              .catch((err) => {
                                // this.props.hideMainLoading();
                                this.props.showMainModal(
                                  locales_es.errorModal.title,
                                  this.helpers.getErrorMsg(err)
                                );
                              });
                          }
                        );
                      })
                      .catch((err) => {
                        // this.props.hideMainLoading();
                        this.props.showMainModal(
                          locales_es.errorModal.title,
                          this.helpers.getErrorMsg(err)
                        );
                      });
                  }
                );
              })
              .catch((err) => {
                // this.props.hideMainLoading();
                this.props.showMainModal(
                  locales_es.errorModal.title,
                  this.helpers.getErrorMsg(err)
                );
              });
          }
        );
      })
      .catch((err) => {
        this.props.showMainModal(
          locales_es.errorModal.title,
          this.helpers.getErrorMsg(err)
        );
      });
  }


  resetState = () => {
    this.setState({
      name: "",
      lastname: "",
      email: "",
      phoneNumber: "",
      identification: "",
      identificationTypeId: "1",
      medicalInsuranceId: "",
      medicalInsurancePlan: "",
      medicalInsuranceNumber: "",
      medicalInsuranceName: "",
      medicId: { id: "" },
      comment: "",
      medicines: [],
      errors: [],
      sentRequest: false,
      visible: false,
      modalTitle: "",
      modalContent: "",
      loadingConfig: true,
      gender: '',
      amount: 0,
      amountFormatted: '',
    });
  };



  clearErrors() {
    this.setState({ errors: [] });
  }

  removeFromErrors(string) {
    let errors = JSON.parse(JSON.stringify(this.state.errors));
    errors = errors.filter((item) => item !== string);
    this.setState({
      errors,
    });
  }

  handleChange = (state) => (ev) => {
    this.setState({ [state]: ev.target.value });
  };

  selectMedicalIInsuranceNone = (medic) => {
    if (medic.insurancePermission == false) {
      const insuranceNone = this.state.medicalInsurancesOptions.find((item) => item.name == 'Sin cobertura');
      this.setState({ medicalInsuranceId: insuranceNone });
      this.setState({ medicalInsurancePlan: '' });
      this.setState({ medicalInsuranceNumber: '' });
      this.setState({ medicalInsuranceName: insuranceNone.name  });
    }
  }

  handleReactSelectChange = (state) => (value) => {
    if (state === "medicId") {
      this.selectMedicalIInsuranceNone(value);
    } else if (state === "medicalInsuranceId" && value) {
      this.setState({ medicalInsuranceName: value.name })
    }
    this.setState({ [state]: value });
    if(value.idFinanciador > 0 && value.tieneCobertura == 1){
      this.nroFinanciador = value.idFinanciador;
    }
  };

  handleOpenPaymentLink = () => {
    let link = (this.state.clinicData.hasPaylink) ? this.state.clinicData.paylink_url : this.state.prescriptionProfile.charger_accounts.pay_link;

    link = link.replace(/^https?:\/?\/?/i, '');

    link = `https://${link}`;
    window.open(link, "_blank");
  };

  render() {
    const { medicines, errors } = this.state;

    const classNameError = this.props.classNameError;

    const inputs = [
      {
        label: locales_es.name,
        placeholder: locales_es.fullName,
        onBlur: () => this.removeFromErrors("name"),
        id: 1,
        state: "name",
        value: this.state.name,
        type: "name",
        required: true,
        wrapperCustomClassName: "form-group col-12 col-md-6 float-left pl-md-0",
        customClassName: errors.includes("name") && classNameError,
      },
      {
        label: locales_es.lastname,
        placeholder: locales_es.lastname,
        onBlur: () => this.removeFromErrors("lastname"),
        id: 2,
        state: "lastname",
        value: this.state.lastname,
        type: "lastname",
        required: true,
        wrapperCustomClassName: "form-group col-12 col-md-6 float-left pr-md-0",
        customClassName: errors.includes("lastname") && classNameError,
      },
      {
        label: locales_es.email,
        placeholder: locales_es.email,
        onBlur: () => this.removeFromErrors("email"),
        id: 3,
        state: "email",
        value: this.state.email,
        type: "email",
        required: true,
        wrapperCustomClassName: "form-group col-12 col-md-6 float-left pl-md-0",
        customClassName: errors.includes("email") && classNameError,
        validate: true,
      },
      {
        label: locales_es.phoneNumber,
        placeholder: locales_es.phoneNumberExample,
        onBlur: () => this.removeFromErrors("phoneNumber"),
        id: 4,
        state: "phoneNumber",
        value: this.state.phoneNumber,
        type: "number",
        required: true,
        wrapperCustomClassName: "form-group col-12 col-md-6 float-left pr-md-0",
        customClassName:
          "cellphoneInput " +
          (errors.includes("phoneNumber") && classNameError),
      },
    ];

    const inputsIdentification = [
      {
        label: locales_es.identificationType,
        placeholder: locales_es.identificationType,
        onBlur: () => this.removeFromErrors("identificationTypeId"),
        id: 5,
        state: "identificationTypeId",
        value: this.state.identificationTypeId,
        type: "select",
        required: true,
        options: this.state.identificationOptions,
        wrapperCustomClassName: "form-group col-12 col-md-3 float-left pl-md-0",
        customClassName:
          errors.includes("identificationTypeId") && classNameError,
      },
      {
        label: locales_es.identification,
        placeholder: locales_es.identification,
        onBlur: () => this.removeFromErrors("identification"),
        id: 6,
        state: "identification",
        value: this.state.identification,
        type: "text",
        required: true,
        wrapperCustomClassName:
          "form-group col-12 col-md-3 float-left pl-md-0 clear",
        customClassName: errors.includes("identification") && classNameError,
      },
    ];

    const inputsInsurance = [
      {
        label: locales_es.medicalInsurance,
        placeholder: locales_es.medicalInsurance,
        onBlur: () => this.removeFromErrors("medicalInsuranceId"),
        id: 7,
        state: "medicalInsuranceId",
        value: this.state.medicalInsuranceId,
        disabled: (this.state.medicId && this.state.medicId.insurancePermission === false),
        type: "react-select",
        required: false,
        options: this.state.medicalInsurancesOptions,
        wrapperCustomClassName:
          "form-group col-12 col-md-4 float-left pl-md-0 clear",
        customClassName:
          errors.includes("medicalInsuranceId") && classNameError,
      },
      {
        label: locales_es.medicalInsurancePlan,
        placeholder: locales_es.medicalInsurancePlan,
        onBlur: () => this.removeFromErrors("medicalInsurancePlan"),
        id: 8,
        disabled: (this.state.medicId && this.state.medicId.insurancePermission === false),
        state: "medicalInsurancePlan",
        value: this.state.medicalInsurancePlan,
        required: false,
        wrapperCustomClassName: "form-group col-12 col-md-4 float-left pl-md-0",
        customClassName:
          errors.includes("medicalInsurancePlan") && classNameError,
        visible: this.state.medicalInsuranceName.toLocaleLowerCase() !== 'mpn - medicamentos de primer nivel'
      },
      {
        label: locales_es.medicalInsuranceNumber,
        placeholder: locales_es.medicalInsuranceNumber,
        onBlur: () => this.removeFromErrors("medicalInsuranceNumber"),
        id: 9,
        disabled: (this.state.medicId && this.state.medicId.insurancePermission === false),
        state: "medicalInsuranceNumber",
        value: this.state.medicalInsuranceNumber,
        required: (this.state.medicalInsuranceId && this.state.medicalInsuranceId.length && this.state.medicalInsuranceName.toLocaleLowerCase() !== 'mpn - medicamentos de primer nivel') ? true : false,
        wrapperCustomClassName:
          "form-group col-12 col-md-4 float-left pl-md-0 clearfix",
        customClassName:
          errors.includes("medicalInsuranceNumber") && classNameError,
        visible: this.state.medicalInsuranceName.toLocaleLowerCase() !== 'mpn - medicamentos de primer nivel'
      },
    ];

    const inputsPrescription = [
      {
        label: locales_es.selectMedicToRequestPrescription,
        placeholder: locales_es.selectMedic,
        onBlur: () => this.removeFromErrors("medicId"),
        id: 10,
        state: "medicId",
        value: this.state.medicId,
        type: "react-select",
        required: true,
        options: this.state.clinicMedics,
        wrapperCustomClassName: "form-group col-sm-8 col-md-4 pl-md-0",
        customClassName: errors.includes("medicId") && classNameError,
      },
    ];

    const onSelectItem = (selectedItem, selectedPresentationIndex) => {
      this.clearErrors();
      selectedItem.qubito_reg_no =
        selectedItem.presentations[selectedPresentationIndex].qubito_reg_no;
      selectedItem.presentation =
        selectedItem.presentations[selectedPresentationIndex].name;
      selectedItem.quantity = 1;
      selectedItem.comment = "";
      selectedItem.is_generic = false;
      addMedicine(selectedItem);
    };

    const addMedicine = (item) => {
      const medicines = JSON.parse(JSON.stringify(this.state.medicines));
      medicines.push(item);
      this.setState({
        medicines,
      });
    };

    const openSearchMedicines = () => {
      this.props.showMainModal(
        null,
        <SearchMedicines
          showMainModal={this.props.showMainModal}
          isPublic={true}
          nroFinanciador={this.nroFinanciador}
          onSelectItem={onSelectItem}
        />,
        null,
        null,
        "modal-lg"
      );
    };

    const renderMedicine = (item, index) => {
      const sumQuantity = (qty) => {
        const _medicines = JSON.parse(JSON.stringify(this.state.medicines));
        const medicine = _medicines[index];
        medicine.quantity += qty;

        this.setState({
          medicines: _medicines,
        });
      };

      const setMedicineComment = (index, text) => {
        const _medicines = JSON.parse(JSON.stringify(medicines));
        _medicines[index].comment = text;
        this.setState({
          medicines: _medicines,
        });
      };

      const removeMedicine = (index) => {
        const _medicines = JSON.parse(JSON.stringify(medicines));
        _medicines.splice(index, 1);
        this.setState({
          medicines: _medicines,
        });
      };

      return (
        <div key={"render-medicine-" + index}>
          {index >= 1 && <hr />}
          <div className="mb-3">
            <button
              onClick={() => removeMedicine(index)}
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <i className="icofont-close-line" />
            </button>
            <h6>
              <span className="item-icon icofont-drug" />
              &nbsp;
              <span
                className={
                  item.is_generic ? "text-decoration-line-through" : ""
                }
              >
                {item.commercial_name}
              </span>
              &nbsp;
              <span>
                ({item.drug}) {item.presentation}
              </span>
            </h6>
            {/*<div className="row justify-content-center">
              <div className="custom-control custom-switch mb-3">
                <input onChange={() => onSwitchPress(index)} type="checkbox" className="custom-control-input"
                       id={`customCheck-${index}`}
                       checked={item.is_generic}/>
                <label className="custom-control-label"
                       htmlFor={`customCheck-${index}`}>{locales_es.prescriptGenericOnly}</label>
              </div>
            </div>*/}
            <div className="row justify-content-center">
              <div className="col col-md-6">
                <div className="form-group w-100">
                  <input
                    className="form-control"
                    type="text"
                    placeholder={
                      locales_es.clarifiesThisMedicine +
                      " (" +
                      locales_es.optional +
                      ")"
                    }
                    disabled={false}
                    onChange={(e) => setMedicineComment(index, e.target.value)}
                    value={item.comment}
                  />
                </div>
              </div>
            </div>
            <div>{locales_es.quantity}</div>
            <div
              style={{
                maxWidth: "240px",
                margin: "auto",
                transform: "scale(0.75)",
              }}
            >
              <InputSpinner
                max={99}
                min={1}
                step={1}
                colorMax={Colors.Tomato}
                colorMin={Colors.TealBlue}
                color={Colors.TealBlue}
                value={Number(item.quantity)}
                // skin="modern"
                onChange={(num) => sumQuantity(num)}
              />
            </div>
          </div>
        </div>
      );
    };

    const validateForm = () => {
      let errors = [];
      this.clearErrors();
      inputs.map((input) => {
        if (input.required && !this.state[input.state]) {
          errors.push(input.state);
        }
      });

      inputsIdentification.map((input) => {
        if (input.required && !this.state[input.state]) {
          errors.push(input.state);
        }
      });

      inputsInsurance.map((input) => {
        if (input.required && !this.state[input.state]) {
          errors.push(input.state);
        }
      });

      inputsPrescription.map((input) => {
        if (input.required && !this.state[input.state]) {
          errors.push(input.state);
        }
      });

      if (!this.state.gender) {
        errors.push("gender");
     }

      if (!this.state.medicines.length) {
        errors.push("medicines");
        this.props.showMainModal(
          locales_es.infoModal.title,
          "Añada al menos un medicamento para recetar"
        );

      }
      if (this.state.medicalInsuranceId && !this.state.medicalInsuranceNumber.length && this.state.medicalInsuranceName.toLocaleLowerCase() !== 'mpn - medicamentos de primer nivel') {
        if (this.state.medicalInsuranceId.id > 1){
          errors.push('medicalInsuranceNumber');
        this.props.showMainModal(
          locales_es.infoModal.title,
          "Añada su Nro. de afiliado"
          );
        }

      }

      if(!this.state.medicalInsuranceId){
        errors.push('medicalInsuranceId');
        this.props.showMainModal(
          locales_es.infoModal.title,
          "Elija una cobertura"
          );
      }
      //TODO link a terminos y condiciones dinámico
      //if (this.state.acceptedTerms !== true) {
      //  errors.push('acceptedTerms');
      //this.props.showMainModal(locales_es.infoModal.title, 'Debes aceptar los términos y condiciones');
      //}

      this.setState({
        errors,
      });
      return !errors.length;
    };

    const onSubmitPrescriptionForm = () => {
      if (validateForm()) {
        this.setState({ sentRequest: true });

        const data = {
          clinic_id: this.state.clinicData.id,
          patient_name: this.state.name,
          patient_lastname: this.state.lastname,
          patient_email: this.state.email,
          patient_prefix_cell_phone: "+54 9", //TODO implementar en form
          patient_cell_phone: this.state.phoneNumber,
          patient_identification_type_id: this.state.identificationTypeId,
          patient_identification: this.state.identification,
          patient_gender: this.state.gender.id,
          patient_medical_insurance_id: this.state.medicalInsuranceId.id,
          patient_medical_insurance_plan: this.state.medicalInsurancePlan,
          patient_medical_insurance_number: this.state.medicalInsuranceNumber,
          medic_id: this.state.medicId.id,
          medicines: this.state.medicines,
          comments: this.state.comment,
        };

        this.api
          .postMedicalPrescriptionRequest(data)
          .then((res) => {
            const { clinicData, prescriptionProfile } = this.state;
            if (clinicData.hasPaylink || (clinicData.hasMedicPaylinkEnabled && prescriptionProfile && prescriptionProfile.charger_accounts)) {
              this.props.showMainModal(
                locales_es.successModal.title,
                <SuccessModalContent />
              );
            } else {
              this.props.showMainModal(
                locales_es.successModal.title,
                locales_es.sentPrescriptionRequestSuccessfully
              );
            }
            this.resetState();
          })
          .catch((err) => {
            this.props.showMainModal(
              locales_es.errorModal.title,
              this.helpers.getErrorMsg(err)
            );
            this.setState({ sentRequest: false });
          });
      } else {
        this.props.showMainModal(
          locales_es.errorModal.title,
          locales_es.errorModal.completeAllRequiredFormFields
        );
      }
    };

    const SuccessModalContent = () => {
      const { clinicData, prescriptionProfile } = this.state;
      let amountText = '';
      if (this.state.amount != 0) {
        const amountFormatted = this.state.amountFormatted;
        amountText = `$ ${amountFormatted}`;
      }
      return (
        <div
          className={
            "clear flex-wrap text-center justify-content-center justify-content-md-around mb-5 "
          }
        >
          <p>
            {/* Dada la situación actual del sistema de salud, los profesionales
            estan cobrando un bono de compensación del acto profesional. {"\n"}
            Para la generación de la receta, debe hacer una contribución de ${" "}
            {this.state.prescriptionProfile.charger_accounts.amount} {"\n"} */}
            {(this.state.clinicData && this.state.clinicData.hasPaylink) ? this.state.clinicData.paylink_reason : this.state.prescriptionProfile.charger_accounts.reason} {" "}
            {"\n"} {amountText} {"\n"}

          </p>
          <button
            type="button"
            onClick={() => this.handleOpenPaymentLink()}
            className="btn btn-primary"
          >
            Link de pago del profesional
          </button>
        </div>
      );
    };

    return (
      <>
        <div className="app-loader">
          <i className="icofont-spinner-alt-4 rotate" />
        </div>
        {this.state.clinicData ? (
          <Header clinicData={this.state.clinicData} />
        ) : null}

        <div className="page-box w-100">
          <div className="app-container">
            <main className="main-content pb-5">
              <div className="main-content-wrap">
                <header className="page-header">
                  <h1 className="page-title">
                    Formulario de Solicitud de Recetas Online
                  </h1>
                </header>

                <div className="page-content">
                  <p>
                    Ingrese por favor los datos solicitados para poder
                    confeccionar su receta.
                    <br />
                    <br />
                    Verifique que los datos ingresados sean correctos antes de
                    confirmar la solicitud, en caso de estar mal ingresados, su
                    receta no podrá ser confeccionada.
                    <br />
                    <br />
                    Una vez que esté lista su receta, recibirá un e-mail de
                    notificación para poder pasar a retirarla.
                    <br />
                    <br />
                    TODOS LOS CAMPOS SON OBLIGATORIOS, excepto observaciones.
                  </p>
                  <hr />
                  <div className="card">
                    <div className="card-header">Seleccione el profesional</div>
                    <Form
                      style={"mt-3 mb-5"}
                      inputs={inputsPrescription}
                      handleChange={this.handleChange}
                      handleReactSelectChange={this.handleReactSelectChange}
                    />
                    {((this.state.medicId.id && this.state.clinicData) &&
                      ((this.state.clinicData.hasPaylink) ||
                      (this.state.clinicData.hasMedicPaylinkEnabled && this.state.prescriptionProfile && this.state.prescriptionProfile.charger_accounts))) ? (
                        <div
                          className={
                            "clear flex-wrap text-center justify-content-center justify-content-md-around mb-5 "
                          }
                        >
                          <p>
                            {
                              (this.state.clinicData.hasPaylink) ? this.state.clinicData.paylink_reason : this.state.prescriptionProfile.charger_accounts
                                .reason
                            }
                            {(this.state.amount != 0) ? ` $ ${this.state.amountFormatted}` : ``}
                            {"\n"}
                          </p>
                          <button
                            type="button"
                            onClick={() => this.handleOpenPaymentLink()}
                            className="btn btn-primary"
                            disabled={this.state.sentRequest}
                          >
                            Link de pago del profesional
                          </button>
                        </div>
                      ) : null}
                  </div>
                  <div className="card">
                    <div className="card-header">Información Básica</div>

                    <Form
                      style={"mt-3"}
                      inputs={inputs}
                      handleChange={this.handleChange}
                      handleReactSelectChange={this.handleReactSelectChange}
                    />

                    <Form
                      style={"clearfix mt-3"}
                      inputs={inputsIdentification}
                      handleChange={this.handleChange}
                      handleReactSelectChange={this.handleReactSelectChange}
                    />
                    <View style={styles.formRow}>
                    <View style={styles.flexGrow}>
                        <Text size={14} lineHeight={20} marginTop={24} bold>
                          Sexo <RequireIndicator />
                        </Text>
                        <View style={styles.genders}>
                          {this.state.gendersList && Object.entries(this.state.gendersList).length ? (
                            Object.entries(this.state.gendersList).map(([key, value], index) => {
                              const onPress = () => {
                                this.setState({gender: value});
                              };
                              return (
                                <GenderItem
                                  {...value}
                                  testID={value.name}
                                  isChoose={value.id == this.state.gender.id}
                                  isLastItem={index === Object.entries(this.state.gendersList).length - 1}
                                  onPress={onPress}
                                  key={key}
                                  stylesOverride={{ width: 80, height: 80 }}
                                />
                              );
                            })
                          ) : (
                            <ActivityIndicator
                              style={CommonStyles.activityIndicator}
                            />
                          )}
                        </View>
                    </View>
                    </View>

                    <Form
                      style={"clearfix mt-3"}
                      inputs={inputsInsurance}
                      handleChange={this.handleChange}
                      handleReactSelectChange={this.handleReactSelectChange}
                    />

                    <hr />
                  </div>

                  <h5 className="page-title clear">
                    Ingrese los datos para la confección de la receta
                  </h5>

                  <div
                    className={
                      "clear flex-wrap text-center justify-content-center justify-content-md-around mb-5 " +
                      (errors.includes("medicines") ? "invalid-field p-3" : "")
                    }
                  >
                    {!medicines.length ? (
                      <p>
                        Aún no posee medicamentos seleccionados para esta receta.
                      </p>
                    ) : null}
                    {medicines && medicines.length ? (
                      <div className="card text-secondary border-secondary">
                        <div className="card-header">
                          {locales_es.medicines}
                        </div>
                        <div className="card-body">
                          {medicines.map((medicine, medicineIndex) =>
                            renderMedicine(medicine, medicineIndex)
                          )}
                        </div>
                      </div>
                    ) : null}
                    {medicines && medicines.length >= 3 ? (
                      <p>No puede añadir más medicamentos a esta receta.</p>
                    ) : (
                      <button
                        type="button"
                        onClick={openSearchMedicines}
                        disabled={medicines.length >= 3}
                        className="btn btn-info"
                      >
                        {locales_es.addMedicine}
                      </button>
                    )}
                  </div>

                  <div className="clear flex-wrap justify-content-center justify-content-md-around text-center mt-2">
                    <textarea
                      onChange={(e) =>
                        this.setState({
                          comment: e.target.value,
                        })
                      }
                      className="form-control rounded"
                      placeholder={locales_es.observations}
                    >
                      {this.state.observations}
                    </textarea>
                  </div>

                  {/* <div
                    className="clear flex-wrap justify-content-center justify-content-md-around text-center m-5">
                    <div className="custom-control custom-checkbox mb-3">
                      <input type="checkbox"
                             className="custom-control-input"
                             id="termsCheckbox"
                             onChange={(e) => this.setState({acceptedTerms: e.target.checked})}
                             checked={this.state.acceptedTerms}/>
                      <label
                        className={"custom-control-label " + (errors.includes('acceptedTerms') ? 'before-danger' : '')}
                        htmlFor="termsCheckbox">{locales_es.accept} <a
                        href="https://woopi.com.ar" target="_blank">{locales_es.terms}</a></label>
                    </div>
                  </div> */}

                  <div className="clear flex-wrap justify-content-center justify-content-md-around text-center mt-5">
                    <button
                      type="button"
                      onClick={onSubmitPrescriptionForm}
                      className="btn btn-primary"
                      disabled={this.state.sentRequest}
                    >
                      {locales_es.sendPrescriptionRequest}
                    </button>
                  </div>
                </div>
              </div>
            </main>
          </div>
        </div>

        {this.state.clinicData ? (
          <Footer
            clinicName={this.state.clinicData.name}
            service={"Servicio de Solicitud de Receta"}
          />
        ) : null}
      </>
    );
  }
}

const styles = StyleSheet.create({
  genders: {
    marginTop: 24,
    ...Theme.flexRow,
  },
  formRow: {
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "baseline",
    gap: 16,
  },
  flexGrow: { flex: 1 },

})
