import React, { useCallback, useState } from "react";
import { StyleSheet, Image, View } from "react-native";
import Text from "../../components/Text";
import { Colors, Routes } from "../../configs";
import Theme from "../../style/Theme";
import scale from "../../utils/scale";
import ProfileItem from "./ProfileItem";
import Container from "../../components/Layout/Container";
import ButtonLinear from "../../components/Buttons/ButtonLinear";
import Line from "../../components/Layout/Line";
import { useTheme } from "../../configs/Theme";
import { useHistory } from "react-router-dom";
import PrescriptionProfile from "./PrescriptionProfile";
import { SOURCE_ICON } from "../../images";
import { EventRegister } from "../../modules/EventRegister";
import { EVENT_SHOW_MODAL_INFO } from "../../constants/Events";
import locales_es from "../../locales/es";
import ButtonIcon from "../Buttons/ButtonIcon";
import APIService from "../../modules/apiService";
import Helpers from "../../modules/helpers";
import { showModalInfo, showModalAction } from "../../modules/ViewHelpers";
import PrescriptionService from "../../modules/PrescriptionService";

interface PrescriptionProfilesProps {}

const PrescriptionProfiles = (props: PrescriptionProfilesProps) => {
  const api = new APIService();
  const helpers = new Helpers();
  const [deletingProfile, setDeletingProfile] = useState(false);
  const history = useHistory();
  const prescriptionService = new PrescriptionService();

  const onPressNewProfile = useCallback(() => {
    prescriptionService.clear();
    history.push(`/${Routes.WorkProfile}`, {
      stepTitle: locales_es.stepOneOfTwo,
      stepNumber: 1,
    });
  }, [history]);

  const _onProfileSelect = (profileId) => {
    if (props.onProfileSelect) {
      props.onProfileSelect(profileId);
    }
  };

  const deleteProfile = async (profileId: string, setLoading: any) => {
    const selectedProfileToDelete = props.prescriptionProfiles.find(
      (item) => item.id === profileId
    );
    const confirmDeletePrescriptionProfile = async () => {
      setLoading(true);
      setDeletingProfile(true);
      await api
        .deletePrescriptionProfile(profileId)
        .then(async (res: any) => {
          await props.loadPrescriptionProfiles();
          setLoading(false);
          showModalInfo(locales_es.successModal.title, res.message);
          setDeletingProfile(false);
        })
        .catch((err: any) => {
          setLoading(false);
          setDeletingProfile(false);
          helpers.processError(err);
        });
    };
    if (deletingProfile) return;
    if (
      !deletingProfile &&
      props.prescriptionProfiles &&
      props.prescriptionProfiles.length === 1
    ) {
      showModalInfo(
        locales_es.deletePrescriptionProfileInfo.title,
        locales_es.deletePrescriptionProfileInfo.subtitle
      );
      return;
    }
    if (
      !deletingProfile &&
      props.prescriptionProfiles &&
      props.prescriptionProfiles.length > 1 &&
      selectedProfileToDelete.active_charger_account === 1
    ) {
      showModalAction(
        locales_es.deletePrescriptionProfileWithChargerAccount.title,
        locales_es.deletePrescriptionProfileWithChargerAccount.subtitle,
        confirmDeletePrescriptionProfile,
        "100%"
      );
    }
    if (
      !deletingProfile &&
      props.prescriptionProfiles &&
      props.prescriptionProfiles.length > 1 &&
      selectedProfileToDelete.active_charger_account === 0
    ) {
      showModalAction(
        locales_es.deletePrescriptionProfile.title,
        locales_es.deletePrescriptionProfile.subtitle,
        confirmDeletePrescriptionProfile
      );
    }
  };

  return (
    <Container style={[styles.container, props.style]}>
      <ProfileItem
        style={props.itemStyle}
        icon={"exp"}
        title={props.removeTitle ? "" : "Perfiles de Receta"}
        rightButton={
          <ButtonIcon
            onPress={() => {
              EventRegister.emit(EVENT_SHOW_MODAL_INFO, {
                title: locales_es.prescriptionProfilesInfo.title,
                message: locales_es.prescriptionProfilesInfo.content,
              });
            }}
            imgStyle={{ width: 30, height: 30 }}
            icon={"help"}
            style={styles.buttonEdit}
          />
        }
      >
        {props.prescriptionProfiles && props.prescriptionProfiles.length ? (
          props.prescriptionProfiles.map((profile, index) => {
            return props.editable === true ? (
              <View>
                <PrescriptionProfile
                  {...profile}
                  onProfileSelect={
                    props.onProfileSelect ? _onProfileSelect : null
                  }
                  checked={props.selectedProfileId === profile.id}
                  deleteProfile={deleteProfile}
                />
                {index + 1 !== props.prescriptionProfiles.length ? (
                  <Line height={2} marginTop={30} />
                ) : null}
              </View>
            ) : (
              <View>
                {props.selectedProfileId === profile.id && (
                  <PrescriptionProfile
                    {...profile}
                    disabled={true}
                    checked={true}
                  />
                )}
              </View>
            );
          })
        ) : (
          <Text marginTop={24} marginLeft={24} size={13} lineHeight={16}>
            No se encontraron perfiles de receta
          </Text>
        )}
        {props.onProfileSelect ? (
          <View style={{ marginTop: 30 }} />
        ) : (
          <>
            <Line height={2} marginTop={30} />
            <ButtonLinear
              white
              leftChildren={
                <Image style={styles.iconEdit} source={SOURCE_ICON.add} />
              }
              style={styles.buttonUpdate}
              colors={[Colors.BlueCrayola, Colors.Blue]}
              title={"Añadir Perfil Nuevo"}
              onPress={onPressNewProfile}
            />
          </>
        )}
      </ProfileItem>
    </Container>
  );
};

export default PrescriptionProfiles;

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  iconEdit: {
    marginRight: 12,
    tintColor: "white",
  },
  box: {
    marginTop: 16,
    borderRadius: 16,
    paddingBottom: 24,
    marginHorizontal: 16,
    overflow: "hidden",
  },
  button: {
    borderWidth: 0,
    marginVertical: 10,
  },
  topBox: {
    ...Theme.flexRowSpace,
    paddingTop: 16,
    paddingHorizontal: 24,
  },
  rounded: {
    width: 32,
    height: 32,
    borderRadius: 8,
    backgroundColor: Colors.OysterBay,
    ...Theme.center,
  },
  buttonEdit: {
    width: 42,
    height: 42,
    backgroundColor: "#fff",
    left: 10,
  },
  buttonUpdate: {
    marginHorizontal: 24,
    marginVertical: 12,
  },
  images: {
    width: scale(348),
    height: scale(200),
  },
  iconPhone: {
    backgroundColor: Colors.RedNeonFuchsia,
    width: 24,
    height: 24,
    borderRadius: 8,
  },
  flexRow: {
    ...Theme.flexRow,
    marginTop: 16,
    marginLeft: 24,
  },
  contentIcon: {
    width: 40,
    height: 40,
    backgroundColor: Colors.TealBlue,
    ...Theme.center,
    borderRadius: 16,
    marginRight: 16,
  },
  setRow: {
    ...Theme.flexRow,
    paddingHorizontal: 24,
    paddingVertical: 12,
  },
  viewLanguage: {
    ...Theme.flexRow,
    marginLeft: 24,
    marginTop: 8,
  },
  schoolPhoto: {
    width: "100%",
    height: 56,
    maxHeight: 56,
    // borderRadius: 56,
    marginRight: 8,
    alignSelf: "flex-start",
  },
  viewEducation: {
    ...Theme.flexRow,
    marginLeft: 24,
    marginTop: 8,
  },
  itemAward: {
    marginTop: 24,
    marginLeft: 24,
  },
});
