import React, {useCallback, useState} from 'react';
import {
  View,
  StyleSheet,
  Platform,
  ScrollView,
  ActivityIndicator,
} from 'react-native';
import Text from '../../../components/Text';
import Theme from '../../../style/Theme';
import {Colors, Routes} from '../../../configs';
import Container from '../../../components/Layout/Container';
import Layout from '../../../components/Layout/Layout';
import HeaderButton from '../../../components/HeaderButton';
import UserInfo from '../../../components/ConsultDetail/UserInfo';
import {getBottomSpace, getStatusBarHeight} from 'react-native-iphone-x-helper';
import ButtonIcon from '../../../components/Buttons/ButtonIcon';
import locales_es from '../../../locales/es';
import SearchBox from '../../../components/Home/SearchBox';
import {useHistory} from "react-router-dom";
import Overlay from '../../../components/Overlay';
import SearchPatients from "../../SearchPatients";
import useMyPatientsPage from '../shared/useMyPatientsPage';

interface MyPatientsProps {
}

const MyPatientsMobile = (props: MyPatientsProps) => {
  const {selectPatientId, patients, onPressWhitePlusButton, onUserInfoPress, goToUserProfile} = useMyPatientsPage(props.selectPatientId, props.onSelectPatient);
  const history = useHistory();

  const [overlay, setOverlay] = useState(false);
  const [keySearch, setKeySearch] = useState('');
  const onUserInfoPressAndSelectPatientId = (item) => onUserInfoPress(item);
  const onUserInfoPressAndNoPatientSelection = (item) => history.replace(`${Routes.PatientProfile}`, {patientId: item.id});

  const onFocus = useCallback(() => {
    setOverlay(<SearchPatients {...props}
      showMainModal={(title, content, actions, customStyles, contentCustomClassName) => props.showModal(title, content, actions, customStyles, contentCustomClassName)}
      hideMainLoading={props.hideMainLoading}
      keyItemsToSearch={['name', 'lastname']}
      onPressItem={selectPatientId ? onUserInfoPressAndSelectPatientId : onUserInfoPressAndNoPatientSelection}
      onGoBack={() => setOverlay(null)}
    />)

    /*history.push(`${Routes.SearchPatients}`, {
      items: patients,
      keyItemsToSearch: ['name', 'lastname'],
      onPressItem: selectPatientId ? String(onUserInfoPressAndSelectPatientId) : String(onUserInfoPressAndNoPatientSelection),
    });*/
  }, [patients]);

  return (
    <Container style={styles.container}>
      {overlay && <Overlay>{overlay}</Overlay>}
      <Layout style={styles.header}>
        <HeaderButton
          onPress={props.onGoBack}
          style={{
            marginTop: 26,
            marginLeft: 24,
          }}
        />
        {patients && patients.length ? (
          <SearchBox
            placeholder={locales_es.searchPatient + '...'}
            value={keySearch}
            onChangeText={setKeySearch}
            shadow={false}
            style={{
              marginHorizontal: 24,
              marginBottom: 12,
              flex: 1,
            }}
            onFocus={onFocus}
          />
        ) : null}
      </Layout>
      {/*<TopPatient />*/}
      <View style={{height: 30}}/>
      <Text size={17} lineHeight={20} bold style={{marginHorizontal: 24, paddingBottom: 12}}>
        {selectPatientId ? 'Seleccione el paciente' : 'Todos los Pacientes'}
      </Text>
      <ScrollView
        showsVerticalScrollIndicator={false}
        contentContainerStyle={styles.contentContainerStyle}>
        {patients === null ? (
          <ActivityIndicator style={{marginVertical: 24}}/>
        ) : patients && patients.length ? (
          patients.map((patient, index) => {
            return (
              <UserInfo
                onPress={
                  selectPatientId
                    ? () => onUserInfoPress(patient)
                    : () => goToUserProfile(patient)
                }
                key={index}
                user={patient}
                style={{
                  marginTop: 24,
                  marginHorizontal: 24,
                }}
              />
            );
          })
        ) : (
          <Text
            size={14}
            lineHeight={20}
            marginTop={30}
            center
            style={{marginHorizontal: 24}}>
            Aún no tiene pacientes registrados
          </Text>
        )}
      </ScrollView>
      <ButtonIcon
        testID="addPatientButton"
        icon={'whitePlus'}
        style={styles.filterView}
        onPress={onPressWhitePlusButton}
        imgStyle={{width: 20, height: 20}}
      />
    </Container>
  );
};

export default MyPatientsMobile;

const styles = StyleSheet.create({
  header: {
    paddingTop: Platform.OS === 'ios' ? getStatusBarHeight() : 0,
    ...Theme.flexRowSpace,
    paddingBottom: 10,
  },
  container: {
    ...Theme.flexOne,
  },
  contentContainerStyle: {
    // paddingHorizontal: 24,
    // paddingTop: 40,
    paddingBottom: getBottomSpace() + 120,
  },
  filterView: {
    position: 'absolute',
    alignSelf: 'flex-end',
    bottom: getBottomSpace() + 16,
    right: 24,
    backgroundColor: Colors.BlueCrayola,
    width: 56,
    height: 56,
    borderRadius: 16,
  },
});
