import React, {memo} from 'react';
import {View, StyleSheet} from 'react-native';
import Text from '../../components/Text';
import {Colors} from '../../configs';
import ButtonLinear from '../../components/Buttons/ButtonLinear';
import locales_es from '../../locales/es';
import Layout from '../../components/Layout/Layout';

import Modal from "../Modal";

interface ModalInfoRegisterProps {
  title: string;
  message: string;
  onPressDefault: any;
  logoutModal?: boolean;
  actions?: any;
}

const ModalInfoRegister = memo((props: ModalInfoRegisterProps) => {
  const { title, message, onRequestClose } = props;
  return (
    <Modal title={title} message={message} onRequestClose={onRequestClose} fullWidthAction>
      <View className="links-stores" style={styles.container}>
        <a
          style={styles.btn}
          href="https://apps.apple.com/us/app/rcta/id1597919289"
          target="blank"
          className="btn"
        >
          Descargá desde{" "}
          <img
            style={styles.icon}
            src={"https://rcta.me/img/ic-apple.png"}
            alt="AppleStore"
          />
        </a>
        <a
          style={styles.btn}
          href="https://play.google.com/store/apps/details?id=com.innovamed.rcta"
          target="blank"
          className="btn"
        >
          Descargá desde{" "}
          <img
            style={styles.icon}
            src={"https://rcta.me/img/ic-google-play.png"}
            alt="Google Play"
          />
        </a>
      </View>
    </Modal>
  );
});

export default ModalInfoRegister;

const styles = StyleSheet.create({
  container: {
    display: 'inline-flex',
    flexDirection: 'row',
    gap: 8
  },
  btn: {
    borderRadius: 30,
    backgroundColor: Colors.Blue,
    color: "#fff",
  },
  icon: {
    margin: 10,
    marginRight: 0,
    width: 14,
    height: 14,
  },
});
