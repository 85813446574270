import React, {Component} from "react";
import './index.css';

export default class Footer extends Component {

    render() {
        return (
          <div className={"app-footer " + this.props.className}>
            <div className="footer-wrap">
              <div className="row h-100 align-items-center">
                <div id="page-breadcrumbs" className="col-12 col-md-6 d-none d-md-block">
                  <ul className="page-breadcrumbs">

                    <li className="item">
                      <a href={this.props.clinicName ? '#' : 'https://rcta.me'} className="link" target="_blank">{this.props.clinicName || 'RCTA'}</a>
                      {this.props.service && <i className="separator icofont-thin-right"/>}
                    </li>

                    <li className="item">
                      {/* TODO reemplazar el nombre del servicio activo */}
                      <a href="#" className="link">{this.props.service}</a>
                      <i className="separator icofont-thin-right"/>
                    </li>

                  </ul>
                </div>

                <div id="app-version" className="col-12 col-md-6 text-right">
                  <div className="d-flex align-items-center justify-content-center justify-content-md-end">
                    <span>Version {process.env.REACT_APP_VERSION}{process.env.REACT_APP_ENV === 'dev' ? '-test' : ''}</span>
                  </div>
                </div>
              </div>

              {/*<div className="footer-skeleton">
                <div className="row align-items-center">
                  <div className="col-12 col-md-6 d-none d-md-block">
                    <ul className="page-breadcrumbs">
                      <li className="item bg-1 animated-bg" />
                      <li className="item bg animated-bg" />
                    </ul>
                  </div>

                  <div className="col-12 col-md-6">
                    <div className="info justify-content-center justify-content-md-end">
                      <div className="version bg animated-bg"></div>
                      <div className="settings animated-bg"></div>
                    </div>
                  </div>
                </div>
              </div>*/}
            </div>
          </div>
        )
    }
}
