import React from "react";
import {
  View,
  StyleSheet,
  Image,
  Modal,
  TouchableOpacity,
  ImageBackground,
  ActivityIndicator,
} from "react-native";
import Text from "../../../components/Text";
import HeaderButton from "../../../components/HeaderButton";
import Theme from "../../../style/Theme";
import InputApp from "../../../components/InputApp";
import { Colors } from "../../../configs";
import TagItem from "../../../components/WorkProfile/TagItem";
import ButtonLinear from "../../../components/Buttons/ButtonLinear";
import locales_es from "../../../locales/es";
import { getStatusBarHeight } from "react-native-iphone-x-helper";
import ModalSelect from "../../../components/ModalSelect";
import { USER_STATUS_APPROVED } from "../../../models/constants";
import iconSearch from "../../../images/ic_search_normal@3x.png";
import iconNext from "../../../images/ic_next@3x.png";
import ImgLoadAndCrop from "../../../components/imgLoadAndCrop";
import Stepper from "../../BasicInformation/BasicInformationDesktop/stepper";
import { SOURCE_ICON } from "../../../images";
import useWorkProfilePage from "../shared/useWorkProfilePage";
import RequireIndicator from "../../../components/RequireIndicator";

interface WorkProfileProps {}

const WorkProfile = (props: WorkProfileProps) => {
  const {
    stepNumber,
    theme,
    searchSpecialtiesResults,
    onSearchSpecialtiesChange,
    searchSpecialties,
    profession,
    setProfession,
    errors,
    onBlur,
    onPressSearchResult,
    renderListItem,
    specialties,
    onPressSpecialtyX,
    translations,
    prescriptionProfileAdditionalFields,
    renderAdditionalField,
    userData,
    imgLoadAndCrop,
    uploadImage,
    identificationImage,
    setCropping,
    cropping,
    openCrop,
    onSubmit,
    visibleAdditionalFieldMenuOption,
    closeAdditionalFieldMenuOption,
    additionalFieldsOptions,
    onChangeInput,
    medicalLicenseConfiguration,
    clinicData,
  } = useWorkProfilePage();
  return (
    <View style={styles.container}>
      <View style={styles.content}>
        {stepNumber ? <Stepper step={stepNumber} hasTwoSteps={clinicData && !clinicData.paylink_enabled} /> : <Stepper step={2} hasTwoSteps={clinicData && !clinicData.paylink_enabled} />}
        <View style={styles.headerWrapper}>
          <HeaderButton />
          <Text size={32} lineHeight={36} bold>
            {locales_es.workProfile.title}
          </Text>
          <Text
            size={16}
            lineHeight={18}
            marginTop={8}
            style={{ maxWidth: "100%" }}
          >
            {locales_es.workProfile.intro}
          </Text>
        </View>
        <View style={styles.pageContent}>
          <View style={styles.backgroundImageWrapper}>
            <Image source={SOURCE_ICON.rx} style={styles.backgroundImage} />
          </View>
          <View style={styles.formContainer}>
            <View style={styles.form}>
              <View style={[styles.formRow, styles.firstInfo]}>
                <View style={styles.flexGrow}>
                  <InputApp
                    title={'Profesión'}
                    placeholder={'Médico Cirujano'}
                    value={profession}
                    onChangeText={setProfession}
                    borderColor={
                    errors.includes('profession')
                        ? theme.lineErrorColor
                        : theme.innearColor
                    }
                    onBlur={() => onBlur('profession')}
                    blurOnSubmit={true}
                    showRequired = {true}
                  />
                </View>
                <View style={styles.flexGrow}>
                  <InputApp
                  title={'Especialidades'}
                    placeholder="Busque o escriba sus especialidades..."
                    value={searchSpecialties}
                    onChange={onSearchSpecialtiesChange}
                    borderColor={
                      errors.includes('searchSpecialties')
                        ? theme.lineErrorColor
                        : theme.innearColor
                    }
                    isShowIconLeft
                    iconLeft={
                      <Image
                        source={iconSearch}
                        style={styles.iconSearch}
                      />
                    }
                    autoCorrect={false}
                    autoCapitalize="words"
                    onBlur={() => onBlur('searchSpecialties')}
                    showRequired = {true}
                  />
                  <View
                    style={[
                      {backgroundColor: 'white'},
                      (searchSpecialtiesResults && searchSpecialtiesResults.length)
                        ? {
                            borderColor: theme.innearColor,
                            borderWidth: 1,
                            borderRadius: 8,
                            position: "absolute",
                            zIndex: 2,
                            top: 75,
                            left: 0,
                            width: "100%",
                            height: 100,
                            overflow: "auto",
                          }
                        : null,
                      ]}>
                      {searchSpecialtiesResults && searchSpecialtiesResults.length ? (
                        searchSpecialtiesResults.map(result => renderListItem(result.name))
                      ) : null}
                  </View>
                  <View style={styles.spec}>
                    {specialties && specialties.length ? (
                      specialties.map((specialty, index) => (
                        <TagItem
                          title={specialty}
                          key={index.toString()}
                          onPressX={() => onPressSpecialtyX(index)}
                        />
                      ))
                    ) : (
                      <Text style={{color: 'red'}}>
                        Escriba al menos una especialidad
                      </Text>
                    )}
                  </View>
                </View>
              </View>
              <Text size={18} lineHeight={20} bold marginTop={32}>
                {translations ? translations.medicLicense : ""}
              </Text>
              <View style={styles.formRow}>
                <View style={styles.flexGrow}>
                  {medicalLicenseConfiguration === null ? (
                    <ActivityIndicator />
                  ) : medicalLicenseConfiguration &&
                    medicalLicenseConfiguration.length ? (
                    medicalLicenseConfiguration.map((additionalField) =>
                      renderAdditionalField(additionalField)
                    )
                  ) : null}
                </View>
                {userData && userData.status !== USER_STATUS_APPROVED && (
                  <View style={[styles.flexGrow, { height: "100%" }]}>
                    <>
                      <ImgLoadAndCrop
                        containerStyles={{ marginTop: 0 }}
                        ref={imgLoadAndCrop}
                        silentMode={true}
                        image={identificationImage}
                        inputLabel={"Imagen"}
                        imageId={"identificationImage"}
                        imageState={"identificationImage"}
                        inputWrapperCustomClassName="col-12 pl-md-0"
                        canvasCustomClassName="profile"
                        saveCrop={(image) => {
                          uploadImage(image);
                        }}
                        deleteCrop={() => console.log('deleteCrop')}
                        deletedImage={() => console.log('deleteImage')}
                        onCropping={() => setCropping(true)}
                        onStopCropping={() => setCropping(false)}
                      />
                      {!cropping && (
                        <View style={{ flex: 1 }}>
                          <Text size={15} lineHeight={18} bold marginTop={32}>
                            {translations ? <>
                            {translations.medicLicensePicture}
                            <RequireIndicator/>
                            </>: ''}
                          </Text>
                          <View style={{ flex: 1 }}>
                            {identificationImage === null ? (
                              <ActivityIndicator />
                            ) : identificationImage ? (
                              <>
                                <TouchableOpacity
                                  onPress={() => openCrop()}
                                  style={styles.licenseImage}>
                                  <ImageBackground
                                    resizeMode="contain"
                                    style={styles.image}
                                    source={identificationImage}
                                  />
                                </TouchableOpacity>
                              </>
                            ) : (
                              <TouchableOpacity
                                style={styles.imgUpload}
                                onPress={() => openCrop()}
                              >
                                <View style={{ alignItems: "center" }}>
                                  <Image
                                    source={{
                                      uri: SOURCE_ICON.photoLibrary,
                                      width: 50,
                                      height: 50,
                                    }}
                                    style={{ tintColor: "grey" }}
                                  />
                                  <Text size="20" lineHeight={20}>
                                    SUBIR IMAGEN{" "}
                                  </Text>
                                </View>
                              </TouchableOpacity>
                            )}
                          </View>
                          <Text size={16} lineHeight={22} marginTop={16}>
                            {translations
                              ? translations.medicLicensePictureDescription
                              : ""}
                          </Text>
                        </View>
                      )}
                    </>
                  </View>
                )}
              </View>
              <View style={styles.cta}>
                <ButtonLinear
                  white
                  title={"Continuar"}
                  children={
                    <Image source={iconNext} style={styles.buttonChildren} />
                  }
                  onPress={onSubmit}
                  style={styles.buttonLinear}
                />
              </View>
            </View>
          </View>
        </View>
      </View>
      {visibleAdditionalFieldMenuOption && (
        <Modal
          visible={true}
          onRequestClose={closeAdditionalFieldMenuOption}
          transparent
          animationType={"fade"}
        >
          <ModalSelect
            onPressItem={(item) => {
              onChangeInput(item.value, item);
            }}
            choices={additionalFieldsOptions}
            close={closeAdditionalFieldMenuOption}
            style={{
              width: '60%',
              marginLeft: 'auto',
              marginRight: 'auto',
              marginBottom: 0,
            }}
          />
        </Modal>
      )}
    </View>
  );
};

export default WorkProfile;

const styles = StyleSheet.create({
  buttonChildren: {
    ...Theme.icons,
    marginLeft: 8,
  },
  buttonLinear: {
    marginTop: 32,
  },
  imgContainer: { minWidth: 380, width: "70%", marginTop: 0 },
  avatarStyles: { width: 150, height: 150 },
  container: { flex: 1, minHeight: "100%" },
  content: {
    minHeight: "100%",
    paddingBottom: 24,
    paddingLeft: 24,
    paddingRight: 24,
    paddingTop: getStatusBarHeight(),
  },
  headerWrapper: {
    flexDirection: "column",
    alignItems: "flex-start",
    maxWidth: "100%",
    marginTop: 32,
  },
  pageContent: {
    flexDirection: "row-reverse",
    flexWrap: "wrap",
    justifyContent: "flex-end",
    marginTop: 32,
  },
  backgroundImageWrapper: {
    width: "40%",
    minWidth: 400,
    alignItems: "flex-start",
    marginBottom: 32,
  },
  backgroundImage: { width: 420, height: 380 },
  formContainer: { flex: 1, minWidth: 400 },
  form: { marginTop: 24 },
  prefixField: { width: 200 },
  prefixSelector: {
    padding: 0,
    marginTop: 0,
  },
  formRow: {
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    gap: 16,
  },
  cta: { width: 200 },
  flexGrow: { flex: 1 },
  iconSearch: {
    ...Theme.icons,
    tintColor: Colors.DodgerBlue,
  },
  spec: {
    marginTop: 8,
    ...Theme.flexRow,
    flexWrap: "wrap",
  },
  specLanguage: {
    marginTop: 8,
    ...Theme.flexRow,
    flexWrap: "wrap",
    paddingBottom: 52,
  },
  imgUpload: {
    flex: 1,
    border: "2px dashed grey",
    borderRadius: 16,
    marginTop: 16,
    justifyContent: "center",
    alignItems: "center",
  },
  image: {
    flex: 1,
    justifyContent: "center",
  },
  licenseImage: {
    marginTop: 4,
    minHeight: 150,
    borderRadius: 8,
    backgroundColor: "#fff",
  },
  firstInfo: {
    paddingBottom: 24,
    borderBottomWidth: 1,
    borderColor: "#e8e8eb",
  },
});
