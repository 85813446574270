import React, {Component} from 'react';
import {View, Text} from 'react-native';

import locales_es from './../../locales/es.js';
import {CommonStyles} from '../../styles/CommonStyles';
import Lottie from 'react-lottie';
import animationData from './../../animations/green-check.json';

export default class FullScreenGreenCheck extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const animationSize = 250;
    const animationOptions = {
      loop: false,
      autoplay: true,
      animationData: animationData,
      rendererSettings: {
        preserveAspectRatio: "xMidYMid slice"
      }
    };
    return (
      <View
        style={[
          {
            flex: 1,
            alignItems: 'center',
            justifyContent: 'center',
            position: 'absolute',
            top: 0,
            right: 0,
            left: 0,
            bottom: 0,
            zIndex: 1000,
            flexDirection: 'column',
            backgroundColor: 'rgba(255,255,255,0.9)',
            minHeight: 'auto'
          },
        ]}>
        <View
          style={{
            height: '30%',
            width: '90%',
          }}>
          <Lottie
            options={animationOptions}
            height={animationSize}
            width={animationSize}
          />
        </View>
        <Text
          style={[
            {
              color: '#000',
              backgroundColor: 'transparent',
              textAlign: 'center',
              marginTop: 10,
              fontSize: 26,
              width: '80%',
            },
            CommonStyles.alignSelfCenter,
          ]}>
          {this.props.text || locales_es.successModal.title}
        </Text>
      </View>
    );
  }
}
