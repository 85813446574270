import React, { memo, useCallback } from "react";
import {
  View,
  StyleSheet,
  TouchableOpacity,
  Image,
  ActivityIndicator,
  ScrollView,
} from "react-native";
import Text from "../../../components/Text";
import Theme from "../../../style/Theme";
import scale from "../../../utils/scale";
import Container from "../../../components/Layout/Container";
import Layout from "../../../components/Layout/Layout";
import HeaderButton from "../../../components/HeaderButton";
import {
  getBottomSpace,
  getStatusBarHeight,
} from "react-native-iphone-x-helper";
import UserInfo from "../../../components/ConsultDetail/UserInfo";
import SelectPatient from "../../../components/Woopi/SelectPatient";
import ButtonLinear from "../../../components/Buttons/ButtonLinear";
import Colors from "../../../configs/Colors";
import Routes from "../../../configs/Routes";
import ButtonIcon from "../../../components/Buttons/ButtonIcon";
import Line from "../../../components/Layout/Line";
import TextInput from "../../../components/TextInput";
import locales_es from "../../../locales/es";
import FullScreenLoading from "../../../components/fullscreenLoading";
import FullScreenGreenCheck from "../../../components/FullscreenGreenCheck";
import Content from "../../../components/Layout/Content";
import { ConsultsType } from "../../../configs/Const";
import PrescriptionProfiles from "../../../components/WorkProfileManagement/PrescriptionProfiles";
import {
  PRESCRIPTION_STYLE_FREE,
  PRESCRIPTION_STYLE_PRACTICES,
} from "../../../models/constants";
import ButtonText from "../../../components/Buttons/ButtonText";
import { FEATURE_ICON } from "../../../images/MyWork";
import FeatureItem from "../../../components/FeatureItem";
import TagItem from "../../../components/WorkProfile/TagItem";

import FormNewDatePicker from "../../../components/formNewDatePicker";
import Overlay from "../../../components/Overlay";
import { MyPatientsMobile } from "../../MyPatients";
import iconFeedActive from "../../../images/ic_feed_active.png";
import iconArrowRight from "../../../images/ic_arrow_right.png";
import SearchPractices from "../../SearchPractices";
import useAddCertificatePage from "../shared/useAddCertificatePage";

interface AddCertificateProps {}

const AddCertificate = memo((props: AddCertificateProps) => {
  const {
    overlay,
    minDate,
    congrats,
    loading,
    load,
    onPressPracticeX,
    indications,
    onBlur,
    setText,
    text,
    setIndications,
    onSend,
    setOverlay,
    setPatientId,
    setPrescriptionStyle,
    practices,
    setPractices,
    setUserData,
    patientId,
    theme,
    prescriptionStyle,
    enabledSelectionType,
    clearPrescriptionType,
    loadingText,
    prescriptionProfiles,
    userData,
    onPrescriptionProfileSelect,
    prescriptionProfileId,
    errors,
    setDates,
    setDateArray,
    dates,
  } = useAddCertificatePage();
  const onFocus = useCallback(() => {
    setOverlay(
      <SearchPractices
        showMainModal={(
          title,
          content,
          actions,
          customStyles,
          contentCustomClassName
        ) =>
          props.showMainModal(
            title,
            content,
            actions,
            customStyles,
            contentCustomClassName
          )
        }
        hideMainLoading={props.hideMainLoading}
        onComplete={(_practices) => {
          setPractices(practices.concat(_practices));
          setOverlay(null);
        }}
        prescriptionProfileId={prescriptionProfileId}
        selectedPractices={practices}
        onGoBack={() => setOverlay(null)}
      />
    );
    /*history.push(`/${Routes.SearchPractices}`, {
    onComplete: _practices => {
      setPractices(practices.concat(_practices));
      history.push(`/${Routes.AddCertificate}`);
    },
    prescriptionProfileId,
    selectedPractices: practices,
  });*/
  }, [practices, prescriptionProfileId]);
  const selectPatient = () => {
    /* history.push(`/${Routes.MyPatients}`, {
     selectPatientId: true,
     selectionType: ConsultsType.Certificate,
   });*/

    setOverlay(
      <MyPatientsMobile
        showMainModal={(
          title,
          content,
          actions,
          customStyles,
          contentCustomClassName
        ) =>
          props.showMainModal(
            title,
            content,
            actions,
            customStyles,
            contentCustomClassName
          )
        }
        hideMainLoading={props.hideMainLoading}
        selectPatientId={true}
        selectionType={ConsultsType.Certificate}
        onSelectPatient={(patient) => {
          setPatientId(patient.id);
          setUserData(patient);
          setOverlay(null);
        }}
        onGoBack={() => setOverlay(null)}
      />
    );
  };

  const renderFreePrescription = (label: string, placeholder: string) => {
    return (
      <Layout
        style={{
          marginTop: 12,
          paddingTop: 8,
          shadowColor: "#fff",
          shadowOffset: {
            width: 0,
            height: 4,
          },
          shadowOpacity: 0.3,
          shadowRadius: 4,
          elevation: 4,

          // borderRadius: 16,
          // ...Theme.shadow,
        }}
      >
        <View
          style={{
            ...Theme.flexRow,
            paddingHorizontal: 24,
            paddingBottom: 16,
          }}
        >
          <ButtonIcon
            icon={"doctor"}
            style={{
              width: 32,
              height: 32,
              backgroundColor: Colors.TealBlue20,
            }}
            tintColor={Colors.TealBlue}
            disabled
          />
          <Text size={15} lineHeight={18} bold marginLeft={16}>
            {label}
          </Text>
        </View>
        <View>
          <Line />
          <View
            style={{
              ...Theme.flexRow,
              paddingHorizontal: 24,
              paddingBottom: 16,
            }}
          >
            <TextInput
              placeholderTextColor={theme.placeholder}
              placeholder={placeholder}
              value={text}
              style={{
                fontFamily: "Muli_400Regular",
                fontSize: 15,
                lineHeight: 18,
                color: theme.text,
                // height: scale(160),
                height: 160,
                marginTop: 12,
                flex: 1,
                borderWidth: 1,
                borderColor: errors.includes("text")
                  ? theme.lineErrorColor
                  : theme.innearColor,
              }}
              onChangeText={setText}
              multiline={true}
              editable={true}
              onBlur={() => onBlur("text")}
            />
          </View>
        </View>
      </Layout>
    );
  };
  const renderIndications = (label: string, placeholder: string) => {
    return (
      <Layout
        style={{
          marginTop: 24,
          paddingTop: 16,
          borderRadius: 16,
          borderWidth: 0,
          ...Theme.shadowHide,
          // ...Theme.shadow,
        }}
      >
        <View
          style={{
            ...Theme.flexRow,
            paddingHorizontal: 24,
            paddingBottom: 16,
          }}
        >
          <ButtonIcon
            icon={"pencil"}
            style={{
              width: 32,
              height: 32,
              backgroundColor: Colors.TealBlue20,
            }}
            tintColor={Colors.TealBlue}
            disabled
          />
          <Text size={15} lineHeight={18} bold marginLeft={16}>
            {label}
          </Text>
        </View>
        <View>
          <Line />
          <View
            style={{
              ...Theme.flexRow,
              paddingHorizontal: 24,
              paddingBottom: 16,
            }}
          >
            <TextInput
              placeholderTextColor={theme.placeholder}
              placeholder={placeholder}
              value={indications}
              onChangeText={setIndications}
              onBlur={() => onBlur("indications")}
              style={{
                fontFamily: "Muli_400Regular",
                fontSize: 15,
                lineHeight: 18,
                color: theme.text,
                height: scale(160),
                marginTop: 12,
                flex: 1,
                borderWidth: 1,
                borderColor: errors.includes("indications")
                  ? theme.lineErrorColor
                  : theme.innearColor,
              }}
              multiline={true}
              editable={true}
            />
          </View>
        </View>
      </Layout>
    );
  };

  const renderPracticesPrescription = useCallback(() => {
    return (
      <>
        <Layout
          style={[
            styles.containerBtnPractices,
            {
              borderColor: theme.lineColor,
              backgroundColor: props.dark
                ? theme.userInfo
                : theme.backgroundItem,
            },
          ]}
        >
          <TouchableOpacity
            onPress={onFocus}
            style={[Theme.flexRow, styles.containerBtnPracticesContent]}
          >
            <Image
              source={iconFeedActive}
              style={{ width: 32, height: 32, marginRight: 20 }}
            />
            <View style={{ flexDirection: "column" }}>
              <Text size={15} lineHeight={18} bold>
                {practices.length > 0
                  ? locales_es.add_more_practices
                  : locales_es.add_practices}
              </Text>
              {practices.length > 0 && (
                <Text left size={13} lineHeight={20} color={"#36c6c8"}>
                  {`${practices.length} Prácticas añadidas`}
                </Text>
              )}
              {/*<Text size={12} lineHeight={18} semibold>
              ({MAX_CERTIFICATE_PRESCRIPTIONS} {locales_es.max_practices})
            </Text>*/}
              <>
                {practices.length === 0 && (
                  <Text size={12} lineHeight={18} bold color="red">
                    {locales_es.no_practices_added}
                  </Text>
                )}
              </>
            </View>
            <Image
              source={iconArrowRight}
              style={{
                width: 16,
                height: 16,
                right: 24,
                position: "absolute",
                alignSelf: "center",
              }}
            />
          </TouchableOpacity>
          {practices && practices.length ? (
            <View
              style={{
                borderBottomColor: Colors.WhiteGray,
                borderBottomWidth: 0.5,
              }}
            />
          ) : null}
          {practices && practices.length ? (
            <ScrollView style={[styles.containerBtnPracticesContent]}>
              {practices.map((pr, index) => {
                return (
                  <TagItem
                    title={pr.name}
                    onPressX={() => onPressPracticeX(index)}
                  />
                );
              })}
            </ScrollView>
          ) : null}
          {practices && practices.length && practices.length > 1 ? (
            <ButtonText
              title={locales_es.clean}
              style={[styles.button, { marginVertical: 20 }]}
              onPress={() => setPractices([])}
            />
          ) : null}
        </Layout>

        <Layout
          style={{
            marginTop: 24,
            paddingTop: 16,
            borderRadius: 16,
            ...Theme.shadow,
          }}
        >
          <View />

          {renderFreePrescription(
            "Notas adicionales (Opcional)",
            "Agregue aquí aclaraciones si lo cree necesario"
          )}
          {renderIndications(
            `${locales_es.indications} (${locales_es.optional})`,
            locales_es.addPrescriptionIndications
          )}
        </Layout>
      </>
    );
  }, [practices, prescriptionProfileId, errors, text, indications]);

  const renderFeatureItem = useCallback(({ item, index }) => {
    const oddStyle = index % 2 === 0 ? { marginRight: 16 } : null;
    return <FeatureItem style={[oddStyle]} {...item} />;
  }, []);

  const imgStyle = { width: "50%", marginTop: 20 };
  const FEATURE = [
    {
      id: 4,
      img: FEATURE_ICON.healthTip,
      title: "Texto libre",
      route: Routes.AddPatient,
      imgStyle,
      onPress: () => setPrescriptionStyle(PRESCRIPTION_STYLE_FREE),
    },
    {
      id: 7,
      img: FEATURE_ICON.healthGuide,
      title: "Sistema de prácticas médicas",
      imgStyle,
      onPress: () => setPrescriptionStyle(PRESCRIPTION_STYLE_PRACTICES),
    },
  ];
  return (
    <>
      {overlay && <Overlay>{overlay}</Overlay>}
      {congrats && (
        <FullScreenGreenCheck
          text={locales_es.createdCertificateSuccessfully}
        />
      )}
      {loading && <FullScreenLoading text={loadingText} />}
      <Layout style={styles.header}>
        <HeaderButton
          style={{
            marginTop: 26,
            marginLeft: 24,
          }}
        />
        <Text
          // marginTop={getStatusBarHeight() + 24}
          marginTop={getStatusBarHeight() + 36}
          marginHorizontal={24}
          bold
          size={16}
          lineHeight={18}
          style={{ marginHorizontal: 24, marginBottom: 24, flex: 1 }}
        >
          Crear Prescripción
        </Text>
      </Layout>
      <Container style={styles.container}>
        <Content
          style={styles.container}
          showsVerticalScrollIndicator={false}
          extraHeight={100}
          extraScrollHeight={100}
          contentContainerStyle={styles.contentContainerStyle}
        >
          {prescriptionProfiles && (
            <View style={styles.section}>
              <PrescriptionProfiles
                {...userData}
                prescriptionProfiles={prescriptionProfiles}
                onProfileSelect={onPrescriptionProfileSelect}
                selectedProfileId={prescriptionProfileId}
                style={
                  errors.includes("prescriptionProfileId")
                    ? styles.sectionError
                    : null
                }
                editable={true}
              />
            </View>
          )}

          <Layout
            style={{
              marginBottom: 24,
              paddingTop: 16,
              borderRadius: 16,
              ...Theme.shadow,
            }}
          >
            <View
              style={{
                ...Theme.flexRow,
                paddingHorizontal: 24,
                paddingBottom: 16,
              }}
            >
              <ButtonIcon
                icon={"calendar"}
                style={{
                  width: 32,
                  height: 32,
                  backgroundColor: Colors.TealBlue20,
                }}
                imgStyle={styles.buttonIconImgStyle}
                tintColor={Colors.TealBlue}
                disabled
              />
              <Text size={15} lineHeight={18} bold marginLeft={16}>
                {dates.length > 1
                  ? locales_es.certificatesDates
                  : locales_es.certificateDate}
              </Text>
            </View>
            <View
              style={{
                flex: 1,
                paddingHorizontal: 24,
                paddingBottom: 16,
              }}
            >
              {dates && dates.length
                ? dates.map((_date: any, index: number) => {
                    return (
                      <View key={"datePicker-" + index}>
                        <FormNewDatePicker
                          withPortal={true}
                          portalId={"datePicker-" + index}
                          value={_date}
                          placeholder={locales_es.date}
                          // required={true}
                          handleChange={(date: any) => {
                            setDateArray(date, index);
                          }}
                          minDate={minDate}
                        />
                      </View>
                    );
                  })
                : null}
            </View>
          </Layout>

          <TouchableOpacity onPress={selectPatient}>
            {patientId && userData ? (
              <UserInfo user={userData} />
            ) : (
              <SelectPatient
                style={{
                  borderWidth: 1,
                  borderColor: errors.includes("patientId")
                    ? theme.lineErrorColor
                    : theme.innearColor,
                }}
              />
            )}
          </TouchableOpacity>

          {prescriptionStyle && enabledSelectionType ? (
            <ButtonText
              marginTop={60}
              title="Cambiar tipo de prescripción"
              style={styles.button}
              onPress={() => clearPrescriptionType()}
            />
          ) : null}
          {load ? (
            <ActivityIndicator
              style={{
                marginVertical: 30,
              }}
            />
          ) : !prescriptionStyle ? (
            <View>
              <Text
                marginTop={getStatusBarHeight() + 24}
                marginHorizontal={12}
                bold
                center
                size={24}
                lineHeight={28}
              >
                Elija tipo de prescripción
              </Text>
              <View
                style={[
                  styles.topView,
                  Theme.flexRowSpace,
                  { marginBottom: 30, justifyContent: "center" },
                ]}
              >
                <Text size={15} lineHeight={18} center>
                  Puede hacer una prescripción con texto libre, o puede utilizar
                  nuestro nuevo sistema de prácticas
                </Text>
              </View>
              <View
                style={[{ flexDirection: "row", justifyContent: "center" }]}
              >
                {FEATURE.map((feature, index) =>
                  renderFeatureItem({ item: feature, index })
                )}
              </View>
            </View>
          ) : prescriptionStyle === PRESCRIPTION_STYLE_FREE ? (
            <Layout
              style={{
                marginTop: 24,
                borderRadius: 16,
                ...Theme.shadow,
              }}
            >
              {renderFreePrescription(
                "Texto Libre",
                "Aquí puede generar recetas personalizadas, prescripción libre, indicaciones, etc."
              )}
              {renderIndications(
                `${locales_es.indications} (${locales_es.optional})`,
                locales_es.addPrescriptionIndications
              )}
            </Layout>
          ) : (
            renderPracticesPrescription()
          )}
          {!load && (
            <ButtonLinear
              white
              styleButton={{ flex: 1 }}
              title={locales_es.createCertificate}
              onPress={onSend}
              disabled={!prescriptionStyle}
            />
          )}
        </Content>
      </Container>
    </>
  );
});

export default AddCertificate;

const styles = StyleSheet.create({
  header: {
    ...Theme.flexRowSpace,
  },
  container: {
    ...Theme.flexOne,
  },
  topView: {
    marginTop: 32,
    marginBottom: 12,
    paddingHorizontal: 24,
  },
  contentContainerStyle: {
    paddingHorizontal: 24,
    paddingTop: 40,
    paddingBottom: getBottomSpace() + 120,
  },
  buttonText: {
    borderWidth: 0,
    height: 50,
  },
  section: {
    marginTop: 0,
    marginVertical: 24,
  },
  button: {
    borderWidth: 0,
  },
  spec: {
    marginTop: 8,
    ...Theme.flexRow,
    flexWrap: "wrap",
    paddingVertical: 16,
  },
  containerBtnPractices: {
    // padding: 24,
    borderRadius: 16,
    marginTop: 30,
    ...Theme.shadow,
    // ...Theme.flexRow,
  },
  containerBtnPracticesContent: {
    padding: 24,
  },
  buttonIconImgStyle: {
    width: 24,
    height: 24,
  },
  successImage: {
    width: scale(160, true),
    height: scale(160, true),
    marginBottom: scale(55, true),
  },
});
