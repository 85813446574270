import React, {Component} from 'react';
import validationEmail from "../../utils/validation/email";
import {CLASSNAME_INVALID_INPUT_FIELD, CLASSNAME_VALID_INPUT_FIELD} from "../../models/constants";

export default class Input extends Component {

  constructor(props) {
    super(props);

    this.state = {
      valid: null
    }
  }

  togglePasswordVisibility(ev) {
    const target = ev.target.previousSibling;
    const actualType = target.type;
    target.type = actualType === 'password' ? 'text' : 'password';

    const input = ev.target;
    input.classList.toggle('icon-eye__closed');
  }

  getInputByType(params) {
    const {id, value, placeholder, required, disabled, step, min, max, handleChange, customClassName, wrapperCustomClassName, onBlur, validate} = this.props;
    const defaultClassName = 'form-control rounded ';
    const className = customClassName ? (defaultClassName + customClassName) : defaultClassName;

    const defaultWrapperClassName = 'form-group ';
    const wrapperClassName = wrapperCustomClassName ? (defaultWrapperClassName + wrapperCustomClassName) : defaultWrapperClassName;

    const renderLabel = () => {
      return (
        this.props.label &&
        <>
          <label>{this.props.label} {this.props.required ? '*' : ''}</label><br/>
        </>
      )
    };

    switch (params) {
      case 'password':
        return (
          <div className={wrapperClassName}>
            {renderLabel()}
            <input className={className} type="password"
                   onChange={handleChange}
                   value={value}
                   placeholder={placeholder}
                   key={id}
                   required={required}
                   disabled={disabled}
                   id={id}
                   onBlur={onBlur}
            />
            <i onClick={(ev) => this.togglePasswordVisibility(ev)}
               className="icon-eye icon-eye__closed"></i>
            {this.props.advice &&
            <span className="form-text text-muted">{this.props.advice}</span>
            }
           <div style={{height: '30px'}}>
            {this.props.errorMessage && this.props.error && <small className="form-text text-danger">{this.props.errorMessage}</small>}

            </div>            
          </div>
        );
      case 'number':
        return (
          <div className={wrapperClassName}>
            {renderLabel()}
            <input className={className} type={params}
                   onChange={handleChange}
                   value={value}
                   placeholder={placeholder}
                   key={id}
                   required={required}
                   disabled={disabled}
                   step={step}
                   min={min}
                   max={max}
                   onBlur={onBlur}
            />
            <div style={{height: '30px'}}>
            {this.props.errorMessage && this.props.error && <small className="form-text text-danger">{this.props.errorMessage}</small>}

            </div>
          </div>
        );
      case 'textarea':
        return (
          <div className={wrapperClassName}>
            {renderLabel()}
            <textarea className={className}
                      onChange={handleChange}
                      value={value}
                      placeholder={placeholder}
                      key={id}
                      required={required}
                      disabled={disabled}
                      style={{
                        resize: 'none'
                      }}
                      onBlur={onBlur}
            />
            <div style={{height: '30px'}}>
            {this.props.errorMessage && this.props.error && <small className="form-text text-danger">{this.props.errorMessage}</small>}

            </div>
          </div>
        );
      case 'email':
        let classNameParsed = className;
        if (value && validate && this.state.valid !== null) {
          classNameParsed += (' ' + (this.state.valid ? CLASSNAME_VALID_INPUT_FIELD : CLASSNAME_INVALID_INPUT_FIELD));
        }
        return (
          <div className={wrapperClassName}>
            {renderLabel()}
            <input className={classNameParsed} type={params}
                   onChange={handleChange}
                   value={value}
                   placeholder={placeholder}
                   key={id}
                   required={required}
                   disabled={disabled}
                   maxLength={this.props.maxLength || undefined}
                   onBlur={(e) => {
                     if (validate) {
                       this.setState({valid: validationEmail(e.target.value)})
                     }
                     onBlur && onBlur();
                   }}
            />
            {this.props.children}
            <div style={{height: '20px'}}>
            {this.props.errorMessage && this.props.error && <small className="form-text text-danger">{this.props.errorMessage}</small>}

            </div>
          </div>
        );
      default:
        return (
          <div className={wrapperClassName}>
            {renderLabel()}
            <input className={className} type={params}
                   onChange={handleChange}
                   value={value}
                   placeholder={placeholder}
                   key={id}
                   required={required}
                   disabled={disabled}
                   onBlur={onBlur}
                   maxLength={this.props.maxLength || undefined}
            />
            {this.props.children}
            <div style={{height: '30px'}}>
            {this.props.errorMessage && this.props.error && <small className="form-text text-danger">{this.props.errorMessage}</small>}

            </div>
          </div>
        );
    }
  }
  ;

  render() {
    return (
      this.getInputByType(this.props.type)
    );
  }
}
