export default {
  medicLicense: "Matrícula",
  medicLicensePicture: "Foto de su DNI",
  medicLicensePictureDescription:
    "Envíanos una foto legible que acredite su identidad. Nos ayuda a fortalecer su seguridad y la de sus pacientes, y evita la eventual suspención de su cuenta.",
  shareAppInfoText:
    "Te invito a descargar RCTA desde https://rcta.me. Con esta app podrás generar recetas de forma rápida y segura.",
  darkMode: "Modo oscuro",
  inviteToApp: "Invite a sus colegas a probar RCTA.me",
  invite: "Invitar",
  about: "Sobre nosotros",
  support: "Ayuda y Soporte",
  medicalInsurancePlan: "Plan Médico",
  medicalInsuranceNumber: "Nº de Afiliado",
  recoveryPassword: "Recuperar Contraseña",
  forgotYourPassword: "¿Olvidaste tu contraseña?",
  sendEmail: "Enviar E-Mail",
  sendByEmail: "Enviar por E-Mail",
  sendByEmailPlural: "Enviarlas por E-Mail",
  shareByExternal: "Compartir por otras vías",
  shareByExternalPlural: "Compartirlas por otras vías",
  professionalPrefix: {
    1: "Doctor",
    2: "Doctora",
  },
  changePasswordInfo:
    "Escriba su contraseña actual, e ingrese dos veces su nueva contraseña para poder actualizarla",
  recoveryPasswordInfo:
    "Escriba la dirección de correo electrónico de su usuario para recuperar su contraseña",
  logoutModal: {
    title: "Cerrar Sesión",
    subtitle: "¿Está seguro de querer cerrar sesión?",
    primaryAction: "Cerrar Sesión",
  },
  removeImageModal: {
    title: "Eliminar",
    subtitle: "¿Está seguro de querer eliminar esta imagen?",
    primaryAction: "Sí, eliminar",
  },
  removeSignatureModal: {
    title: "Eliminar",
    subtitle: "¿Está seguro de querer eliminar su firma?",
    primaryAction: "Sí, eliminar",
  },
  deleteUser: {
    title: "Inactivar Paciente",
    subtitle: "¿Está seguro de querer inactivar este paciente?",
    primaryAction: "Sí, inactivar",
  },
  medicalInsuraceModalInfo: {
    title: 'Cobertura Médica',
    description: 'Aquí encontrarás una extensa lista con las coberturas médicas las cuales laboramos, seleccionando una de ellas podrás ingresar nombre del plan y número de afiliado del paciente. \n\nPuedes agregar las diferentes coberturas médicas que posee el paciente.',
    accept: 'Aceptar',
  },
  privacyPolicy: "Política de Privacidad",
  terms: "Términos y Condiciones",
  payConsultation: "PAGAR CONSULTA",
  payWithThisOption: "Pagar con estos medios",
  payWithBankTransfer: "Pagar por transferencia bancaria",
  bankTransferPayment:
    "Paga por transferencia bancaria y avisale al médico cuando completes la operación",
  transferThisAmount: "Transfiere",
  toThisBankAccount: "a esta cuenta bancaria",
  stampTitle: "Texto del Sello",
  customText: "Texto personalizado",
  stampPlaceholder: "Nombre y Apellido \nEspecialidad \nM. 627682762876",
  prescriptionAddress: "Dirección en tu receta",
  name: "Nombre",
  your_names: "Sus Nombres",
  lastname: "Apellido",
  your_lastnames: "Sus Apellidos",
  back: "Volver",
  close: "Cerrar",
  selectValue: "Seleccione...",
  clickToPhoneCall: "Haz click para llamar",
  editProfile: "EDITAR PERFIL",
  editPatient: "Editar Paciente",
  searchTypeSelector: {
    searchByBrand: "Buscar por Marca",
    searchByDrugs: "Buscar por Droga",
  },
  global: {
    errorTitle: "Error",
  },
  register: {
    welcome: "Bienvenido a RCTA!",
    intro: "Únase a los médicos de la próxima generación.",
    onRegisterAs: "Al registrarme como",
    iAcceptThe: "ACEPTO los",
    andThe: "y el",
    termsAndConditions: "Términos y Condiciones",
    privacyPolicy: "Aviso de Privacidad",
    for: "para",
    errorName: "Ingrese un nombre",
    errorEmail: "El E-Mail es inválido",
    errorSpecialtyMissing: "Seleccione al menos una especialidad",
    errorPassword: "Ingrese una contraseña",
    errorPasswordRepeatNotMatched: "Las contraseñas no coinciden",
    errorTerms: "Debe aceptar los Términos y Condiciones",
    errorTransferCategoryName: "Debe ingresar un nombre",
    errorProfileImageMissing: "Debe añadir una foto suya",
    terms: "Acepto los",
    signup: "¿Ya tiene cuenta?",
    successIntro:
      "Por favor, completa tu perfil y tu configuración de receta para empezar a usar la app.",
  },
  menu: {
    configuration: "Configuración",
    createPrescriptions: "Generar Recetas",
    myPrescriptions: "Mis Recetas",
    myPatients: "Mis Pacientes",
    otherPrescriptions: "Solicitudes de Estudios, Certificados y Otros",
  },
  sections: {
    contactPage: "Contacto",
    myProfileMedic: "Configuración",
  },
  unassignMedicConfirmModal: {
    unassignMedicTitle: "¿Está seguro de desvincularse de este médico?",
    unassignMedicSubtitle: "Confirme que desea desvincular este médico",
  },
  unassignChildMedicConfirmModal: {
    unassignMedicTitle: "¿Está seguro de desvincular este médico de su hijo?",
    unassignMedicSubtitle: "Confirme que desea desvincular este médico",
  },
  myChildren: {
    removeChildTitle: "¿Está seguro de remover este hijo?",
    removeChildSubtitle: "Confirme que desea remover este hijo",
  },
  myConsultations: {
    removeConsultationTitle: "¿Está seguro de eliminar esta consulta?",
    removeConsultationSubtitle: "Confirme que desea elimina esta consulta",
  },
  myAppointmentsModal: {
    removeAppointmentTitle: "¿Está seguro de eliminar esta cita?",
    removeAppointmentSubtitle: "Confirme que desea elimina esta cita",
  },
  myPayments: {
    removePaymentTitle: "¿Está seguro de eliminar este medio de pago?",
    removePaymentSubtitle: "Confirme que desea elimina este medio de pago",
  },
  indicationsList: {
    removeIndicationTitle: "¿Está seguro de eliminar esta indicación?",
    removeIndicationSubtitle: "Recuerde que el paciente ya no podrá verla",
  },
  signaturesList: {
    removeSignatureTitle: "¿Está seguro de eliminar esta firma?",
    removeSignatureSubtitle:
      "Recuerde que los pacientes ya no podrán verla ni estará disponible para firmar los documentos",
  },
  stampsList: {
    removeStampTitle: "¿Está seguro de eliminar este sello?",
    removeStampSubtitle:
      "Recuerde que los pacientes ya no podrán verlo ni estará disponible para sellar los documentos",
  },
  medicinesList: {
    removeMedicineTitle:
      "¿Está seguro de quitar esta medicamento de la receta actual?",
    removeMedicineSubtitle: "Esta acción no puede deshacerse",
  },
  prescriptionsList: {
    removePrescriptionTitle: "¿Está seguro de eliminar esta receta?",
    removePrescriptionSubtitle: "Recuerde que el paciente ya no podrá verla",
  },
  medicalOrdersList: {
    removeMedicalOrderTitle: "¿Está seguro de eliminar esta órden?",
    removeMedicalOrderSubtitle: "Recuerde que el paciente ya no podrá verla",
  },
  medicalCertificatesList: {
    removeMedicalCertificateTitle:
      "¿Está seguro de eliminar este prescripción libre?",
    removeMedicalCertificateSubtitle:
      "Recuerde que el paciente ya no podrá verlo",
  },
  viewConsultation: {
    confirmConsultationTitle: "¿Está seguro de finalizar esta consulta?",
    confirmConsultationSubtitle: "Confirme que desea finalizar esta consulta",
  },
  markAsPayedConfirm: {
    title: "¿Está seguro de marcar como pagada?",
    subtitle:
      "Utilice esta opción para marcar una consulta como cobrada. Recuerde que el paciente verá esta consulta como Pagada.",
  },
  imagePicker: {
    title: "Cambiar foto de perfil...",
    cancelButtonTitle: "Cancelar",
    takePhotoButtonTitle: "Sacar una foto",
    chooseFromLibraryButtonTitle: "Elegir de la galería de fotos",
  },
  consultationStatus: {
    created: "Creada",
    confirmed: "Confirmada",
    completed: "Completada",
    canceled: "Cancelada",
    rejected: "Rechazada",
    payment_rejected: "Pago Rechazado",
    pending_payment: "Pago Pendiente",
  },
  paymentStatus: {
    approved: "Aprobada",
    pending: "Pendiente de revisión",
    rejected: "Rechazada",
    tokenized: "Pendiente",
    payment_rejected: "Pago Rechazado",
    pending_payment: "Pago Pendiente",
  },
  movementStatus: {
    completed: "Aprobado",
    approved: "Aprobada",
    pending: "Pendiente",
    rejected: "Rechazada",
    tokenized: "Procesando medio de pago",
    payment_rejected: "Pago Rechazado",
    pending_payment: "Pago Pendiente",
  },
  testPrescription: "Receta de Prueba",
  possibleAssignmentPatientsTitle: "Pacientes que puedes vincular",
  noAvailablePatientsToAssign:
    "No tiene pacientes para vincular con este médico",
  myChildrenMedics: "Médicos de sus hijos",
  adultsEnabled: "Atiende adultos",
  childsEnabled: "Atiende niños",
  specialty: "Especialidad",
  specialties: "Especialidades",
  retry: "Reintentar",
  issuedBy: "emitida por",
  checkingYourPayments: "Comprobando sus medios de pago",
  thisCouldTakeALong: "Esto puede demorar un poco...",
  cardEndingIn: "Tarjeta terminada en",
  cantUnassignMedicActiveConsultation:
    "No puedes desvincularte con un médico mientras tengas una consulta activa",
  select: "Seleccionar",
  bookAppointment: "Solicitar Cita",
  myAppointments: "Mis Citas",
  seeAppointments: "Ver Citas",
  future: "Futuro",
  appointment: "Cita",
  appointments: "Citas",
  appointmentsFuture: "Citas",
  appointmentsPast: "Citas Pasados",
  hour: "Hora",
  status: "Estado",
  chat: "Chat",
  weight: "Peso en kgs.",
  patientWeight: "Peso del paciente en kgs.",
  consultationReasonTitle: "Motivo de la consulta",
  consultationDescriptionTitle: "Descripción",
  consultationInformation: "Información de consulta",
  consultationPrice: "Precio de la consulta",
  paymentOption: "Medio de pago",
  availablePaymentOptions: "Medio de pago disponible",
  freeConsultation: "Consulta sin cargo",
  patientData: "Datos del paciente",
  medicToAddConsultation: "Médico a consultar",
  remove: "Quitar",
  identificationType: "Tipo de Identificación",
  accountType: "Tipo de Cuenta",
  birth_date: "Fecha de nacimiento",
  prescriptionDate: "Fecha de la receta",
  prescriptionsDates: "Fechas de las recetas",
  parentRole: "Rol con tu hijo",
  presentation: "Presentación",
  gender: "Género",
  save: "Guardar",
  clean: "Limpiar",
  erase: "Eliminar",
  send: "Enviar",
  add: "Agregar",
  initConsultation: "Iniciar consulta",
  seeActiveConsultation: "Ver consulta activa",
  emptySearch: "Ingrese un texto para buscar",
  notEnoughSearch: "Ingrese más de 3 caracteres para iniciar la búsqueda",
  notEnoughChildren: "Debe ingresar los datos de su hijo.",
  addYourFirstChild: "Ingrese ahora los datos de tu hijo.",
  search: "Buscar",
  signatures: "Firmas",
  signature: "Firma",
  stamps: "Sellos personales",
  stamp: "Sello personal",
  clarifies: "Aclaraciones",
  searchPrescription: "Buscar receta...",
  searchMedicine: "Buscar medicamento...",
  noChildren: "No tiene hijos ingresados en el sistema.",
  noChildrenByPatient: "Este paciente no tiene hijos ingresados en el sistema.",
  noIndication: "Esta consulta no tiene indicaciones.",
  noSignature: "Usted no tiene ninguna firma digital configurada.",
  noStamp: "Usted no tiene ningún sello configurado.",
  noMedicines: "Esta receta no tiene medicamentos añadidas",
  tapAddMedicineToBegin: 'Aprete "Añadir medicamento" para empezar.',
  noPrescriptions: "No tiene recetas aquí",
  noMedicalOrder: "Esta consulta no tiene órdenes.",
  noConsultation: "No tiene consultas en el sistema.",
  noActiveConsultation: "No tiene consultas activas por el momento.",
  noFAQs: "No hay preguntas frecuentes disponibles.",
  noPayments: "No tiene medios de pago activos.",
  paymentGatewayEcuador: "Pagos Procesados por PlaceToPay",
  paymentGatewayMercadoPago:
    "Pagos con todas las tarjetas de crédito y débito, o en Efectivo en Agentes Bancarios y por banca por Internet. Pagos seguros a través de Mercado Pago.",
  paymentGatewayBankTransfer:
    "Paga a través de transferencia bancaria desde su cuenta del banco a la cuenta del médico.",
  noMedicalCertificates: "Esta consulta no tiene prescripción libre.",
  noMovements: "Sin movimientos hasta el momento.",
  noFutureAppointments: "Sin citas futuros.",
  noPastAppointments: "Sin citas pasados.",
  addYourChildren: "Ingrese su hijo a la plataforma ahora",
  addAnIndication: "Ingrese una indicación para el paciente",
  addASignature: "Añada una firma digital para sus documentos",
  addAStamp: "Añada un sello para sus documentos",
  addAMedicine: "Añada una medicamento para el paciente",
  addAPrescription: "Ingrese una receta para el paciente",
  requestAPrescription:
    "Si la consulta está activa, solicitala a su médico a través del chat",
  patientMissing: "Selecciona un paciente para adjuntar esta receta",
  addAnIndicationAtLeast: "Ingrese al menos una indicación para el paciente",
  addAnMedicalOrder: "Ingrese una órden para el paciente",
  requestAMedicalOrder: "Solicitala a su médico desde el chat",
  addAMedicalCertificate: "Ingrese un prescripción libre para el paciente",
  requestAMedicalCertificate:
    "Si la consulta está activa, solicitalo a su médico a través del chat",
  addAnMedicalOrderAtLeast: "Ingrese al menos una órden para el paciente",
  addYourConsultation: "Ingrese su primera consulta",
  addAPaymentMethod: "Agrega un medio de pago ahora.",
  addPaymentMethod: "Agregar medio de pago",
  availabilityInfoAboutMedicalDirectory:
    "Encender esta funcionalidad implica que los usuarios pacientes podrán encontrarte en el directorio médico de Ánima e iniciar consultas médicas contigo.",
  preventReprintPrescriptionsInfo:
    "Encender esta funcionalidad implica que los usuarios pacientes podrán descargar las recetas una única vez. Si necesita ver la receta más de una vez, tendrá que pedir una nueva.",
  message: "Mensaje",
  hivPatient: "Paciente HIV",
  duplicatedPrescription: "Receta duplicada",
  extendedTreatment: "Tratamiento prolongado",
  prescriptOnlyGeneric: "Recetar sólo genérico",
  noReplacement: "No sustituir",
  needToAddAMedicineAtLeast: "Debes agregar al menos un medicamento",
  unlockYourPhoneToCreatePrescription:
    "Desbloquear el teléfono para emitir la receta",
  addPrescriptionDiagnosis: "Añada aquí el diagnóstico de la receta",
  addPrescriptionIndications: "Añada aquí las indicaciones para el paciente",
  addPrescriptionComments:
    "Lugar para texto libre competente a cualquier aclaración general de la receta",
  addChild: "Añadir hijo/a",
  addPatient: "Añadir paciente",
  addConsultation: "Iniciar consulta",
  consultation: "Consulta",
  open: "Abierta",
  addPrescription: "Añadir receta",
  addIndication: "Añadir indicación",
  removeIndication: "Remover indicación",
  addSignature: "Añadir firma digital",
  removeSignature: "Remover firma digital",
  writeStamp: "Escriba aquí su sello personal",
  addStamp: "Añadir sello",
  removeStamp: "Remover sello ",
  addMedicine: "Añadir medicamento",
  maxMedicineReached: "Se permiten 3 medicamentos máximo",
  addAnotherMedicine: "Añadir más medicamento",
  removeMedicine: "Remover medicamento",
  seePrescription: "Ver Receta",
  addEmailField: "Añadir campo de E-mail",
  removeEmailField: "Remover campo de E-mail",
  editIndication: "Editar indicación",
  editSignature: "Editar firma digital",
  editPrescription: "Editar receta",
  addMedicalOrders: "Añadir Órdenes Médicas",
  diagnosis: "Diagnóstico",
  icd10Title: "Diagnóstico (Opcional)",
  icd10AddTitle: "Buscar Diagnóstico",
  icd10AddSuggestedTitle: "Diagnósticos Sugeridos",
  icd10Search: "Ingrese un texto para buscar...",
  icd10AddButton: "Agregar diagnóstico",
  icd10RemoveButton: "Quitar diagnóstico",
  code: "Código",
  otherConfigurations: "Otras configuraciones",
  indications: "Indicaciones",
  indication: "Indicación",
  prescriptions: "Recetas",
  quantity: "Cantidad",
  thisDoctorIsCallingYou: "Este médico está queriendo conectarse contigo",
  medicines: "Medicamentos",
  prescriptionConfig: {
    title: "Configuración Receta",
    goToConfig: "Configurar",
    prescriptionConfigNotSettedWarning:
      "Necesita configurar su receta correctamente para poder generar recetas nuevas",
    prescriptionHeaderSetted:
      "Imagen del encabezado de la receta. Cambia la imagen desde aquí.",
    prescriptionHeaderNotSetted:
      "Encabezado de la receta NO definido. Suba una imagen de encabezado aquí.",
    uploadingHeaderImage: "Subiendo imagen...",
    headerImageSizeInfo: "Tamaño ideal: 1770px por 412px",
  },
  prescription: "Receta",
  medical_orders: "Órdenes de Estudio",
  medical_order: "Órden de Estudio",
  noSelectedMedicalOrdersTemplates:
    "No has seleccionado ningún ítem. Debes seleccionar al menos uno para poder continuar.",
  addMedicalOrder: "Añadir órden",
  removeMedicalOrder: "Remover órden",
  editMedicalOrder: "Editar órden",
  medical_certificates: "Prescripiones",
  medical_certificate: "Prescripción",
  noSelectedMedicalCertificatesTemplates:
    "Debes seleccionar uno para poder continuar.",
  addMedicalCertificate: "Añadir prescripción libre",
  removeMedicalCertificate: "Remover prescripción libre",
  selectMedicalCertificateTemplate: "Seleccione Prescripción",
  editMedicalCertificate: "Editar prescripción libre",
  viewMedicalCertificate: "Ver prescripción libre",
  viewDocument: "Ver documento",
  pendingPaymentConsultation: "Consulta Pendiente de Pago",
  orPayedWithBankTransfer:
    "Consulta impaga. Si pagaste a través de transferencia bancaria, puedes ignorar este mensaje.",
  inactiveConsultation: "Consulta Inactiva",
  completeConsultation: "Finalizar Consulta",
  youGot: "Tienes",
  acceptVideocall: "Aceptar Videollamada",
  rejectedVideocallByPatient:
    "Llamada rechazada. Le avisaremos a su médico que no puedes atenderlo en estos momentos.",
  rejectVideocall: "Rechazar Videollamada",
  initVideocall: "Iniciar Videollamada",
  joinVideocall: "Unirse a la Videollamada",
  endedVideocallError:
    "Esta videollamada ha terminado. El médico puede iniciar una nueva si fuese necesario.",
  loadingVideocall: "Cargando videollamada...",
  enteringVideocall: "Ingresando a la videollamada...",
  endingVideocall: "Cerrando videollamada...",
  goToChat: "Ir al Chat",
  requestVaccines: "Solicitar Vacunas",
  requestCertificates: "Solicitar Prescripciones",
  certificate: "Prescripción",
  certificates: "Prescripciones",
  seeIndications: "Ver indicaciones",
  createIndication: "Crear Indicación",
  createMedicalOrder: "Crear Órden",
  requestAppointment: "Solicitar Cita",
  createAppointment: "Crear Sobrecita",
  seeExamRequests: "Ver Ordenes de Estudio",
  createExamRequest: "Crear Orden de Estudio",
  requestExam: "Solicitar Órden de Estudio",
  exams: "Órdenes de Estudio",
  requestRecipe: "Solicitar Receta",
  recipes: "Recetas",
  seePrescriptions: "Ver Recetas",
  createPrescription: "Generar receta",
  createPrescriptions: "Generar recetas",
  creatingPrescription: "Generando receta",
  creatingPrescriptions: "Generando recetas",
  createdPrescription: "Receta creada",
  createdPrescriptions: "Recetas creadas",
  createdPrescriptionSuccessfully: "Creación exitosa",
  seeCertificates: "Ver Prescripciones",
  otherPrescriptions: "Otras prescripciones",
  createCertificate: "Generar Prescripción",
  creatingCertificate: "Generando Prescripción",
  createdCertificate: "Prescripción creada",
  createdCertificateSuccessfully: "Prescripción generada exitosamente",
  protectedPrescriptions: "Recetas protegidas",
  protectedPrescriptionsInfo:
    "Su teléfono puede pedirle su pin o su huella dactilar para emitir recetas, lo que evita que alguien emita una receta con sus datos si capturan su teléfono",
  prescriptionsActivatedNow:
    "Puede desactivar la opción desde la pantalla Configuración",
  prescriptionsActivatedLater:
    "Puede activar la opción cuando lo desee desde la pantalla Configuración",
  invalidAccess: "El acceso es inválido",
  required: "Requerido",
  generated: "Generada",
  activateNow: "Activar ahora",
  maybeLater: "Quizás después",
  configuration: "Configuración",
  addFAQ: "Enviar FAQ",
  orYouCanSearchInOurMedicalDirectory:
    "O puedes buscar un médico en nuestro directorio...",
  searchAnotherMedicInTheMedicalDirectory: "Buscar en directorio",
  typeToSearchYourMedic:
    "Ingrese el nombre de su médico de cabecera para encontrarlo en Ánima",
  typeToSearch: "Ingrese un texto de búsqueda",
  typeAndSearch: "Ingrese un texto y pulse buscar",
  addMedicinesToGetICD10Results:
    "Ingrese medicamentos a su receta para encontrar aquí los ICD10 relacionados",
  ICD10ResultsNotFound:
    "No se encontraron ICD10 relacionados a las medicamentos que indicaste",
  ICD10SearchNotFound: "No se encontraron ICD10 relacionados a su búsqueda.",
  searchNotFound: "No se encontraron resultados",
  children: "Hijos/Hijas",
  child: "Hijo/a",
  credit_card_debit: "Tarjeta de crédito/débito",
  pediatrician: "Médico",
  searchPatient: "Buscar paciente",
  patient: "Paciente",
  medic: "Médico",
  medics: "Médicos",
  confirmAssignMedicTitle: "¿Asignar este médico?",
  confirmAssignMedicSubtitle:
    "Acepta para vincular y hacer consultas con este médico",
  confirmUnassignMedicTitle: "¿Desvincular este médico?",
  confirmUnassignMedicSubtitle:
    "¿Desea desvincularse de este médico? Ya no podrá realizar consultas con este médico.",
  noChildrenMedicsAssigned: "Tus hijos no tienen médicos vinculados",
  noMedicsAssigned: "No tiene médicos vinculados",
  assignThisMedic: "Vinculate con este médico",
  unassignThisMedic: "Desvincular médico",
  cantCreateConsultationActiveConsultation:
    "No puedes crear una consulta nueva mientras tengas otra consulta todavía activa.",
  selectAtLeastAPatientToAsignMedic:
    "Selecciona al menos un paciente para vincular con el médico seleccionado",
  selectMedic: "Selecciona un médico",
  selectPatient: "Selecciona un paciente",
  assignMedic: "Vincular médico",
  assignedMedic: "Médico Vinculado",
  assignedMedics: "Médicos Vinculados",
  assignMoreMedic: "Vincula más médicos",
  assignMoreMedic2:
    "Puedes vincular médicos de distintas especialidades y hacer consultas para usted y/o sus hijos",
  download: "Descargar",
  downloadedSuccesfully: "Descargado con Éxito",
  downloadFailed:
    "Falló la descarga. Revise los permisos de la aplicación para escribir archivos.",
  medicAssignedSuccesfully: "Médico Vinculado con Éxito",
  unassignMedic: "Desvincular a tú médico",
  updateAppTitle: "Debe actualizar la aplicación para poder continuar.",
  updateAppMessage: "Su versión instalada es demasiado antigua.",
  updateText: "Actualizar",
  version: "Versión",
  loading: "Cargando",
  uploadingImage: "Subiendo imagen...",
  whatsappShareInvitationTitle: "Invite pacientes nuevos",
  whatsappShareInvitationTitlePatients: "Invite a su médico",
  whatsappShareInvitationSubtitle:
    "Envíeles una invitación para vincularse con usted a través de Ánima",
  whatsappShareInvitationButtonText: "Invitar por WhatsApp",
  whatsappShareButtonText: "Compartir por WhatsApp",
  whatsapp_share_text_intro_patients: "*Hola, soy",
  whatsapp_share_text_intro: "*Soy su médico/a",
  whatsapp_share_text:
    " recibo consultas como las suyas vía WhatsApp a diario... pero *ahora uso ÁNIMA*, una herramienta diseñada *especialmente para consultas médicas*. Por favor escríbame directo por esta app. Es segura, y hace que *toda su información*, al igual que la de todos mis pacientes esté *resguardada y ordenada*.\n\n Le invito a usarla descargándola para Android desde ['url-play-store'] o para iPhone desde ['url-ios-store']. \n\n *Además* puedo generar *recetas online* en cualquier momento, que servirán para *reembolso* con aseguradoras.\n\n Puedes ver mas información aquí: https://appanima.mx",
  whatsapp_share_text_patients:
    " *Te invito a usar ÁNIMA*, una herramienta diseñada *especialmente para consultas médicas*. Es más segura, y hace que *toda nuestra información* esté *resguardada y ordenada*.\n\n Te invito a usarla descargándola para Android desde ['url-play-store'] o para iPhone desde ['url-ios-store']. \n\n *Además* podemos generar *recetas online* en cualquier momento, que servirán para *reembolso* en nuestra aseguradora.\n\n Puedes ver mas info aquí: https://appanima.mx",
  whatsapp_share_text_success: "Gracias por invitar a sus pacientes a unirse",
  whatsapp_share_text_error:
    "No se pudo compartir por WhatsApp, pero aún puedes invitar a sus pacientes a descargar la aplicación desde https://appanima.mx, a buscarte y a contactarte directamente.",
  whatsapp_share_config_error:
    "No se pudo compartir por un problema de configuración, pero aún puedes invitar a sus pacientes a descargar la aplicación desde https://appanima.mx, a buscarte y a contactarte directamente.",
  shareChatTitle: "Comparte por chat",
  shareChatSubtitle: "Elige el chat de la consulta en la que quieras compartir",
  completeAllFields: "Complete todos los campos",
  confirmLogoutModal: {
    title: "¿Está seguro?",
    subtitle: "¿Quiere cerrar sesión?",
    accept: "Salir",
    cancel: "Cancelar",
  },
  confirmBackAlert: {
    title: "¿Está seguro de volver atrás?",
    subtitle: "Perderá su configuración actual",
    accept: "Sí, volver atrás",
    cancel: "No, no volver",
  },
  confirmNoIdentificationImage: {
    title: "¿Está seguro de continuar?",
    subtitle:
      "No adjuntó una imagen que acredite su identidad. Esto le permitirá utilizar la plataforma pero puede incurrir en potenciales bloqueos de su usuario si no podemos corroborar sus datos fehacientemente",
    accept: "Sí, dejar para más tarde",
    cancel: "Quiero adjuntarla ahora",
  },
  successModal: {
    title: "Éxito",
  },
  errorModal: {
    title: "Error",
    checkData: "Revise sus datos",
    accept: "Aceptar",
    unexpectedErrorHasOcurred: "Ha ocurrido un error inesperado",
  },
  infoModal: {
    title: "Información",
    checkData: "Revise sus datos",
    accept: "Aceptar",
  },
  quotationExpiredErrorModal: {
    title: "Error",
    subtitle:
      "Ha pasado demasiado tiempo para confirmar este viaje. Por favor, solicite un viaje nuevo.",
    accept: "Aceptar",
  },
  userDeniedGeolocationErrorModal: {
    title: "No se pudo obtener su ubicación",
    subtitle:
      "Debe conceder permisos de geolocalización a nuestra app para poder hacer uso de esta funcionalidad",
    accept: "Aceptar",
  },
  prescriptionDatesInfo: {
    title: "Recetas Posdatadas",
    content:
      "Esta es la fecha de prescripción de la receta. Si añade múltiples fechas, se generará una receta por cada fecha que agregue, ideal para emitir varias copias de esta receta con diferentes fechas cada una. Útil para tratamientos prolongados o crónicos que necesitan varias recetas en el año.",
    accept: "Aceptar",
  },
  toast: {
    null: {
      created: "Se creó ${x} documento",
      createdPlural: "Se crearon ${x} documentos",
    },
    undefined: {
      created: "Se creó ${x} documento",
      createdPlural: "Se crearon ${x} documentos",
    },
    Prescription: {
      created: "Se creó ${x} receta",
      createdPlural: "Se crearon ${x} recetas",
    },
    Certificate: {
      created: "Se creó ${x} prescripción",
      createdPlural: "Se crearon ${x} prescripciones",
    },
  },
  functionNotGuaranteed:
    "El correcto funcionamiento de esta prestación no está garantizado. Por favor, revise los permisos de la aplicación",
  userType: {
    medic: "Médico",
    patient: "Paciente",
  },
  confirmAccountValidationModal: {
    title: "Le hemos enviado un e-mail para que valide su cuenta.",
    subtitle:
      "Revise su casilla de correo para poder usar la aplicación de forma ininterrumpida.",
    accept: "Ya validé mi cuenta",
    resend: "Reenviar e-mail",
  },
  writeYourCustomPresentation:
    "Escriba la presentación que necesite para poder continuar",
  customPresentation: "Presentación personalizada...",
  prescriptionToSend: "Se enviará ${x} receta",
  prescriptionsToSend: "Se enviarán ${x} recetas en un mismo e-mail",
  documentVisorLoadedFailed: "El documento no se cargó correctamente",
  age: "Edad",
  year: "Año",
  years: "Años",
  month: "Mes",
  months: "Meses",
  day: "Día",
  days: "Días",
  role: "Rol",
  patients: "Pacientes",
  hasNot: "No tiene",
  no_patients: "No tiene pacientes vinculados",
  enjoy_ride: "¡Disfruta del viaje!",
  wait_please: "Espere, por favor",
  domain: "Patente",
  driver_waiting_at_origin: "El chófer está esperandote",
  currency_sign: "MXN $",
  price: "Precio",
  setPriceToContinueEvenZero:
    "Configure un precio para continuar, aunque sea 0",
  quotate_trip: "Cotizar Viaje",
  google_directions_failed:
    "La solicitud de direcciones falló por lo siguiente:",
  select_origin: "Selecciona origen del viaje",
  country: "País",
  accept: "Aceptar",
  birthDate: "Fecha de Nacimiento",
  user: "Usuario",
  username: "Nombre de Usuario",
  phoneNumber: "Teléfono Móvil",
  password: "Contraseña",
  actualPassword: "Contraseña actual",
  newPassword: "Nueva contraseña",
  repeatNewPassword: "Repetir Nueva contraseña",
  updatePassword: "Actualizar contraseña",
  changePassword: "Cambiar contraseña",
  repeatPassword: "Repetir Contraseña",
  login: "Iniciar sesión",
  new_user: "¿Usuario nuevo?",
  signUp: "Registrarse",
  facebook: "Facebook",
  google: "Google",
  fullname: "Nombre(s)",
  email: "E-Mail",
  preexisting_diseases: "Antecedentes. Ej.: alergias, etc.",
  takes_medication: "¿Toma alguna medicación?",
  preexisting_medication_by_comma: "Remedios separados por coma",
  preexisting_medication: "¿Toma alguna medicación?",
  preexistences: "Antecedentes",
  medication: "Medicación",
  resendDocument: "Reenviar Documento",
  optional: "Opcional",
  professional_registration: "Cédula Profesional",
  professional_registration_number: "Nº de Cédula Profesional",
  identification: "Nº de Documento",
  create_password: "Generar Contraseña",
  confirm_password: "Confirmar Contraseña",
  sign_up_now: "Registrarse ahora",
  already_registered: "¿Ya tiene usuario?",
  verification: "Verificación",
  goToConfiguration: "Ir a Configuración",
  notNow: "Ahora no",
  dontWantToCharge: "No quiero cobrar",
  next: "Siguiente",
  enter_confirmation_code: "Ingrese su código de confirmación",
  notCorrectlyPaymentMethodAndPriceSetup:
    "No podrás cobrar sus consultas si no configuras tu precio y sus medios de pago",
  sent_to_you_via_sms: "enviado a través de SMS !",
  skip: "Saltear",
  add_money_to_your_wallet_for: "Agregar dinero a tu billetera virtual por",
  quick_payment: "pago rápido",
  card_number: "Número de Tarjeta",
  expiry_date: "Fecha de Expiración",
  cvv_code: "Código de la Tarjeta",
  inter_amout_in: "Ingresar monto (en $)",
  referral_code: "¿Tienes un código de referencia?",
  add_referral_code: "Agregar código de referencia y",
  first_cab_ride_free: "obtén tu primer viaje con descuento.",
  i_dont_have: "No tengo",
  continue: "Continuar",
  add_6_digit_referral_code: "Ingrese su código de referencia de 6 digitos",
  from: "Desde",
  to: "Hasta",
  tab_to_add_location: "Tap para agregar locación",
  your_location: "Tu localización",
  wallet: "Billetera Virtual",
  movement: "Movimiento",
  movements: "Movimientos",
  cash: "Efectivo",
  choose_cab_type: "Elige el tipo de móvil",
  request_cab: "Solicitar vehículo",
  schedule_pick_up: "Agendar solicitud de vehículo",
  select_date: "Seleccionar Fecha",
  addDate: "Añadir Fecha",
  removeDate: "Eliminar Fecha",
  date: "Fecha",
  select_time: "Seleccionar Hora",
  cancel: "Cancelar",
  confirm: "Confirmar",
  confirm_trip: "¿Confirmar Viaje?",
  finding_your_ride: "Buscando Tu Viaje",
  driver_info: "Información del Chófer",
  cancel_ride: "Cancelar Viaje",
  arriving_in: "Llegando en",
  contact: "Contacto",
  trip_info: "Información del Viaje",
  distance: "Distancia",
  traveling_time: "Tiempo de recorrido",
  arrival: "Arribo",
  goBack: "Volver",
  pay: "Pagar",
  markAsPayed: "Marcar como Pagada",
  payment: "Pago",
  receipt: "Recibo",
  fare_summary: "Resumen de Tarifas",
  cash_pay: "Pagar en Efectivo",
  wallet_pay: "Pago con Billetera Virtual",
  choose_payment_mode: "Cambiar método de pago",
  submit_rating: "Calificar y pagar",
  rate_now: "Calificar ahora",
  view_profile: "Ver Perfil",
  home: "Inicio",
  my_trips: "Mis viajes",
  help: "Ayuda",
  blog: "Blog",
  refer_and_earn: "Referir y ganar",
  logout: "Cerrar Sesión",
  contact_number: "Teléfono",
  your_referral_code: "Tu Código de Referencia",
  referral_code_meassage:
    "Comparte este código de referencia y obtén un 30% de descuento en tu próximo viaje",
  past: "Pasado",
  upcoming: "Próximamente",
  balance: "Balance",
  available_balance: "Saldo Disponible",
  send_to_bank: "Enviar a tu Banco",
  add_money: "+ Agregar dinero",
  enter_bank_info: "Ingrese Información del Banco",
  account_number: "Nº de Cuenta",
  account_holder_name: "Titular de la Cuenta",
  bank: "Banco",
  bank_code: "CBU",
  enter_amount_to_transfer: "Ingrese monto a transferir",
  enter_amount_to_transfer_in: "Ingrese monto a transferir (en $)",
  proceed: "Proceda",
  trips_and_fare: "Viajes y Tarifas",
  choose_your_issue: "Elige tu inconveniente",
  any_issue_regarding_your_trip_and_fare:
    "Inconvenientes con sus viajes o tarifas",
  problem_while_paying_fare_or_related_issue: "Problemas con sus pagos",
  app_usability: "Usabilidad de la Aplicación",
  any_issue_while_using_our_app: "Problemas para usar la apicación",
  account: "Cuenta",
  your_account_info_can_t_change_details_or_change_password:
    "La información de tu cuenta no se puede modificar",
  otherInformationAsign: "Asígnele un nombre",
  otherInformationInfo:
    'De esta manera, podrá reconocer este perfil de receta fácilmente\nentre otros diferentes que puede agregar después. Ejemplo:"Consultorio", "Hospital" o "Sanatorio".',
  prescriptionInfo:
    "Al finalizar el proceso, podrá revisar cómo quedó su receta o editarla a su gusto",
  updateInformation: {
    avatar:
      "Se usará para que su paciente lo reconozca más fácilmente, aunque no es obligatorio",
  },
  workProfile: {
    title: 'Información Profesional',
    intro:
      "Completa estos datos para comenzar a generar tu recetario. Son datos mínimos necesarios.",
  },
  medicine_duplicate: 
    'El medicamento ya ha sido agregado a la lista de medicamentos',
  addPractice: "Agregar Práctica",
  deleteAllPractices: "Eliminar todas las prácticas",
};
