import Colors from './Colors';
import React, {useContext} from 'react';
import {ColorValue} from 'react-native';
import {DARK_MODE_TAG, LIGHT_MODE_TAG} from '../models/constants';

export type TMode = 'dark' | 'light';

export interface ITheme {
  placeholder: string | ColorValue | undefined;
  background: ColorValue | string;
  backgroundItem: string;
  text: ColorValue | string;
  textDisabled: ColorValue | string;
  activeBackgroundColor: ColorValue | string;
  inactiveBackgroundColor: ColorValue | string;
  activeTincolor: ColorValue | string;
  backgroundHeader: ColorValue | string;
  textCalender: string;
  lineColor: ColorValue | string;
  lineErrorColor: ColorValue | string;
  innearColor: ColorValue | string;
  modalColor: ColorValue | string;
  userInfo: ColorValue | string;
  searchBox: ColorValue | string;
  chatBox: ColorValue | string;
}
export interface IThemeContext {
  theme: ITheme;
  toggleTheme: () => void;
}

export const themes = {
  dark: {
    mode: DARK_MODE_TAG,
    background: Colors.DarkLicorice,
    backgroundItem: Colors.DarkJungleGreen,
    text: Colors.White,
    textDisabled: Colors.WhiteGray,
    textBlur: Colors.GrayBlue,
    placeholder: Colors.WhiteOpacity,
    activeBackgroundColor: Colors.TealBlue,
    activeTincolor: Colors.WhiteGray,
    inactiveBackgroundColor: Colors.Platinum,
    backgroundHeader: Colors.Black,
    textCalender: Colors.White,
    // lineColor: Colors.RaisinBlack,
    lineColor: Colors.InnearColor,
    lineErrorColor: Colors.RedNeonFuchsia,
    innearColor: Colors.InnearColor,
    userInfo: Colors.RaisinBlack,
    modalColor: Colors.DarkShadow,
    searchBox: Colors.RaisinBlack,
    chatBox: Colors.LightChatBox,
  },
  light: {
    mode: LIGHT_MODE_TAG,
    background: Colors.Snow,
    backgroundItem: Colors.White,
    text: Colors.DarkJungleGreen,
    textDisabled: Colors.GrayBlue,
    textBlur: Colors.GrayBlue,
    // placeholder: Colors.Isabelline,
    placeholder: '#ccc',
    activeTincolor: Colors.DarkJungleGreen,
    inactiveBackgroundColor: Colors.DarkJungleGreen,
    activeBackgroundColor: Colors.TealBlue,
    backgroundHeader: Colors.White,
    textCalender: Colors.RaisinBlack,
    // lineColor: Colors.Snow,
    lineColor: Colors.Isabelline,
    lineErrorColor: Colors.Tomato,
    innearColor: Colors.Platinum,
    userInfo: Colors.WhiteSmoke,
    // searchBox: Colors.Isabelline,
    searchBox: Colors.White,
    modalColor: Colors.White,
    chatBox: Colors.ChatBox,
  },
};

export const ThemeContext = React.createContext({
  theme: themes.light,
  toggleTheme: () => {},
});

export const useTheme = (): IThemeContext => {
  const {theme, toggleTheme} = useContext(ThemeContext);
  return {
    theme,
    toggleTheme,
  };
};
