import React, { useState } from "react";
import {
  View,
  StyleSheet,
  TouchableOpacity,
  ImageBackground,
  ActivityIndicator,
  Image,
  Switch,
} from "react-native";
import Text from "../../../components/Text";
import HeaderButton from "../../../components/HeaderButton";
import Theme from "../../../style/Theme";
import InputApp from "../../../components/InputApp";
import { Colors } from "../../../configs";
import ButtonLinear from "../../../components/Buttons/ButtonLinear";
import ButtonAdd from "../../../components/Buttons/ButtonAdd";
import Container from "../../../components/Layout/Container";
import FullScreenLoading from "../../../components/fullscreenLoading";
import Overlay from "../../../components/Overlay";

import locales_es from "../../../locales/es";
import Content from "../../../components/Layout/Content";
import ImgLoadAndCrop from "../../../components/imgLoadAndCrop";
import AddSignature from "../../AddSignature";
import iconWarning from "../../../images/img_warning@3x.png";
import useOtherInformationPage from "../shared/useOtherInformationPage";
import ButtonBorder from "../../../components/Buttons/ButtonBorder";

interface OtherInformationProps {}

const OtherInformation = (props: OtherInformationProps) => {
  const {
    stamp,
    errors,
    prescriptionProfileId,
    theme,
    imgLoadAndCrop,
    uploadImage,
    setCropping,
    stampRef,
    clinicId,
    translations,
    address,
    setAddress,
    showWarningInfo,
    signatureImage,
    cropping,
    loading,
    loadingText,
    logoImage,
    onBlur,
    onRemoveLogoImage,
    onRemoveSignatureImage,
    onSend,
    openCrop,
    openMenuOption,
    setStamp,
    stampErrorFlag,
    stampRequiredError,
    setStampEditorEnabled,
    defaultStampPreview,
    stampEditorEnabled,
    setStampRequiredError,
    validateCustomStamp,
    showStep,
    stepTitle,
    firstProfile,
    nameStep,
    setNameStep,
    name
  } = useOtherInformationPage();

  const [overlay, setOverlay] = useState(false);

  const goToAddSignature = () => {
    // history.push(`/${Routes.AddSignature}`, {prescriptionProfileId});
    setOverlay(
      <AddSignature
        showMainModal={(
          title,
          content,
          actions,
          customStyles,
          contentCustomClassName
        ) =>
          props.showModal(
            title,
            content,
            actions,
            customStyles,
            contentCustomClassName
          )
        }
        hideMainLoading={props.hideMainLoading}
        onFinish={() => {
          setOverlay(null);
        }}
        prescriptionProfileId={prescriptionProfileId}
      />
    );
  };

  const renderWarningSign = () => {
    return (
      <Text size={13} lineHeight={20} marginTop={10} marginLeft={5}>
        <Image
          source={iconWarning}
          style={{ height: 14, width: 14 }}
          resizeMode="contain"
        />{" "}
        {locales_es.nonEditableProfileField}.
      </Text>
    );
  };

  return (
    <Container style={styles.container}>
      {overlay && <Overlay>{overlay}</Overlay>}
      {loading && <FullScreenLoading text={loadingText} />}
      <ImgLoadAndCrop
        ref={imgLoadAndCrop}
        silentMode={true}
        image={logoImage}
        inputLabel={"Imagen"}
        imageId={"logoImage"}
        imageState={"logoImage"}
        inputWrapperCustomClassName="col-12 pl-md-0"
        canvasCustomClassName="profile"
        saveCrop={uploadImage}
        deleteCrop={() => console.log("deleteCrop")}
        deletedImage={() => console.log("deleteImage")}
        onCropping={() => setCropping(true)}
        onStopCropping={() => setCropping(false)}
      />
      {!cropping && (
        <Content
          style={styles.container}
          showsVerticalScrollIndicator={false}
          extraHeight={100}
          extraScrollHeight={100}
          contentContainerStyle={Theme.updateProfileContentScrollStyle}
        >
          <HeaderButton />
          {showStep && <Text size={13} lineHeight={16} bold marginTop={32}>
            { (stepTitle && !firstProfile) ? stepTitle : '¡Último Paso!' }
          </Text>}
          <Text size={24} lineHeight={28} bold marginTop={16}>
            Configuración de Receta
          </Text>
          <Text size={13} lineHeight={22} marginTop={16}>
            {translations
              ? translations.prescriptionInfo
              : locales_es.prescriptionInfo}
          </Text>
          <View style={styles.centerContent}>
            <Text size={17} lineHeight={20} bold>
              Logo / Ícono de Consultorio o Centro Médico
            </Text>
            {logoImage === null ? (
              <ActivityIndicator />
            ) : logoImage ? (
              <>
                <TouchableOpacity
                  onPress={clinicId ? showWarningInfo : openMenuOption}
                  style={styles.licenseImage}
                >
                  <ImageBackground
                    resizeMode="contain"
                    style={styles.image}
                    source={logoImage}
                  />
                </TouchableOpacity>
                {!clinicId && (
                  <TouchableOpacity
                    style={Theme.alignSelfCenter}
                    onPress={onRemoveLogoImage}
                  >
                    <Text
                      size={13}
                      lineHeight={16}
                      color={Colors.GrayBlue}
                      semibold
                      center
                      marginTop={24}
                      textDecorationLine={"underline"}
                    >
                      {locales_es.erase}
                    </Text>
                  </TouchableOpacity>
                )}
              </>
            ) : (
              <ButtonAdd title={"Agregar Imagen"} onPress={openCrop} />
            )}
            {clinicId && (
              <View style={{ alignItems: "center", marginTop: 10 }}>
                {renderWarningSign()}
              </View>
            )}
          </View>
          <View style={styles.centerContent}>
            <Text size={17} lineHeight={20} bold>
              {locales_es.signature}
            </Text>
            {signatureImage === null ? (
              <ActivityIndicator />
            ) : signatureImage ? (
              <>
                <TouchableOpacity
                  onPress={goToAddSignature}
                  style={styles.licenseImage}
                >
                  <ImageBackground
                    resizeMode="contain"
                    style={styles.image}
                    source={signatureImage}
                  />
                </TouchableOpacity>
                <TouchableOpacity
                  style={Theme.alignSelfCenter}
                  onPress={onRemoveSignatureImage}
                >
                  <Text
                    size={13}
                    lineHeight={16}
                    color={Colors.GrayBlue}
                    semibold
                    center
                    marginTop={24}
                    textDecorationLine={"underline"}
                  >
                    {locales_es.erase}
                  </Text>
                </TouchableOpacity>
              </>
            ) : (
              <ButtonAdd title={"Agregar Firma"} onPress={goToAddSignature} />
            )}
          </View>
          <View style={styles.centerContent}>
            <Text size={17} lineHeight={20} bold>
              Dirección
            </Text>
            <InputApp
              title={locales_es.prescriptionAddress}
              placeholder={locales_es.prescriptionAddress}
              marginTop={38}
              onChangeText={setAddress}
              value={address}
              borderColor={
                errors.includes("address")
                  ? theme.lineErrorColor
                  : theme.innearColor
              }
              onBlur={() => onBlur("address")}
              blurOnSubmit={true}
              // onSubmitEditing={onSubmit}
              autoCapitalize="words"
              returnKeyType="next"
              autoFocus={false}
              onSubmitEditing={() => {
                stampRef.current.focus();
              }}
              editable={!clinicId}
              onPress={clinicId ? showWarningInfo : null}
              // onPress={onGoToChangeAddress}
            />
            {clinicId && renderWarningSign()}
          </View>
          <View style={styles.centerContent}>
            <Text size={17} lineHeight={40} bold>
              {translations ? translations.stamp : ""}
            </Text>

            <View style={styles.switchContainer}>
              <Text style={styles.switchLabel}>{translations?.customText}</Text>
              <Switch
                value={!!stampEditorEnabled}
                onValueChange={(e: any) => {
                  setStampEditorEnabled(e);
                  if (stamp == "") {
                    setStamp(defaultStampPreview);
                  }
                }}
              />
            </View>

            {!stampEditorEnabled ? (
              <View
                style={{
                  padding: "1em",
                  border: "1px solid lightgray",
                  borderRadius: 8,
                  marginTop: "1em",
                }}
              >
                <Text
                  style={{
                    fontSize: "1.1em",
                    fontWeigth: "bold",
                    color: "black",
                  }}
                >
                  {defaultStampPreview}
                </Text>
              </View>
            ) : (
              <>
                <InputApp
                  style={{ height: 95, padding: 10 }}
                  forwardRef={stampRef}
                  value={stamp}
                  marginTop={10}
                  onChangeText={(text: any) => {
                    if (text == "") {
                      setStampRequiredError(true);
                    } else if (stampRequiredError) {
                      setStampRequiredError(false);
                    }
                    validateCustomStamp({ stamp: text });
                    setStamp(text);
                  }}
                  borderColor={
                    stampRequiredError
                      ? theme.lineErrorColor
                      : theme.innearColor
                  }
                  autoCapitalize="words"
                  returnKeyType="next"
                  autoFocus={false}
                  multiline={true}
                />
                <Text
                  size={13}
                  lineHeight={16}
                  color={
                    stampErrorFlag || stampRequiredError ? "red" : "#e67e22"
                  }
                  marginTop={5}
                  marginLeft={5}
                >
                  {stampRequiredError
                    ? locales_es.stampRequired
                    : locales_es.stampLimitWarning}
                </Text>
              </>
            )}
          </View>
          <ButtonLinear
            white
            title={locales_es.continue}
            style={styles.sendButton}
            disabled={stampRequiredError}
            onPress={onSend}
          />
        </Content>
      )}

      {nameStep && (
          <View style={styles.modalContainer}>
            <Text size={20} lineHeight={24} bold marginTop={55}>
              {translations && translations.otherInformationAsign
                ? translations.otherInformationAsign
                : locales_es.otherInformationAsign}
            </Text>
            <View style={{ width: "100%" }}>
              <InputApp
                onChangeText={setName}
                value={name}
                borderColor={
                  errors.includes("name")
                    ? theme.lineErrorColor
                    : theme.innearColor
                }
                onBlur={() => onBlur("name")}
                blurOnSubmit={true}
                autoCapitalize="words"
                returnKeyType="go"
                autoFocus={false}
                onSubmitEditing={onSubmit}
              />
            </View>
            <Text size={15} lineHeight={24} marginTop={16} center>
              {translations && translations.otherInformationInfo
                ? translations.otherInformationInfo
                : locales_es.otherInformationInfo}
            </Text>
            <ButtonLinear
              white
              title={"Guardar"}
              style={{ paddingHorizontal: 32 }}
              onPress={onSubmit}
            />
            <ButtonBorder
              style={{ marginTop: 10, paddingHorizontal: 32 }}
              title={"Volver"}
              onPress={() => setNameStep(false)}
              placeholder
            />
          </View>
        )}
    </Container>
  );
};

export default OtherInformation;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    paddingTop: 24,
  },
  inputApp: {
    marginTop: 24,
    ...Theme.flexDirection,
  },
  yearInput: {
    width: 100,
    marginLeft: 8,
  },
  buttonAdd: {
    marginTop: 24,
    borderWidth: 1,
    borderColor: Colors.Platinum,
    height: 36,
    ...Theme.center,
    borderRadius: 8,
    ...Theme.flexDirection,
  },
  iconRight: {
    width: 16,
    height: 16,
    marginRight: 8,
  },
  centerContent: {
    // marginTop: scale(32),
    marginTop: 32,
    // paddingTop: scale(48),
    paddingTop: 48,
    borderTopColor: Colors.TealBlue,
    borderTopWidth: 1,
  },
  npiNumber: {
    ...Theme.flexOne,
    marginLeft: 8,
  },
  licenseNumber: {
    ...Theme.flexOne,
    marginRight: 8,
  },
  licenseImage: {
    marginTop: 24,
    minHeight: 160,
    borderRadius: 8,
    backgroundColor: "#fff",
    // paddingHorizontal: scale(52),
    paddingHorizontal: 52,
  },
  iconButton: {
    ...Theme.icons,
    marginRight: 8,
  },
  award: {
    // marginTop: scale(32),
    marginTop: 32,
    // paddingTop: scale(32),
    paddingTop: 32,
    borderTopColor: Colors.TealBlue,
    borderTopWidth: 1,
  },
  sendButton: {
    // marginTop: scale(52),
    marginTop: 52,
  },
  genders: {
    marginTop: 24,
    ...Theme.flexRow,
    ...Theme.center,
  },
  address: {
    marginTop: 32,
  },
  image: {
    flex: 1,
    justifyContent: "center",
  },
  modalContainer: {
    ...Theme.container,
    ...Theme.center,
    flex: 1,
    position: "absolute",
    zIndex: 900,
    top: 0,
    right: 0,
    left: 0,
    bottom: 0,
    backgroundColor: "#fff",
  },

  switchContainer: {
    flexDirection: "row",
    alignItems: "center",
    marginBottom: 10,
  },
  switchLabel: {
    marginRight: 10,
  },
});
