import React, {memo} from 'react';
import {
  View,
  StyleSheet,
  Image,
  ImageSourcePropType,
  ViewStyle,
} from 'react-native';
import Text from '../../../components/Text';
import Theme from '../../../style/Theme';
import Layout from '../../../components/Layout/Layout';
import {useTheme} from '../../../configs/Theme';
import iconAccountNormal from '../../../images/ic_account_normal@3x.png';
import iconArrowRight from '../../../images/ic_arrow_right@3x.png';

interface SelectPatientProps {
  user?: {
    name: string;
    gender: string;
    age: number;
    phone: string;
    img: ImageSourcePropType;
  };
  style?: ViewStyle;
  dark?: boolean;
}

const SelectPatient = memo((props: SelectPatientProps) => {
  const {theme} = useTheme();
  return (
    <Layout
      style={[
        styles.container,
        {
          borderColor: theme.lineColor,
          backgroundColor: props.dark ? theme.userInfo : theme.backgroundItem,
        },
        props.style,
      ]}>
      <Image
        source={iconAccountNormal}
        style={{width: 32, height: 32, marginRight: 24}}
      />
      <View>
        <Text size={15} lineHeight={18} bold>
          Elegir Paciente
        </Text>
      </View>
      <Image
        source={iconArrowRight}
        style={{
          width: 16,
          height: 16,
          right: 24,
          position: 'absolute',
          alignSelf: 'center',
        }}
      />
    </Layout>
  );
});

export default SelectPatient;

const styles = StyleSheet.create({
  container: {
    padding: 24,
    borderRadius: 16,
    ...Theme.shadow,
    ...Theme.flexRow,
  },
});
