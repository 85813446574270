import React from "react";
import { StyleSheet, TouchableOpacity, ViewStyle } from "react-native";
import LinearColors from "../../../components/LinearColors";
import Text from "../../../components/Text";
import Theme from "../../../style/Theme";
import { Colors } from "../../../configs";
import ButtonBorder from "../ButtonBorder";
import { useTheme } from "../../../configs/Theme";

interface ButtonLinearProps {
  testID?: string;
  title: string;
  style?: ViewStyle;
  onPress?: () => void;
  children?: any;
  leftChildren?: any;
  styleButton?: ViewStyle;
  disabled?: boolean;
  white?: boolean;
  black?: boolean;
  colors?: string[];
}

const ButtonLinear = ({
  testID,
  title,
  style,
  onPress,
  children,
  styleButton,
  leftChildren,
  white,
  black,
  disabled,
  colors,
}: ButtonLinearProps) => {
  const { theme } = useTheme();
  if (disabled) {
    return (
      <ButtonBorder
        testID={testID}
        black={black}
        title={title}
        style={{ ...styles.container, ...styleButton }}
        backgroundColor={theme.placeholder}
        borderColor={Colors.Isabelline}
      />
    );
  }
  return (
    <TouchableOpacity
      testID={testID}
      style={styleButton}
      activeOpacity={0.54}
      onPress={onPress}
    >
      <LinearColors
        style={{ ...styles.container, ...style }}
        vertical
        locations={[0, 0.75]}
        colors={colors || [Colors.TurquoiseBlue, Colors.TealBlue]}
      >
        {leftChildren}
        {/*<Text white color={Colors.White} type="H5" bold>*/}
        <Text white={white} type="H5" bold center>
          {title}
        </Text>
        {children}
      </LinearColors>
    </TouchableOpacity>
  );
};

export default ButtonLinear;

const styles = StyleSheet.create({
  container: {
    height: 50,
    marginTop: 8,
    borderRadius: 12,
    ...Theme.center,
    ...Theme.flexRow,
  },
});
