import React from "react";
import { StyleSheet, View } from "react-native";
import Text from "../../components/Text";
import { Colors } from "../../configs";
import locales_es from "../../locales/es";

const Stepper = ({step = 1, hasTwoSteps}: {step?: number, hasTwoSteps?: boolean}) => {
  return (
    <View>
      <ul style={styles.stepperWrapper}>
        <li style={{ ...styles.stepperItem, ...styles.stepperFirstItem, ...hasTwoSteps ? styles.stepperItemTwoSteps: {} }}>
          <View
            style={[
              styles.stepperCircle,
              styles.stepperActiveCircle,
              styles.stepperFirstCircle,
            ]}
          />
          <Text size={16}>{locales_es.basicInformation_page.step_one}</Text>
        </li>
        <li style={{ ...styles.stepperItem, ...styles.stepperSecondItem }}>
          {!hasTwoSteps && <View style={[styles.stepperCircle, step >= 2 ? styles.stepperActiveCircle:{}]} />}
         {!hasTwoSteps && <Text size={16}>{locales_es.basicInformation_page.step_two}</Text>}
          <View style={[styles.stepperLine, step >= 2 ? styles.stepperActiveLine : {}, hasTwoSteps ? styles.twoStepsLine : {}]} />
        </li>
        <li style={{ ...styles.stepperItem, ...styles.stepperLastItem, ...hasTwoSteps ? styles.stepperItemTwoSteps: {} }}>
          <View style={[styles.stepperCircle, styles.stepperLastCircle, step >= 3 ? styles.stepperActiveCircle:{}]} />
          <Text size={16}>{locales_es.basicInformation_page.step_three}</Text>
          <View style={[styles.stepperLine, styles.stepperLastLine, step >= 2 ? styles.stepperActiveLine : {}]} />
        </li>
      </ul>
    </View>
  );
};

export default Stepper;

const styles = StyleSheet.create({
  stepperWrapper: { padding: 0 },
  stepperItem: {
    listStyleType: "none",
    float: "left",
    width: "33%",
    position: "relative",
    fontSize: 16,
    fontWeight: "600",
  },
  stepperItemTwoSteps: {
    width: '50%'
  },
  stepperFirstItem: { textAlign: "left" },
  stepperSecondItem: { textAlign: "center" },
  stepperLastItem: { textAlign: "right" },
  stepperCircle: {
    lineHeight: 40,
    borderRadius: 20,
    width: 40,
    height: 40,
    border: "1px solid #ddd",
    textAlign: "center",
    margin: "auto",
    marginBottom: 8,
    backgroundColor: Colors.WhiteGray,
  },
  stepperActiveCircle: {
    borderColor: Colors.TealBlue,
    backgroundColor: Colors.TealBlue,
  },
  stepperFirstCircle: {
    margin: 0,
  },
  stepperLastCircle: {
    margin: 0,
    marginLeft: "auto",
  },
  stepperLine: {
    position: "absolute",
    height: 2,
    backgroundColor: "#ddd",
    top: 20,
    zIndex: -1,
    width: "150%",
    left: "-100%",
  },
  twoStepsLine: {
    left: "-150%",
  },
  stepperActiveLine: {
    backgroundColor: Colors.TealBlue
  },
  stepperLastLine: {
    width: "150%",
    left: "-50%",
  },
});
