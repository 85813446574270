import React from "react";
import iconNext from "../../../images/ic_next.png";

import { View, StyleSheet, Image, Switch } from "react-native";
import Text from "../../../components/Text";
import { useTheme } from "../../../configs/Theme";
import Theme from "../../../style/Theme";
import InputApp from "../../../components/InputApp";
import ButtonLinear from "../../../components/Buttons/ButtonLinear";
import FullScreenLoading from "../../../components/fullscreenLoading";
import ButtonBorder from "../../../components/Buttons/ButtonBorder";
import locales_es from "../../../locales/es";
import Stepper from "../../BasicInformation/stepper";
import { getStatusBarHeight } from "react-native-iphone-x-helper";
import usePaymentInformationPage from "../shared/usePaymentInformationPage";
import {
  InitForm,
  handleValidatorInputsTypes,
} from "../../../utils/validation/forms";
import scale from "../../../utils/scale";
import { Colors } from "../../../configs";
import Layout from "../../../components/Layout/Layout";
import HeaderButton from "../../../components/HeaderButton";
import InputAppNumber from "../../../components/InputAppNumber";

interface PaymentInformationProps {}

const PaymentInformation = (props: PaymentInformationProps) => {
  const { theme } = useTheme();

  const {
    activeAccount,
    amount,
    errors,
    errorMessages,
    loading,
    name,
    nameStep,
    payLink,
    reason,
    showModal,
    translations,
    clinicData,
    isClinicPaylink,
    fieldsOptional,
    initValues,
    handleSave,
    setActiveAccount,
    setAmount,
    setEnableButton,
    setErrors,
    setErrorMessages,
    setName,
    setNameStep,
    setPayLink,
    setReason,
    onBlur,
    onBlurAmount,
    onDelete,
    onMaxlink,
    onMinAmount,
    onSubmit,
    onSendAccount,
    setFieldsOptional,
    setWithPaymentData
  } = usePaymentInformationPage();

  const initForm: InitForm = {
    setErrors,
    errors,
    setErrorMessages,
    errorMessages,
    setEnableButton,
    initValues,
  };

  const payLinkRef: any = React.useRef();
  const reasonRef: any = React.useRef();
  const amountRef: any = React.useRef();

  return (
    <View style={styles.container}>
      {loading && <FullScreenLoading text={"Cargando..."} />}
      <View style={styles.content}>
        {<Stepper step={3} hasTwoSteps={false} />}
        <View style={styles.headerWrapper}>
          <HeaderButton />
        </View>
        <View style={styles.pageContent}>
          <View style={styles.formContainer}>
            <Text size={13} lineHeight={16} bold marginTop={10}>
              {"¡Último Paso!"}
            </Text>
            <Text size={24} lineHeight={28} bold marginTop={16}>
              {locales_es.chargerConfig}
            </Text>
            <View style={styles.form}>
              <View style={styles.formRow}>
                <View style={styles.flexGrow}>
                  <View style={styles.switchContainer}>
                    <Text style={styles.switchLabel}>
                      {locales_es.requestPayment}
                    </Text>
                    <Switch
                      value={activeAccount}
                      onValueChange={(value: boolean) => {
                        setActiveAccount(value);
                      }}
                      disabled={clinicData!==null}
                    />
                  </View>
                  {activeAccount && (
                    <>
                      <InputApp
                        testID="inputPayLink"
                        title={`${locales_es.payLink} *`}
                        placeholder={locales_es.payLink}
                        value={payLink}
                        onChangeText={(value: any) => {
                          let optional = false;
                          if (value == '' && clinicData) {
                            setFieldsOptional(true);
                            optional = true;
                          } else {
                            setFieldsOptional(false);
                          }
                          handleValidatorInputsTypes( initForm, value, "link", setPayLink, "payLink", 20, optional );
                          onMaxlink(value);
                        }}
                        borderColor={
                          errors.includes("payLink")
                            ? theme.lineErrorColor
                            : theme.innearColor
                        }
                        blurOnSubmit={true}
                        autoCapitalize="words"
                        returnKeyType="next"
                        autoFocus={false}
                        marginTop={scale(5)}
                        onSubmitEditing={() => {
                          payLinkRef.current.focus();
                        }}
                        maxLength={255}
                          style={(isClinicPaylink) ? styles.inputDisabled : undefined}
                        disabled={isClinicPaylink}
                      />

                      {errors.includes("payLink") && (
                        <Text
                          size={13}
                          lineHeight={16}
                          color="red"
                          marginTop={5}
                          marginLeft={5}
                        >
                          {errorMessages.payLink}
                        </Text>
                      )}
                      
                      <InputApp
                        testID="inputReason"
                        title={locales_es.reason}
                        placeholder={locales_es.reason}
                        placeholderTextColor={theme.placeholder}
                        value={reason}
                        borderColor={
                          errors.includes("reason")
                            ? theme.lineErrorColor
                            : theme.innearColor
                        }
                        style={(isClinicPaylink) ? styles.inputDisabled : {
                          fontFamily: "Muli_400Regular",
                          fontSize: 15,
                          lineHeight: 18,
                          color: theme.text,
                          height: scale(30),
                          marginTop: 4,
                        }}
                        onChangeText={(value: any) => {
                          handleValidatorInputsTypes( initForm, value, "", setReason, "reason", 25, fieldsOptional );
                        }}
                        multiline={true}
                        editable={true}
                        blurOnSubmit={true}
                        autoCapitalize="words"
                        returnKeyType="next"
                        autoFocus={false}
                        showRequired={true}
                        onSubmitEditing={() => {
                          reasonRef.current.focus();
                        }}
                        marginTop={scale(5)}
                        disabled={isClinicPaylink}
                      />

                      {errors.includes("reason") && (
                        <Text
                          size={13}
                          lineHeight={16}
                          color="red"
                          marginTop={5}
                          marginLeft={5}
                        >
                          {errorMessages.reason}
                        </Text>
                      )}
                      <Text type="H6" semibold>
                        {`${locales_es.amount} *`}
                      </Text>
                      <InputAppNumber 
                        value={amount} 
                        placeholder={locales_es.amount} 
                        thousandSeparator="." 
                        decimalSeparator="," 
                        decimalScale={2}
                        style={(isClinicPaylink) ? styles.inputDisabled : undefined}
                        borderColor={
                          errors.includes("amount")
                            ? theme.lineErrorColor
                            : theme.innearColor
                        }
                        onChangeText={(data: any) => {
                          handleValidatorInputsTypes( initForm, data.value, "currency", setAmount, "amount", 0, fieldsOptional );
                        }}
                        marginTop={scale(5)}
                        disabled={isClinicPaylink}
                        />
                      {errors.includes("amount") && (
                        <Text
                          size={13}
                          lineHeight={16}
                          color="red"
                          marginTop={5}
                          marginLeft={5}
                        >
                          {errorMessages.amount}
                        </Text>
                      )}
                    </>
                  )}
                </View>
              </View>
              <View style={styles.cta}>
                <ButtonLinear
                  white
                  title={(isClinicPaylink || (clinicData && !clinicData.paylink_enabled)) ? "Continuar" : "Guardar"}
                  style={styles.buttonLinear}
                  children={
                    <Image source={iconNext} style={styles.buttonChildren} />
                  }
                  onPress={() => handleSave(true)}
                  disabled={
                    errors.length ||
                    ((activeAccount && (!amount || !reason || !payLink) && (!clinicData || (!isClinicPaylink && clinicData && payLink))))
                  }
                />
              </View>
            </View>
          </View>
        </View>
        {showModal && (
          <View style={styles.containerModal}>
            <Layout
              style={{
                borderRadius: 16,
                paddingHorizontal: 24,
                paddingVertical: 32,
                maxWidth: 800,
              }}
            >
              <Text center size={20} lineHeight={20} bold>
                {locales_es.modalChargerAccount.title}
              </Text>
              <Text center size={15} lineHeight={22} marginTop={16}>
                {locales_es.modalChargerAccount.description}
              </Text>
              <View style={styles.centerButtonModal}>
                <ButtonLinear
                  white
                  title={locales_es.cancel}
                  onPress={() => {
                    onDelete();
                  }}
                  style={{ width: "150px" }}
                  colors={[Colors.Tomato, Colors.Tomato]}
                />
                <ButtonLinear
                  white
                  title={locales_es.accept}
                  onPress={() => {
                    onSendAccount();
                  }}
                  style={{ width: "150px" }}
                />
              </View>
            </Layout>
          </View>
        )}
      </View>
      {nameStep && (
        <View style={styles.modalContainer}>
          <Text size={20} lineHeight={24} bold marginTop={55}>
            {translations && translations.otherInformationAsign
              ? translations.otherInformationAsign
              : locales_es.otherInformationAsign}
          </Text>
          <View style={{ width: "100%" }}>
            <InputApp
              onChangeText={setName}
              value={name}
              borderColor={
                errors.includes("name")
                  ? theme.lineErrorColor
                  : theme.innearColor
              }
              onBlur={() => onBlur("name")}
              blurOnSubmit={true}
              autoCapitalize="words"
              returnKeyType="go"
              autoFocus={false}
              onSubmitEditing={onSubmit}
            />
          </View>
          <Text size={15} lineHeight={24} marginTop={16} center>
            {translations && translations.otherInformationInfo
              ? translations.otherInformationInfo
              : locales_es.otherInformationInfo}
          </Text>
          <ButtonLinear
            white
            title={"Guardar"}
            style={{ paddingHorizontal: 32 }}
            onPress={onSubmit}
          />
          <ButtonBorder
            style={{ marginTop: 10, paddingHorizontal: 32 }}
            title={"Volver"}
            onPress={() => setNameStep(false)}
            placeholder
          />
        </View>
      )}
    </View>
  );
};

export default PaymentInformation;

const styles = StyleSheet.create({
  licenseImage: {
    marginTop: 16,
    minHeight: 170,
    borderRadius: 8,
    backgroundColor: "#fff",
    paddingHorizontal: 52,
  },
  sendButton: {
    marginTop: 52,
  },
  image: {
    flex: 1,
    justifyContent: "center",
  },
  modalContainer: {
    ...Theme.container,
    ...Theme.center,
    flex: 1,
    position: "absolute",
    zIndex: 900,
    top: 0,
    right: 0,
    left: 0,
    bottom: 0,
    backgroundColor: "rgba(255,255,255,1)",
  },
  centerButtonModal: {
    marginTop: 32,
    gap: 20,
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
  },
  nameStepWrapper: {
    backgroundColor: "#fff",
  },
  buttonLinear: {
    marginTop: 32,
  },
  container: { flex: 1, minHeight: "100%" },
  content: {
    minHeight: "100%",
    paddingBottom: 24,
    paddingLeft: 24,
    paddingRight: 24,
    paddingTop: getStatusBarHeight(),
  },
  headerWrapper: {
    flexDirection: "column",
    alignItems: "flex-start",
    maxWidth: "100%",
    marginTop: 32,
  },
  pageContent: {
    flexDirection: "row-reverse",
    flexWrap: "wrap",
    justifyContent: "flex-end",
    marginTop: 32,
  },
  backgroundImageWrapper: {
    width: "40%",
    minWidth: 400,
    alignItems: "flex-start",
    marginBottom: 32,
  },
  backgroundImage: { width: 420, height: 380 },
  formContainer: { flex: 1, minWidth: 400 },
  form: { marginTop: 24 },
  formRow: {
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    gap: 16,
  },
  cta: { width: 200 },
  flexGrow: { flex: 1 },
  containerModal: {
    backgroundColor: Colors.Black68,
    ...StyleSheet.absoluteFillObject,
    paddingHorizontal: 24,
    justifyContent: "center",
    position: "fixed",
    alignItems: "center",
  },
  imgUpload: {
    flex: 1,
    border: "2px dashed grey",
    borderRadius: 16,
    marginTop: 16,
    justifyContent: "center",
    alignItems: "center",
    padding: 48,
  },
  switchContainer: {
    flexDirection: "row",
    alignItems: "flex-start",
    marginTop: 16,
    gap: 16,
  },
  inputDisabled: {
    backgroundColor: '#f7f7f7'
  }
});
