import React, {memo, useRef, useState, useCallback, useEffect} from 'react';
import {View, StyleSheet, ViewStyle, TextStyle, Animated} from 'react-native';
import ScrollTab from './ScrollTab';
import {width} from '../../configs/Const';
import Container from '../../components/Layout/Container';

interface Props {
  titles: string[];
  children: any;
  tabStyle?: ViewStyle;
  renderHeader?: any;
  labelStyle?: TextStyle;
  titleSize?: number;
  titleFocusStyle?: boolean;
}

const ScrollableTab = memo(
  ({
     titles,
     children,
     tabStyle,
     renderHeader,
     labelStyle,
     titleSize,
     titleFocusStyle,
   }: Props) => {
    const [layouts, setLayouts] = useState<number[]>([]);
    const [pointers, setPointers] = useState<number[]>([]);
    const [indexPage, setIndex] = useState(0);
    const scrollX = useRef(new Animated.Value(0)).current;

    useEffect(() => {
      const points = [0];
      let res = 0;
      for (let i = 1; i < titles.length; i++) {
        points.push(res += width);
      }
      setPointers(points);
    }, [])

    const scrollRef: any = useRef();
    const tabScrollRef: any = useRef();

    const onScrollTo = useCallback(
      (index: number, isPress?: boolean) => {
        let _layouts = [...layouts];
        _layouts.sort(function (a, b) {
          return a - b;
        });
        if (isPress) {
          setIndex(index);
        }
        tabScrollRef.current.scrollTo({
          x: _layouts[index] - 28,
          y: 0,
          animated: true,
        });
      },
      [layouts],
    );
    const onPressTab = useCallback(
      (index: number) => {
        scrollRef.current
          .getNode()
          .scrollTo({x: width * index, y: 0, animated: true});
        onScrollTo(index);
      },
      [onScrollTo],
    );

    const _renderHeader = useCallback(() => {
      if (!renderHeader) {
        return null;
      }
      if (renderHeader) {
        return renderHeader(indexPage);
      }
    }, [renderHeader, indexPage]);

    return (
      <Container style={styles.container}>
        {_renderHeader()}

        <View style={[styles.scrollableTab, tabStyle]}>
          <ScrollTab
            {...{
              scrollX,
              titles,
              onPressTab,
              tabScrollRef,
              onScrollTo,
              indexPage,
              setIndex,
              layouts,
              setLayouts,
              labelStyle,
              titleSize,
              titleFocusStyle,
            }}
          />
        </View>
        <Animated.ScrollView
          ref={scrollRef}
          horizontal
          pagingEnabled
          scrollEventThrottle={16}
          onScroll={(event) => {
            Animated.event(
              [{nativeEvent: {contentOffset: {y: scrollX}}}],
              {useNativeDriver: false},
            );
            pointers.map((point, index) => {
              if (event.nativeEvent.contentOffset.x === point) {
                setIndex(index);
              }
            })
          }}
          showsHorizontalScrollIndicator={false}>
          {children}
        </Animated.ScrollView>
      </Container>
    );
  },
);

export default ScrollableTab;

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  scrollableTab: {
    flexDirection: 'row',
    paddingTop: 8,
  },
});
