import {  useWindowDimensions } from "react-native";

const useDimensions = (initValue: boolean = false) => {
  const layout = useWindowDimensions();
  const isDesktop = layout.width >= 768;
  return {
    isDesktop
  };
};
export default useDimensions;
