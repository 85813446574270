/* eslint-disable react/style-prop-object */
import React, { useEffect,useState } from "react";
import {StyleSheet, View, ActivityIndicator} from "react-native";
import RequireIndicator from "../../components/RequireIndicator";
import GenderItem from "../../components/UpdateProfile/BasicInformation/GenderItem";
import Theme from "../../style/Theme";
import locales_es from "../../locales/es";
import Form from "../../components/form";
import SearchMedicines from "../../components/searchMedicines";
import InputSpinner from "../../components/inputSpinner";
import Colors from "../../configs/Colors";
import usePrescriptionRequest from "./usePrescriptionRequest";
import Text from "../../components/Text";
import { CommonStyles } from "../../styles/CommonStyles";

export default function PersonalPrescriptionRequestPage(props) {
  const { showMainModal, parseTelInputs, classNameError, hideMainLoading } =
    props;
  const {
    state,
    dispatch,
    helpers,
    removeFromErrors,
    postPrescriptionRequest,
    clearForm,
  } = usePrescriptionRequest(showMainModal, hideMainLoading);

  const [nroFinanciador, setNroFinanciador] = useState(0);


  useEffect(() => {
    if (window && window.document) {
      window.document.body.classList.add("rcta-hide-intercom");
      parseTelInputs();
    }
  }, [hideMainLoading, parseTelInputs]);

  const inputs = [
    {
      label: locales_es.name,
      placeholder: locales_es.fullName,
      onBlur: () => removeFromErrors("name"),
      id: 1,
      state: "name",
      value: state.name,
      type: "name",
      required: true,
      wrapperCustomClassName: "form-group col-12 col-md-6 float-left pl-md-0",
      customClassName: state.errors?.includes("name") && classNameError,
    },
    {
      label: locales_es.lastname,
      placeholder: locales_es.lastname,
      onBlur: () => removeFromErrors("lastname"),
      id: 2,
      state: "lastName",
      value: state.lastName,
      type: "lastName",
      required: true,
      wrapperCustomClassName: "form-group col-12 col-md-6 float-left pr-md-0",
      customClassName: state.errors?.includes("lastName") && classNameError,
    },
    {
      label: locales_es.email,
      placeholder: locales_es.email,
      onBlur: () => removeFromErrors("email"),
      id: 3,
      state: "email",
      value: state.email,
      type: "email",
      required: true,
      wrapperCustomClassName: "form-group col-12 col-md-6 float-left pl-md-0",
      customClassName: state.errors?.includes("email") && classNameError,
      validate: true,
    },
    {
      label: locales_es.phoneNumber,
      placeholder: locales_es.phoneNumberExample,
      onBlur: () => removeFromErrors("phoneNumber"),
      id: 4,
      state: "phoneNumber",
      value: state.phoneNumber,
      type: "number",
      required: true,
      wrapperCustomClassName: "form-group col-12 col-md-6 float-left pr-md-0",
      customClassName:
        "cellphoneInput " +
        (state.errors.includes("phoneNumber") && classNameError),
    },
  ];

  const inputsInsurance = [
    {
      label: locales_es.medicalInsurance,
      placeholder: locales_es.medicalInsurance,
      onBlur: () => removeFromErrors("medicalInsurance"),
      id: 7,
      state: "medicalInsurance",
      value: state.medicalInsurance,
      type: "react-select",
      disabled: state.medicalInsuranceDisabled,
      required: true,
      options: state.medicalInsurancesOptions,
      wrapperCustomClassName:
        "form-group col-12 col-md-4 float-left pl-md-0 clear",
      customClassName:
        state.errors.includes("medicalInsurance") && classNameError,
    },
    {
      label: locales_es.medicalInsurancePlan,
      placeholder: locales_es.medicalInsurancePlan,
      onBlur: () => removeFromErrors("medicalInsurancePlan"),
      id: 8,
      state: "medicalInsurancePlan",
      disabled: state.medicalInsuranceDisabled,
      value: state.medicalInsurancePlan,
      required: false,
      wrapperCustomClassName: "form-group col-12 col-md-4 float-left pl-md-0",
      customClassName:
        state.errors.includes("medicalInsurancePlan") && classNameError,
      visible: state.medicalInsuranceName.toLocaleLowerCase() !== 'mpn - medicamentos de primer nivel'
    },
    {
      label: locales_es.medicalInsuranceNumber,
      placeholder: locales_es.medicalInsuranceNumber,
      onBlur: () => removeFromErrors("medicalInsuranceNumber"),
      id: 9,
      disabled: state.medicalInsuranceDisabled,
      state: "medicalInsuranceNumber",
      value: state.medicalInsuranceNumber,
      required: false,
      wrapperCustomClassName:
        "form-group col-12 col-md-4 float-left pl-md-0 clearfix",
      customClassName:
        state.errors.includes("medicalInsuranceNumber") && classNameError,
      visible: state.medicalInsuranceName.toLocaleLowerCase() !== 'mpn - medicamentos de primer nivel'
      },
  ];

  const inputsIdentification = [
    {
      label: locales_es.identificationType,
      placeholder: locales_es.identificationType,
      onBlur: () => removeFromErrors("identificationTypeId"),
      id: 5,
      state: "identificationTypeId",
      value: state.identificationTypeId,
      type: "select",
      required: true,
      options: state.identificationOptions,
      wrapperCustomClassName: "form-group col-12 col-md-3 float-left pl-md-0",
      customClassName:
        state.errors.includes("identificationTypeId") && classNameError,
    },
    {
      label: locales_es.identification,
      placeholder: locales_es.identification,
      onBlur: () => removeFromErrors("identification"),
      id: 6,
      state: "identification",
      value: state.identification,
      type: "text",
      required: true,
      wrapperCustomClassName:
        "form-group col-12 col-md-3 float-left pl-md-0 clear",
      customClassName:
        state.errors.includes("identification") && classNameError,
    },
  ];

  const handleChange = (input) => (ev) => {
    dispatch({
      type: input,
      payload: ev.target.value,
    });

  };

  const handleReactSelectChange = (input) => (value) => {
    if(value.idFinanciador > 0 && value.tieneCobertura == 1){
      setNroFinanciador(value.idFinanciador);
    }
    if (input === "medicalInsurance" && value) {
      dispatch({ 
        type: 'medicalInsuranceName', 
        payload: value.name 
      })
    }
    dispatch({
      type: input,
      payload: value,
    });
  };

  const handleOpenPaymentLink = () => {
    let link = "";
    if (state.prescriptionProfile.charger_accounts.pay_link.includes("http")) {
      link = state.prescriptionProfile.charger_accounts.pay_link;
    } else {
      link = `https://${state.prescriptionProfile.charger_accounts.pay_link}`;
    }
    window.open(link, "_blank");
  };

  const onSelectItem = (selectedItem, selectedPresentationIndex) => {
    clearErrors();
    selectedItem.qubito_reg_no =
      selectedItem.presentations[selectedPresentationIndex].qubito_reg_no;
    selectedItem.presentation =
      selectedItem.presentations[selectedPresentationIndex].name;
    selectedItem.quantity = 1;
    selectedItem.comment = "";
    selectedItem.is_generic = false;
    addMedicine(selectedItem);
  };

  const addMedicine = (item) => {
    const medicines = JSON.parse(JSON.stringify(state.medicines));
    medicines.push(item);
    dispatch({
      type: "medicines",
      payload: medicines,
    });
  };

  const clearErrors = () => {
    dispatch({ type: "errors", payload: [] });
  };

  const openSearchMedicines = () => {
    showMainModal(
      null,
      <SearchMedicines
        showMainModal={showMainModal}
        isPublic={true}
        nroFinanciador={nroFinanciador}
        onSelectItem={onSelectItem}
      />,
      null,
      null,
      "modal-lg"
    );
  };

  const validateForm = () => {
    let errors = [];
    clearErrors();
    inputs.map((input) => {
      if (input.required && !state[input.state]) {
        errors.push(input.state);
      }
    });

    inputsIdentification.map((input) => {
      if (input.required && !state[input.state]) {
        errors.push(input.state);
      }
    });

    inputsInsurance.map((input) => {
      if (input.required && !state[input.state]) {
        errors.push(input.state);
      }
    });

    if (!state.medicines.length) {
      errors.push("medicines");
      showMainModal(
        locales_es.infoModal.title,
        "Añada al menos un medicamento para recetar"
      );
    }
    if (!state.gender) {
      errors.push("gender");
   }
   if (state.medicalInsurance && !state.medicalInsuranceNumber.length){
    if (state.medicalInsurance.id > 1 && state.medicalInsuranceName.toLocaleLowerCase() !== 'mpn - medicamentos de primer nivel' && state.medicalInsuranceName.toLocaleLowerCase() !== 'sin cobertura'){
      errors.push('medicalInsuranceNumber');
      showMainModal(
        locales_es.infoModal.title,
        "Añada su Nro. de afiliado"
      );}
    }
    // se valida si esta vacio el campo medical insurance
  if (!state.medicalInsurance.id){
    errors.push('medicalInsurance')
    showMainModal(
      locales_es.infoModal.title,
      "Elija una cobertura"
      );
  }

    dispatch({
      type: "errors",
      payload: errors,
    });
    return !errors.length;
  };

  const renderMedicine = (item, index) => {
    const sumQuantity = (qty) => {
      const _medicines = JSON.parse(JSON.stringify(state.medicines));
      const medicine = _medicines[index];
      medicine.quantity += qty;

      dispatch({
        type: "medicines",
        payload: _medicines,
      });
    };

    const setMedicineComment = (index, text) => {
      const _medicines = JSON.parse(JSON.stringify(state.medicines));
      _medicines[index].comment = text;
      dispatch({
        type: "medicines",
        payload: _medicines,
      });
    };

    const removeMedicine = (index) => {
      const _medicines = JSON.parse(JSON.stringify(state.medicines));
      _medicines.splice(index, 1);
      dispatch({
        type: "medicines",
        payload: _medicines,
      });
    };

    return (
      <div key={"render-medicine-" + index}>
        {index >= 1 && <hr />}
        <div className="mb-3">
          <button
            onClick={() => removeMedicine(index)}
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <i className="icofont-close-line" />
          </button>
          <h6>
            <span className="item-icon icofont-drug" />
            &nbsp;
            <span
              className={item.is_generic ? "text-decoration-line-through" : ""}
            >
              {item.commercial_name}
            </span>
            &nbsp;
            <span>
              ({item.drug}) {item.presentation}
            </span>
          </h6>
          <div className="row justify-content-center">
            <div className="col col-md-6">
              <div className="form-group w-100">
                <input
                  className="form-control"
                  type="text"
                  placeholder={
                    locales_es.clarifiesThisMedicine +
                    " (" +
                    locales_es.optional +
                    ")"
                  }
                  disabled={false}
                  onChange={(e) => setMedicineComment(index, e.target.value)}
                  value={item.comment}
                />
              </div>
            </div>
          </div>
          <div>{locales_es.quantity}</div>
          <div
            style={{
              maxWidth: "240px",
              margin: "auto",
              transform: "scale(0.75)",
            }}
          >
            <InputSpinner
              max={99}
              min={1}
              step={1}
              colorMax={Colors.Tomato}
              colorMin={Colors.TealBlue}
              color={Colors.TealBlue}
              value={Number(item.quantity)}
              onChange={(num) => sumQuantity(num)}
            />
          </div>
        </div>
      </div>
    );
  };

  const formatAmount = (number, decimals = 2, decPoint = ',', thousandsSep = '.') => {

    number = (number + '').replace(/[^0-9+\-Ee.]/g, '')
    const n = !isFinite(+number) ? 0 : +number
    const prec = !isFinite(+decimals) ? 0 : Math.abs(decimals)
    const sep = typeof thousandsSep === 'undefined' ? ',' : thousandsSep
    const dec = typeof decPoint === 'undefined' ? '.' : decPoint
    let s = ''

    const toFixedFix = (n, prec) => {
      if (('' + n).indexOf('e') === -1) {
        return +(Math.round(n + 'e+' + prec) + 'e-' + prec)
      } else {
        const arr = ('' + n).split('e')
        let sig = ''
        if (+arr[1] + prec > 0) {
          sig = '+'
        }
        return (+(Math.round(+arr[0] + 'e' + sig + (+arr[1] + prec)) + 'e-' + prec)).toFixed(prec)
      }
    }

    // @todo: for IE parseFloat(0.55).toFixed(0) = 0;
    s = (prec ? toFixedFix(n, prec).toString() : '' + Math.round(n)).split('.')
    if (s[0].length > 3) {
      s[0] = s[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, sep)
    }
    if ((s[1] || '').length < prec) {
      s[1] = s[1] || ''
      s[1] += new Array(prec - s[1].length + 1).join('0')
    }

    return s.join(dec)
  }

  const SuccessModalContent = () => {
    let amountText = '';
    if (state.prescriptionProfile.charger_accounts.amount != 0) {
      const amountFormatted = formatAmount(state.prescriptionProfile.charger_accounts.amount);
      amountText = `$ ${amountFormatted}`;
    }
    return (
      <div
        className={
          "clear flex-wrap text-center justify-content-center justify-content-md-around mb-5 "
        }
      >
        <p>
          {/* Dada la situación actual del sistema de salud, los profesionales estan
          cobrando un bono de compensación del acto profesional. {"\n"}
          Para la generación de la receta, debe hacer una contribución de  */}
          {state.prescriptionProfile.charger_accounts.reason} {" "}
          {amountText} {"\n"}
        </p>
        <button
          type="button"
          onClick={() => handleOpenPaymentLink()}
          className="btn btn-primary"
        >
          Link de pago del profesional
        </button>
      </div>
    );
  };

  const onSubmitPrescriptionForm = () => {
    if (validateForm()) {
      dispatch({ type: "sentRequest", payload: true });
      const data = {
        patient_name: state.name,
        patient_lastname: state.lastName,
        patient_email: state.email,
        patient_prefix_cell_phone: "+54 9", //TODO implementar en form
        patient_cell_phone: state.phoneNumber,
        patient_identification_type_id: state.identificationTypeId,
        patient_identification: state.identification,
        patient_gender: state.gender.id,
        patient_medical_insurance_id: state.medicalInsurance.id,
        patient_medical_insurance_plan: state.medicalInsurancePlan,
        patient_medical_insurance_number: state.medicalInsuranceNumber,
        medic_id: state.medicId,
        medicines: state.medicines,
        comments: state.comment,
        prescription_profile_id: state.prescriptionProfile.id,
      };
      postPrescriptionRequest(data)
        .then((res) => {
          dispatch({ type: "sentRequest", payload: false });
          if (state.prescriptionProfile.active_charger_account === 1) {
            showMainModal(locales_es.successModal.title, <SuccessModalContent />);
          } else {
            showMainModal(
              locales_es.successModal.title,
              locales_es.sentPrescriptionRequestSuccessfully
            );
          }
          clearForm();
        })
        .catch((err) => {
          dispatch({ type: "sentRequest", payload: false });
          showMainModal(locales_es.errorModal.title, helpers.getErrorMsg(err));
        });
    } else {
      showMainModal(
        locales_es.errorModal.title,
        locales_es.errorModal.completeAllRequiredFormFields
      );
    }
  };

  return (
    <>
      <div className="app-loader">
        <i className="icofont-spinner-alt-4 rotate" />
      </div>
      {state.showView ? (
        <div className="page-box w-100">
          <div className="app-container">
            <main className="main-content pb-5">
              <div className="main-content-wrap">
                <header className="page-header">
                  <h1 className="page-title">
                    Formulario de Solicitud de Recetas Online
                  </h1>
                </header>

                <div className="page-content">
                  <p>
                    Ingrese por favor los datos solicitados para poder
                    confeccionar su receta.
                    <br />
                    <br />
                    Verifique que los datos ingresados sean correctos antes de
                    confirmar la solicitud, en caso de estar mal ingresados, su
                    receta no podrá ser confeccionada.
                    <br />
                    <br />
                    Una vez que esté lista su receta, recibirá un e-mail de
                    notificación para poder pasar a retirarla.
                    <br />
                    <br />
                    TODOS LOS CAMPOS SON OBLIGATORIOS, excepto observaciones.
                  </p>
                  <hr />

                  <div className="card">
                    <div className="card-header">Información Básica</div>

                    <Form
                      style={"mt-3"}
                      inputs={inputs}
                      handleChange={handleChange}
                      handleReactSelectChange={handleReactSelectChange}
                    />

                    <Form
                      style={"clearfix mt-3"}
                      inputs={inputsIdentification}
                      handleChange={handleChange}
                      handleReactSelectChange={handleReactSelectChange}
                    />
                    <View style={styles.formRow}>
                    <View style={styles.flexGrow}>
                        <Text size={14} lineHeight={20} marginTop={24} bold>
                          Sexo <RequireIndicator />
                        </Text>
                        <View style={styles.genders}>
                          {state.gendersList && state.gendersList.length ? (
                            Object.entries(state.gendersList).map(([key, value], index) => {
                              const onPress = () => {
                                dispatch({
                                  type: "gender",
                                  payload: value,
                                });
                              };
                              return (
                                <GenderItem
                                  {...value}
                                  testID={value.name}
                                  isChoose={value.id == state.gender.id}
                                  isLastItem={index === state.gendersList.length - 1}
                                  onPress={onPress}
                                  key={key}
                                  stylesOverride={{ width: 80, height: 80 }}
                                />
                              );
                            })
                          ) : (
                            <ActivityIndicator
                              style={CommonStyles.activityIndicator}
                            />
                          )}
                        </View>
                    </View>
                    </View>

                    <Form
                      style={"clearfix mt-3"}
                      inputs={inputsInsurance}
                      handleChange={handleChange}
                      handleReactSelectChange={handleReactSelectChange}
                    />

                    <hr />
                  </div>

                  <h5 className="page-title clear">
                    Ingrese los datos para la confección de la receta
                  </h5>

                  <div
                    className={
                      "clear flex-wrap text-center justify-content-center justify-content-md-around mb-5 " +
                      (state.errors.includes("medicines")
                        ? "invalid-field p-3"
                        : "")
                    }
                  >
                    {!state.medicines.length ? (
                      <p>
                        Aún no posee medicamentos seleccionados para esta receta.
                      </p>
                    ) : null}
                    {state.medicines && state.medicines.length ? (
                      <div className="card text-secondary border-secondary">
                        <div className="card-header">
                          {locales_es.medicines}
                        </div>
                        <div className="card-body">
                          {state.medicines.map((medicine, medicineIndex) =>
                            renderMedicine(medicine, medicineIndex)
                          )}
                        </div>
                      </div>
                    ) : null}
                    {state.medicines && state.medicines.length >= 3 ? (
                      <p>No puede añadir más medicamentos a esta receta.</p>
                    ) : (
                      <button
                        type="button"
                        onClick={openSearchMedicines}
                        disabled={state.medicines.length >= 3}
                        className="btn btn-info"
                      >
                        {locales_es.addMedicine}
                      </button>
                    )}
                  </div>

                  <div className="clear flex-wrap justify-content-center justify-content-md-around text-center mt-2">
                    <textarea
                      onChange={(e) =>
                        dispatch({
                          type: "comment",
                          payload: e.target.value,
                        })
                      }
                      className="form-control rounded"
                      placeholder={locales_es.observations}
                    >
                      {state.comment}
                    </textarea>
                  </div>

                  <div className="clear flex-wrap justify-content-center justify-content-md-around text-center mt-5">
                    <button
                      type="button"
                      onClick={onSubmitPrescriptionForm}
                      className="btn btn-primary"
                      disabled={state.sentRequest}
                    >
                      {locales_es.sendPrescriptionRequest}
                    </button>
                  </div>
                </div>
              </div>
            </main>
          </div>
        </div>
      ) : null}
    </>
  );
}


const styles = StyleSheet.create({
  genders: {
    marginTop: 24,
    ...Theme.flexRow,
  },
  formRow: {
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "baseline",
    gap: 16,
  },
  flexGrow: { flex: 1 },

})