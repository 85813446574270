import React, { useEffect, useState } from "react";
import {
  StyleSheet,
  View,
  Image,
  ImageBackground,
  TouchableOpacity,
} from "react-native";
import { useLocation, NavLink, useHistory, Link } from "react-router-dom";
import { RWebShare } from "react-web-share";
import { Colors, Routes } from "../../configs";
import locales_es from "../../locales/es";
import { SOURCE_ICON } from "../../images";
import logo from "../../images/logo.png";
import { IMAGE } from "../../images/Images";
import Text from "../../components/Text";
import Line from "../Layout/Line";
import APIService from "../../modules/apiService";
import { EventRegister } from "../../modules/EventRegister";
import { auth, helpers } from "../../import";
import {
  CONTACT_SUPPORT_LINK,
  FEATURED_ROUTES,
  MEDICINES_PERMISSION,
  PRACTICES_PERMISSION,
  WEBSITE_URL,
  userDataLocalStorage,
} from "../../models/constants";
import {EVENT_FORBIDDEN, EVENT_MAIN_OVERFLOW, EVENT_SHOW_MODAL_INFO, EVENT_USER_CHANGED} from "../../constants/Events";
import useDimensions from "../../hooks/useDimensions";
import {isDesktopRoute, showModalAction, showModalInfo} from "../../modules/ViewHelpers";
import useHomePage from "../../pages/Home/shared/useHomePage";
import { API_FRONT_URL } from "../../models/front-urls";
import EnvironmentLabel from './EnvironmentLabel';
import Collapsible from "../Collapsible";
import { height, width } from "../../configs/Const";

export const MED_PRESCRIPTION_REQ_SERVICE_TAG = "medical_prescription_request";
let listenerOverflow = null;



interface IProps {
  children: React.ReactNode;
}

interface IUserData {
  fullname: string;
  avatar: string;
  profession: string;
  mn: string;
  mnLabel: string;
  countryId: number;
}

interface ICountry {
  id: number;
  privacy_policies_url: string;
  terms_and_conditions_url: string;
}

function AppLayout({ children }: IProps) {
  const history = useHistory();
  const location = useLocation();
  const params = history.location.state;
  const hasSignUp = params && params.hasSignup;
  const { isDesktop } = useDimensions();
  const [userData, setUserData] = useState<IUserData | null>(null);
  const [countries, setCountries] = useState<ICountry[]>([]);
  const [overflow, setOverflow] = useState(true);
  const [shareLink, setShareLink] = useState("");
  const [featuredRoutes, setFeatureRoutes] = useState([...FEATURED_ROUTES]);


  const OTHER_ROUTES = [
  {
    title: locales_es.about,
    icon: "home",
    onClick: (userData?: IUserData | null, countries?: ICountry[]) => {
     history.push(`/${Routes.About}`,{userData, countries});
    },

  },

];

  useEffect(() => {
    const routeName = location.pathname.slice(1);
    if (!isDesktop || !isDesktopRoute(routeName)) return;
    if (routeName == Routes.AddPrescription) {
      setOverflow(false);
    } else {
      setOverflow(true);
    }
    const api = new APIService();
    Promise.all([
      auth.getRemoteUserData(),
      auth.getPermissions(),
      api.getMyPrescriptionProfiles(),
    ])
      .then(([{ data: userData }, permissions, { data: prescriptionProfileData }]) => {
        const user = userData?.user;
        const profileData = prescriptionProfileData?.[0];
        if (!user) return;
        setShareLink(`${API_FRONT_URL}/ar/SignUp?utm_source=${user.id}&utm_campaign=referred&utm_medium=rcta`);
        auth.getLocalUserData().then(userD => {
          userD.user = user;
          auth.saveUserData(userD);
        })
        if (permissions) {
          let newRoutes = [...FEATURED_ROUTES];

          if (permissions.some(p => p.tag === MEDICINES_PERMISSION)) {
            newRoutes.splice(1, 0, 
              {
                title: locales_es.menu.myPrescriptions,
                route: Routes.Prescriptions,
                icon: "note",
              },
              {
              title: locales_es.prescriptionsRequest,
              route: Routes.PrescriptionsRequests,
              icon: "list",
            }, 
          )
          }

          if (permissions.some(p => p.tag === PRACTICES_PERMISSION)) {
            newRoutes.splice(-2, 0, {
              title: locales_es.menu.otherPrescriptions,
              route: Routes.Certificates,
              icon: "table",
            })
          }

          setFeatureRoutes(newRoutes);
        }
        setUserData({
          avatar: user.full_profile_image,
          fullname: `${user.professional_prefix.name} ${user.name} ${user.lastname}`,
          profession: profileData?.profession ?? "",
          mn:
            profileData?.additional_fields?.inputs?.find(
              ({ name }: { name: string }) =>
                name === "professional_registration_number"
            )?.value ?? "",
          mnLabel:
            profileData?.additional_fields?.inputs?.find(
              ({ name }: { name: string }) =>
                name === "professional_registration_type"
            )?.value ?? "",
          countryId: user.country_id,
        });
      })
      .catch(() => {
        setUserData(null);
      });

    api
      .getCountries()
      .then((res) => {
        setCountries(res.data);
      })
      .catch((err) => {
        EventRegister.emit(EVENT_SHOW_MODAL_INFO, {
          title: locales_es.errorModal.title,
          message: helpers.getErrorMsg(err),
        });
      });
  }, [location.pathname]);

  if (!isDesktop || !isDesktopRoute(location.pathname.slice(1)))
    return <>{children}</>;

  const closeAllSessionsCancelAction = () => {
    auth.logout(true);
  }

  const closeAllSessionsOKAction = async () => {
    await auth.closeAllSessions();
    auth.logout(true);
  }

  const logoutAction = () => {
    showModalAction(locales_es.closeAllSessionsModal.title, locales_es.closeAllSessionsModal.subtitle, closeAllSessionsOKAction, "50%", closeAllSessionsCancelAction, "No", "Si");
  }
  

  return (
    <>
    
      <View style={styles.container}>
        {/* Left section */}
        {!hasSignUp && (
        <Collapsible 
          widthClose="0%" 
          widthOpen="320px" 
          colorClose="#2ad1d2" 
          colorOpen="#27c0c1" >
          <View style={styles.navContainer}>
            {/* Logo section */}
            <View style={{zIndex: 1000,marginLeft:4, marginTop:7, alignSelf: 'left'}}>
              <EnvironmentLabel />
            </View>
            <View style={styles.logoContainer}>
              <Link to={Routes.Home}>
                <Image
                  source={{ uri: logo, width: 190, height: 100}}
                  style={{...styles.logo, marginTop: 25}}
                  resizeMode="contain"
                />
              </Link>
              {/* <View style={styles.logoBorder} /> */}
            </View>
            {/* Doctor profile section */}
            {userData && (
              <View style={styles.userDataContainer}>
                <TouchableOpacity
                  onPress={() => history.push(`/${Routes.Configuration}`)}
                >
                  <ImageBackground
                    source={{ uri: userData.avatar }}
                    resizeMode="cover"
                    style={styles.avatar}
                    imageStyle={{...styles.avatarImage,
                                tintColor: userData.avatar.endsWith('/rcta/uploads/images/profile_images/user.png')
                                ? 'white' : null}}
                  />
                </TouchableOpacity>
                <View style={styles.userDataText}>
                  <Text size={16} lineHeight={22} color={Colors.White}>
                    {userData.fullname}
                  </Text>
                  <Text size={16} lineHeight={22} color={Colors.White} bold>
                    {userData.profession}
                  </Text>
                  <Text size={12} lineHeight={20} color={Colors.White}>
                    {userData.mnLabel}: {userData.mn}
                  </Text>
                </View>
              </View>
            )}
            {/* Nav links section */}
            <nav style={styles.nav}>
              {featuredRoutes.map(({ route, title, icon }) => (
                <NavLink style={styles.link} to={route} key={route}>
                  <Image
                    source={SOURCE_ICON[icon]}
                    style={styles.linkImg}
                    resizeMode="contain"
                  />
                  {title}
                </NavLink>
              ))}
              <Line />
              {OTHER_ROUTES.map(({ onClick, title, icon }) => (
                <TouchableOpacity
                  key={title}
                  onPress={() => history.push(`/${Routes.About}`, { userData, countries })}
                >
                  <View style={styles.link} key={title}>
                    <Image
                      source={SOURCE_ICON[icon]}
                      style={styles.linkImg}
                      resizeMode="contain"
                    />
                    <Text style={styles.link}>{title}</Text>
                  </View>
                </TouchableOpacity>
              ))}

              <RWebShare
                data={{
                  text: locales_es.shareAppInfoTextWeb,
                  url: shareLink,
                  title: locales_es.shareAppInfoTitle,
                }}
                closeText="Cerrar"
              >
                <Text style={styles.link}>
                  <Image
                    source={SOURCE_ICON.invite}
                    style={styles.linkImg}
                    resizeMode="contain"
                  />
                  <Text style={styles.link}>{locales_es.menu.share}</Text>
                </Text>
              </RWebShare>

              <TouchableOpacity
                onPress={() => {
                  EventRegister.emit(EVENT_SHOW_MODAL_INFO, {
                    title: locales_es.logoutModal.title,
                    message: locales_es.logoutModal.subtitle,
                    actions: [
                      {
                        text: locales_es.confirmLogoutModal.accept,
                        onPress: logoutAction,
                        white: true,
                      },
                    ],
                  });
                }}
              >
                <View style={{...styles.link}}>
                  <Image
                    source={SOURCE_ICON.logout}
                    style={styles.linkImg}
                    resizeMode="contain"
                  />
                  <Text style={styles.link}>{locales_es.menu.logout}</Text>
                </View>
              </TouchableOpacity>
              <br style={{color:'white'}}/>
              <br style={{color:'white'}}/>
              <br style={{color:'white'}}/>
              <br style={{color:'white'}}/>
            </nav>
          </View>
        </Collapsible>
        )}
        <View style={styles.currentContent}>{children}</View>
      </View>
    </>
  );
}

const widthtScreen = window.innerWidth;

const styles = StyleSheet.create<any>({
  container: {
    display: "flex",
    flex: 1,
    flexDirection: "row",
  },
  navContainer: {
    backgroundImage: `url(${IMAGE.background})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    height: "100vh",
  },
  logoContainer: {
    flexDirection: "column",
    alignItems: "center",
    paddingTop: 0,
    marginTop: -10,
    backgroundColor: "white",
    width: '360px',
    height: '150px',
    borderBottomLeftRadius: 120,
    borderBottomRightRadius: 120,
    marginLeft: -25,
    
    
  },
  logoBorder: {
    position: "absolute",
    bottom: -23,
    width: 600,
    height: 300,
    backgroundColor: "white",
    borderRadius: "100%",
  },
  logo: { zIndex: 1 },
  userDataContainer: {
    paddingTop: 38,
    justifyContent: "center",
    alignItems: "center",
  },
  userDataText: { paddingTop: 16, textAlign: "center", alignItems: "center" },
  nav: {
    padding: widthtScreen >= 1400 ? 32 : 20,
    display: "flex",
    flexDirection: "column",
    gap: widthtScreen >= 1400 ? 24 : 18,
    width: 320,
    overflow: "auto",
    marginBottom: widthtScreen >= 1400 ? 0 : 32,
    
    
  },
  link: {
    color: "white",
    fontSize: 16,
    fontWeight: "600",
    display: "flex",
    flexDirection: "row",
    gap: widthtScreen >= 1400 ? 16 : 12,
    alignItems: "center",
    cursor: "pointer",
  },
  linkImg: { width: widthtScreen >= 1400 ? 24: 20, height: widthtScreen >= 1400 ? 24: 20, tintColor: "white" },
  avatar: {
    width: 94,
    height: 94,
    border: "2px solid white",
    borderRadius: 47,
    justifyContent: "center",
    alignItems: "center",
  },
  avatarImage: {
    borderRadius: 40,
    width: 80,
    height: 80,
    position: "relative",
  },
  currentContent: {
    flex: 1,
    zIndex: 2,
    padding: 32,
    backgroundColor: "#fafafa",
    overflow: "auto",
  },
});

export default AppLayout;
