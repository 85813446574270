import locales_es from '../locales/es';

class AppHelpers {
  /*
   *
   * Utility to check if a variable named num (possibly a string) is effectively a number
   */
  isNumeric(num) {
    return (
      !isNaN(num) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
      !isNaN(parseFloat(num))
    ); // ...and ensure strings of whitespace fail
  }

  validateIdentification(countryId, identification, identificationTypeId) {
    // Argentina
    if (countryId === 1) {
      if (!identification) {
        return {
          title: locales_es.patientValidationNoIdentification,
          message: locales_es.patientValidationNoIdentificationExplanation,
          fieldValue: identification,
          fieldName: 'identification',
          type: 'numeric',
          fieldTitle: locales_es.identification,
          fieldPlaceholder: locales_es.identification,
        };
      }

      if (identificationTypeId === 1) {
        if (!this.isNumeric(identification)) {
          return {
            title: locales_es.patientValidationInvalidIdentification,
            message:
            locales_es.patientValidationInvalidIdentificationExplanation,
            fieldValue: identification,
            fieldName: 'identification',
            type: 'numeric',
            fieldTitle: locales_es.identification,
            fieldPlaceholder: locales_es.identification,
          };
        }

        if (
          Number(identification) > 150000000 ||
          Number(identification) < 10000
        ) {
          return {
            title: locales_es.patientValidationInvalidIdentificationNumber,
            message:
            locales_es.patientValidationInvalidIdentificationExplanationNumber,
            fieldValue: identification,
            fieldName: 'identification',
            type: 'numeric',
            fieldTitle: locales_es.identification,
            fieldPlaceholder: locales_es.identification,
          };
        }
      }
    }
    return true; // valid
  }

  /*
   *
   * Utility to get ErrorMessages from ApiService responses
   */
  checkPatientIntegrity(patientData) {
    if (patientData && patientData.country_id) {
      const validation = this.validateIdentification(
        patientData.country_id,
        patientData.identification,
        patientData.identification_type_id,
      );
      return validation;
    }
    return false;
  }
}

//unregister();

export default AppHelpers;
