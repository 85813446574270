import React, { useState } from "react";
import { StyleSheet, Image } from "react-native";
import { Keyframes } from "@emotion/react";
import ic_reset_search from "../../images/ic_close_16@3x.png";
import ic_radio_active from "../../images/ic_accept@3x.png";
import ic_additional from "../../images/ic_addtional@3x.png";
import ic_warning from "../../images/img_warning.png";
import { height } from "../../configs/Const";

interface Props {
  variant?: "success" | "error" | "warning" | "info";
  text?: string;
  children?: React.ReactNode;
  position?:
    | "top"
    | "bottom"
    | "left"
    | "right | topLeft"
    | "topRight"
    | "bottomLeft"
    | "bottomRight";
  mobile?: boolean;
}

export const Toast = ({
  variant = "success",
  text = "This is a toast message",
  children,
  position = "topRight",
  mobile = false,
}: Props) => {
  const classVariant: any = {
    success: {
      backgroundColor: "#79dfc1",
      color: "#198754",
    },
    error: {
      backgroundColor: "#f44336",
      color: "#fff",
    },
    warning: {
      backgroundColor: "#ffda6a",
      color: "#cc9a06",
    },
    info: {
      backgroundColor: "#2196f3",
      color: "#fff",
    },
  };

  const classPosition: any = {
    top: {
      top: mobile ? 20 : 25,
    },
    bottom: {
      bottom: mobile ? 20 : 25,
    },
    left: {
      left: mobile ? 20 : 55,
    },
    right: {
      right: mobile ? 20 : 55,
    },
    topLeft: {
      top: mobile ? 20 : 25,
      left: mobile ? 20 : 55,
    },
    topRight: {
      top: mobile ? 20 : 25,
      right: mobile ? 20 : 55,
    },
    bottomLeft: {
      bottom: mobile ? 20 : 25,
      left: mobile ? 20 : 55,
    },
    bottomRight: {
      bottom: mobile ? 20 : 25,
      right: mobile ? 20 : 55,
    },
  };

  return (
    <div
      className="p-4 shadow flex gap-2 items-center"
      style={{
        ...styles.toastContainer,
        maxWidth: `${mobile ? "75%" : "25%"}`,
        ...classVariant[variant],
        ...classPosition[position],
      }}
    >
      <span>
        {variant === "success" ? (
          <Image
            source={ic_radio_active}
            style={{ width: 25, height: 25, tintColor: "#198754" }}
          />
        ) : variant === "error" ? (
          <Image
            source={ic_reset_search}
            style={{ width: 25, height: 25, tintColor: "#fff" }}
          />
        ) : variant === "warning" ? (
          <Image
            source={ic_additional}
            style={{ width: 25, height: 25, tintColor: "#cc9a06" }}
          />
        ) : variant === "info" ? (
          <Image source={ic_additional} style={{ width: 25, height: 25 }} />
        ) : (
          <Image source={ic_radio_active} style={{ width: 25, height: 25 }} />
        )}
      </span>
      {children}

      <div className="p-2">
        <span
          style={{
            color: classVariant[variant].color,
            fontSize: 18,
            fontWeight: 900,
          }}
        >
          {text}
        </span>
      </div>
    </div>
  );
};

const styles = StyleSheet.create<any>({
  toastContainer: {
    backgroundColor: "#79dfc1",
    color: "#198754",
    borderRadius: "0.5rem",
    zIndex: 1,
    position: "fixed",
    height: 55,
    display: "flex",
    gap: 2,
    alignItems: "center",
  },
  toast: {},
});

export default Toast;
