export default {
  cellphone: "Celular",
  medicLicense: "Datos profesionales",
  medicLicensePicture: "Foto de Registro Médico",
  medicLicensePictureDescription:
    "Envíanos una foto legible que acredite su registro médico. Nos ayuda a fortalecer tu seguridad y la de sus pacientes, y evita una posible suspensión de su cuenta.",
  stampPlaceholder: "Nombre y Apellido\nEspecialidad \nRegistro Médico 123456",
  menu: {
    configuration: "Configuración",
    createPrescriptions: "Generar Recetas",
    myPrescriptions: "Mis Recetas",
    myPatients: "Mis Pacientes",
    otherPrescriptions: "Prescripción Libre",
  },
  medicalInsurance: "Cobertura Médica (Opcional)",
  medicalInsurancePlan: "Plan (Opcional)",
  medicalInsuranceNumber: "Nº de Afiliado (Opcional)",
  customText: "Texto personalizado",
};
