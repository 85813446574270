import {
  StyleSheet,
  Animated,
  View,
  Dimensions,
  TouchableOpacity,
} from "react-native";
import React from "react";

const { width } = Dimensions.get("window");

const Pagination = ({
  data,
  scrollX,
  flatlistRef,
}: {
  data: any[];
  flatlistRef: React.MutableRefObject<any>;
  scrollX: Animated.Value;
}) => {
  return (
    <View style={styles.container}>
      {data.map((_, idx) => {
        const inputRange = [(idx - 1) * width, idx * width, (idx + 1) * width];

        const dotWidth = scrollX.interpolate({
          inputRange,
          outputRange: [12, 30, 12],
          extrapolate: "clamp",
        });

        const backgroundColor = scrollX.interpolate({
          inputRange,
          outputRange: ["#ccc", "#000", "#ccc"],
          extrapolate: "clamp",
        });

        return (
          <TouchableOpacity
            onPress={() =>
              flatlistRef.current.scrollToIndex({ index: idx, animated: true })
            }
          >
            <Animated.View
              key={idx.toString()}
              style={[styles.dot, { width: dotWidth, backgroundColor }]}
            />
          </TouchableOpacity>
        );
      })}
    </View>
  );
};

export default Pagination;

const styles = StyleSheet.create({
  container: {
    position: "absolute",
    bottom: 35,
    flexDirection: "row",
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
  },
  dot: {
    width: 12,
    height: 12,
    borderRadius: 6,
    marginHorizontal: 3,
    backgroundColor: "#ccc",
  },
});
