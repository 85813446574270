import React from "react";
import useDimensions from "../../hooks/useDimensions";
import { MobileDownload } from "../../pages/MobileDownload";
import { Routes } from "../../configs";

interface IProps {
  MobileVersion?: () => React.ReactElement;
  DesktopVersion?: () => React.ReactElement;
}

function RenderMobileOrDesktop({ MobileVersion, DesktopVersion }: IProps) {
  const {isDesktop} = useDimensions()
  const routeName = window.location.pathname.slice(1);
  let mobileVersion: any = <><MobileDownload/></>;
  if (routeName == `ar/${Routes.SignUp}` || routeName == Routes.SignUp) {
    mobileVersion = MobileVersion;
  }
  return (isDesktop && DesktopVersion ? DesktopVersion : mobileVersion);
}

export default RenderMobileOrDesktop;
