import React, {useState, useEffect} from 'react';
import {StyleSheet} from 'react-native';
import Text from '../../components/Text';
import Theme from '../../style/Theme';
import scale from '../../utils/scale';
import {Colors} from '../../configs';
import ButtonLinear from '../../components/Buttons/ButtonLinear';
import Container from '../../components/Layout/Container';
import locales_es from '../../locales/es';
import {showModalInfo} from '../../modules/ViewHelpers';
import FullScreenLoading from '../../components/fullscreenLoading';
import {api, helpers, appHelpers} from '../../import';
import InputApp from '../../components/InputApp';
import {EventRegister} from "../../modules/EventRegister";
import {
  EVENT_CLEAR_PATIENT,
  EVENT_VALIDATE_PATIENT,
} from '../../constants/Events';
import {
  PATIENT_VALIDATE_FIELD_IDENTIFICATION,
  USER_TYPE_PATIENT,
} from '../../models/constants';
import {validateEmptyFields} from '../../utils/validation/forms';
import {useTheme} from '../../configs/Theme';
import {useHistory} from "react-router-dom";

interface CreatingYourFirstPrescriptionProps {}

const CreatingYourFirstPrescription = (
  props: CreatingYourFirstPrescriptionProps,
) => {
  const {theme} = useTheme();
  const history = useHistory();
  const params = history.location.state;
  const [field, setField] = useState(
    params ? params.fieldValue : null,
  );
  const userId = params?.userId || props.patientData?.userId || null;
  const userType = params?.userType || props.patientData?.userType || null;
  const userData = params?.userData || props.patientData?.userData || null;
  const title = params?.title || props.patientData?.title || null;
  const message = params?.message || props.patientData?.message || null;
  const type = params?.type || props.patientData?.type || null;
  const fieldName = params?.fieldName || props.patientData?.fieldName || null;
  const fieldTitle = params?.fieldTitle || props.patientData?.fieldTitle ||  null;
  const fieldPlaceholder = params?.fieldPlaceholder || props.patientData?.fieldPlaceholder || null;
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState([]);

  const identificationRef = React.createRef();

  useEffect(() => {
    if (userId && userType === USER_TYPE_PATIENT) {
      if (fieldName === PATIENT_VALIDATE_FIELD_IDENTIFICATION) {
        api.getPatient(userId).then((res: any) => {
          if (res && res.data && res.data.identification) {
            setField(res.data.identification);
          }
        });
      }
    }
  }, [userId]);

  const save = () => {
    if (userId && userType === USER_TYPE_PATIENT) {
      const errors = validateEmptyFields({
        field,
      });

      if (errors.length) {
        setErrors(errors);
        showModalInfo(locales_es.infoModal.title, locales_es.completeAllFields);
        return;
      }

      if (fieldName === PATIENT_VALIDATE_FIELD_IDENTIFICATION) {
        const objData = Object.assign(userData, {identification: field});
        const validation = appHelpers.checkPatientIntegrity(objData);
        if (validation !== true) {
          showModalInfo(validation.title, validation.message);
          return;
        }

        setLoading(true);
        api
          .putPatient(userId, Object.assign(objData))
          .then((res: any) => {
            setLoading(false);
            showModalInfo(locales_es.successModal.title, res.message);
            EventRegister.emit(EVENT_VALIDATE_PATIENT, {});
            props.onSave && props.onSave();
          })
          .catch((err: any) => {
            showModalInfo(
              locales_es.errorModal.title,
              helpers.getErrorMsg(err),
            );
            setLoading(false);
          });
      }
    }
  };

  const cancel = () => {
    EventRegister.emit(EVENT_CLEAR_PATIENT, {});
    props.onCancel && props.onCancel();
  };

  return (
    <>
      {loading && <FullScreenLoading />}
      <Container style={styles.container}>
        <Text center size={20} lineHeight={24} bold marginTop={scale(55)}>
          {title}
        </Text>
        <Text center size={15} lineHeight={24} marginTop={16} center>
          {message}
        </Text>
        <InputApp
          style={{width: '90%'}}
          testID="inputIdentification"
          forwardRef={identificationRef}
          title={fieldTitle}
          placeholder={fieldPlaceholder}
          marginTop={24}
          value={field}
          onChangeText={setField}
          keyboardType={type}
          blurOnSubmit={true}
          returnKeyType="next"
          autoFocus={false}
          onSubmitEditing={() => {
            save();
          }}
          borderColor={
            errors.includes(fieldName) ? theme.lineErrorColor : theme.innearColor
          }
        />
        <ButtonLinear
          white
          title={locales_es.save}
          style={{marginTop: 30, paddingHorizontal: 32}}
          onPress={save}
        />
        <Text
          size={15}
          lineHeight={24}
          color={Colors.DodgerBlue}
          style={{marginTop: 30, paddingHorizontal: 32}}
          onPress={cancel}>
          {locales_es.cancel}
        </Text>
      </Container>
    </>
  );
};

export default CreatingYourFirstPrescription;

const styles = StyleSheet.create({
  container: {
    ...Theme.container,
    ...Theme.center,
  },
});
