import React, {useState,useEffect, useCallback} from 'react';
import {validateEmptyFields,handleValidatorInputsTypes,InitForm} from '../../utils/validation/forms';
import { showModalInfo, showModalInfoAction } from '../../modules/ViewHelpers';
import locales_es from '../../locales/es';
import {useHistory, useLocation} from "react-router-dom";
import { set } from 'react-native-reanimated';
import * as validator from "validator";
import { useParams } from 'react-router-dom';
import {api, helpers, auth} from '../../import';

type ValidatePasswordProps = {
  value: string;
  minLength?: number;
  setErrorMessages: React.Dispatch<React.SetStateAction<ErrorMessages>>;
  fieldKey: string;
  initForm: InitForm;
};

type ErrorMessages = {
  [key: string]: string;
};

interface RouteParams {
  token: string;
}

const useResetPassword = () => {

  const history: any = useHistory();
  const location: any = useLocation();
  const [type, setType] = useState<string>('');
  const [errors, setErrors] = useState<any>([]);
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(true);
  const [success, setSuccess] = useState<boolean>(false);
  const { token }: RouteParams = useParams<RouteParams>();
  const [enableButton, setEnableButton] = useState<boolean>(true);
  const [samePassword, setSamePassword] = useState<boolean>(false);
  const [focusPassword, setFocusPassword] = useState<boolean>(false);
  const [confirmPassword, setConfirmPassword] = useState<string>('');
  const [errorMessages, setErrorMessages] = useState<ErrorMessages>({});
  const [visiblePassword, setVisiblePassword] = useState<boolean>(false);
  const [screenWidth, setScreenWidth] = useState<number>(window.innerWidth);
  const [focusConfirmPassword, setFocusConfirmPassword] = useState<boolean>(false);
  const [visibleConfirmPassword, setVisibleConfirmPassword] = useState<boolean>(false);

  useEffect(() => {
    (confirmPassword === password && confirmPassword.length > 0) ? setSamePassword(true) : setSamePassword(false);
  },[confirmPassword,password])

  const goToLogin = () => {
    history.push('/login');
  }

  useEffect(() => {

    const routeName = location.pathname.slice(1);
    if (routeName.toLocaleLowerCase().includes('reset')) {
      setType('reset');
    } else {
      setType('create');
    }

    api
      .validateResetToken(token)
      .then((response) => {
        setLoading(false);
        setEmail(response.data.email);
      })
      .catch((err) => {
        setLoading(false);
        showModalInfoAction(type === 'reset' ? locales_es.recoveryPassword :  locales_es.createPassword ,helpers.getErrorMsg(err), [
          {
            text: locales_es.confirmLogoutModal.accept,
            onPress: goToLogin,
            white: true,
          }
        ])
      });
  }, []);

  const onShowHidePassword = useCallback(() => {
      setVisiblePassword(prev => !prev);
    }, []);

  const onShowHideConfirmPassword = useCallback(() => {
      setVisibleConfirmPassword(prev => !prev);
    }, []);
  
  const initValues = {
      password,
      confirmPassword,
    }

  const initForm: InitForm = {
      setErrors,
      errors,
      setErrorMessages,
      errorMessages,
      setEnableButton,
      initValues,
    }

  useEffect(() => {
      if(errors.length > 0){
        setEnableButton(true)
      }
    },[password,confirmPassword])


    const validatePassword = ({
      value,
      minLength = 0,
      setErrorMessages,
      fieldKey,
      initForm,
    }: ValidatePasswordProps) => {
      const { setErrors, errors, setEnableButton, initValues } = initForm;
    
      const requirements = {
        uppercase: /[A-Z]/,
        lowercase: /[a-z]/,
        digit: /\d/,
        specialCharacter: /[\W_]/,
      };
    
      const newErrors: ErrorMessages = {};
    
      const allErrors = errors.filter((item: string) => item !== fieldKey);
      setErrors(allErrors);
    
      const passwordRegex = /^(?=.*?[A-Z])(?=.*?[a-zñÑ])(?=.*?[0-9])(?=.*?[\W_]).{8,}$/;
    
      if (!passwordRegex.test(value)) {
        newErrors[fieldKey] = `La contraseña debe contener al menos 8 caracteres, letras mayúsculas y minúsculas, y al menos un caracter especial (por ejemplo '!')`;
      }
    
      if (Object.keys(newErrors).length > 0) {
        setErrors([...allErrors, fieldKey]);
        setErrorMessages((prevErrorMessages) => ({
          ...prevErrorMessages,
          ...newErrors,
        }));
        setEnableButton(false);
      } else {
        setErrorMessages((prevErrorMessages) => ({
          ...prevErrorMessages,
          [fieldKey]: '',
        }));
        setEnableButton(true);
      }
    };

    useEffect(() => {
      const { errors: currentErrors, setErrors, setErrorMessages, setEnableButton } = initForm;
      const newErrors: ErrorMessages = {};
    
      let newPassword = password;
      let newConfirmPassword = confirmPassword;
      
      if (newConfirmPassword !== newPassword && newPassword.length > 0 && newConfirmPassword.length > 0) {
        newErrors.confirmPassword = 'Las contraseñas no coinciden';
      } else {
        delete newErrors.confirmPassword;
      }
    
      const existingErrors = currentErrors.filter((item: string) => item !== 'confirmPassword');
    
      setErrors(existingErrors.concat(Object.keys(newErrors)));
    
      setErrorMessages((prevErrorMessages: any) => ({
        ...prevErrorMessages,
        ...newErrors,
      }));
    
    }, [password, confirmPassword]); 
    
    
    useEffect(() => {
      console.log(errors);
      if (errors.length === 0 && password.length > 0 && confirmPassword.length > 0 && samePassword) {
        setEnableButton(false);
      } 
      else {
        setEnableButton(true);
      }
    }, [errors]);

    useEffect(() => {
      const handleResize = () => setScreenWidth(window.innerWidth);
      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
    },[])

  const onSubmit = () => {
    if (loading) return;
    const validateObj = {
      email,
      token,
      password,
      password_confirmation : confirmPassword
    } 
    const errors = validateEmptyFields({password,confirmPassword});
    if(errors.length > 0){
      setErrors(errors);
      return;
    }
    setLoading(true);
  api
    .resetPassword(validateObj)
    .then((response) => {
      setSuccess(true);
      setLoading(false);
      auth
      .saveUserData(response.data);  
      auth
      .setHeaderInOrderOfUserStatus();
      setTimeout(() => {
        history.push('/home');
      }, 4000);
    })
    .catch((err) => {
      showModalInfo(locales_es.recoveryPassword,helpers.getErrorMsg(err));
      setLoading(false);
    }); 
  }

  return {
    type,
    errors, 
    success, 
    loading, 
    password,
    initForm,
    onSubmit,
    setErrors, 
    setSuccess, 
    setLoading, 
    setPassword, 
    screenWidth,
    samePassword,
    enableButton,
    errorMessages,
    focusPassword,
    confirmPassword, 
    visiblePassword, 
    setEnableButton,
    setFocusPassword,
    validatePassword,
    setErrorMessages,
    onShowHidePassword,
    setVisiblePassword, 
    setConfirmPassword, 
    focusConfirmPassword,
    visibleConfirmPassword, 
    setFocusConfirmPassword,
    setVisibleConfirmPassword, 
    onShowHideConfirmPassword,
  }
}
 
export default useResetPassword;



  