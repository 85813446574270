import React, { memo } from "react";
import {
  View,
  StyleSheet,
  Modal as RNModal,
  TouchableOpacity,
  TouchableWithoutFeedback,
  Image,
} from "react-native";
import Text from "../../components/Text";
import { Colors } from "../../configs";
import ButtonLinear from "../../components/Buttons/ButtonLinear";
import locales_es from "../../locales/es";
import closeIcon from "../../images/ic_close.png";
import useDimensions from "../../hooks/useDimensions";

interface ModalProps {
  title?: string;
  message?: string;
  onRequestClose: any;
  actions?: any;
  children?: any;
  defaultActionText?: string;
  fullWidthAction?: boolean;
}

const Modal = memo((props: ModalProps) => {
  const { isDesktop } = useDimensions();
  const {
    title,
    message,
    onRequestClose,
    actions,
    defaultActionText,
    children,
    fullWidthAction,
  } = props;

  return (
    <RNModal
      onRequestClose={onRequestClose}
      onDismiss={onRequestClose}
      transparent
      animationType={isDesktop ? "fade" : "slide"}
      onBackdropPress={onRequestClose}
      visible
    >
      <TouchableOpacity
        style={styles.backgroundOverlay}
        onPress={onRequestClose}
      >
        <TouchableWithoutFeedback>
          <View
            style={[
              styles.mobileContainer,
              isDesktop && styles.desktopContainer,
            ]}
          >
            <TouchableOpacity
              style={styles.closeIconContainer}
              onPress={onRequestClose}
            >
              <Image source={{ uri: closeIcon }} style={styles.icon} />
            </TouchableOpacity>
            <View style={styles.content}>
              <View style={styles.textContainer}>
                {title && (
                  <Text center size={20} bold>
                    {title}
                  </Text>
                )}
                {message && (
                  <Text center size={14} lineHeight={18} color="#6a6a6a">
                    {message}
                  </Text>
                )}
              </View>
              {children}
              {actions && actions.length ? (
                <View style={styles.actionWrapper}>
                  {actions.map((action: any, i: number) => (
                    <ButtonLinear
                      key={"action-" + i}
                      white={action.white}
                      title={action.text}
                      styleButton={styles.actionButton}
                      onPress={() => {
                        action.onPress && action.onPress();
                        onRequestClose();
                      }}
                      colors={action.colors}
                    />
                  ))}
                </View>
              ) : (
                <ButtonLinear
                  style={{
                    ...styles.defaultAction,
                    ...(fullWidthAction ? styles.fullWidthAction : {}),
                  }}
                  white
                  title={defaultActionText || locales_es.accept}
                  onPress={onRequestClose}
                />
              )}
            </View>
          </View>
        </TouchableWithoutFeedback>
      </TouchableOpacity>
    </RNModal>
  );
});

export default Modal;

const styles = StyleSheet.create<any>({
  backgroundOverlay: {
    backgroundColor: Colors.Black68,
    flex: 1,
    justifyContent: "center",
  },
  mobileContainer: {
    backgroundColor: "white",
    justifyContent: "center",
    alignItems: "center",
    padding: 32,
    width: "100%",
    height: "100%",
    cursor: "default",
  },
  desktopContainer: {
    alignItems: "center",
    maxWidth: 600,
    minWidth: 300,
    width: "auto",
    height: "auto",
    margin: "auto",
    borderRadius: 12,
  },
  content: { marginTop: 24, gap: 24 },
  closeIconContainer: {
    position: "absolute",
    right: 12,
    top: 12,
  },
  textContainer: { gap: 16 },
  defaultAction: { marginTop: 0, width: 150, alignSelf: "center" },
  fullWidthAction: { width: "100%" },
  icon: { width: 18, height: 18, tintColor: "#ababab" },
  actionWrapper: { flexDirection: "row", flex: 1, gap: 16 },
  actionButton: { flex: 1 },
});
