import React, {
  memo,
  useCallback, useEffect, useState,
} from 'react';
import {
  View,
  StyleSheet,
  FlatList,
  ActivityIndicator,
} from 'react-native';
import Text from '../../components/Text';
import Theme from '../../style/Theme';
import {Routes} from '../../configs';
import {useHistory} from 'react-router-dom';
import Container from '../../components/Layout/Container';
import Layout from '../../components/Layout/Layout';
import HeaderButton from '../../components/HeaderButton';
import {getBottomSpace} from 'react-native-iphone-x-helper';
import keyExtractor from '../../utils/keyExtractor';
import {FEATURE_ICON} from '../../images/MyWork';
import FeatureItem from '../../components/FeatureItem';

import {api, helpers} from '../../import';
import locales_es from '../../locales/es';
import {showModalInfo} from '../../modules/ViewHelpers';
import {CommonStyles} from '../../styles/CommonStyles';
import {RWebShare} from "react-web-share";
import {CLINIC_PATIENTS_SERVICE_TAG} from '../../models/constants';
import useDimensions from '../../hooks/useDimensions';

interface AddPatientHubProps {
}

const AddPatientHub = memo((props: AddPatientHubProps) => {
  const { isDesktop } = useDimensions();
  const history = useHistory();
  const params = history.location.state;
  const historyBackSteps = params && params.historyBackSteps ? params.historyBackSteps : null;
  const [addPatientWebLink, setAddPatientWebLink] = useState(null)
  const [availableOptions, setAvailableOptions] = useState([]);

  useEffect(() => {
    api
      .getPatientSharerLink()
      .then((res: any) => {
        setAddPatientWebLink(res.data.url);
      })
      .catch((err: any) => {
        helpers.processError(err);
      });

    const defaultOptions = [
      {
        id: 4,
        img: FEATURE_ICON.careTeam,
        title: 'Manualmente completando sus datos',
        route: Routes.AddPatient,
        params: {historyBackSteps},
      },
      {
        id: 7,
        img: FEATURE_ICON.workProfile,
        title: 'Invita al paciente a que complete sus datos',
        // onPress: onShare,
        sharer: true,
      },
    ];
    // Check if we have access to some clinic patients
    api
      .getMyServices()
      .then((res: any) => {
        if (res && res.data && res.data.length) {
          res.data.map(service => {
            if (service.tag === CLINIC_PATIENTS_SERVICE_TAG) {
              defaultOptions.push({
                id: 8,
                img: FEATURE_ICON.clinicPatients,
                title: 'Consulta de Padrón Institucional',
                onPress: () => history.push(`/${Routes.SearchClinicPatients}`),
              },
              // se crea este item para que el layout no se rompa
              {
                id: 9,
                img: "",
                title: '',
                style: { display: 'none' }
              });
            }
          });
        }
        setAvailableOptions(defaultOptions);
      })
      .catch((err: any) => {
        setAvailableOptions(defaultOptions);
      });
  }, [])

  const renderFeature = (item, index) => {
    return (
      <span 
      className="cursor-pointer" 
      style={{
        flex: 0.5, 
        justifyContent: 'center',
        marginTop: 10,
        width: '100%',
      }}>
        <FeatureItem
        sharer={item.sharer}
        style={[index % 2 === 0 ? {marginRight: 0} : null, {paddingTop: 10, paddingBottom: 10}]}
        imgStyle={{padding: 20, width: '60%',}}
        textSize={14}
        {...item}
      />
      </span>
    )
  }

  const renderFeatureItem = useCallback(({item, index}) => {
    if (item.sharer) {
      return <RWebShare
        data={{
          title: locales_es.sharePatientFormInfoTitle,
          text: locales_es.sharePatientFormInfoText,
          url: addPatientWebLink,
        }}
        closeText="Cerrar"
        onClick={() => console.log("shared successfully!")}
      >
        {renderFeature(item, index)}
      </RWebShare>
    } else {
      return renderFeature(item, index)
    }
  }, [addPatientWebLink]);

  const FEATURE = [
    {
      id: 4,
      img: FEATURE_ICON.careTeam,
      title: 'Manualmente completando sus datos',
      route: Routes.AddPatient,
      params: {historyBackSteps},
      style: {padding: 16}
    },
    {
      id: 7,
      img: FEATURE_ICON.workProfile,
      title: 'Invita al paciente a que complete sus datos',
      // onPress: onShare,
      sharer: true,
      style: {padding: 16}
    },
  ];

  return (
    <>
      <Layout style={{...CommonStyles.header, ...isDesktop ? styles.desktopHeader : {}}}>
        <HeaderButton
          style={{
            alignSelf: 'center',
            marginLeft: 24,
          }}
        />
        <Text
          marginHorizontal={24}
          bold
          size={24}
          style={{marginHorizontal: 24, flex: 1, justifySelf: 'center'}}>
          Añadir Paciente
        </Text>
      </Layout>
      <Container style={styles.container}>
        <Text
          marginTop={24}
          marginHorizontal={24}
          bold
          size={24}
          lineHeight={28}>
          ¿Cómo deseas añadirlo?
        </Text>
        <View style={[styles.topView, Theme.flexRowSpace]}>
          <Text size={15} lineHeight={18}>
            Puedes agregarlo manualmente, o puedes enviarle un enlace para que tu
            paciente los complete
          </Text>
        </View>
        {availableOptions.length ? (
          <FlatList
            data={availableOptions}
            renderItem={renderFeatureItem}
            keyExtractor={keyExtractor}
            numColumns={2}
            scrollEventThrottle={16}
            contentContainerStyle={styles.contentContainerStyle}
            showsVerticalScrollIndicator={false}
          />
          
        ) : (
          <ActivityIndicator />
        )}
      </Container>
    </>
  );
});

export default AddPatientHub;

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  topView: {
    marginTop: 12,
    marginBottom: 12,
    paddingHorizontal: 24,
  },
  contentContainerStyle: {
    paddingHorizontal: 24,
    paddingTop: 30,
    paddingBottom: getBottomSpace(),
    display: 'grid',
    gridTemplateColumns: '500px 500px',
  },
  desktopHeader: {
    backgroundColor: 'transparent',
    shadowOpacity: 0,
    shadowOffset: {
      height: 0,
      width: 0
    },
    shadowRadius: 0,
    elevation: 0,
  }
});
