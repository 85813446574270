import careTeam from '../../images/MyWork/img_care_team@3x.png';
import healthGuide from '../../images/MyWork/img_health_guide@3x.png';
import healthTip from '../../images/MyWork/img_health_tip@3x.png';
import workProfile from '../../images/MyWork/img_work_profile@3x.png';
import clinicPatients from '../../images/MyWork/img_clinic_patients@3x.png';

export const FEATURE_ICON = {
  careTeam,
  healthGuide,
  healthTip,
  workProfile,
  clinicPatients,
};
