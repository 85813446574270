import React from "react";
import {
  View,
} from "react-native";
import locales_es from "../../../locales/es";
import { Colors } from "../../../configs";
import Text from "../../../components/Text";
import iconAlert from "../../../images/img_warning.png";
import ButtonLinear from "../../../components/Buttons/ButtonLinear";

export const IncompleteProfileView = ({onCompleteYourProfile}) => {
    return (
      <View
        style={{
          flex: 1,
          backgroundColor: "white",
          justifyContent: "center",
          alignItems: "center",
          position: "fixed",
          top: 0,
          right: 0,
          left: 0,
          bottom: 0,
          zIndex: 100,
        }}
      >
        <img src={iconAlert} />
        <Text
          color={Colors.DarkJungleGreen}
          style={[{ lineHeight: 28, marginTop: 30 }]}
          size={24}
        >
          {locales_es.incompleteProfileView}
        </Text>
        <Text
          color={Colors.DarkJungleGreen}
          style={{
            textAlign: "center",
            paddingHorizontal: 60,
            lineHeight: 22,
          }}
          marginVertical={20}
          size={16}
        >
          {locales_es.incompleteProfileViewInfo}
        </Text>
        <Text
          color={Colors.DarkJungleGreen}
          style={{
            textAlign: "center",
            paddingHorizontal: 60,
            lineHeight: 22,
          }}
          marginVertical={20}
          size={16}
        >
          Debes completar tus perfiles desde la app de RCTA en tu teléfono.
        </Text>
        <ButtonLinear
          style={{paddingHorizontal: 12}}
          white
          title={locales_es.incompleteProfileViewAction}
          onPress={onCompleteYourProfile}
        />
      </View>
    );
  };

export default IncompleteProfileView

