import { Colors } from "../../configs";
import React, { memo, useCallback } from "react";
import {
  View,
  StyleSheet,
  TouchableOpacity,
  ViewStyle,
  ActivityIndicator,
} from 'react-native';
import Text from '../../components/Text';
import Layout from '../../components/Layout/Layout';
import RequireIndicator from '../../components/RequireIndicator';
interface PrefixSelectorProps {
  onPressItem: (prefixId: number) => void;
  style?: ViewStyle;
  containerStyle?: ViewStyle;
  prefixes: [];
  professionalPrefixId?: number | null;
  showRequire?: boolean;
}

const PrefixSelector = memo((props: PrefixSelectorProps) => {
  return (
    <View style={[styles.allContent, props.style]}>
      <Text size={15} lineHeight={24} bold>
        Prefijo {props.showRequire && <RequireIndicator />}
      </Text>
      <Layout style={[styles.container, props.containerStyle]}>
        {props.prefixes && props.prefixes.length ? (
          props.prefixes.map((prefix) => (
            <TouchableOpacity
              style={[
                styles.genderButton,
                prefix.id === props.professionalPrefixId && styles.buttonActive,
              ]}
              onPress={() => props.onPressItem(prefix.id)}
            >
              <Text
                size={13}
                lineHeight={16}
                semibold
                color={
                  prefix.id === props.professionalPrefixId
                    ? Colors.White
                    : Colors.GrayBlue
                }
              >
                {prefix.name}
              </Text>
            </TouchableOpacity>
          ))
        ) : (
          <ActivityIndicator />
        )}
      </Layout>
    </View>
  );
});

export default PrefixSelector;

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    justifyContent: "space-around",
    marginTop: 12,
    borderRadius: 8,
  },
  genderButton: {
    flex: 1,
    alignItems: "center",
    paddingVertical: 12,
    borderRadius: 8,
  },
  buttonActive: {
    backgroundColor: "#12B2B3",
  },
  allContent: {
    paddingBottom: 24,
    marginTop: 32,
  },
});
