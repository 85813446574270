import React, {memo} from 'react';
import {
  StyleSheet,
  Image,
  TouchableOpacity,
  ViewProps,
  ViewStyle,
} from 'react-native';
import Text from '../../../components/Text';
import {Colors} from '../../../configs';
import Theme from '../../../style/Theme';
import iconAdd from '../../../images/ic_add_16@3x.png';
interface ButtonAddProps {
  icon?: any;
  iconStyle?: ViewStyle;
  title: string;
  onPress?: () => void;
  style?: ViewProps;
  titleStyle?: ViewProps;
}

const ButtonAdd = memo((props: ButtonAddProps) => {
  return (
    <TouchableOpacity
      style={[styles.buttonAdd, props.style]}
      onPress={props.onPress}>
      <Image
        source={props.icon || iconAdd}
        style={[styles.iconRight, props.iconStyle]}
      />
      <Text
        size={13}
        lineHeight={16}
        color={Colors.GrayBlue}
        bold
        style={props.titleStyle}>
        {props.title}
      </Text>
    </TouchableOpacity>
  );
});

export default ButtonAdd;

const styles = StyleSheet.create({
  buttonAdd: {
    marginTop: 24,
    borderWidth: 1,
    borderColor: Colors.Platinum,
    height: 36,
    ...Theme.center,
    borderRadius: 8,
    ...Theme.flexDirection,
  },
  iconRight: {
    width: 16,
    height: 16,
    marginRight: 8,
  },
});
