import ar from '../images/country/img_flag_ar.png';
import usa from '../images/country/img_flag_usa.png';
import ec from '../images/country/img_flag_ec.png';
import py from '../images/country/img_flag_py.png';
export const countryFlag = {
  ar,
  usa,
  ec,
  py,
};
