import React, {memo, useCallback, useEffect, useState} from 'react';
import {
  View,
  StyleSheet,
  Image,
  TouchableOpacity,
  Text as NativeText,
} from 'react-native';
import Text from '../Text';
import Theme from '../../style/Theme';
import {useTheme} from '../../configs/Theme';
import locales_es from '../../locales/es';
import Colors from '../../configs/Colors';
import ButtonIcon from '../../components/Buttons/ButtonIcon';
import TextInput from '../../components/TextInput';
import {showModalInfo} from '../../modules/ViewHelpers';
import {api, helpers} from '../../import';
import ListItem from '../../components/ListItem';
import iconClose from '../../images/ic_close@3x.png';

interface DiagnosticAutocompleteItemProps {
  diagnosticIn: any;
  diagnosticCodIn: any;
  tagIn: any;
  errorsIn: any;
  paddingRightFlagIn: boolean;
  buttonIconFlag?: boolean;
  onPress: (item: any) => void;
  onChange: (e: any) => void;
  widthLetter?: string;
  initialState?: any;

}
let nativeEventTestSearchDiagnostics = '';

const DiagnosticAutocompleteItem = memo(({diagnosticIn, diagnosticCodIn, tagIn, errorsIn, paddingRightFlagIn, buttonIconFlag = false, onPress, onChange, widthLetter, initialState}: DiagnosticAutocompleteItemProps) => {
  const {theme} = useTheme();
  const [diagnostic, setDiagnostic] = useState('');
  const [diagnosticCod, setDiagnosticCod] = useState('');
  const [tag, setTag] = useState('');
  const [paddingRightFlag, setPaddingRightFlagIn] = useState(false);
  const [errors, setErrors] = useState([]);
  const [searchDiagnostics, setSearchDiagnostics] = useState('');
  const [searchDiagnosticsResults, setSearchDiagnosticsResults] = useState([]);
  const [initState, setInitState] = useState('');

  useEffect(() => {
    setDiagnostic(diagnosticIn);
    setDiagnosticCod(diagnosticCodIn);
    setTag(tagIn);
    setErrors(errorsIn);
    setPaddingRightFlagIn(paddingRightFlagIn);
    if (diagnosticIn && !diagnosticCodIn && !initialState) {

      setSearchDiagnostics(diagnosticIn);
    } else if (diagnosticIn && !diagnosticCodIn && initialState) {
      // verificamos si el diagnostico contiene un guion para separar el codigo del diagnostico, y contamos los 4 primeros caracteres
      if (initialState.substring(0, 6).includes('-')) {
        let splitDiagnostic = initialState.split('-');
        setDiagnostic(initialState);
        setDiagnosticCod(splitDiagnostic[0]);
      } else {
        setSearchDiagnostics(initialState);
      }

    } else if (!diagnosticIn||(diagnosticIn && diagnosticCodIn)) {
      setSearchDiagnostics('');
    }
  }, [diagnosticIn, diagnosticCodIn, errorsIn, initialState]);

  const onSearchDiagnosticsChange = (e: any) => {
    // e.persist(); // https://reactjs.org/docs/legacy-event-pooling.html
    nativeEventTestSearchDiagnostics = e.nativeEvent.text;
    setSearchDiagnostics(e.nativeEvent.text);
    onChange(e.nativeEvent.text);
    setInitState('');
  };

  useEffect(() => {
    autocompleteDiagnostic(searchDiagnostics);
  }, [searchDiagnostics]);

  const autocompleteDiagnostic = (textSearch: string) => {
    if (textSearch && textSearch.length > 2) {
      setSearchDiagnosticsResults(null);
      setTimeout(() => {
        if (
          textSearch.length === nativeEventTestSearchDiagnostics.length
        ) {
          api
            .searchDiagnostics(textSearch)
            .then(res => {
              setSearchDiagnosticsResults(res.data);
            })
            .catch(err => {
              setSearchDiagnosticsResults([]);
            });
        } else {
          setSearchDiagnosticsResults([]);
        }
      }, 400); // 800
    }
  }

  const renderListItem = (item: any) => {
    return (
      <ListItem
        style={{width: widthLetter ? widthLetter : '100%', fontSize: 13}}
        key={tag + item.iddiagnostico}
        item={{
          id: new Date().getTime(),
          name: `${item.coddiagnostico} - ${item.descdiagnostico}`,
        }}
        onPress={() => onPressSearchResult(item)}
      />
    );
  };

  const onPressSearchResult = (item: any) => {
    setDiagnostic(`${item.coddiagnostico} - ${item.descdiagnostico}`);
    setDiagnosticCod(item.coddiagnostico);
    setSearchDiagnosticsResults([]);
    setSearchDiagnostics('');
    onPress(item);
    setInitState('');
  };

  const removeDiagnostic = () => {
    setDiagnostic('');
    setDiagnosticCod('');
    setSearchDiagnosticsResults([]);
    setSearchDiagnostics('');
    onPress({coddiagnostico: '', descdiagnostico: ''});
    onChange('');
    setInitState('');
  }

  return (
    <View style={{flexDirection: 'row'}}>
      <View style={{
        flex: 1
        }}>

        {diagnostic && diagnosticCod &&(<View
          style={{
            ...Theme.flexColumn,
            paddingLeft: 24,
            paddingRight: (paddingRightFlag) ? 24 : 0,
            paddingBottom: 16
          }}>
          <Text size={15} lineHeight={18} marginLeft={16}>
            {diagnostic}
          </Text>
        </View>)}

        {(!diagnostic || (diagnostic && !diagnosticCod)) ? (<View
          style={{
            ...Theme.flexColumn,
            paddingLeft: 24,
            paddingRight: (paddingRightFlag) ? 24 : 0,
            paddingBottom: 16,
            
          }}>
          <TextInput
            placeholderTextColor={theme.placeholder}
            value={searchDiagnostics}
            placeholder={locales_es.addPrescriptionDiagnosis}
            style={{
              fontFamily: 'Muli_400Regular',
              fontSize: 15,
              lineHeight: 18,
              color: theme.text,
              marginTop: 12,
              marginRight: 10,
              flex: 1,
              borderWidth: 1,
              width: '90%',
              borderColor: errors.includes('diagnostic')
              ? theme.lineErrorColor
              : theme.innearColor,
            }}
            onChange={onSearchDiagnosticsChange}
            editable={(diagnosticCod) ? false : true}
          />
          <View
            style={[
              {backgroundColor: '#fff'},
              {width: '90%'},

              (searchDiagnosticsResults && searchDiagnosticsResults.length)
                ? {
                  borderColor: theme.innearColor,
                  borderWidth: 1,
                  borderRadius: 8,
                  
                  overflowX: 'hidden',
                  overflow: 'auto',
                  height: '500px',
                  

                }
                : null,
            ]}>
            {searchDiagnosticsResults && searchDiagnosticsResults.length ? (
              searchDiagnosticsResults.map(result => renderListItem(result))
            ) : null}
          </View>
        </View>) : null}
              
      </View>

      {diagnostic && diagnosticCod && (<View>
        <TouchableOpacity
          key={tag.toString() + 1}
          onPress={() => {
            removeDiagnostic();
          }}
          style={{
            zIndex: 2,
            marginRight: 16,
            paddingVertical: 10,
          }}>
          <Image
            source={iconClose}
            style={{
              tintColor: 'red',
              width: 20,
              height: 20,
            }}
          />
        </TouchableOpacity>
      </View>)}
      
    </View>
  );
});

export default DiagnosticAutocompleteItem;

const styles = StyleSheet.create({
  buttonIconImgStyle: {
    width: 24,
    height: 24,
  }
});
