import React, {memo, useCallback} from 'react';
import {View, StyleSheet, Image, TouchableOpacity} from 'react-native';
import Theme from '../../../style/Theme';
import Text from '../../../components/Text';
import {useHistory} from 'react-router-dom';
import {Routes} from '../../../configs';
import locales_es from '../../../locales/es';

interface GreetingProps {}

const Greeting = (props: GreetingProps) => {
  const history = useHistory();

  const onPressAvatar = useCallback(() => {
    history.push(`/${Routes.MyProfile}`);
  }, [history]);

  return (
    <TouchableOpacity onPress={onPressAvatar} style={styles.container}>
      {props.full_profile_image && (
        <Image
          source={{uri: props.full_profile_image}}
          style={{width: 40, height: 40, marginRight: 16, borderRadius: 8}}
        />
      )}
      <View style={{justifyContent: 'space-between', height: 40}}>
        <Text size={17} lineHeight={20} bold>
          Hola {props.professional_prefix && props.professional_prefix.id ? locales_es.professionalPrefix[props.professional_prefix.id] : ''},
        </Text>
        <Text size={15} lineHeight={18}>
          ¿Qué recetas mandamos hoy?
        </Text>
      </View>
    </TouchableOpacity>
  );
};

export default Greeting;

const styles = StyleSheet.create({
  container: {
    ...Theme.flexRow,
  },
});
