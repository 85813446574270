import React, {useState} from "react";
import Layout from "../../components/Layout/Layout";
import HeaderButton from "../../components/HeaderButton";
import {SOURCE_ICON} from "../../images";
import Container from '../../components/Layout/Container';
import {Colors} from "../../configs";
import {StyleSheet} from "react-native";
import {getBottomSpace, getStatusBarHeight} from "react-native-iphone-x-helper";
import Theme from "../../style/Theme";
import {useHistory} from "react-router-dom";
// import {MyComponent} from "@WoopiApps/rcta-components";

export default function WebviewLoader(props) {
    const history = useHistory();
    const params = history.location.state;
    const [uri, setUri] = useState(
      params ? params.uri : null,
    );

    return <>
        <Layout style={styles.header}>
            <HeaderButton
              icon={SOURCE_ICON.close}
              style={{
                  marginTop: 26,
                  marginLeft: 24,
              }}
              onPress={props.onCancel}
            />
        </Layout>
        <Container style={styles.container}>
            <iframe style={styles.iframe} src={uri} />
        </Container>
    </>;
}

const styles = StyleSheet.create({
    container: {
        paddingTop: 32,
        paddingHorizontal: 24,
        paddingBottom: getBottomSpace() + 12,
        ...Theme.flexOne,
    },
    scrollView: {
        flex: 1,
    },
    header: {
        ...Theme.flexRowSpace,
        paddingRight: 24,
    },
    iframe: {
        width: '100%',
        height: '100%',
        border: 0,
    }
});
