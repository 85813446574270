import {StyleSheet} from 'react-native';
import {Metrics} from '../../Themes/';

const styles = StyleSheet.create({
  loadingScreen: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    // width: Metrics.WIDTH,
    // height: Metrics.HEIGHT,
    top: 0,
    right: 0,
    left: 0,
    bottom: 0,
    zIndex: 1000,
    backgroundColor: 'rgba(255,255,255,0.5)',
    flexDirection: 'column',
  },
});

export default styles;
