import React, { useState, useEffect, useCallback } from 'react';
import {useHistory} from 'react-router-dom';
import { Routes } from '../../../configs';
import { showModalInfo } from '../../../modules/ViewHelpers';
import locales_es from '../../../locales/es';
import { EVENT_RELOAD_PATIENTS } from '../../../constants/Events';
import { EventRegister } from '../../../modules/EventRegister';
import APIService from '../../../modules/apiService';
import Helpers from '../../../modules/helpers';
import { ConsultsType } from '../../../configs/Const';
import SearchPatients from '../../SearchPatients';
import { set } from 'react-native-reanimated';

function useDebounce(value: string, delay: any) {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return debouncedValue;
}

let nativeEventKeySearch = '';
const useMyPatientsPage = (patientId, onSelectPatient) => {
  const api = new APIService();
  const helpers = new Helpers();
  const history = useHistory();
  const params = history.location.state;

  const [keySearch, setKeySearch] = useState('');
  const debouncedSearchTerm = useDebounce(keySearch, 500);

  const [selectPatientId, setSelectPatientId] = useState(
    patientId
      ? patientId
      : params
      ? params.selectPatientId
      : null
  );
  const [selectionType, setSelectionType] = useState(
    patientId
      ? patientId
      : params
      ? params.selectionType
      : null
  );
  const [load, setLoad] = useState(false);
  const [patients, setPatients] = useState([]);
  const [nextPageUrl, setNextPageUrl] = useState('');
  const [refreshing, setRefreshing] = useState(true);
  let loading = false

  const onPressWhitePlusButton = useCallback(() => {
    history.push(`${Routes.AddPatientHub}`, { historyBackSteps: 3 });
  }, [history]);


  const onKeySearchChange = e => {
    const key = e.nativeEvent.text;
    nativeEventKeySearch = key;
    setKeySearch(e.nativeEvent.text);
    if (nativeEventKeySearch.trim() === '') {
      setKeySearch('');
    }
  };


  const getPatients = () => {
      const objData = {
        search: nativeEventKeySearch,
      };

    if (refreshing && !loading){
      return;
    }
    loading = true;
    setRefreshing(true);
    api
      .getMyPatients(nextPageUrl,objData)
      .then((res: any) => {
        setRefreshing(false);
        setNextPageUrl(res.next_page_url);
        setPatients(currentPatients => currentPatients ? currentPatients.concat(res.data) : res.data);
      })
      .catch((err: any) => {
        setRefreshing(false);
        helpers.processError(err);
      })
      .finally(() => {
        loading = false;
      });
  };

  useEffect(() => {

      setPatients([]);
      setNextPageUrl('');

    if (nativeEventKeySearch.length == 0) {
      const objData = {
        search: nativeEventKeySearch,
      };

      if (nativeEventKeySearch.length !== 0) {
        return;
       }

    setRefreshing(true);
    api
      .getMyPatients('',objData)
      .then((res: any) => {
        setRefreshing(false);
        setPatients(res.data);
        setNextPageUrl(res.next_page_url);

      })
      .catch((err: any) => {
        setRefreshing(false);
        helpers.processError(err);
      })

    }
  }, [nativeEventKeySearch]);



  useEffect(() => {
  if (debouncedSearchTerm) {
    setPatients([]);
    const objData = {
      search: nativeEventKeySearch,
    };
    if (nativeEventKeySearch.length === 0) {
      return;
    }

    setRefreshing(true);

    api
      .getMyPatients('', objData)
      .then((res: any) => {
        setRefreshing(false);
        setPatients(res.data);
        setNextPageUrl(res.next_page_url);
      })
      .catch((err: any) => {
        setRefreshing(false);
        showModalInfo(
          locales_es.infoModal.title,
          helpers.getErrorMsg(err),
        );
      });
  }
  }, [debouncedSearchTerm]);

  const onUserInfoPress = (patient) => {
    if (onSelectPatient) {
      onSelectPatient(patient);
    } else {
      if (selectionType === ConsultsType.Certificate) {
        history.push(`${Routes.AddCertificate}`, {
          patientId: patient.id,
        });
      } else {
        history.push(`${Routes.AddPrescription}`, {
          patientId: patient.id,
        });
      }
    }
  };

  const goToUserProfile = (patient) => {
    setKeySearch('')
    setPatients([]);
    setNextPageUrl('');
    nativeEventKeySearch = '';
    history.push(`${Routes.PatientProfile}`, {
      patientId: patient.id,
    });
  };

  return {
    onUserInfoPress,goToUserProfile, onPressWhitePlusButton, onKeySearchChange,patients, selectPatientId, load, selectionType, keySearch, setKeySearch, history, nextPageUrl, refreshing, getPatients
  };
};

export default useMyPatientsPage;
