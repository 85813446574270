import React from 'react';
import Text from '../../components/Text';
import locales_es from '../../locales/es';
import { CgDanger } from "react-icons/cg";
import { SOURCE_ICON } from "../../images";
import {useTheme} from '../../configs/Theme';
import {Colors, Routes} from '../../configs';
import { FaCheckCircle } from "react-icons/fa";
import InputApp from '../../components/InputApp';
import useResetPassword from './useResetPassword';
import { IoWarningOutline } from "react-icons/io5";
import eyeOnIcon from '../../images/ic_eye_on.png';
import {View, StyleSheet, Image} from 'react-native';
import { AiOutlineEyeInvisible } from "react-icons/ai";
import ButtonLinear from '../../components/Buttons/ButtonLinear';
import FullScreenLoading from '../../components/fullscreenLoading';


const ResetPassword = () => {
    const {theme} = useTheme();
    const {
        type,
        errors,
        loading,
        success,
        password,
        initForm,
        onSubmit,
        setPassword,
        samePassword,
        enableButton,
        errorMessages,
        focusPassword,
        confirmPassword,
        visiblePassword,
        setErrorMessages,
        setFocusPassword,
        validatePassword,
        setConfirmPassword,
        onShowHidePassword,
        focusConfirmPassword,
        visibleConfirmPassword,
        setFocusConfirmPassword,
        onShowHideConfirmPassword,
        screenWidth
    } = useResetPassword();

    return (

        <div style={styles.mainContainer}>
            <div style={styles.container}>
                <div className="row h-100">
                    {loading && <FullScreenLoading />}
                    <div className="d-none d-md-inline-flex col-6">
                    <div className="rcta-bg-logo"/>
                    </div>

                    <div className="col-12 col-md-6 align-self-md-center text-left">
                        {!success && (
                        <View style={{gap: screenWidth > 1000 ? 30 : 15,
                            marginLeft: screenWidth > 1000 ? 130 : 30,
                            marginRight: screenWidth > 1000 ?  130 : 30,}}>
                            { screenWidth < 1000 &&
                            <Image source={SOURCE_ICON.logo}
                            style={{width: 250,
                                height: 150,
                                alignSelf: 'center',
                                marginBottom: 20,
                                marginTop: 50}}
                                resizeMode="contain" />
                            }


                            <Text bold size={screenWidth > 1000 ? 33 : 20} marginBottom={screenWidth > 1000 ? 50 : 0} color='black'>
                                {type == 'reset' ? 'Restablecer contraseña' : 'Crear contraseña'}
                            </Text>
                            { screenWidth > 1000 ?
                            <>
                                <Text bold size={25} marginBottom={0} >
                                    Hola Dr/a
                                </Text>
                                <Text bold size={20} marginBottom={15} >
                                Seleccioná una nueva contraseña para ingresar a RCTA
                                </Text>
                            </> :
                                <Text semibold size={15} marginBottom={15} >
                                Seleccioná una nueva contraseña de ingreso
                                </Text>

                            }
                            <InputApp
                                onBlur={() => setFocusPassword(false)}
                                onFocus={() => setFocusPassword(true)}
                                title={<Text size={screenWidth > 1000 ? 17 : 14} marginBottom={10} semibold>{locales_es.newPassword}</Text>}
                                placeholder={'Ingresá tu contraseña'}
                                secureTextEntry={!visiblePassword}
                                label={
                                    errors.includes('password') ? (
                                    <View style={{flexDirection: 'row'}}>
                                        <CgDanger color='Red' size={screenWidth > 1000 ? 30 : 60} style={{marginRight:10, marginBottom:10}} />
                                        <Text size={screenWidth > 1000 ? 15 : 14}  marginBottom={10} lineHeight={20}  color={Colors.Red}>
                                            {errorMessages.password}
                                        </Text>
                                    </View>
                                    ) : !errors.includes('password') && password.length > 0 ?  '' :
                                    <Text size={screenWidth > 1000 ? 15 : 14}  marginBottom={10} lineHeight={20}  >
                                        La contraseña debe contener al menos 8 caracteres, letras mayúsculas y minúsculas, y al menos un caracter especial (por ejemplo '!')
                                    </Text>
                                }
                                value={password}
                                onChangeText={(value:string)=>{validatePassword({value, minLength: 8,initForm: initForm, setErrorMessages: setErrorMessages, fieldKey: 'password'}); setPassword(value)}}
                                keyboardType="password"
                                blurOnSubmit={true}
                                borderColor={
                                    focusPassword ? 'blue' :
                                    errors.includes('password')
                                        ? theme.lineErrorColor
                                        : theme.innearColor
                                    }
                                iconPress={onShowHidePassword}

                                icon={
                                    !visiblePassword ? <AiOutlineEyeInvisible size={25} color={focusPassword ? 'blue': 'gray'} />  :
                                    <Image
                                        source={eyeOnIcon}
                                        style={{...styles.icon, tintColor: focusPassword ? 'blue': '#3b3b3b'}}
                                    />
                                    }
                                    isShowIcon
                                autoCapitalize="none"
                                returnKeyType="next"
                                autoFocus={false}
                                maxLength={16}
                                style={{fontSize: screenWidth > 1000 ? 17 : 13}}
                            />

                            <InputApp
                                onBlur={() => setFocusConfirmPassword(false)}
                                onFocus={() => setFocusConfirmPassword(true)}
                                title={<Text semibold size={screenWidth > 1000 ? 17 : 14} marginBottom={10}>{locales_es.confirmNewPassword}</Text>}
                                placeholder={'Ingresá tu contraseña nuevamente'}
                                secureTextEntry={!visibleConfirmPassword}
                                value={confirmPassword}
                                onChangeText={(value:string)=> setConfirmPassword(value)}
                                keyboardType="password"
                                blurOnSubmit={true}
                                label={
                                    errors.includes('confirmPassword') ? (
                                    <View style={{flexDirection: 'row'}}>
                                        <CgDanger color='Red' size={22} style={{marginRight:10, marginBottom:10}} />
                                        <Text size={15}  marginBottom={10} lineHeight={20}  color={Colors.Red}>
                                            {errorMessages.confirmPassword}
                                        </Text>
                                    </View>
                                    ) : ''
                                }
                                borderColor={
                                    focusConfirmPassword ? 'blue' :
                                    errors.includes('confirmPassword')
                                        ? theme.lineErrorColor
                                        : theme.innearColor
                                    }

                                iconPress={onShowHideConfirmPassword}
                                icon={ samePassword == true ? <FaCheckCircle size={20} color='green' /> :
                                    !visibleConfirmPassword ? <AiOutlineEyeInvisible size={25} color={focusConfirmPassword ? 'blue' : 'gray'} />  :
                                    <Image
                                        source={eyeOnIcon}
                                        style={{...styles.icon, tintColor: focusConfirmPassword ? 'blue' : '#3b3b3b'}}
                                        resizeMode="contain"

                                        />
                                    }
                                    isShowIcon
                                autoCapitalize="none"
                                returnKeyType="next"
                                autoFocus={false}
                                maxLength={16}
                                style={{fontSize: screenWidth > 1000 ? 17 : 13}}
                            />

                                <Text size={screenWidth > 1000 ? 17 : 14} marginTop={35} center>
                                    <IoWarningOutline size={25} style={{marginRight:10}} />
                                    Por su seguridad evite el uso de datos personales
                                </Text>


                            <ButtonLinear
                                white
                                title={'Confirmar'}
                                disabled={enableButton}
                                onPress={onSubmit}
                                colors={[Colors.BlueCrayola, Colors.Blue]}
                                />
                        </View>
                        )}
                        {success && (
                            <View>
                                { screenWidth < 1000 && (
                                    <Image source={SOURCE_ICON.logo}
                                    style={{width: 250,
                                        height: 150,
                                        alignSelf: 'center',
                                        marginBottom: 20,
                                        marginTop: 50}}
                                        resizeMode="contain" />
                                )}
                                <FaCheckCircle size={screenWidth > 1000 ? 120 : 90} color='green' style={{alignSelf:'center'}}/>
                                <Text bold size={screenWidth > 1000 ? 30 : 22} marginTop={50} center color='black'>
                                    ¡Listo! Contraseña cambiada con éxito
                                </Text>
                                <View style={{width:screenWidth > 1000 ?'57%' : '85%', alignSelf: 'center', marginTop: 25}}>
                                    <Text size={screenWidth > 1000 ? 20 : 17} marginTop={20} style={{textAling: 'left'}} lineHeight={30}  color='black'>
                                        Puedes iniciar sesion con tu nueva contraseña y continuar utilizando RCTA
                                    </Text>
                                </View>
                            </View>
                        )}
                    </div>
                </div>
            </div>
        </div>

    );
}

export default ResetPassword;

const styles = StyleSheet.create({
    container: {
        flexDirection: 'row',
        height: '100%',
        width:  '100%',
    },
    mainContainer: {
        flexDirection: 'row',
        height: '100%',
        width:  '100%',
        display: 'flex',
        overflowY: 'scroll',
    },
    formContainer:{
        gap: 30
    },
    icon: {
        width: 24,
        height: 24,
      },


})
