import React, { useState, useEffect } from "react";
import {
  View,
  StyleSheet,
  Image,
  TouchableOpacity,
  Modal,
  ScrollView,
  Text as NativeText,
} from "react-native";
import Theme from "../../../style/Theme";
import Text from "../../../components/Text";
import scale from "../../../utils/scale";
import { useHistory } from "react-router-dom";
import Container from "../../../components/Layout/Container";
import Layout from "../../../components/Layout/Layout";
import HeaderButton from "../../../components/HeaderButton";
import {
  getBottomSpace,
  getStatusBarHeight,
} from "react-native-iphone-x-helper";
import UserInfo from "../../../components/ConsultDetail/UserInfo";
import SelectPatient from "../../../components/Woopi/SelectPatient";
import ButtonLinear from "../../../components/Buttons/ButtonLinear";
import Colors from "../../../configs/Colors";
import Routes from "../../../configs/Routes";
import ButtonIcon from "../../../components/Buttons/ButtonIcon";
import TextInput from "../../../components/TextInput";
import { useTheme } from "../../../configs/Theme";
import InputSpinner from "react-native-input-spinner";

import { api, helpers, dateTimeService } from "../../../import";
import locales_es from "../../../locales/es";
import { showModalInfo } from "../../../modules/ViewHelpers";
import FullScreenLoading from "../../../components/fullscreenLoading";
import FullScreenGreenCheck from "../../../components/FullscreenGreenCheck";
import FullscreenProgress from "../../../components/FullscreenProgress";
import {
  validateEmptyFields,
  validateEmptyArrays,
} from "../../../utils/validation/forms";
import {
  AsyncStorageEnum,
  getFromStorage,
  setInStorage,
} from "../../../utils/storage";
import Content from "../../../components/Layout/Content";
import CoachMarksService from "../../../modules/coachmarks";
import ButtonBorder from "../../../components/Buttons/ButtonBorder";

import LocalAuthService from "../../../modules/LocalAuthService";
import AccountItem from "../../../components/AccountItem/Index";

import ButtonText from "../../../components/Buttons/ButtonText";
import {
  EVENT_RELOAD_PRESCRIPTIONS,
  EVENT_SHOW_MODAL_INFO,
} from "../../../constants/Events";
import { ConsultsType } from "../../../configs/Const";
import PrescriptionProfiles from "../../../components/WorkProfileManagement/PrescriptionProfiles";
import { PRESCRIPTION_REQUEST_STATUS_ACCEPTED } from "../../../models/constants";
import { EventRegister } from "../../../modules/EventRegister";
import iconHelpWhite from "../../../images/ic_help_white@3x.png";
import iconClose from "../../../images/ic_close@3x.png";
import iconWarning from '../../../images/img_warning.png';
import iconSuccess from "../../../images/img_success@3x.png";
import FormNewDatePicker from "../../../components/formNewDatePicker";
import Overlay from "../../../components/Overlay";
import { MyPatientsMobile } from "../../MyPatients";
import SearchMedicines from "../../SearchMedicines";
import useAddPrescriptionPage from "../shared/useAddPrescriptionPage";
import DiagnosticAutocompleteItem from "../../../components/DiagnosticAutocomplete";

interface AddPrescriptionProps {}

const AddPrescription = (props: AddPrescriptionProps) => {
  const {
    overlay,
    congrats,
    loadingText,
    loading,
    userData,
    errors,
    dates,
    minDate,
    diagnostic,
    indications,
    extendedTreatment,
    hiv,
    removeDate,
    removeMedicine,
    patientId,
    theme,
    comments,
    medicines,
    prescriptionProfileId,
    prescriptionProfiles,
    prescriptionRequestId,
    progress,
    onSend,
    onSwitchPressIconNeedsDuplicate,
    onBlur,
    onPrescriptionProfileSelect,
    onSwitchPressNeedsDuplicate,
    onSwitchPressNoReplacement,
    onSwitchPressOnlyGeneric,
    addNewDate,
    setComments,
    setDateArray,
    setDiagnostic,
    setExtendedTreatment,
    setHiv,
    setIndications,
    setMedicineQuantity,
    setOverlay,
    setPatientId,
    setUserData,
    loadMedicine,
    prescriptionError,
    onPressSearchResult,
    setMedicineDiagnostic,
    setPrescriptionError,
    sendWithNoInsurance,
    correctPatientNoInsurance,
    diagnosticCod
  } = useAddPrescriptionPage();

  const goToSearchMedicines = () => {
    setOverlay(
      <SearchMedicines showMainModal={(title, content, actions, customStyles, contentCustomClassName) => props.showModal(title, content, actions, customStyles, contentCustomClassName)}
        hideMainLoading={props.hideMainLoading}
        onSelectItem={(selectedItem) => {
          setOverlay(null);
          loadMedicine(selectedItem);
        }}
        onCancel={() => {
          setOverlay(null);
        }}
      />
    );
    // history.push(`/${Routes.SearchMedicines}`);
  };
  const renderItem = (item, index) => {
    return (
      <div key={'medicine-item-' + index + item.id}>
        <View style={{flexDirection: 'row'}}>
          <View style={{flex: 1}}>
            <View
              key={index.toString()}
              style={{
                paddingHorizontal: 24,
                marginVertical: 10,
                zIndex: 1,
              }}>
              <Text
                blueLight
                size={15}
                lineHeight={24}
                color={Colors.BlueCrayola}
                medium>
                <NativeText
                  style={[
                    item.is_generic ? styles.lineThrough : {},
                    item.no_replacement ? styles.underline : {},
                  ]}>
                  {item.commercial_name}
                </NativeText>{' '}
                ({item.drug}) {item.presentation}
              </Text>
            </View>
            <AccountItem
              style={styles.switch}
              name={locales_es.prescriptOnlyGeneric}
              isToggle={true}
              onPress={() => onSwitchPressOnlyGeneric(index)}
              // onValueChange={() => onSwitchPressOnlyGeneric(index)}
              switchValue={item.is_generic}
            />
            <AccountItem
              style={styles.switch}
              name={locales_es.noReplacement}
              isToggle={true}
              onPress={() => onSwitchPressNoReplacement(index)}
              // onValueChange={() => onSwitchPressNoReplacement(index)}
              switchValue={item.no_replacement}
            />
            <AccountItem
              icon={
                item.need_duplicate_reason
                  ? iconHelpWhite
                  : null
              }
              linearColorDisabled={true}
              iconInTitle={true}
              iconStyle={styles.switchIconStyle}
              style={styles.switch}
              nameStyle={item.need_duplicate_reason ? {minWidth: 120, marginLeft: 0} : null}
              name={locales_es.needsDuplicate}
              isToggle={true}
              onPress={() => onSwitchPressNeedsDuplicate(index)}
              onPressIcon={() => onSwitchPressIconNeedsDuplicate(index)}
              // onValueChange={() => onSwitchPressNeedsDuplicate(index)}
              switchValue={item.is_duplicated}
            />
            <DiagnosticAutocompleteItem 
                    diagnosticIn={item.diagnostic}
                    diagnosticCodIn={item.diagnostic_cod}
                    errorsIn={errors}
                    tagIn={`medicine-diagnostic-${index}-`}
                    paddingRightFlagIn={false}
                    onPress={(item) => onPressSearchResult(item, index)}
                    onChange={(text) => setMedicineDiagnostic(index, text)}
            />
            <View
              style={{
                alignSelf: 'center',
                marginTop: 10,
                marginBottom: 20,
                paddingLeft: 40,
                transform: [{scale: 0.75}],
              }}>
              <InputSpinner
                editable={false}
                max={10}
                min={1}
                step={1}
                colorMax={Colors.Tomato}
                colorMin={Colors.TealBlue}
                color={Colors.TealBlue}
                value={Number(item.quantity)}
                skin="modern"
                onChange={num => setMedicineQuantity(index, num)}
              />
            </View>
          </View>
          <View>
            <TouchableOpacity
              key={index.toString() + 1}
              onPress={() => {
                removeMedicine(index);
              }}
              style={{
                zIndex: 2,
                marginRight: 16,
                paddingVertical: 10,
              }}>
              <Image
                source={iconClose}
                style={{
                  tintColor: 'red',
                  width: 20,
                  height: 20,
                }}
              />
            </TouchableOpacity>
          </View>
          
        </View>
      </div>
    );
  };

  const selectPatient = () => {
    setOverlay(
      <MyPatientsMobile
        showMainModal={(
          title,
          content,
          actions,
          customStyles,
          contentCustomClassName
        ) =>
          props.showModal(
            title,
            content,
            actions,
            customStyles,
            contentCustomClassName
          )
        }
        hideMainLoading={props.hideMainLoading}
        selectPatientId={true}
        onSelectPatient={(patient) => {
          setPatientId(patient.id);
          setUserData(patient);
          setOverlay(null);
        }}
        onGoBack={() => setOverlay(null)}
      />
    );
  };

  return (
    <>
      {overlay && <Overlay>{overlay}</Overlay>}
      {progress ? (
        <FullscreenProgress progress={progress} text={loadingText}/>
      ) : null}
      {congrats && (
        <FullScreenGreenCheck
          text={locales_es.createdPrescriptionSuccessfully}
        />
      )}
      {loading && <FullScreenLoading text={loadingText}/>}
      <Layout style={styles.header}>
        <HeaderButton
          style={{
            marginTop: 26,
            marginLeft: 24,
          }}
        />
        <Text
          // marginTop={getStatusBarHeight() + 24}
          marginTop={getStatusBarHeight() + 36}
          marginHorizontal={24}
          bold
          size={16}
          lineHeight={18}
          style={{marginHorizontal: 24, marginBottom: 24, flex: 1}}>
          Crear Receta
        </Text>
      </Layout>
      <Container style={styles.container}>
        <Content
          style={styles.container}
          showsVerticalScrollIndicator={false}
          extraHeight={100}
          extraScrollHeight={100}
          contentContainerStyle={styles.contentContainerStyle}>
          {prescriptionProfiles && (
            <View style={styles.section}>
              <PrescriptionProfiles
                {...userData}
                prescriptionProfiles={prescriptionProfiles}
                onProfileSelect={onPrescriptionProfileSelect}
                selectedProfileId={prescriptionProfileId}
                style={
                  errors.includes('prescriptionProfileId')
                    ? styles.sectionError
                    : null
                }
                editable={!prescriptionRequestId}
                // editable={false}
              />
            </View>
          )}

          <Layout
            style={{
              marginBottom: 24,
              paddingTop: 16,
              borderRadius: 16,
              ...Theme.shadow,
            }}>
            <View
              style={{
                ...Theme.flexRow,
                paddingHorizontal: 24,
                paddingBottom: 16,
              }}>
              <ButtonIcon
                icon={'calendar'}
                style={{
                  width: 32,
                  height: 32,
                  backgroundColor: Colors.TealBlue20,
                }}
                imgStyle={styles.buttonIconImgStyle}
                tintColor={Colors.TealBlue}
                disabled
              />
              <Text size={15} lineHeight={18} bold marginLeft={16}>
                {dates.length > 1
                  ? locales_es.prescriptionsDates
                  : locales_es.prescriptionDate}
              </Text>
              <ButtonIcon
                onPress={() => {
                  EventRegister.emit(EVENT_SHOW_MODAL_INFO, {
                    title: locales_es.prescriptionDatesInfo.title,
                    message: locales_es.prescriptionDatesInfo.content,
                  });
                }}
                icon={'help'}
                style={{
                  width: 32,
                  height: 32,
                  backgroundColor: Colors.White,
                  position: 'absolute',
                  bottom: 15,
                  right: 20,
                }}
                imgStyle={styles.buttonIconImgStyle}
                tintColor={Colors.TealBlue}
              />
            </View>
            <View
              style={{
                flex: 1,
                paddingHorizontal: 24,
                paddingBottom: 16,
              }}>
              {dates && dates.length
                ? dates.map((_date: any, index: number) => {
                    return (
                    <View key={'datePicker-' + index}>
                        <FormNewDatePicker
                          withPortal={true}
                          portalId={'datePicker-' + index}
                          value={_date}
                          placeholder={locales_es.date}
                          // required={true}
                          handleChange={(date: any) => {
                            setDateArray(date, index);
                          }}
                          minDate={minDate}
                        />
                      </View>
                    );
                  })
                : null}

              {dates && dates.length && dates.length > 1 ? (
                <ButtonText
                  marginTop={20}
                  title={locales_es.removeDate}
                  style={styles.buttonDate}
                  onPress={removeDate}
                  titleColor={Colors.Red}
                />
              ) : null}
              {dates &&
              dates.length &&
              dates.length < 12 &&
              !prescriptionRequestId ? (
                <ButtonText
                  marginTop={20}
                  title={locales_es.addDate}
                  style={styles.buttonDate}
                  onPress={addNewDate}
                />
              ) : null}
            </View>
          </Layout>

          <TouchableOpacity onPress={selectPatient}>
            {patientId && userData ? (
              <UserInfo user={userData}/>
            ) : (
              <SelectPatient
                style={{
                  borderWidth: 1,
                  borderColor: errors.includes('patientId')
                    ? theme.lineErrorColor
                    : theme.innearColor,
                }}
              />
            )}
          </TouchableOpacity>

          <>
            {!medicines.length ? (
              <Text size={15} lineHeight={18} center marginTop={32}>
                Aún no posee medicamentos seleccionados para esta receta.
              </Text>
            ) : medicines.length >= 3 ? (
              <Text size={15} lineHeight={18} center marginTop={32}>
                No puede añadir más medicamentos a esta receta.
              </Text>
            ) : null}
            <ButtonLinear
              white
              styleButton={{flex: 1}}
              title={locales_es.addMedicine}
              onPress={goToSearchMedicines}
              disabled={medicines.length >= 3}
            />
          </>

          <>
            {medicines && medicines.length ? (
              <>
                <Layout
                  style={{
                    marginTop: 24,
                    paddingTop: 16,
                    borderRadius: 16,
                    ...Theme.shadow,
                  }}>
                  <View
                    style={{
                      ...Theme.flexRow,
                      paddingHorizontal: 24,
                      paddingBottom: 16,
                    }}>
                    <ButtonIcon
                      icon={'medication'}
                      style={{
                        width: 32,
                        height: 32,
                        backgroundColor: Colors.TealBlue20,
                      }}
                      imgStyle={styles.buttonIconImgStyle}
                      tintColor={Colors.TealBlue}
                      disabled
                    />
                    <Text size={15} lineHeight={18} bold marginLeft={16}>
                      {locales_es.medicines}
                    </Text>
                  </View>
                  {medicines.map(renderItem)}
                </Layout>
              </>
            ) : (
              <>
                <Text size={15} lineHeight={18} center marginTop={32}>
                  {locales_es.noMedicines}.
                </Text>
                <Text size={15} lineHeight={18} center marginTop={32} bold>
                  {locales_es.tapAddMedicineToBegin}.
                </Text>
              </>
            )}

            {(medicines && medicines.length) ||
            diagnostic ||
            indications ||
            comments ? (
              <>
                <Layout
                  style={{
                    marginTop: 24,
                    paddingTop: 16,
                    borderRadius: 16,
                    ...Theme.shadow,
                  }}>
                  <View
                    style={{
                      ...Theme.flexRow,
                      paddingHorizontal: 24,
                      paddingBottom: 16,
                    }}>
                    <ButtonIcon
                      icon={'additionalInformation'}
                      style={{
                        width: 32,
                        height: 32,
                        backgroundColor: Colors.TealBlue20,
                      }}
                      imgStyle={styles.buttonIconImgStyle}
                      tintColor={Colors.TealBlue}
                      disabled
                    />
                    <Text size={15} lineHeight={18} bold marginLeft={16}>
                      {locales_es.otherConfigurations}
                    </Text>
                  </View>
                  <View
                    style={{
                      flex: 1,
                      paddingHorizontal: 12,
                      paddingRight: 24,
                    }}>
                    <AccountItem
                      style={styles.switchExtended}
                      name={locales_es.extendedTreatment}
                      isToggle={true}
                      onPress={() => setExtendedTreatment(!extendedTreatment)}
                      // onValueChange={() => setExtendedTreatment(!extendedTreatment)}
                      switchValue={extendedTreatment}
                    />
                  </View>
                  <View
                    style={{
                      flex: 1,
                      paddingHorizontal: 12,
                      paddingRight: 24,
                    }}>
                    <AccountItem
                      style={styles.switchExtended}
                      name={locales_es.hivPatient}
                      isToggle={true}
                      onPress={() => setHiv(!hiv)}
                      // onValueChange={() => setHiv(!hiv)}
                      switchValue={hiv}
                    />
                  </View>
                </Layout>
                <Layout
                  style={{
                    marginTop: 24,
                    paddingTop: 16,
                    borderRadius: 16,
                    ...Theme.shadow,
                  }}>
                  <DiagnosticAutocompleteItem 
                    diagnosticIn={diagnostic}
                    diagnosticCodIn={diagnosticCod}
                    errorsIn={errors}
                    buttonIconFlag={true}
                    tagIn={'prescription-medicine'}
                    paddingRightFlagIn={true}
                    onPress={(item) => onPressSearchResult(item, -1)}
                    onChange={(text) => setDiagnostic(text)}
                    />
                </Layout>
                <Layout
                  style={{
                    marginTop: 24,
                    paddingTop: 16,
                    borderRadius: 16,
                    ...Theme.shadow,
                  }}>
                  <View
                    style={{
                      ...Theme.flexRow,
                      paddingHorizontal: 24,
                      paddingBottom: 16,
                    }}>
                    <ButtonIcon
                      icon={'hospitalBed'}
                      style={{
                        width: 32,
                        height: 32,
                        backgroundColor: Colors.TealBlue20,
                      }}
                      imgStyle={styles.buttonIconImgStyle}
                      tintColor={Colors.TealBlue}
                      disabled
                    />
                    <Text size={15} lineHeight={18} bold marginLeft={16}>
                      {locales_es.indications}
                    </Text>
                  </View>
                  <View
                    style={{
                      ...Theme.flexRow,
                      paddingHorizontal: 24,
                      paddingBottom: 16,
                    }}>
                    <TextInput
                      placeholderTextColor={theme.placeholder}
                      value={indications}
                      placeholder={locales_es.addPrescriptionIndications}
                      style={{
                        fontFamily: 'Muli_400Regular',
                        fontSize: 15,
                        lineHeight: 18,
                        color: theme.text,
                        height: scale(160),
                        marginTop: 12,
                        flex: 1,
                        borderWidth: 1,
                        borderColor: errors.includes('indications')
                          ? theme.lineErrorColor
                          : theme.innearColor,
                      }}
                      onChangeText={setIndications}
                      multiline={true}
                      editable={true}
                      onBlur={() => onBlur("indications")}
                    />
                  </View>
                </Layout>
                <Layout
                  style={{
                    marginTop: 24,
                    paddingTop: 16,
                    borderRadius: 16,
                    ...Theme.shadow,
                  }}>
                  <View
                    style={{
                      ...Theme.flexRow,
                      paddingHorizontal: 24,
                      paddingBottom: 16,
                    }}>
                    <ButtonIcon
                      icon={'pencil'}
                      style={{
                        width: 32,
                        height: 32,
                        backgroundColor: Colors.TealBlue20,
                      }}
                      tintColor={Colors.TealBlue}
                      disabled
                    />
                    <Text size={15} lineHeight={18} bold marginLeft={16}>
                      Texto adicional (Libre y opcional)
                    </Text>
                  </View>
                  <View
                    style={{
                      ...Theme.flexRow,
                      paddingHorizontal: 24,
                      paddingBottom: 16,
                    }}>
                    <TextInput
                      placeholderTextColor={theme.placeholder}
                      value={comments}
                      placeholder={locales_es.addPrescriptionComments}
                      style={{
                        fontFamily: 'Muli_400Regular',
                        fontSize: 15,
                        lineHeight: 18,
                        color: theme.text,
                        height: scale(160),
                        marginTop: 12,
                        flex: 1,
                      }}
                      onChangeText={setComments}
                      multiline={true}
                      editable={true}
                    />
                  </View>
                </Layout>
                <ButtonLinear
                  white
                  styleButton={{flex: 1}}
                  title={
                    dates && dates.length && dates.length > 1
                      ? locales_es.createPrescriptions
                      : locales_es.createPrescription
                  }
                  onPress={onSend}
                />
              </>
            ) : null}
          </>

          {prescriptionError ? (
            <Modal
              visible={true}
              transparent={true}
              animationType={'slide'}>
              <ScrollView
                style={{
                  flex: 1,
                  backgroundColor: '#fff',
                }}
                contentContainerStyle={{
                  flex: 1,
                  justifyContent: 'center',
                  alignItems: 'center',
                }}>
                <View
                  style={{
                    width: '100%',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}>
                  <Image
                    source={iconWarning}
                    style={styles.successImage}
                  />
                </View>
                <View style={{justifyContent: 'center', alignItems: 'center'}}>
                  <Text
                    center={true}
                    color={Colors.DarkJungleGreen}
                    style={[{lineHeight: 28}]}
                    size={24}>
                    Hay un problema con su prescripción
                  </Text>
                  <Text
                    color={Colors.DarkJungleGreen}
                    style={{
                      textAlign: 'center',
                      paddingHorizontal: 60,
                      lineHeight: 22,
                    }}
                    marginVertical={20}
                    size={16}>
                    {prescriptionError}
                  </Text>
                  <ButtonLinear
                    style={{paddingHorizontal: 12}}
                    white
                    title="Revisar y corregir datos del afiliado"
                    onPress={correctPatientNoInsurance}
                  />
                  <ButtonBorder
                    style={{paddingHorizontal: 12, marginTop: 12}}
                    title="Realizar receta sin cobertura"
                    onPress={sendWithNoInsurance}
                  />
                  <Text
                    marginTop={20}
                    blueLight
                    type="H6"
                    color={Colors.BlueCrayola}
                    semibold
                    onPress={() => setPrescriptionError(false)}>
                    {locales_es.close}
                  </Text>
                </View>
              </ScrollView>
            </Modal>
          ) : null}
        </Content>
      </Container>
    </>
  );
};

export default AddPrescription;

const styles = StyleSheet.create({
  header: {
    ...Theme.flexRowSpace,
  },
  container: {
    ...Theme.flexOne,
  },
  contentContainerStyle: {
    paddingHorizontal: 24,
    // paddingTop: 40,
    paddingTop: 20,
    paddingBottom: getBottomSpace() + 120,
  },
  buttonText: {
    borderWidth: 0,
    height: 50,
  },
  successImage: {
    width: scale(65, true),
    height: scale(65, true),
    marginBottom: scale(20, true),
  },
  switch: {
    ...Theme.flexRowSpace,
    paddingTop: 16,
    paddingBottom: 24,
    left: 10,
    paddingRight: 20,
  },
  switchIconStyle: {
    tintColor: Colors.DodgerBlue,
    height: 20,
    maxHeight: 20,
  },
  switchExtended: {
    ...Theme.flexRowSpace,
    paddingTop: 16,
    paddingBottom: 24,
  },
  lineThrough: {
    textDecorationLine: 'line-through',
    textDecorationStyle: 'solid',
  },
  underline: {
    textDecorationLine: 'underline',
    textDecorationStyle: 'solid',
  },
  buttonDate: {
    borderWidth: 0,
    marginVertical: 10,
  },
  section: {
    marginTop: 0,
    marginVertical: 24,
  },
  sectionError: {
    borderWidth: 1,
    borderColor: 'red',
    borderStyle: 'solid',
    borderRadius: 16,
  },
  buttonIconImgStyle: {
    width: 24,
    height: 24,
  }
});
